import React from 'react';
// import { TextField, Autocomplete, Chip } from '@mui/material';
import { useQuery } from "@apollo/client";
import { VALUE_SETS } from '../../constants/gqls/dataValues';

//******************************************************************************
// component definition
//******************************************************************************
export default function DataSetsQuery({where, setDataSets}) {
  const { data } = useQuery(VALUE_SETS, { variables: { where }});
  
  React.useEffect(() => {
    // console.log(data);
    if (data?.valueSets) {
      setDataSets(data.valueSets);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  //----------------------------------------------------------------------------
  // component rendering
  //----------------------------------------------------------------------------
  return (
    <div />
  );
}

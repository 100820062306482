import React from 'react';
import { Grid, TextField, IconButton, FormControl, InputLabel, Select, MenuItem } from '@mui/material';
import ClearIcon from '@mui/icons-material/Clear';
import { useMutation } from "@apollo/client";
import { UPDATE_TEMPLATE_VAR } from '../../constants/gqls/templateVars';

const typeOptions = [
  'data',
  'currentDate',
  'issuedDate'
];

//******************************************************************************
// component definition
//******************************************************************************
export default function TemplateVariableItem({variable, deleteVar, loading}) {
  const [changed, setChanged] = React.useState(false);
  const [key, setKey] = React.useState(variable.key || '');
  const [valueType, setValueType] = React.useState(variable.valueType);
  const [format, setFormat] = React.useState(variable.format || '');
  const [updateVar, { loading: updateLoading }] = useMutation(UPDATE_TEMPLATE_VAR);

  //----------------------------------------------------------------------------
  // funtions
  //----------------------------------------------------------------------------
  const handleKeyChange = (event) => {
    setKey(event.target.value);
    setChanged(true);
  };

  const handleValueTypeChange = (event) => {
    updateVar({ variables: {
      where: { _id: variable._id },
      update: { 
        valueType: event.target.value
      }
    }}).then(res=>{
      // console.log(res);
      setValueType(res.data.updateTemplateVars.templateVars[0].valueType)
    });
  };

  const handleFomateChange = (event) => {
    setFormat(event.target.value);
    setChanged(true);
  };

  const handleUpdate = () => {
    if (changed) {
      updateVar({ variables: {
        where: { _id: variable._id },
        update: { key, format }
      }}).then(res=>{
        // console.log(res);
        setChanged(false);
      });
    }
  };

  const handleDelete = () => {
    deleteVar(variable._id);
  }

  //----------------------------------------------------------------------------
  // component rendering
  //----------------------------------------------------------------------------
  return (
    <React.Fragment>
      <Grid item xs={4}>
        <TextField fullWidth size="small" variant="outlined" label="Key"
          value={key} disabled={loading || updateLoading}
          onChange={handleKeyChange} onBlur={handleUpdate} />
      </Grid>
      <Grid item xs={3}>
        <FormControl fullWidth size='small' 
          disabled={loading ||updateLoading}>
          <InputLabel>Value type</InputLabel>
          <Select
            value={valueType}
            label="Value type"
            onChange={handleValueTypeChange}
          >
            {typeOptions.map(opt=>
              <MenuItem key={opt} value={opt}>{opt}</MenuItem>
            )}
          </Select>
        </FormControl>
      </Grid>
      <Grid item xs={4}>
        <TextField fullWidth size="small" variant="outlined" label="Value format"
          value={format} disabled={loading || updateLoading}
          onChange={handleFomateChange} onBlur={handleUpdate} />
      </Grid>
      <Grid item xs={1}>
        <IconButton aria-label="delete" onClick={handleDelete}>
          <ClearIcon />
        </IconButton>
      </Grid>
    </React.Fragment>
  );
}

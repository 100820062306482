import React from 'react';
import { Container, Paper, Tabs, Tab, CardContent } from '@mui/material';
import { makeStyles } from '@material-ui/core/styles';
import UsersList from '../components/AdminUsers/UsersList';
import ImportInputs from '../components/AdminUsers/ImportInputs';
import UsersListFilter from '../components/AdminUsers/UsersListFilter';

//******************************************************************************
// style definition
//******************************************************************************
const useStyles = makeStyles((theme) => ({
  container: {
    paddingTop: theme.spacing(3)
  },
}));

const tabs = [{
  label: 'Request Users',
  where: { request: true }
}, {
  label: 'Activated Users',
  where: { active: true }
}, {
  label: 'Passive Users',
  where: { active: false },
}, {
  label: 'Import'
}]

//******************************************************************************
// component definition
//******************************************************************************
export default function AdminUsers() {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);
  const [where, setWhere] = React.useState(null);

  //----------------------------------------------------------------------------
  // funtions
  //----------------------------------------------------------------------------
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  //----------------------------------------------------------------------------
  // component rendering
  //----------------------------------------------------------------------------
  return (
    <Container maxWidth="xl" className={classes.container}>
      <Paper>
        <Tabs value={value} onChange={handleChange}>
          { tabs.map((tab, i)=>(
            <Tab key={i} label={tab.label}/>
          )) }
        </Tabs>

        { tabs[value].label !== 'Import' &&
          <>
            <CardContent>
              <UsersListFilter setWhere={setWhere} tabWhere={tabs[value].where} />
            </CardContent>
            <UsersList where={where} />
          </>
        }
        { tabs[value].label === 'Import' &&
          <ImportInputs />
        }
      </Paper>
    </Container>
  );
}

import React from 'react';
import { TextField, Autocomplete } from '@mui/material';
// import AccountCircleIcon from '@mui/icons-material/AccountCircle';

const options = ['serviceSessions', 'CSV file'];

//******************************************************************************
// component definition
//******************************************************************************
export default function ReportTypeSelect({value, onChange, setWhereType, setOptionsType, disabled}) {
  const [inputValue, setInputValue] = React.useState('');
  // console.log(value);


  //----------------------------------------------------------------------------
  // funtions
  //----------------------------------------------------------------------------
  const onValueChange = (event, newValue) => {
    // console.log(newValue);
    onChange(newValue);
    switch (newValue) {
      case 'serviceSessions':
        setWhereType('ServiceSessionWhere');
        setOptionsType('ServiceSessionOptions');
        break;
    
      default:
        setWhereType(null);
        setOptionsType(null);
        break;
    }
  }

  const onInputChange = (event, newValue) => {
    setInputValue(newValue);
  }

  //----------------------------------------------------------------------------
  // component rendering
  //----------------------------------------------------------------------------
  return (
    <Autocomplete disabled={disabled}
      value={value} onChange={onValueChange}
      inputValue={inputValue} onInputChange={onInputChange}
      options={options}
      renderInput={(params) => <TextField {...params}
        label="Report type"
        variant="standard"
      />}
    />
  );
}

import React from 'react';
import { IconButton, Tooltip } from '@mui/material';
import FolderSharedIcon from '@mui/icons-material/FolderShared';
import ShareDialog from '../DocumentShare/ShareDialog';

//******************************************************************************
// component definition
//******************************************************************************
export default function ShareBtn({doc, setPrevent}) {
  const [open, setOpen] = React.useState(false);

  //----------------------------------------------------------------------------
  // funtions
  //----------------------------------------------------------------------------
  const handleOpen = () => {
    setOpen(true);
    setPrevent(true);
  };

  const handleClose = () => {
    setOpen(false);
    setPrevent(false);
  };

  //----------------------------------------------------------------------------
  // component rendering
  //----------------------------------------------------------------------------
  return (
    <React.Fragment>
      <Tooltip title="Manage document sharing">
        <IconButton onClick={handleOpen}>
          <FolderSharedIcon />
        </IconButton>
      </Tooltip>
      <ShareDialog doc={doc} open={open} handleClose={handleClose} />
    </React.Fragment>
  )
}

import React from 'react';
import { Button, IconButton, CardActions } from '@mui/material';
import LoginIcon from '@mui/icons-material/Login';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { makeStyles } from '@material-ui/core/styles';

function getLoginUrl(state, others) {
  let url = others? process.env.REACT_APP_SSOID_AUTH_URL_OTHER : process.env.REACT_APP_SSOID_AUTH_URL;
  let params = new URLSearchParams({
    response_type: 'code',
    client_id: process.env.REACT_APP_SSOID_CLIENT_ID,
    redirect_uri: process.env.REACT_APP_SSOID_REDIRECT_URL,
    scope: 'get_user_portfolio',
    state
  });
  return url +'?'+ params.toString();
}

//******************************************************************************
// style definition
//******************************************************************************
const useStyles = makeStyles((theme) => ({
  root: {
    '& .MuiButton-root': {
      margin: 'auto'
    }
  }
}));

//******************************************************************************
// component definition
//******************************************************************************
export default function SSOidLoginBtn({ state }) {
  const classes = useStyles();
  const loginUrl = getLoginUrl(state);
  const loginUrlOthers = getLoginUrl(state, true);

  //----------------------------------------------------------------------------
  // component rendering
  //----------------------------------------------------------------------------
  return (
    <CardActions className={classes.root}>
      <Button variant="contained" color="secondary"
        startIcon={<LoginIcon />}
        href={loginUrl}>
        Login
      </Button>
      <IconButton size="small" href={loginUrlOthers}>
        <MoreVertIcon fontSize="inherit" />
      </IconButton>
    </CardActions>
  );
}

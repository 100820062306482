import { gql } from '@apollo/client';
import { personSum } from './fragments';


export const SERVICE_SESSION_ATTENDANCES = gql`
  query ServiceSessionAttendances($serviceSessionId: ID!) {
    serviceSessionAttendances(serviceSessionId: $serviceSessionId) {
      _id time submitted note privateNote hours date deleteable
      participants { ${personSum} }
      sPrivateInfo { _id comment student { _id name }}
    }
  }
`;

export const CREATE_ATTENDANCE = gql`
  mutation CreateAttendance($serviceSessionId: ID!) {
    createAttendance(serviceSessionId: $serviceSessionId) {
      _id
    }
  }
`;

export const UPDATE_ATTENDANCE = gql`
  mutation UpdateAttendance($id: ID!, $inUpdate: AttendanceUpdateInput!) {
    updateAttendance(id: $id, inUpdate: $inUpdate) {
      attendances {
        _id time submitted note privateNote date hours
        participants { ${personSum} }
        sPrivateInfo { _id comment student { _id name }}
      }
    }
  }
`;

export const DELETE_ATTENDANCE = gql`
  mutation DeleteAttendance($id: ID!) {
    deleteAttendance(id: $id)
  }
`;

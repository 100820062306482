import React from 'react';
import { CSVLink } from "react-csv";
import Button from '@mui/material/Button';
import { makeStyles } from '@material-ui/core/styles';
import ProfileKeysSelector from './ProfileKeysSelector';

//******************************************************************************
// style definition
//******************************************************************************
const useStyles = makeStyles((theme) => ({
  link: {
    textDecoration: 'none',
    cursor: 'auto'
  },
}));

//******************************************************************************
// component definition
//******************************************************************************
function TemplateGenerator() {
  const classes = useStyles();
  const [selected, setSelected] = React.useState([]);
  const [error, setError] = React.useState(false);
  const [sample, setSample] = React.useState([]);
  // console.log(sample);

  React.useEffect(() => {
    let obj = null;
    if (!error && selected.length !== 0) {
      obj = {email: 'value'};
      if (selected[0].valueFields.length === 0) {
        selected.forEach(pk=>{
          obj[pk.key] = 'value';
        })
      } else {
        selected[0].valueFields.forEach(vk=>{
          obj[`${selected[0].key}.${vk.name}`] = 'value';
        })
      }
    }
    setSample(obj? [obj] : []);
  }, [selected, error]);


  //----------------------------------------------------------------------------
  // funtions
  //----------------------------------------------------------------------------
  const handleDownload = () => {
    // console.log("You click the link");
    // console.log(sample.length === 0);
    if (sample.length === 0) {
      return false;
    }
  }

  //----------------------------------------------------------------------------
  // component rendering
  //----------------------------------------------------------------------------
  return (
    <div>
      <ProfileKeysSelector 
        selected={selected} setSelected={setSelected}
        error={error} setError={setError} />
      <CSVLink uFEFF={false} className={classes.link}
        data={sample} 
        filename="profile-data-template.csv"
        onClick={handleDownload} >
        <Button variant="outlined" disabled={sample.length === 0}>
          Download template
        </Button>
      </CSVLink>
    </div>
  );
}

export default TemplateGenerator;

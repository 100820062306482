import { gql } from '@apollo/client';


export const GET_STUDENT_PRIVATE_INFO = gql`
  query GetStudentPrivateInfo($studentId: ID!, $refId: ID!) {
    getStudentPrivateInfo(studentId: $studentId, refId: $refId) {
      _id confirmed preTestResult postTestResult comment myRole 
    }
  }
`;

export const UPDATE_STUDENT_PRIVATE_INFO = gql`
  mutation UpdateStudentPrivateInfo($studentId: ID!, $type: String!, $refId: ID!, $input: StudentPrivateInfoUpdateInput!) {
    updateStudentPrivateInfo(studentId: $studentId, type: $type, refId: $refId, input: $input) {
      studentPrivateInfos {
        _id confirmed preTestResult postTestResult comment
      }
    }
  }
`;


import React from 'react';
import { Button, Dialog, DialogTitle,
  DialogContent, DialogContentText, DialogActions } from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import { useMutation, useQuery } from '@apollo/client';
import { CLEAR_PASSIVE_USERS, COUNT_PASSIVE_USERS } from '../../constants/gqls/user';
  

//******************************************************************************
// component definition
//******************************************************************************
export default function ClearUsersBtn() {
  const [open, setOpen] = React.useState(false);
  const { data } = useQuery(COUNT_PASSIVE_USERS);
  const [clearPassiveUsers, { loading }] = useMutation(CLEAR_PASSIVE_USERS, {
    refetchQueries: [ COUNT_PASSIVE_USERS ],
  });

  //----------------------------------------------------------------------------
  // funtions
  //----------------------------------------------------------------------------
  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleClear = () => {
    clearPassiveUsers().then(res=>{
      // console.log(res);
      setOpen(false);
    });
  }

  //----------------------------------------------------------------------------
  // component rendering
  //----------------------------------------------------------------------------
  return (
    <>
      <Button onClick={handleOpen}>
        Clear passive users
      </Button>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>
          Confirm clear?
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            You are going to clear all passive users who has not login to this system befor.
            <br />Total: {data? data.countPassiveUsers : '...'}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <LoadingButton variant="outlined"
            loading={loading}
            onClick={handleClear}>
            Clear
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </>
  );
}

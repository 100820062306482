import React from 'react';
import { CardContent } from '@mui/material';
import ProfileKeysList from './ProfileKeysList';
import SearchInput from '../InputsCommt/SearchInput';

//******************************************************************************
// component definition
//******************************************************************************
export default function ProfileKeyTab() {
  const [search, setSearch] = React.useState('');

  //----------------------------------------------------------------------------
  // funtions
  //----------------------------------------------------------------------------
  const onChange = (value) => {
    setSearch(value)
  }

  //----------------------------------------------------------------------------
  // component rendering
  //----------------------------------------------------------------------------
  return (
    <div>
      <CardContent>
        <SearchInput value={search} onChange={onChange} />
      </CardContent>
      <ProfileKeysList where={{ OR: [
        { key_MATCHES: `(?i).*${search}.*`},
        { attribute_MATCHES: `(?i).*${search}.*`}
      ]}} />
    </div>
  );
}

import React from 'react';
import { generatePath } from "react-router-dom";
import { CardHeader, CardContent, Typography } from '@mui/material';
import HistoryEduIcon from '@mui/icons-material/HistoryEdu';
// import { useQuery } from "@apollo/client";
import { makeStyles } from '@material-ui/core/styles';
import CardComm from './CardComm';
// import { DOCUMENT_PREV } from '../../constants/gqls/documents';
import SiteMap from '../../constants/siteMap';

//******************************************************************************
// style definition
//******************************************************************************
const useStyles = makeStyles((theme) => ({
  content: {
    "&.MuiCardContent-root": {
      paddingTop: 0
    }
  }
}));

//******************************************************************************
// component definition
//******************************************************************************
export default function DocumentCard({doc, setSignal}) {
  const classes = useStyles();
  // const { loading, data } = useQuery(DOCUMENT_PREV, { variables: { docId }});
  const goTo = generatePath(SiteMap.document, { id: doc._id });
  // console.log(docId);

  React.useEffect(() => {
    if (setSignal) {
      setSignal(`${doc._id} loaded`);
    }
  }, [doc, setSignal]);

  //----------------------------------------------------------------------------
  // component rendering
  //----------------------------------------------------------------------------
  // if (loading) return <LinearProgress />;
  // if (!data) return 'Network error.'

  return (
    <CardComm tags={doc.tags}
      icon={<HistoryEduIcon />} goTo={goTo}>
      <CardHeader title={doc.title} />
      <CardContent className={classes.content}>
        <Typography>
          {doc.description}
        </Typography>
      </CardContent>
    </CardComm>
  );
}

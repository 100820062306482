import React from 'react';
import { generatePath } from "react-router-dom";
import { Grid, Button, Paper, CardHeader, CardContent } from '@mui/material';
import { makeStyles } from '@material-ui/core/styles';
import SiteMap from '../../constants/siteMap';

//******************************************************************************
// style definition
//******************************************************************************
const useStyles = makeStyles((theme) => ({
  paper: {
    borderTop: '1px dashed '+theme.palette.primary.light
  },
}));

//******************************************************************************
// component definition
//******************************************************************************
export default function ServiceYears({service}) {
  const classes = useStyles();
  // console.log(service);

  //----------------------------------------------------------------------------
  // funtions
  //----------------------------------------------------------------------------

  //----------------------------------------------------------------------------
  // component rendering
  //----------------------------------------------------------------------------
  return (
    <Paper className={classes.paper}>
      <CardHeader title="Academic Years" />
      <CardContent>
        <Grid container spacing={2}>
          { service.serviceSessionsYears.map(item=>(
            <Grid item xs="auto" key={item}>
              <Button variant="outlined" href={generatePath(SiteMap.serviceSessionsData, {
                id: service._id,
                year: item
              })}>
                {item}
              </Button>
            </Grid>
          ))}
        </Grid>
      </CardContent>
    </Paper>

  )
}

import React from 'react';
import { List, LinearProgress } from '@mui/material';
// import { useQuery } from "@apollo/client";
import ActionItem from './ActionItem';
// import { DOCUMENT_ACTIONS } from '../../constants/gqls/actions';

//******************************************************************************
// component definition
//******************************************************************************
export default function ActionsList({actionsQuery, scope}) {
  const { data, loading } = actionsQuery
  const actions = data?.documentActions || data?.serviceActions || data?.certTypeActions

  //----------------------------------------------------------------------------
  // funtions
  //----------------------------------------------------------------------------

  //----------------------------------------------------------------------------
  // component rendering
  //----------------------------------------------------------------------------
  if (loading) return <LinearProgress />;
  if (!data) return 'Network error.';
  if (actions.length === 0) return '';

  return (
    <List>
      { actions.map(action=>(
        <ActionItem key={action._id} action={action} scope={scope} />
      )) }
    </List>
  );
}

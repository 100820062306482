import React from 'react';
import { Button } from '@mui/material';
import InputValueSettingDialog from './InputValueSettingDialog';

//******************************************************************************
// component definition
//******************************************************************************
export default function InputValueSettingBtn({inputId, valueFields}) {
  const [open, setOpen] = React.useState(false);
  // console.log(importFormId);

  //----------------------------------------------------------------------------
  // funtions
  //----------------------------------------------------------------------------
  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  //----------------------------------------------------------------------------
  // component rendering
  //----------------------------------------------------------------------------
  return (
    <React.Fragment>
      <Button onClick={handleOpen}>Value Fields</Button>
      <InputValueSettingDialog open={open} handleClose={handleClose}
        inputId={inputId} valueFields={[...valueFields].sort((a, b)=>a.index-b.index)} />
    </React.Fragment>
  )
}

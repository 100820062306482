import React from 'react';
import { useNavigate } from "react-router-dom";
import { Button, Dialog, DialogTitle, DialogContentText, DialogContent, DialogActions } from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
// import { makeStyles } from '@material-ui/core/styles';
import { useMutation } from "@apollo/client";
import { DELETE_DATA_TABLE } from '../../constants/gqls/dataAnalysis';

//******************************************************************************
// component definition
//******************************************************************************
export default function DeleteBtn({dataTableId}) {
  const navigate = useNavigate();
  const [open, setOpen] = React.useState(false);
  const [deleteTable, { loading }] = useMutation(DELETE_DATA_TABLE);

  //----------------------------------------------------------------------------
  // funtions
  //----------------------------------------------------------------------------
  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const onDeleteClick = () => {
    deleteTable({ variables: {
      where: { _id: dataTableId },
      delete: { dataTableColumns: [{}]}
    }}).then(res=>{
      // goto
      navigate(-1, { replace: true });
    });
  }

  //----------------------------------------------------------------------------
  // component rendering
  //----------------------------------------------------------------------------
  return (
    <>
      <Button variant="outlined" onClick={handleOpen}>
        Delete
      </Button>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>
          Confirm delete?
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            It's sugguested to export the JSON data before removing the table. Click "Delete" button to remove this table.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <LoadingButton variant="outlined"
            loading={loading} onClick={onDeleteClick}>
            Delete
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </>
  )
}

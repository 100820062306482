import React from 'react';
import { TextField, Autocomplete, Chip } from '@mui/material';
import { useQuery } from "@apollo/client";
import { DOCUMENT_SEARCH } from '../../constants/gqls/documents';

//******************************************************************************
// component definition
//******************************************************************************
/**
 * @typedef {Object} DocumentInputProps
 * @property {object} value - value.
 * @property {boolean} disabled - is disabled.
 * @property {function} onChange - Handle value change.
 * @property {string} label - input label.
 * @property {boolean} multiple - is multiple.
 */

/**
 * Document input component.
 * @param {DocumentInputProps} props - propertis
 */
export default function DocumentInput({value, onChange, label, multiple, disabled}) {
  const [inputValue, setInputValue] = React.useState(value?
    `${value.name} (${value.email || "Space"})` : ''
  );

  const { data } = useQuery(DOCUMENT_SEARCH, { variables: {
    search: inputValue
  } });
  // console.log(variables);
  // console.log(data);


  //----------------------------------------------------------------------------
  // funtions
  //----------------------------------------------------------------------------
  const onValueChange = (event, newValue) => {
    // console.log(newValue);
    onChange(newValue);
  }

  const onInputChange = (event, newValue) => {
    setInputValue(newValue);
  }

  //----------------------------------------------------------------------------
  // component rendering
  //----------------------------------------------------------------------------
  return (
    <Autocomplete multiple={multiple} disabled={disabled}
      value={value} onChange={onValueChange}
      inputValue={inputValue} onInputChange={onInputChange}
      getOptionLabel={option=>option.title}
      options={ data ? data.documentSearch : [] }
      isOptionEqualToValue={(option, value)=>option._id===value._id}
      renderInput={(params) => <TextField {...params}
        label={label}
        variant="standard"
      />}
      renderTags={(value, getTagProps) =>
        value.map((option, index) => (
          <Chip variant="outlined" label={option.title}
            {...getTagProps({ index })} />
        ))
      }
    />
  );
}

import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Dialog, DialogContent, DialogTitle, DialogContentText, FormControl, InputLabel, MenuItem, Select, FormGroup, FormControlLabel, Checkbox, Tabs, Tab } from '@mui/material';
import { useQuery } from "@apollo/client";
import DefaultFieldValues from './DefaultFieldValues';
import { VALUE_SET_INPUTS } from '../../../constants/gqls/items';

//******************************************************************************
// style definition
//******************************************************************************
const useStyles = makeStyles((theme) => ({
  dialog: {
    '& .MuiPaper-root': {
      width: '600px'
    },
  }
}));

//******************************************************************************
// component definition
//******************************************************************************
export default function ValueSetInputDialog({type, valueSetInput, displayValueFields, defaultFieldValues, updateInput, open, handleClose, loading}) {
  const classes = useStyles();
  const [tabValue, setTabValue] = React.useState(0);
  const [options, setOptions] = React.useState([]);
  const [valueFields, setValueFields] = React.useState([]);
  // const [defaultFieldValues, setDefaultFieldValues] = React.useState('');
  const { data } = useQuery(VALUE_SET_INPUTS);

  React.useEffect(() => {
    if (data?.valueSetInputs) {
      setOptions(data.valueSetInputs);
    } else {
      setOptions([]);
    }
  }, [data]);

  React.useEffect(() => {
    if (valueSetInput) {
      let tempValueFields = [...valueSetInput.valueFields];
      tempValueFields.sort((a, b) => a.index - b.index);
      setValueFields(tempValueFields);
      // setValueFields(valueSetInput.valueFields);
    } else {
      setValueFields([]);
    }
  }, [valueSetInput]);

  //----------------------------------------------------------------------------
  // funtions
  //----------------------------------------------------------------------------
  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const handleValueSetInputChange = (event) => {
    //TODO: do not allow update if data is selected
    updateInput({
      type,
      valueSetInput: { 
        connect: { where: {
          node: { _id: event.target.value}
        }},
        disconnect: { where: {
          node: { _id_NOT: event.target.value}
        }}
      }
    }).then(res=>{
      console.log(res);
      handleClose();
    });
  };

  const handleDisplayValueFieldsChange = (event) => {
    let tempValue = [...displayValueFields];
    if (event.target.checked) {
      tempValue = [...tempValue, event.target.value];
    } else {
      tempValue = tempValue.filter(vf=>vf!==event.target.value);
    }
    let newValue = valueSetInput.valueFields.filter(vf=>tempValue.includes(vf._id)).map(vf=>vf._id);
    console.log(tempValue);
    updateInput({
      displayValueFields: newValue
    }).then(res=>{
      // console.log(res);
      // handleClose();
    });
  };

  const handleDefaultFieldValuesChange = (newValue) => {
    updateInput({
      defaultFieldValues: newValue
    }).then(res=>{
      console.log(res);
      // handleClose();
    });
  }

  //----------------------------------------------------------------------------
  // component rendering
  //----------------------------------------------------------------------------
  return (
    <Dialog open={open} onClose={handleClose} className={classes.dialog}>
      <DialogTitle>Select profile item</DialogTitle>
      <Tabs value={tabValue} onChange={handleTabChange}>
        <Tab label="Profile item"/>
        <Tab label="Default values" disabled={!valueSetInput} />
        <Tab label="Display fields" disabled={!(valueSetInput && displayValueFields)} />
      </Tabs>
      <DialogContent>
        { tabValue === 0 &&
          <>
            <DialogContentText>
              Define which profile item user is going to select from
            </DialogContentText>
            <FormControl fullWidth disabled={loading}>
              <InputLabel>Select profile input</InputLabel>
              <Select label="Select profile input"
                value={valueSetInput?._id || ''} 
                onChange={handleValueSetInputChange}
              >
                { options.map(opt=>(
                  <MenuItem key={opt._id} value={opt._id}>{opt.profileKey?.key || 'No key'}</MenuItem>
                ))}
              </Select>
            </FormControl>
          </>
        }
        { tabValue === 1 && 
          <DefaultFieldValues 
            valueFields={valueFields}
            defaultFieldValues={defaultFieldValues}
            onChange={handleDefaultFieldValuesChange}
            loading={loading} />
        }
        { tabValue === 2 && 
          <>
            <DialogContentText>Display fields</DialogContentText>
            <FormGroup>
              { valueFields.map((field, index)=>(
                <FormControlLabel key={index} control={
                  <Checkbox value={field._id}
                    checked={displayValueFields.includes(field._id)} 
                    onChange={handleDisplayValueFieldsChange} />
                } label={field.name} />
              ))}
            </FormGroup>
          </>
        }
      </DialogContent>
    </Dialog>
  )
}

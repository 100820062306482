import React from 'react';
import { Stack } from '@mui/material';
import { makeStyles } from '@material-ui/core/styles';
import { useMutation } from "@apollo/client";
import AddBtn from './AddBtn';
import Tag from '../Tag';
import { TAGS_FOR_ADD, DETAG_OBJECT } from '../../constants/gqls/tags';

//******************************************************************************
// style definition
//******************************************************************************
const useStyles = makeStyles((theme) => ({
  tags: {
    color: theme.palette.text.secondary,
    fontSize: '0.875rem',
    "& button": {
      margin: '0 6px 0 0'
    }
  }
}));

//******************************************************************************
// component definition
//******************************************************************************
export default function TagsShowcase({tags, objId, emptyLabel}) {
  const classes = useStyles();
  const [deTagObject, { loading }] = useMutation(DETAG_OBJECT, {
    refetchQueries: [ TAGS_FOR_ADD ],
  });
  // console.log(tags);
  // const empty = {
  //   Document: "No tags yet, click tag button on the left to add one.",
  //   Item: ""
  // }[targetType];

  //----------------------------------------------------------------------------
  // funtions
  //----------------------------------------------------------------------------
  const removeTag = (tagId) => {
    return deTagObject({ variables: {
      tagId, objId
    }});
  };

  //----------------------------------------------------------------------------
  // component rendering
  //----------------------------------------------------------------------------
  return (
    <Stack direction="row" alignItems="center" spacing={1}>
      <AddBtn excludes={tags.map(tag=>tag._id)} objId={objId} />
      <div className={classes.tags}>
        { tags.length? tags.map(tag=>(
            <Tag key={tag._id} text={tag.key} type={tag.type}
              removeTag={tag.tagable? removeTag : null} tagId={tag._id} loading={loading} />
          ))
          : emptyLabel
        }
      </div>
    </Stack>
  );
}

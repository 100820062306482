import React from 'react';
import InfiniteScroll from 'react-infinite-scroller';
import { List, LinearProgress } from '@mui/material';
import { useQuery, useReactiveVar } from "@apollo/client";
import TemplateListItem from './TemplateListItem';
import { EMAIL_TEMPLATES } from '../../constants/gqls/emailTemplates';
import { doRefetch } from '../../constants/cache';
const limit = 10;

//******************************************************************************
// component definition
//******************************************************************************
export default function TemplateList() {
  const needRefetch = useReactiveVar(doRefetch);
  const { loading, data, fetchMore, refetch } = useQuery(EMAIL_TEMPLATES, { variables: {
    options: { limit, offset: 0, sort: [{ createdAt: "ASC" }]}
  }});

  React.useEffect(() => {
    if (needRefetch) {
      // console.log('refetch');
      refetch();
      doRefetch(false)
    }
  }, [needRefetch, refetch]);

  //----------------------------------------------------------------------------
  // funtions
  //----------------------------------------------------------------------------
  const loadMore = () => {
    fetchMore({ variables: {
      options: { limit, offset: data.emailTemplates.length }
    }});
  }

  //----------------------------------------------------------------------------
  // component rendering
  //----------------------------------------------------------------------------
  if (loading) return <LinearProgress />;
  if (!data) return 'Network error.'
  if (data.emailTemplatesCount === 0) {
    return '**Empty list**'
  }

  return (
    <InfiniteScroll
      loadMore={loadMore}
      hasMore={data.emailTemplatesCount > data.emailTemplates.length}
      loader={<LinearProgress key="loading" />}
    >
      <List>
        { data.emailTemplates.map(template=>(
          <TemplateListItem key={template._id} template={template} />
        )) }
      </List>
    </InfiniteScroll>
  );
}

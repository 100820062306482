import React from 'react';
import ReactDOM from 'react-dom';
import { ApolloProvider } from '@apollo/client';
import { createTheme, ThemeProvider } from '@mui/material/styles'
import { createTheme as createThemeV4,
  ThemeProvider as ThemeProviderV4 } from '@material-ui/core/styles';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { DndProvider } from 'react-dnd';
import themeOptions from './constants/theme';
import './index.css';
import App from './App';
import client from './constants/client';
import reportWebVitals from './reportWebVitals';

const theme = createTheme(themeOptions);
const themeV4 = createThemeV4(themeOptions);

// *****************************************************************************
// rendering
// *****************************************************************************
ReactDOM.render(
  <React.StrictMode>
  <ThemeProviderV4 theme={themeV4}>
    <ThemeProvider theme={theme}>
      <ApolloProvider client={client}>
        <DndProvider backend={HTML5Backend}>
          <App />
        </DndProvider>
      </ApolloProvider>
    </ThemeProvider>
    </ThemeProviderV4>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

import React from 'react';
import { SketchPicker } from 'react-color';
import { Popover } from '@mui/material';

class Color {
  constructor(inColor) {
    if (typeof inColor === 'string') {
      let rgxHex3 = /^#[0-9a-fA-F]{3}$/;
      let rgxHex6 = /^#[0-9a-fA-F]{6}$/;
      if (inColor.match(rgxHex3) || inColor.match(rgxHex6)) {
        this.hex = inColor;
      } else {
        let rgxRGBA = /^rgba\(([0-9]{1,3}),[ ]*([0-9]{1,3}),[ ]*([0-9]{1,3}),[ ]*([0-9.]*)\)$/;
        let rgbaMatch = inColor.match(rgxRGBA);
        if (rgbaMatch) {
          this.rgb = {
            r: parseInt(rgbaMatch[1]),
            g: parseInt(rgbaMatch[2]),
            b: parseInt(rgbaMatch[3]),
            a: parseFloat(rgbaMatch[4]),
          }
        } else {
          let rgxRGB = /^rgb\(([0-9]{1,3}),[ ]*([0-9]{1,3}),[ ]*([0-9]{1,3})\)$/;
          let rgbMatch = inColor.match(rgxRGB);
          if (rgbMatch) {
            this.rgb = {
              r: parseInt(rgbMatch[1]),
              g: parseInt(rgbMatch[2]),
              b: parseInt(rgbMatch[3]),
              a: 1,
            }
          }
        }
      }
    } else if (inColor.rgb || inColor.hex || inColor.hsl) {
      this.hex = inColor.hex;
      this.rgb = inColor.rgb;
      this.hsl = inColor.hsl;
    } else if (inColor.r && inColor.g && inColor.b) {
      this.rgb = inColor;
    } else if (inColor.h && inColor.s && inColor.l) {
      this.hsl = inColor;
    } else {
      this.hex = "#fff";
    }
  }

  toString() {
    if (this.rgb) {
      return `rgba(${this.rgb.r}, ${this.rgb.g}, ${this.rgb.b}, ${this.rgb.a})`;
    } else {
      return this.hex || '#fff';
    }
  }

  toInput() {
    if (this.rgb) {
      return this.rgb;
    } else {
      return this.hex || '#fff';
    }
  }
}

//******************************************************************************
// component definition
//******************************************************************************
export default function ColorPicker({open, anchorEl, handleClose, color, colorChange, alpha}) {
  const colorObj = new Color(color);
  // console.log(color, colorObj);

  //----------------------------------------------------------------------------
  // funtions
  //----------------------------------------------------------------------------
  const onChange = (inColor) => {
    let newColor = new Color(inColor);
    colorChange(newColor);
  }

  //----------------------------------------------------------------------------
  // component rendering
  //----------------------------------------------------------------------------
  return (
    <Popover open={open} anchorEl={anchorEl} onClose={handleClose}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
      transformOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
    >
      <SketchPicker disableAlpha={!alpha}
        color={colorObj.toInput()} onChangeComplete={onChange} />
    </Popover>
  );
}

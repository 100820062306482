import React from 'react';
import { generatePath } from "react-router-dom";
import { useQuery } from "@apollo/client";
import { Collapse, List, ListItemButton, ListItemText, Tooltip } from '@mui/material';
// import { makeStyles } from '@material-ui/core/styles';
import SessionItem from '../ServiceSessions/SessionItem';
import AttendanceRate from './AttendanceRate';
import SiteMap from '../../constants/siteMap';
import { MY_SERVICE_SESSIONS } from '../../constants/gqls/serviceSessions';
import ServiceHours from './ServiceHours';

//******************************************************************************
// style definition
//******************************************************************************
// const useStyles = makeStyles((theme) => ({
//
// }));

//******************************************************************************
// component definition
//******************************************************************************
export default function ServiceCardItem({ service, person }) {
  // const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const { data } = useQuery(MY_SERVICE_SESSIONS, {
    variables: { serviceId: service._id }
  });
  const href = generatePath(SiteMap.service, { key: service.key || service._id })
  // console.log(service);

  React.useEffect(() => {
    if (data && data.myServiceSessionsCount) {
      setOpen(true);
    } else {
      setOpen(false);
    }
  }, [data]);

  //----------------------------------------------------------------------------
  // funtions
  //----------------------------------------------------------------------------
  const handleCollapse = (event) => {
    event.preventDefault();
    setOpen(!open);
  }

  //----------------------------------------------------------------------------
  // component rendering
  //----------------------------------------------------------------------------
  return (
    <>
      <ListItemButton component="a" href={href}
        onContextMenu={handleCollapse}>
        <Tooltip placement="top-start"
          title="Click to go to service page, and right click to hide/show sessions.">
          <ListItemText 
            primary={service.name}
            secondary={data && data.myServiceSessionsCount!==0 && 
              `Total: ${data.myServiceSessionsCount} sessions`
            } />
        </Tooltip>
        <ServiceHours 
          serviceId={service._id}
          personId={person._id} />
        <AttendanceRate
          serviceId={service._id}
          personId={person._id} />
      </ListItemButton>
      <Collapse in={open} unmountOnExit sx={{ pl: 4 }}>
        <List disablePadding>
          { data?.myServiceSessions.map(session=>(
            <SessionItem key={session._id} session={session} />
          )) }
        </List>
      </Collapse>
    </>
  );
}

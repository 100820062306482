import React from 'react';
import InfiniteScroll from 'react-infinite-scroller';
import { List, LinearProgress } from '@mui/material';
import { useQuery } from "@apollo/client";
import { CERTIFICATES_BY_TYPE } from '../../constants/gqls/certificates';
import CertificateItem from './CertificateItem';
const limit = 10;

//******************************************************************************
// component definition
//******************************************************************************
export default function CertificatesList({certTypeId}) {
  const { loading, data, fetchMore } = useQuery(CERTIFICATES_BY_TYPE, {
    variables: { certTypeId, limit, offset: 0 }
  });

  //----------------------------------------------------------------------------
  // funtions
  //----------------------------------------------------------------------------
  const loadMore = () => {
    fetchMore({ variables: {
      limit,
      offset: data.certificatesByType.length
    }});
  }

  //----------------------------------------------------------------------------
  // component rendering
  //----------------------------------------------------------------------------
  if (loading) return <LinearProgress />;
  if (!data) return 'Network error.'
  if (data.certificatesByTypeCount === 0) {
    return 'No request yet...';
  }

  return (
    <InfiniteScroll
        loadMore={loadMore}
        hasMore={data.certificatesByTypeCount > data.certificatesByType.length}
        loader={<LinearProgress key="loading" />}
    >
      <List>
        { data.certificatesByType.map(cert=>(
          <CertificateItem key={cert._id} cert={cert} />
        )) }
      </List>
    </InfiniteScroll>
  );
}

import React from 'react';
import { Container, Paper, Tabs, Tab } from '@mui/material';
import { makeStyles } from '@material-ui/core/styles';
import { doRefetch } from '../constants/cache';
import MySpacesList from '../components/Spaces/MySpacesList';
import SpaceAdd from '../components/Spaces/SpaceAdd';
import Search from '../components/Spaces/Search';

//******************************************************************************
// style definition
//******************************************************************************
const useStyles = makeStyles((theme) => ({
  container: {
    paddingTop: theme.spacing(3)
  },
}));

//******************************************************************************
// component definition
//******************************************************************************
export default function Spaces() {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);

  //----------------------------------------------------------------------------
  // funtions
  //----------------------------------------------------------------------------
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const goMySpaces = () => {
    doRefetch(true);
    setValue(0);
  };

  const tabs = [{
    label: 'My Spaces',
    component: <MySpacesList />,
  }, {
    label: 'Search',
    component: <Search />,
  }, {
    label: 'Create',
    component: <SpaceAdd saveThen={goMySpaces} />,
  }]

  //----------------------------------------------------------------------------
  // component rendering
  //----------------------------------------------------------------------------
  return (
    <Container maxWidth="xl" className={classes.container}>
      <Paper>
        <Tabs value={value} onChange={handleChange}>
          { tabs.map((tab, i)=>(
            <Tab key={i} label={tab.label}/>
          )) }
        </Tabs>
        {tabs[value].component}
      </Paper>
    </Container>
  );
}

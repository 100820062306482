import React from 'react';
import InfiniteScroll from 'react-infinite-scroller';
import { Container, Paper, CardContent, List, LinearProgress } from '@mui/material';
import { makeStyles } from '@material-ui/core/styles';
import { useQuery } from "@apollo/client";
import SearchInput from '../components/InputsCommt/SearchInput';
import ScopeSelect from '../components/InputsCommt/ScopeSelect';
import TagListItem from '../components/TagsMgt/TagListItem';
import TagAdd from '../components/TagsMgt/TagAdd';
import { MY_TAGS } from '../constants/gqls/tags';
import { scopeSelects } from '../constants/utils';
const limit = 10;

//******************************************************************************
// style definition
//******************************************************************************
const useStyles = makeStyles((theme) => ({
  container: {
    paddingTop: theme.spacing(3)
  },
  addBox: {
    display: 'flex',
    '& button': {
      margin: 'auto',
    }
  }
}));

//******************************************************************************
// component definition
//******************************************************************************
export default function MyTags() {
  const classes = useStyles();
  const [search, setSearch] = React.useState('');
  const [scope, setScope] = React.useState(scopeSelects);
  const { loading, data, fetchMore } = useQuery(MY_TAGS, { variables: {
    search, scope, limit, offset: 0
  }});
  // console.log(data);

  //----------------------------------------------------------------------------
  // funtions
  //----------------------------------------------------------------------------
  const loadMore = () => {
    fetchMore({ variables: {
      offset: data.myTags.length
    }});
  }

  //----------------------------------------------------------------------------
  // component rendering
  //----------------------------------------------------------------------------

  return (
    <Container maxWidth="xl" className={classes.container}>
      <Paper>
        <CardContent>
          <div className={classes.addBox}>
            <TagAdd scope="personal" />
          </div>
          <SearchInput value={search} onChange={setSearch} />
          <ScopeSelect value={scope} onChange={setScope} />
          { loading && <LinearProgress /> }
          { data &&
            <InfiniteScroll
              loadMore={loadMore}
              hasMore={data.myTagsCount > data.myTags.length}
              loader={<LinearProgress key="loading" />}
            >
              <List>
                { data.myTags.map(tag=>(
                  <TagListItem key={tag._id} tag={tag} filter="personal" />
                ))}
              </List>
            </InfiniteScroll>
          }
        </CardContent>
      </Paper>
    </Container>
  );
}

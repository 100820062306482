import React from 'react';
import { Tooltip, IconButton, Popover, TextField, Badge } from '@mui/material';
import ProductionQuantityLimitsIcon from '@mui/icons-material/ProductionQuantityLimits';
import { makeStyles } from '@material-ui/core/styles';


//******************************************************************************
// style definition
//******************************************************************************
const useStyles = makeStyles((theme) => ({
  box: {
      padding: theme.spacing(1),
  },
}));

//******************************************************************************
// component definition
//******************************************************************************
export default function OptionQuotaBtn({value, updateQuota, loading}) {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [quota, setQuota] = React.useState(value);
  const [changed, setChanged] = React.useState(false);
  const open = Boolean(anchorEl);

  //----------------------------------------------------------------------------
  // funtions
  //----------------------------------------------------------------------------
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    if (changed) {
      updateQuota(parseInt(quota)).then(res=>{
        setAnchorEl(null);
        setChanged(false)
      })
    } else {
      setAnchorEl(null);
    }
  };

  const handleChange = (event) => {
    setQuota(event.target.value);
    setChanged(true);
  }

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      handleClose();
    }
  }

  //----------------------------------------------------------------------------
  // component rendering
  //----------------------------------------------------------------------------
  return (
    <React.Fragment>
      <Tooltip title="Quota limited"><span>
        <IconButton onClick={handleClick} disabled={loading}>
          <Badge badgeContent={value} color="primary" max={999}>
            <ProductionQuantityLimitsIcon />
          </Badge>
        </IconButton>
      </span></Tooltip>
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <div className={classes.box}>
          <Tooltip title="Enter to submit">
            <TextField size="small" type="number" id="option-quota"
              label="Quota" variant="outlined"
              value={quota} onChange={handleChange} onKeyDown={handleKeyDown} />
          </Tooltip>
        </div>
      </Popover>
    </React.Fragment>
  )
}

import React from 'react';
import { IconButton, ClickAwayListener } from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import DeleteBtn from './DeleteBtn';
import EditBtn from './EditBtn';


//******************************************************************************
// component definition
//******************************************************************************
export default function FunctionBtn({courseId, mode, setMode}) {
  const [open, setOpen] = React.useState(false);
  // const [prevent, setPrevent] = React.useState(false);
  // console.log(mode);

  //----------------------------------------------------------------------------
  // funtions
  //----------------------------------------------------------------------------
  const handleOpen = () => {
    setOpen(true);
  }

  const handleClose = () => {
    setOpen(false);
  }

  const changeMode = () => {
    if (mode==='edit') {
      setMode('view');
    } else {
      setMode('edit');
    }
  }

  //----------------------------------------------------------------------------
  // component rendering
  //----------------------------------------------------------------------------
  if (mode==='edit') {
    return <EditBtn changeMode={changeMode} />
  }
  if (open) {
    return (
      <ClickAwayListener onClickAway={handleClose}>
        <div>
          <DeleteBtn courseId={courseId} />
          <EditBtn changeMode={changeMode} />
        </div>
      </ClickAwayListener>
    );
  }
  return (
    <IconButton color="primary" onClick={handleOpen}>
      <MoreVertIcon />
    </IconButton>
  );
}

import React from 'react';
import { Button, DialogContentText, Select, MenuItem, FormControl, InputLabel, TextField } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';

//******************************************************************************
// style definition
//******************************************************************************
// const useStyles = makeStyles((theme) => ({
//   dialog: {
//     '& .MuiPaper-root': {
//       width: '600px'
//     },
//   }
// }));

//******************************************************************************
// component definition
//******************************************************************************
export default function DefaultFieldValues({defaultFieldValues, onChange, valueFields, loading}) {
  // const classes = useStyles();
  const [field, setField] = React.useState('');
  const [value, setValue] = React.useState('');
  const [fieldValues, setFieldValues] = React.useState({});
  const [fieldNames, setFieldNames] = React.useState({});
  console.log(defaultFieldValues);
  console.log(valueFields);

  React.useEffect(() => {
    if (defaultFieldValues) {
      let temp = null;
      try {
        temp = JSON.parse(defaultFieldValues);
      } catch (error) {
        temp = {};
      }
      setFieldValues(temp);
    } else {
      setFieldValues({});
    }
  }, [defaultFieldValues]);

  React.useEffect(() => {
    if (valueFields) {
      let tempNames = {};
      for (let i = 0; i < valueFields.length; i++) {
        tempNames[valueFields[i]._id] = valueFields[i].name;
      }
      setFieldNames(tempNames);
    } else {
      setFieldNames({});
    }
  }, [valueFields]);

  //----------------------------------------------------------------------------
  // funtions
  //----------------------------------------------------------------------------
  const handleFieldChange = (event) => {
    setField(event.target.value);
  };

  const handleValueChange = (event) => {
    setValue(event.target.value);
  };

  const handleAdd = () => {
    if (field && value) {
      let temp = {...fieldValues};
      temp[field] = value;
      onChange(JSON.stringify(temp));
      setField('');
      setValue('');
    }
  };

  const handleDelete = () => {
    
  };

  // const handleChange = (event) => {
  //   updateInput({ variables: {
  //     inputId: input._id,
  //     update: {
  //       valueSetInput: { 
  //         connect: { where: {
  //           node: { _id: event.target.value}
  //         }},
  //         disconnect: { where: {
  //           node: { _id_NOT: event.target.value}
  //         }}
  //       }
  //     }
  //   }}).then(res=>{
  //     // console.log(res);
  //   });
  // };

  //----------------------------------------------------------------------------
  // component rendering
  //----------------------------------------------------------------------------
  return (
    <div>
      <DialogContentText>Set default values</DialogContentText>
      { Object.entries(fieldValues).map(fv=>(
        <p>{fieldNames[fv[0]]}: {fv[1]}</p>
      ))}
      <FormControl fullWidth>
        <InputLabel>Select value field</InputLabel>
        <Select label="Select profile input"
          value={field} 
          onChange={handleFieldChange}
          disabled={loading}
        >
          { valueFields.map(opt=>(
            <MenuItem key={opt._id} value={opt._id}>{opt.name}</MenuItem>
          ))}
        </Select>
      </FormControl>
      <TextField label="Default value"
        value={value}
        onChange={handleValueChange}
        disabled={loading} />
      <Button onClick={handleAdd} disabled={loading}>
        <AddIcon />
      </Button>
    </div>
  )
}

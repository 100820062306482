import React from 'react';
import { ListItemButton, ListItemAvatar, Avatar, Grid, Collapse } from '@mui/material';
import PendingActionsIcon from '@mui/icons-material/PendingActions';
import DoneOutlineIcon from '@mui/icons-material/DoneOutline';
import BlockIcon from '@mui/icons-material/Block';
import { makeStyles } from '@material-ui/core/styles';
import { useMutation } from "@apollo/client";
import ActivateBtn from './ActivateBtn';
import SpacesCheckBtn from './SpacesCheckBtn';
import RolesSelect from '../InputsCommt/RolesSelect';
import { ACTIVATE_USER, UPDATE_PEOPLE } from '../../constants/gqls/user';

//******************************************************************************
// style definition
//******************************************************************************
const useStyles = makeStyles((theme) => ({
  // btn: {
  //   '& .MuiButtonBase-root':{
  //     backgroundColor: theme.palette.secondary.main,
  //     margin: 0
  //   }
  // },
  email: {
    textAlign: 'right',
    color: theme.palette.text.secondary
  },
  collapse: {
    padding: theme.spacing(2)
  }
}));

//******************************************************************************
// component definition
//******************************************************************************
function UserItem({ user }) {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [roles, setRoles] = React.useState(user.roles);
  const [activateUser, { loading }] = useMutation(ACTIVATE_USER);
  const [updatePeople, { loading:updateLoading }] = useMutation(UPDATE_PEOPLE);
  const isDefaultAdmin = user.email === process.env.REACT_APP_DEFAULT_ADMIN;

  React.useEffect(() => {
    setRoles(user.roles)
  }, [user.roles]);

  //----------------------------------------------------------------------------
  // component rendering
  //----------------------------------------------------------------------------
  const handleClick = () => {
    setOpen(!open);
  };

  const activate = (active) => {
    return activateUser({ variables: { active,
      id: user._id
    }});
  }

  const onRolesChange = (inRoles) => {
    setRoles(inRoles);
    updatePeople({ variables: {
      where: { _id: user._id },
      update: { roles: inRoles }
    }}).then((res)=>{
      // console.log(res);
    });
  }

  //----------------------------------------------------------------------------
  // funtions
  //----------------------------------------------------------------------------
  return (
    <React.Fragment>
      <ListItemButton onClick={handleClick}>
        <ListItemAvatar>
          <Avatar>
            { user.request?
              <PendingActionsIcon />
              : user.active?
              <DoneOutlineIcon />
              : <BlockIcon />
            }
          </Avatar>
        </ListItemAvatar>
        <Grid container>
          <Grid item xs={8}>
            { user.name }
          </Grid>
          <Grid item xs={4} className={classes.email}>
            {user.email}
          </Grid>
        </Grid>
      </ListItemButton>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <Grid container className={classes.collapse}
          direction="row"
          justifyContent="space-between"
          alignItems="center">
          <Grid item>
            Roles:
            <RolesSelect value={roles} onChange={onRolesChange}
              disabled={isDefaultAdmin || updateLoading} />
          </Grid>
          <Grid item>
            { !isDefaultAdmin &&
              <>
                <SpacesCheckBtn userId={user._id} />
                <ActivateBtn user={user} activate={activate} loading={loading} />
              </>
            }
          </Grid>
        </Grid>
      </Collapse>
    </React.Fragment>
  );
}

export default UserItem;

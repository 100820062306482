import React from 'react';
import { FormControl, InputLabel, Select, MenuItem, Grid } from '@mui/material';


// function getTimeOption() {
//   let options = [];
//   for (var start = 8; start < 18; start++) {
//     options.push(`${start}:00 ~ ${start+1}:00`);
//     options.push(`${start}:30 ~ ${start+1}:30`);
//   }
//   return options;
// }
//
// const dayOptions = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
// const timeOptions = getTimeOption();

//******************************************************************************
// component definition
//******************************************************************************
export default function WeeklyHour({id, disabled, data, updateData, weeklyDayOptions, weeklyHourOptions}) {
  const [day, time] = data?.value? data.value.split(' => ') : ['', ''];

  //----------------------------------------------------------------------------
  // funtions
  //----------------------------------------------------------------------------
  const handleDayChange = (event) => {
    updateData({
      value: [event.target.value, time].join(' => ')
    }).then(res => {
      // console.log(res);
    });
  };

  const handleTimeChange = (event) => {
    updateData({
      value: [day, event.target.value].join(' => ')
    }).then(res => {
      // console.log(res);
    });
  };

  //----------------------------------------------------------------------------
  // component rendering
  //----------------------------------------------------------------------------
  return (
    <Grid container spacing={2}>
      <Grid item xs={5}>
        <FormControl fullWidth id={id} disabled={disabled}>
          <InputLabel>Select week day</InputLabel>
          <Select id={'weekly-hour-'+id} label="Select week day"
            value={day} onChange={handleDayChange}
          >
            { weeklyDayOptions.map(option=>
              <MenuItem key={option} value={option} disabled={disabled}>
                {option}
              </MenuItem>
            )}
          </Select>
        </FormControl>
      </Grid>
      <Grid item xs={7}>
        <FormControl fullWidth id={id} disabled={disabled}>
          <InputLabel>Select time</InputLabel>
          <Select id={'cal-time-'+id} label="Select time"
            value={time} onChange={handleTimeChange}
          >
            { weeklyHourOptions.map(option=>
              <MenuItem key={option} value={option} disabled={disabled}>
                {option}
              </MenuItem>
            )}
          </Select>
        </FormControl>
      </Grid>
    </Grid>
  )
}

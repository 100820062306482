import React from 'react';
import { Card, CardHeader } from '@mui/material';
import DocAdd from './DocAdd';

//******************************************************************************
// component definition
//******************************************************************************
export default function DocAddCard() {
  return (
    <Card>
      <CardHeader title="New Document" />
      <DocAdd />
    </Card>
  );
}

import React from 'react';
import { gql } from '@apollo/client';
import { useNavigate, generatePath } from "react-router-dom";
import { useReactiveVar } from "@apollo/client";
import { Dialog, DialogTitle, DialogContent, DialogContentText,
  DialogActions, Button } from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import { makeStyles } from '@material-ui/core/styles';
import { useMutation } from "@apollo/client";
import { formFilledOK } from '../../constants/cache';
import SiteMap from '../../constants/siteMap';

//******************************************************************************
// gql
//******************************************************************************
const CREATE_CERTIFICATE = gql`
  mutation CreateCertificate($certTypeId: ID!, $data: String!) {
    createCertificate(certTypeId: $certTypeId, data: $data) {
      token
      certificate { _id }
    }
  }
`

//******************************************************************************
// style definition
//******************************************************************************
const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: theme.spacing(4),
    margin: 'auto'
  }
}));

//******************************************************************************
// component definition
//******************************************************************************
export default function SubmitBtn({submitPrompt, reqData, certTypeId}) {
  const classes = useStyles();
  const navigate = useNavigate();
  const filledOK = useReactiveVar(formFilledOK);
  const [open, setOpen] = React.useState(false);
  const [certId, setCertId] = React.useState(null);
  const [createCertificate, { loading }] = useMutation(CREATE_CERTIFICATE);

  // console.log(submitPrompt);

  //----------------------------------------------------------------------------
  // funtions
  //----------------------------------------------------------------------------
  const handleSubmit = () => {
    // setOpen(true);
    let variables = {
      certTypeId,
      data: JSON.stringify(reqData)
    }
    createCertificate({variables}).then((res)=>{
      console.log(res);
      setCertId(res.data.createCertificate.certificate._id+'?token='+res.data.createCertificate.token);
      setOpen(true);
    });
  }

  const handleClose = () => {
    setOpen(false);
  };

  const handleOk = () => {
    let path = generatePath(SiteMap.certCheck, {
      id: certId
    });
    // let path = '/';
    navigate(path);
  };

  //----------------------------------------------------------------------------
  // component rendering
  //----------------------------------------------------------------------------
  return (
    <>
      <div className={classes.root}>
        <LoadingButton variant="contained" disabled={!filledOK}
          onClick={handleSubmit} loading={loading}
        >
          Submit
        </LoadingButton>
      </div>
      <Dialog
        open={open}
        onClose={handleClose}
      >
        <DialogTitle>Submitted</DialogTitle>
        <DialogContent>
          {submitPrompt && submitPrompt.promptTemplate.split('\n').map((p, i)=>(
            <DialogContentText key={i}>{p}</DialogContentText>
          ))}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleOk} autoFocus>
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

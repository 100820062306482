import React from 'react';
import { CardContent } from '@mui/material';
// import { makeStyles } from '@material-ui/core/styles';
import HtmlEditor from "../ContentEditor/HtmlEditor";

//******************************************************************************
// style definition
//******************************************************************************
// const useStyles = makeStyles((theme) => ({
//   card: {
//     float: 'left',
//     width: '25%',
//   }
// }));

//******************************************************************************
// component definition
//******************************************************************************
export default function SpaceInfoCard({space}) {
  // const classes = useStyles();

  //----------------------------------------------------------------------------
  // funtions
  //----------------------------------------------------------------------------

  //----------------------------------------------------------------------------
  // component rendering
  //----------------------------------------------------------------------------
  return (
    <CardContent>
      <HtmlEditor html={space.description}
        placeholder="Space description" readOnly={true} />
    </CardContent>
  );
}

import React from 'react';
import { useQuery } from "@apollo/client";
import LinearProgress from '@mui/material/LinearProgress';
import NotFount from '../pages/NotFount';
import { ME } from '../constants/gqls/user';

//******************************************************************************
// component definition
//******************************************************************************
function RequireAdmin({ children }) {
  const { loading, data } = useQuery(ME);

  if (loading) {
    return <LinearProgress />
  }

  if (data.me.roles.includes('admin')) {
    return children;
  }

  return <NotFount />;
}

export default RequireAdmin;

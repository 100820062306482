import { gql } from '@apollo/client';


export const UPDATE_TEMPLATE_VAR = gql`
  mutation UpdateTemplateVars($where: TemplateVarWhere, $update: TemplateVarUpdateInput) {
    updateTemplateVars(where: $where, update: $update) {
      templateVars {
        _id key valueType format
      }
    }
  }
`;

import React from 'react';
import { ListItem, ListItemAvatar, ListItemText, Avatar } from '@mui/material';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
// import { makeStyles } from '@material-ui/core/styles';
import MemberRoleSelect from './MemberRoleSelect';

//******************************************************************************
// style definition
//******************************************************************************
// const useStyles = makeStyles((theme) => ({
//   itemText: {
//     "&.MuiListItemText-root": {
//       whiteSpace: 'nowrap',
//       overflow: 'hidden',
//       textOverflow: 'ellipsis',
//     }
//   }
// }));

//******************************************************************************
// component definition
//******************************************************************************
export default function MemberItem({item, handleUpdate, edit, loading}) {
  // const classes = useStyles();


  //----------------------------------------------------------------------------
  // funtions
  //----------------------------------------------------------------------------
  const handleChange = (event) => {
    switch (event.target.value) {
      case 'Remove':
        handleUpdate({
          remove: [item._id]
        });
        break;
      default:
        handleUpdate({
          add: [item._id],
          role: event.target.value
        });
        break;
    }

    // updateSpace({ variables: {
    //   where: { _id: space._id },
    //   update: { members: [{ connect: {
    //     edge: { role: event.target.value },
    //     where: { node: { _id: item.node._id }}
    //   }}]}
    // }}).then(res => {
    //   // console.log(res);
    // });
  };

  //----------------------------------------------------------------------------
  // component rendering
  //----------------------------------------------------------------------------
  return (
    <ListItem>
      <ListItemAvatar>
       <Avatar>
         <AccountCircleIcon />
       </Avatar>
      </ListItemAvatar>
      <ListItemText primary={item.name} secondary={item.email} />
      { edit ?
        <MemberRoleSelect role={item.role} remove={true}
          handleChange={handleChange} disabled={loading} />
        : item.role
      }
    </ListItem>
  );
}

import React from 'react';
import { useQuery } from "@apollo/client";
import { dataMap } from '../../constants/cache';
import { PROFILE_DATA } from '../../constants/gqls/forms';

//******************************************************************************
// component definition
//******************************************************************************
export default function ProfileDataQuery({keyId, profileId}) {
  const { data } = useQuery(PROFILE_DATA, { variables: { keyId, profileId }});
  // console.log(data);

  React.useEffect(() => {
    // console.log(data);
    if (data) {
      if (data.profileKeyData) {
        let map = new Map(dataMap());
        map.set(keyId, data.profileKeyData);
        dataMap(map);
      }
    }
    // console.log(dataMap());
  }, [data, keyId]);

  //----------------------------------------------------------------------------
  // component rendering
  //----------------------------------------------------------------------------
  return <div />;
}

import { InMemoryCache, makeVar } from '@apollo/client';

//******************************************************************************
// local variables
//******************************************************************************
// console.log(localStorage.getItem('loginToken'));
export const isAuthVar = makeVar(localStorage.getItem('loginToken') ? true : false);
// export const sortDocItems = makeVar(null);
export const doRefetch = makeVar(false);
export const doFocus = makeVar(false);
export const formInputEnabled = makeVar(false);
export const formSubmitted = makeVar(false);
// export const currentSubmission = makeVar(null);
export const dataMap = makeVar(null);
export const formFilledCheck = makeVar({});
export const formFilledOK = makeVar(false);
export const profileDataQuery = makeVar(null);
export const valueSetSelectedRefetch = makeVar(null);

//******************************************************************************
// cache memory
//******************************************************************************
const pagination_policy = {
  // Don't cache separate results based on
  // any of this field's arguments.
  keyArgs: ['where'],
  // Concatenate the incoming list items with
  // the existing list items.
  merge(existing, incoming, { args: { options }}) {
    // console.log(existing);
    // console.log(incoming);
    // console.log(options);
    if (!options) {
      return incoming;
    }
    let offset = options.offset || 0;
    // Slicing is necessary because the existing data is
    // immutable, and frozen in development.
    const merged = existing ? existing.slice(0) : [];
    for (let i = 0; i < incoming.length; ++i) {
      merged[offset + i] = incoming[i];
    }
    // console.log(merged);
    return merged;
  },
}

const myMerge = (existing, incoming, { args: { offset, limit }}) => {
  // console.log(existing);
  // console.log(incoming);
  // console.log(offset);
  if (!offset) {
    return incoming;
  }
  const merged = existing ? existing.slice(0) : [];
  for (let i = 0; i < incoming.length; ++i) {
    merged[offset + i] = incoming[i];
  }
  // console.log(merged);
  return merged;
};

export const cache = new InMemoryCache({
  typePolicies: {
    Query: {
      fields: {
        people: pagination_policy,
        tagTypes: pagination_policy,
        tags: pagination_policy,
        documents: pagination_policy,
        spaces: pagination_policy,
        services: pagination_policy,
        values: pagination_policy,
        emailTemplates: pagination_policy,
        pages: pagination_policy,
        profileKeys: pagination_policy,
        mySpaces: {
          keyArgs: ['search'],
          merge: myMerge,
        },
        myTags: {
          keyArgs: ['search', 'scope'],
          merge: myMerge,
        },
        myDocuments: {
          keyArgs: ['search'],
          merge: myMerge,
        },
        sharedDocuments: {
          keyArgs: ['search'],
          merge: myMerge,
        },
        spaceDocuments: {
          keyArgs: ['spaceId'],
          merge: myMerge,
        },
        taggedObjects: {
          keyArgs: ['tags'],
          merge: myMerge,
        },
        myTaggedTags: {
          keyArgs: ['search'],
          merge: myMerge,
        },
        spaceMembers: {
          keyArgs: ['spaceId'],
          merge: myMerge,
        },
        submitted: {
          keyArgs: ['docId'],
          merge: myMerge,
        },
        docSharedTo: {
          keyArgs: ['docId'],
          merge: myMerge,
        },
        profileUserList: {
          keyArgs: ['docId'],
          merge: myMerge,
        },
        mySubmissions: {
          keyArgs: ['docId'],
          merge: myMerge,
        },
        myServiceSessions: {
          keyArgs: ['personId'],
          merge: myMerge,
        },
        sessionsOfService: {
          keyArgs: ['serviceId'],
          merge: myMerge,
        },
        certTypesList: {
          keyArgs: ['search'],
          merge: myMerge,
        },
        certificatesByType: {
          keyArgs: ['certTypeId'],
          merge: myMerge,
        },
        myCertificates: {
          keyArgs: ['search'],
          merge: myMerge,
        },
        myPages: {
          keyArgs: ['search'],
          merge: myMerge,
        },
        myProfileForms: {
          keyArgs: ['search'],
          merge: myMerge,
        },
      }
    }
  }
})

import React from 'react';
import { Button } from '@mui/material';

//******************************************************************************
// component definition
//******************************************************************************
export default function SessionSave({ handleCancel, handleSave, loading }) {

  //----------------------------------------------------------------------------
  // component rendering
  //----------------------------------------------------------------------------
  return (
    <>
      <Button onClick={handleCancel}>
        Cancel
      </Button>
      <Button variant="contained" disabled={loading} onClick={handleSave}>
        Save
      </Button>
    </>
  )
}

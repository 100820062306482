import React from 'react';
// import { generatePath } from "react-router-dom";
import { CardHeader, CardContent, Typography } from '@mui/material';
import BarChartIcon from '@mui/icons-material/BarChart';
import { makeStyles } from '@material-ui/core/styles';
import CardComm from './CardComm';
// import SiteMap from '../../constants/siteMap';

//******************************************************************************
// style definition
//******************************************************************************
const useStyles = makeStyles((theme) => ({
  content: {
    "&.MuiCardContent-root": {
      paddingTop: 0
    }
  }
}));

//******************************************************************************
// component definition
//******************************************************************************
export default function DataCard({data, setSignal}) {
  const classes = useStyles();
  // const goTo = generatePath(SiteMap.person, { id: person._id });

  React.useEffect(() => {
    if (setSignal) {
      setSignal(`${data._id} loaded`);
    }
  }, [data, setSignal]);

  //----------------------------------------------------------------------------
  // component rendering
  //----------------------------------------------------------------------------\

  return (
    <CardComm tags={data.tags}
      icon={<BarChartIcon />}>
      <CardHeader title="Data" />
      <CardContent className={classes.content}>
        <Typography>
          Data card will display a data object you filled in an input item. Now it's still under development.
        </Typography>
      </CardContent>
    </CardComm>
  );
}

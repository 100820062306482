import React from 'react';
import { CardContent } from '@mui/material';
import ProfileKeysSelector from './ProfileKeysSelector';
import ReportColumns from './ReportColumns';

//******************************************************************************
// component definition
//******************************************************************************
export default function ReportTap() {
  const [selected, setSelected] = React.useState([]);
  const [error, setError] = React.useState(false);
  // console.log(selected);

  //----------------------------------------------------------------------------
  // funtions
  //----------------------------------------------------------------------------
  // const handleChange = (event) => {
  //   setValueKey(event.target.value)
  // }

  //----------------------------------------------------------------------------
  // component rendering
  //----------------------------------------------------------------------------
  return (
    <CardContent>
      <ProfileKeysSelector forReport={true}
        selected={selected} setSelected={setSelected}
        error={error} setError={setError} />
      { !error && 
        <ReportColumns selected={selected} />
      }
    </CardContent>
  );
}

import React from 'react';
import { IconButton, Tooltip, Dialog, DialogTitle  } from '@mui/material';
import { makeStyles } from '@material-ui/core/styles';
import AddIcon from '@mui/icons-material/Add';
import CreateOrEdit from './CreateOrEdit';

//******************************************************************************
// style definition
//******************************************************************************
const useStyles = makeStyles((theme) => ({
  dialog: {
    '& .MuiPaper-root': {
      minWidth: '600px',
      maxWidth: '800px',
    }
  }
}));

//******************************************************************************
// component definition
//******************************************************************************
export default function CreateBtn() {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);

  //----------------------------------------------------------------------------
  // funtions
  //----------------------------------------------------------------------------
  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  //----------------------------------------------------------------------------
  // component rendering
  //----------------------------------------------------------------------------
  return (
    <>
      <Tooltip title="Add new table">
        <IconButton onClick={handleOpen}>
          <AddIcon />
        </IconButton>
      </Tooltip>
      <Dialog open={open} onClose={handleClose} className={classes.dialog}>
        <DialogTitle>
          New data table
        </DialogTitle>
        <CreateOrEdit then={handleClose} />
      </Dialog>
    </>
  )
}

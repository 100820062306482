import React from 'react';
import { Accordion, AccordionSummary, AccordionDetails, Typography } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import FormMyDataList from './FormMyDataList';
import NewInputBtn from './NewInputBtn';

//******************************************************************************
// component definition
//******************************************************************************
export default function FormMyData({doc}) {
  const [open, setOpen] = React.useState(false);

  //----------------------------------------------------------------------------
  // funtions
  //----------------------------------------------------------------------------
  const handleClick = () => {
    setOpen(!open);
  };

  //----------------------------------------------------------------------------
  // component rendering
  //----------------------------------------------------------------------------
  return (
    <Accordion expanded={open} onChange={handleClick}>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Typography>My submissions</Typography>
      </AccordionSummary>
      <AccordionDetails>
        { doc.canSubmit && doc.inputEnabled &&
          <NewInputBtn />
        }
        <FormMyDataList docId={doc._id} />
      </AccordionDetails>
    </Accordion>
  );
}

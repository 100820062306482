import React from 'react';
import { useParams } from "react-router-dom";
import { Container, LinearProgress, Snackbar, Alert } from '@mui/material';
import { makeStyles } from '@material-ui/core/styles';
import { useQuery } from "@apollo/client";
import NotFount from './NotFount';
import TagsShowcase from '../components/TagsShowcase';
import Board from '../components/Person/Board';
import SettingBtn from '../components/Person/SettingBtn';
import { PERSON_BOARDS } from '../constants/gqls/user';

//******************************************************************************
// style definition
//******************************************************************************
const useStyles = makeStyles((theme) => ({
  box: {
    marginTop: theme.spacing(1),
    marginLeft: theme.spacing(1)
  },
  container: {
    paddingTop: theme.spacing(1),
  },
}));

//******************************************************************************
// component definition
//******************************************************************************
export default function Person() {
  const classes = useStyles();
  const params = useParams();
  // const [setting, setSetting] = React.useState(false);
  const [open, setOpen] = React.useState(true);
  const [board, setBoard] = React.useState(null);
  const [showBoard, setShowBoard] = React.useState(null);
  const { loading, data } = useQuery(PERSON_BOARDS, { variables: {
    personId: params.id,
    options: {
      sort: [{ index: 'ASC' }]
    },
  }});
  const notice = process.env.REACT_APP_NOTICE;

  // const [tagPerson, { loading:tagLoading }] = useMutation(TAG_PERSON);
  // console.log(params);
  // console.log(showBoard);

  //----------------------------------------------------------------------------
  // funtions
  //----------------------------------------------------------------------------
  // const addTag = (tagID) => {
  //   return tagPerson({ variables: {
  //     where: { _id: data.person._id  },
  //     connect: { tagsRel: [{
  //       where: { node: { _id: tagID }}
  //     }]}
  //   }});
  // };
  //
  // const removeTag = (tagID) => {
  //   return tagPerson({ variables: {
  //     where: { _id: data.person._id },
  //     disconnect: { tagsRel: [{
  //       where: { node: { _id: tagID }}
  //     }]}
  //   }});
  // };

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };

  //----------------------------------------------------------------------------
  // component rendering
  //----------------------------------------------------------------------------
  if (loading) return <LinearProgress />;
  if (!data) return 'Network error.'
  if (!data.person) return <NotFount />

  return (
    <React.Fragment>
      <div className={classes.box}>
        <TagsShowcase objId={data.person._id} tags={data.person.tags} />
      </div>
      <Container maxWidth="xl" className={classes.container}>
        <Board person={data.person} showBoard={showBoard} setBoard={setBoard} refetchQueries={[PERSON_BOARDS]} />
      </Container>
      { data.person.isMe &&
        <SettingBtn
          showBoard={showBoard} setShowBoard={setShowBoard}
          board={board} refetchQueries={[PERSON_BOARDS]} />
      }
      { notice && notice.trim()!=='' &&
        <Snackbar
          open={open}
          anchorOrigin={{ vertical: 'top', horizontal: 'center'}}
          autoHideDuration={20000}
          onClose={handleClose}
        >
          <Alert severity="warning" variant="filled">{notice}</Alert>
        </Snackbar>
      }
    </React.Fragment>
  );
}

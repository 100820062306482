import React from 'react';
import { Card, CardHeader, Tooltip } from '@mui/material';
import { makeStyles } from '@material-ui/core/styles';
import { useQuery } from "@apollo/client";
import SpaceInfoCard from './SpaceInfoCard';
import SpaceInfoEdit from './SpaceInfoEdit';
import MembersCard from './MembersCard';
import CardActions from './CardActions';
import DocAdd from '../Documents/DocAdd';
import SpaceDocumentsCard from './SpaceDocumentsCard';
import PersonDocumentsCard from './PersonDocumentsCard';
import PersonInfoCard from './PersonInfoCard';
// import PersonInfoEdit from './PersonInfoEdit';
import MyTagsCard from './MyTagsCard';
import MyServicesCard from './MyServicesCard';
import MyPagesCard from './MyPagesCard';
import MyCertificatesCard from './MyCertificatesCard';
import { ME } from '../../constants/gqls/user';

//******************************************************************************
// style definition
//******************************************************************************
function getWidth(widthUnit, w) {
  let width = widthUnit * w;
  width = width > 99 ? 100 : width
  return `${width}%`;
}

function getHeight(h) {
  let height = 320 * h;
  return `${height}px`
}

const useStyles = makeStyles((theme) => ({
  card: {
    position: 'relative',
    float: 'left',
    width: '100%',
    height: (props) => getHeight(props.height),
    padding: theme.spacing(1),
    [theme.breakpoints.up('md')]: {
      width: (props) => getWidth(50, props.width)
    },
    [theme.breakpoints.up('lg')]: {
      width: (props) => getWidth(33.33, props.width)
    },
    [theme.breakpoints.up('xl')]: {
      width: (props) => getWidth(25, props.width)
    },
    "& .MuiPaper-root": {
      height: '100%',
      display: 'flex',
      flexDirection: 'column'
    },
    // "&:last-child": {
    //   marginBottom: theme.spacing(6),
    // }
  },
  content: {
    overflow: 'auto'
  },
}));

//******************************************************************************
// component definition
//******************************************************************************
function renderCard(me, space, person, type, edit, exitEdit, width) {
  switch (type) {
    case 'space-info':
      return {
        title: space.name,
        subheader: space.email,
        helpText: 'Space information here.',
        editable: space?.myRole === 'Manager',
        showEdit: true,
        content: edit ?
          <SpaceInfoEdit space={space} exitEdit={exitEdit} />
          :
          <SpaceInfoCard space={space} />
      };
    case 'space-members':
      return {
        title: 'Members',
        editable: space?.myRole === 'Manager' || me.roles.includes('admin'),
        showEdit: true,
        helpText: 'Manage space members here.',
        subheader: `Total: ${space.membersConnection.totalCount}`,
        content: <MembersCard space={space} edit={edit} />
      };
    case 'doc-add':
      return {
        title: 'Document Add',
        editable: space?.myRole === 'Manager' || me._id === person?._id,
        helpText: 'Create new document here.',
        content: <DocAdd spaceId={space?._id} />
      };
    case 'space-documents':
      return {
        title: 'Documents',
        editable: space?.myRole === 'Manager',
        helpText: 'List all documents this space owns.',
        content: <SpaceDocumentsCard spaceId={space._id} />
      };
    case 'my-tags':
      return {
        title: 'My Tags',
        editable: me._id === person?._id,
        helpText: 'Tags here are things you can access to, and they order by number of times you click on a tag.',
        content: <MyTagsCard />
      };
    case 'person-documents':
      return {
        title: 'My Documents',
        editable: me._id === person?._id,
        helpText: 'List all documents you own.',
        content: <PersonDocumentsCard />
      };
    case 'person-info':
      return {
        title: (person.title ? person.title + ' ' : '') + person.name,
        subheader: person.email,
        helpText: 'Your profile here.',
        editable: me._id === person?._id,
        content: person.isMe? 
          <PersonInfoCard person={person} width={width} />
          : <div />
      };
    // case 'person-info':
    //   return {
    //     title: (person.title ? person.title + ' ' : '') + person.name,
    //     editable: false, //person.roles.includes('staff'),
    //     content: edit ?
    //       <PersonInfoEdit person={person} exitEdit={exitEdit} />
    //       :
    //       <PersonInfoCard person={person} width={width} />
    //   };
    case 'my-services':
      return {
        title: 'My Services',
        // subheader: `Total service hours completed: ${person.totalServiceHoursCompleted}`,
        editable: me._id === person?._id,
        helpText: 'List the latest 10 service sessions you participated in for each service.',
        content: <MyServicesCard person={person} />
      };
    case 'my-pages':
      return {
        title: 'My Pages',
        editable: me._id === person?._id,
        helpText: 'List all pages you own (bata).',
        content: <MyPagesCard />
      };
    case 'my-certificates':
      return {
        title: 'My Certificates',
        editable: me._id === person?._id,
        helpText: 'List of your certificates.',
        content: <MyCertificatesCard />
      };
    default:
      return {
        title: type,
        content: ''
      };
  }
}

export default function CardItem({ space, person, card, board, refetchQueries }) {
  const { data } = useQuery(ME);
  const classes = useStyles({
    width: card.width,
    height: card.height
  });
  const [edit, setEdit] = React.useState(false);
  const exitEdit = () => { setEdit(false) }
  // const isMe = data?.me? person?._id === data.me._id : false;
  const cardItem = renderCard(data?.me, space, person, card.type, edit, exitEdit, card.width);
  

  //----------------------------------------------------------------------------
  // component rendering
  //----------------------------------------------------------------------------
  return (
    <div className={classes.card}>
      
      <Card>
          <CardHeader
            action={cardItem.editable ?
              <CardActions edit={edit} setEdit={cardItem.editable && cardItem.showEdit && setEdit}
                card={card} board={board} refetchQueries={refetchQueries} />
              : null}
            title={
              <Tooltip title={cardItem.helpText} placement="right" arrow>
                <span>{cardItem.title}</span>
              </Tooltip>
            }
            subheader={cardItem.subheader}
          />
        <div className={classes.content}>
          {cardItem.content}
        </div>
      </Card>
    </div>
  );
}

import React from 'react';
import { IconButton, Dialog, DialogTitle, DialogContent, DialogContentText,
  DialogActions, Button } from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import DeleteIcon from '@mui/icons-material/Delete';
import { useMutation } from "@apollo/client";
import { DELETE_CARD_FROM_BOARD } from '../../constants/gqls/boards';

//******************************************************************************
// component definition
//******************************************************************************
export default function DeleteBtn({card, boardId, refetchQueries}) {
  const [open, setOpen] = React.useState(false);
  const [deleteCardFromBoard, { loading }] = useMutation(DELETE_CARD_FROM_BOARD, { refetchQueries });

  //----------------------------------------------------------------------------
  // funtions
  //----------------------------------------------------------------------------
  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const onDeleteClick = () => {
    deleteCardFromBoard({ variables: {
      boardId, cardId: card._id
    }}).then(res=>{
      // console.log(res.data);
      setOpen(false);
    });
  }


  //----------------------------------------------------------------------------
  // component rendering
  //----------------------------------------------------------------------------
  return (
    <React.Fragment>
      <IconButton onClick={handleOpen}>
        <DeleteIcon />
      </IconButton>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>
          Confirm Delete?
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            Click delete button to confirm delete {card.type} card from board. You can add it back in the add card section.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <LoadingButton variant="outlined"
            loading={loading} onClick={onDeleteClick}>
            Delete
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}

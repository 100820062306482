import React from 'react';
import { generatePath } from "react-router-dom";
import { CardActions, Button, Table, TableBody, TableRow, TableCell, Switch } from '@mui/material';
import { makeStyles } from '@material-ui/core/styles';
import { useMutation } from "@apollo/client";
import PageNameInput from './PageNameInput';
import PeopleInput from '../InputsCommt/PeopleInput';
import DocumentInput from '../InputsCommt/DocumentInput';
import { UPDATE_PAGE } from '../../constants/gqls/pages';
import SiteMap from '../../constants/siteMap';

//******************************************************************************
// style definition
//******************************************************************************
const useStyles = makeStyles((theme) => ({
  cell: {
    width: '1%',
    whiteSpace: 'nowrap',
  },
  note: {
    fontFamily: 'inherit'
  },
  labelText: {
    color: theme.palette.secondary.main
  },
  formLink: {
    color: theme.palette.primary.main,
    textDecoration: 'none',
    fontWeight: 'bold'
  }
}));

//******************************************************************************
// component definition
//******************************************************************************
/**
 * @typedef {Object} DocumentDisplayProps
 * @property {object} document - The document object.
 */

/**
 * Document display component.
 * @param {DocumentDisplayProps} props - propertis
 */
function DocumentDisplay({ document }) {
  const docPath = generatePath(SiteMap.document, { id: document._id });

  return (
    <a href={docPath}>
      {document.title}
    </a>
  );
}

/**
 * @typedef {Object} PageDetailsProps
 * @property {object} page - The page object.
 * @property {boolean} editMode - is edit mode.
 * @property {function} editClicked - To update editMode.
 */

/**
 * Page details component.
 * @param {PageDetailsProps} props - propertis
 */
export default function PageDetails({ page, editMode, editClicked }) {
  const classes = useStyles();
  const pagePath = generatePath(SiteMap.pageView, { name: page.name });
  const [name, setName] = React.useState(page.name);
  const [nameCheck, setNameCheck] = React.useState(true);
  const [managers, setManagers] = React.useState(page.managers);
  const [content, setSetContent] = React.useState(page.content);
  const [updatePage, { loading }] = useMutation(UPDATE_PAGE);
  // console.log(page);

  React.useEffect(() => {
    if (!editMode) {
      setManagers(page.managers);
      setSetContent(page.content);
    }
  }, [editMode, page]);

  //----------------------------------------------------------------------------
  // funtions
  //----------------------------------------------------------------------------
  const handleUpdate = (inUpdate) => {
    // console.log(inUpdate);
    return updatePage({
      variables: {
        id: page._id,
        inUpdate
      }
    });
  }

  const handleActiveChange = (event) => {
    handleUpdate({
      active: event.target.checked
    });
  }

  const handleSave = () => {
    let mgrsIds = managers.map(mgr=>mgr._id);
    let update = { 
      name,
      managers: { 
        connect: [{ where: { node: { _id_IN: mgrsIds }}}],
        disconnect: [{ where: { node: { _id_NOT_IN: mgrsIds }}}]
      }
    };
    if (content) {
      if ('Document' === content.__typename) {
        update.contentDocument = {
          connect: { where: { node: { _id: content._id } } },
          disconnect: { where: { node: { _id_NOT: content._id } } }
        }
      } else if ('Person' === content.__typename) {
        update.contentPerson = {
          connect: { where: { node: { _id: content._id } } },
          disconnect: { where: { node: { _id_NOT: content._id } } }
        }
      } if ('Space' === content.__typename) {
        update.contentSpace = {
          connect: { where: { node: { _id: content._id } } },
          disconnect: { where: { node: { _id_NOT: content._id } } }
        }
      }
    }
    handleUpdate(update).then(res => {
      editClicked();
    });
  }


  //----------------------------------------------------------------------------
  // component rendering
  //----------------------------------------------------------------------------
  return (
    <>
      <Table>
        <TableBody>
          <TableRow>
            <TableCell className={classes.cell}>
              <span className={classes.labelText}>Name:</span>
            </TableCell>
            <TableCell>
              {editMode ?
                <PageNameInput
                  name={name} setName={setName}
                  nameCheck={nameCheck} setNameCheck={setNameCheck}
                  originalValue={page.name}
                  disabled={loading} />
                : page.name
              }
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell className={classes.cell}>
              <span className={classes.labelText}>Onwer:</span>
            </TableCell>
            <TableCell>
              {editMode ?
                <PeopleInput multiple={true} label="Manager"
                  value={managers} onChange={setManagers}
                  disabled={loading} />
                : managers.map(mgr=>(
                  <div key={mgr._id}>{mgr.name} ({mgr.email})</div>
                ))
              }
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell className={classes.cell}>
              <span className={classes.labelText}>Active:</span>
            </TableCell>
            <TableCell>
              <Switch checked={page.active}
                onChange={handleActiveChange}
                inputProps={{ 'aria-label': 'page-active' }}
              />
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell className={classes.cell}>
              <span className={classes.labelText}>Content:</span>
            </TableCell>
            <TableCell>
              {editMode ?
                <DocumentInput label="Document"
                  value={content} onChange={setSetContent}
                  disabled={loading} />
                : content?.type === 'Document' ?
                  <DocumentDisplay document={content} />
                  : ''
              }
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
      {editMode ?
        <CardActions>
          <Button onClick={editClicked}>
            Cancel
          </Button>
          <Button variant="contained"
            disabled={loading || !nameCheck || !managers}
            onClick={handleSave}>
            Save
          </Button>
        </CardActions>
        :
        <CardActions>
          <Button href={pagePath}>
            Preview
          </Button>
        </CardActions>
      }
    </>
  );
}

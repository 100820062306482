import React from 'react';
import { Tooltip, TextField } from '@mui/material';
import { makeStyles } from '@material-ui/core/styles';

//******************************************************************************
// style definition
//******************************************************************************
const useStyles = makeStyles((theme) => ({
  description: {
    margin: 0,
    color: theme.palette.primary.main
  },
  box: {
    minHeight: '24px'
  }
}));

//******************************************************************************
// component definition
//******************************************************************************
export default function Description({text, loading = false, update = null}) {
  const classes = useStyles();
  const [edit, setEdit] = React.useState(false);
  const [description, setDescription] = React.useState(text);
  const [changed, setChanged] = React.useState(false);

  //----------------------------------------------------------------------------
  // funtions
  //----------------------------------------------------------------------------
  const toEdit = () => {
    if (update) {
      setEdit(true);
    }
  }

  const onDescriptionChange = (event) => {
    setDescription(event.target.value);
    setChanged(true);
  }

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      handleUpdate();
    }
  }

  const handleUpdate = () => {
    if (changed) {
      if (!loading) {
        update({description}).then(res=>{
          // console.log(res);
          setEdit(false);
          setChanged(false);
        });
      }
    } else {
      setEdit(false);
    }
  }

  //----------------------------------------------------------------------------
  // component rendering
  //----------------------------------------------------------------------------
  if (edit) return (
    <Tooltip title="Enter to submit">
      <TextField id="document-description" label="Description"
        autoFocus fullWidth margin="dense" variant="outlined"
        value={description} onChange={onDescriptionChange}
        onKeyDown={handleKeyDown} onBlur={handleUpdate} disabled={loading} />
    </Tooltip>
  );

  return (
    <Tooltip placement="left-start"
       title={update? "Description (double click to edit)" : "Description"}>
      <div className={classes.box} onDoubleClick={toEdit}>
        <p className={classes.description}>{text}</p>
      </div>
    </Tooltip>
  );
}

import React from 'react';
import { Grid } from '@mui/material';
import SearchInput from '../InputsCommt/SearchInput';
import TagTypeInput from '../InputsCommt/TagTypeInput';
import PeopleInput from '../InputsCommt/PeopleInput';
import DateTimeInput from '../InputsCommt/DateTimeInput';
import ScopeSelect from '../InputsCommt/ScopeSelect';
import { scopeSelects } from '../../constants/utils';

//******************************************************************************
// component definition
//******************************************************************************
export default function TagsListFilter({setWhere}) {
  const [search, setSearch] = React.useState('');
  const [scope, setScope] = React.useState(scopeSelects);
  const [tagType, setTagType] = React.useState(null);
  const [createBy, setCreateBy] = React.useState(null);
  const [createdFrom, setCreatedFrom] = React.useState(null);
  const [createdTill, setCreatedTill] = React.useState(null);
  const [updatedFrom, setUpdatedFrom] = React.useState(null);
  const [updatedTill, setUpdatedTill] = React.useState(null);

  React.useEffect(() => {
    if (setWhere) {
      let match = `(?i).*${search.trim()}.*`;
      let scopeSelect = scope.map(s=>({ scope_INCLUDES: s }));

      let where = { AND: [{
        OR: [{
          _id: search.trim()
        }, {
          key_MATCHES: match
        }, {
          description_MATCHES: match
        }],
      }, {
        OR: scopeSelect
      }]};

      if (tagType) {
        where.type = { _id: tagType._id };
      }
      if (createBy) {
        where.createdBy = { _id: createBy._id };
      }
      if (createdFrom) {
        where.createdAt_GTE = createdFrom;
      }
      if (createdTill) {
        where.createdAt_LTE = createdTill;
      }
      if (updatedFrom) {
        where.updatedAt_GTE = updatedFrom;
      }
      if (updatedTill) {
        where.updatedAt_LTE = updatedTill;
      }

      setWhere(where);
    }
  }, [
    setWhere, search, scope, tagType, createBy,
    createdFrom, createdTill, updatedFrom, updatedTill
  ]);

  //----------------------------------------------------------------------------
  // funtions
  //----------------------------------------------------------------------------

  //----------------------------------------------------------------------------
  // component rendering
  //----------------------------------------------------------------------------
  return (
    <React.Fragment>
      <Grid container spacing={2}>
        <Grid item xs={4}>
          <SearchInput value={search} onChange={setSearch} />
        </Grid>
        <Grid item xs={4}>
          <TagTypeInput value={tagType} onChange={setTagType} />
        </Grid>
        <Grid item xs={4}>
          <PeopleInput value={createBy} onChange={setCreateBy} label="Created by" />
        </Grid>
        <Grid item xs={3}>
          <DateTimeInput id="tag-created-from" label="Created from"
            value={createdFrom} onChange={setCreatedFrom} />
        </Grid>
        <Grid item xs={3}>
          <DateTimeInput id="tag-created-till" label="Created till"
            value={createdTill} onChange={setCreatedTill} />
        </Grid>
        <Grid item xs={3}>
          <DateTimeInput id="tag-updated-from" label="Updated from"
            value={updatedFrom} onChange={setUpdatedFrom} />
        </Grid>
        <Grid item xs={3}>
          <DateTimeInput id="tag-updated-till" label="Updated till"
            value={updatedTill} onChange={setUpdatedTill} />
        </Grid>
      </Grid>
      <ScopeSelect value={scope} onChange={setScope} />
    </React.Fragment>
  );
}

import React from 'react';
import { useMutation } from "@apollo/client";
import HtmlEditor from "../ContentEditor/HtmlEditor";
import { UPDATE_STUDENT_PRIVATE_INFO } from '../../constants/gqls/studentPrivateInfo';
// import { makeStyles } from '@material-ui/core/styles';

//******************************************************************************
// style definition
//******************************************************************************
// const useStyles = makeStyles((theme) => ({
// }));

//******************************************************************************
// component definition
//******************************************************************************
export default function StudentPrivateNote({ studentPrivateInfo, refId, saveClicked, editMode }) {
  // const classes = useStyles();
  const [comment, setComment] = React.useState('');
  const [updateInfo, { loading }] = useMutation(UPDATE_STUDENT_PRIVATE_INFO);
  console.log(studentPrivateInfo);

  React.useEffect(() => {
    if (saveClicked) {
      updateInfo({
        variables: { refId,
          type: 'attendance',
          studentId: studentPrivateInfo.student._id, 
          input: { comment }
        }
      });
    }
  }, [saveClicked]);

  //----------------------------------------------------------------------------
  // funtions
  //----------------------------------------------------------------------------

  const handleNoteChange = (value) => {
    if (value !== comment) {
      setComment(value);
    }
  }


  //----------------------------------------------------------------------------
  // component rendering
  //----------------------------------------------------------------------------
  return (
    <HtmlEditor placeholder="Report or remarks goes here"
      html={studentPrivateInfo.comment} onChange={handleNoteChange}
      readOnly={!editMode} disabled={loading} />
  );
}

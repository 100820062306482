import React from 'react';
// import { makeStyles } from '@material-ui/core/styles';

//******************************************************************************
// style definition
//******************************************************************************
// const useStyles = makeStyles((theme) => ({
//   root: {
//     width: '100%',
//     position: 'absolute',
//     height: '38vh',
//   }
// }));

//******************************************************************************
// component definition
//******************************************************************************
export default function PageBanner() {

  //----------------------------------------------------------------------------
  // component rendering
  //----------------------------------------------------------------------------
  return (
    <div style={{
      width: '100%',
      position: 'absolute',
      height: '38vh',
      background: `url(/banner.png)`,
      backgroundSize: 'cover',
      backgroundPosition: 'center',
    }} />
  );
}

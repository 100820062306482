import React from 'react';
import { generatePath } from "react-router-dom";
import { CardHeader, Typography, CardContent } from '@mui/material';
import HistoryEduIcon from '@mui/icons-material/HistoryEdu';
import { makeStyles } from '@material-ui/core/styles';
import CardComm from '../TagsFilter/CardComm';
import SiteMap from '../../constants/siteMap';

//******************************************************************************
// style definition
//******************************************************************************
const useStyles = makeStyles((theme) => ({
  card: {
    cursor: 'pointer'
  },
  title: {

  },
  content: {
    "&.MuiCardContent-root": {
      paddingTop: 0
    }
  }
}));

//******************************************************************************
// component definition
//******************************************************************************
export default function DocCard({doc}) {
  const classes = useStyles();
  const docPath = generatePath(SiteMap.document, { id: doc._id });

  //----------------------------------------------------------------------------
  // component rendering
  //----------------------------------------------------------------------------
  return (
    <CardComm tags={doc.tags}
      icon={<HistoryEduIcon />} goTo={docPath}>
      <CardHeader title={doc.title} />
      <CardContent className={classes.content}>
        <Typography>
          {doc.description}
        </Typography>
      </CardContent>
    </CardComm>
  );
}

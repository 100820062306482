import React from 'react';
import { Container, Paper } from '@mui/material';
import { makeStyles } from '@material-ui/core/styles';
import AddTemplate from '../components/EmailTemplates/AddTemplate';
import TemplateList from '../components/EmailTemplates/TemplateList';

//******************************************************************************
// style definition
//******************************************************************************
const useStyles = makeStyles((theme) => ({
  container: {
    paddingTop: theme.spacing(3)
  },
}));

//******************************************************************************
// component definition
//******************************************************************************
export default function AdminEmails() {
  const classes = useStyles();

  //----------------------------------------------------------------------------
  // component rendering
  //----------------------------------------------------------------------------
  return (
    <Container maxWidth="xl" className={classes.container}>
      <Paper>
        <AddTemplate />
        <TemplateList />
      </Paper>
    </Container>
  );
}

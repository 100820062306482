import React from 'react';
import { gql } from '@apollo/client';
import { useParams, useSearchParams, useLocation, Link } from "react-router-dom";
import { Container, Paper, LinearProgress, CardContent, CardActions, Table, TableBody, TableRow, TableCell, Button } from '@mui/material';
import { makeStyles } from '@material-ui/core/styles';
import { useQuery } from "@apollo/client";
// import SendEmailReqBtn from '../components/Certificates/SendEmailReqBtn';
import SendEmailReqBtn from '../components/Certificates/SendEmailReqBtn';
import NotFount from './NotFount';
import SiteMap from '../constants/siteMap';

//******************************************************************************
// gql
//******************************************************************************
const CERT_QUERY = gql`
  query CertificateCheck($certId: ID!, $token: String) {
    certificateCheck(certId: $certId, token: $token) {
      _id status issueDate email
      dataKeyValue { key value }
      certType { _id name }
    }
    me { _id email }
  }
`;

//******************************************************************************
// style definition
//******************************************************************************
const useStyles = makeStyles((theme) => ({
  container: {
    paddingTop: theme.spacing(3)
  },
  labelText: {
    color: theme.palette.secondary.main
  },
  cell: {
    width: '1%',
    whiteSpace: 'nowrap',
  },
  btn: {
    margin: 'auto',
    "&.MuiButtonBase-root": {
      margin: 'auto'
    }
  }
}));

//******************************************************************************
// component definition
//******************************************************************************
export default function CertCheck() {
  const classes = useStyles();
  const location = useLocation();
  const params = useParams();
  const [searchParams] = useSearchParams();
  const { loading, data } = useQuery(CERT_QUERY, { variables: {
    certId: params.id,
    token: searchParams.get("token")
  }});
  // console.log(data);

  //----------------------------------------------------------------------------
  // funtions
  //----------------------------------------------------------------------------
  // const handleReqToken = () => {
    
  // }

  //----------------------------------------------------------------------------
  // component rendering
  //----------------------------------------------------------------------------
  if (loading) return <LinearProgress />;
  if (!data) return 'Network error.'
  if (!data.certificateCheck) return <NotFount />

  return (
    <Container maxWidth="md" className={classes.container}>
      <div>
        <a href='/'><img alt="logo" src="/lc-logo.png" /></a>
      </div>
      <Paper>
        {/* <CardHeader title="Certification"  /> */}
        { data.certificateCheck.status? 
            <Table>
              <TableBody>
                <TableRow>
                  <TableCell className={classes.cell}>
                    <span className={classes.labelText}>Certification:</span>
                  </TableCell>
                  <TableCell>
                    {data.certificateCheck.certType.name}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell className={classes.cell}>
                    <span className={classes.labelText}>Status:</span>
                  </TableCell>
                  <TableCell>
                    {data.certificateCheck.status}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell className={classes.cell}>
                    <span className={classes.labelText}>Issue date:</span>
                  </TableCell>
                  <TableCell>
                    {data.certificateCheck.issueDate}
                  </TableCell>
                </TableRow>
                { data.me && data.me.email===data.certificateCheck.email && 
                  <TableRow>
                    <TableCell className={classes.cell}>
                      <span className={classes.labelText}>Published:</span>
                    </TableCell>
                    <TableCell>
                      {data.certificateCheck.publish? 'Yes' : 'No'}
                    </TableCell>
                  </TableRow>
                }
                { data.certificateCheck.dataKeyValue.map(item=>(
                  <TableRow key={item.key}>
                    <TableCell className={classes.cell}>
                      <span className={classes.labelText}>{item.key}:</span>
                    </TableCell>
                    <TableCell>
                      {item.value}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          : 
          <>
            <CardContent>
              <p>
                This certificate is only visible to owner. If you are the owner, please login to the system or click "Send me email" to reqest a temporary entry link.
              </p>
            </CardContent>
            <CardActions>
              <Link className={classes.btn} 
                state={{ from: location }}
                to={SiteMap.login}>
                <Button>Login</Button>
              </Link>
              <SendEmailReqBtn certId={params.id} />
              {/* <Button className={classes.btn}>Send me email</Button> */}
            </CardActions>
          </>
        }
      </Paper>
    </Container>
  );
}

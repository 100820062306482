import React from 'react';
import { useNavigate } from "react-router-dom";
import { Divider, LinearProgress } from '@mui/material';
import { makeStyles } from '@material-ui/core/styles';
import { useQuery } from "@apollo/client";
import Tag from '../Tag';
import { TAGS_FILTER } from '../../constants/gqls/tags';
import SiteMap from '../../constants/siteMap';

//******************************************************************************
// style definition
//******************************************************************************
const useStyles = makeStyles((theme) => ({
  tags: {
    marginTop: theme.spacing(1),
    color: theme.palette.text.secondary,
    fontSize: '0.875rem',
    "& button": {
      margin: '0 6px 0 0'
    }
  },
  hr: {
    "&.MuiDivider-root": {
      marginTop: theme.spacing(1),
    }
  }
}));

//******************************************************************************
// component definition
//******************************************************************************
export default function Filter({tags}) {
  const navigate = useNavigate();
  const classes = useStyles();
  const { loading, data } = useQuery(TAGS_FILTER, { variables: {
    where: { _id_IN: tags }, tags
  }});
  // console.log(data);

  //----------------------------------------------------------------------------
  // funtions
  //----------------------------------------------------------------------------
  const selectTag = (tagId) => {
    navigate(SiteMap.tagsFilter+'?tags='+tags.concat(tagId).join(','));
  }

  const deselectTag = (tagId) => {
    if (tags.length > 1) {
      navigate(SiteMap.tagsFilter+'?tags='+tags.filter(t=>t!==tagId).join(','));
    }
  }

  //----------------------------------------------------------------------------
  // component rendering
  //----------------------------------------------------------------------------
  if (loading) return <LinearProgress />;
  if (!data) return 'Network error.'

  return (
    <div>
      <div className={classes.tags}>
        <span>Selected tags: </span>
        { data.tags.map(tag=>(
          <Tag key={tag._id} text={tag.key} type={tag.type}
            tagId={tag._id} onTagClick={deselectTag} />
        ))}
      </div>
      <Divider className={classes.hr} />
        <div className={classes.tags}>
          <span>Select more: </span>
          { data.relatedTags.map(tag=>(
            <Tag key={tag._id} text={tag.key} type={tag.type}
              tagId={tag._id} onTagClick={selectTag} />
          ))}
        </div>
    </div>
  );
}

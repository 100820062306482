import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

//******************************************************************************
// style definition
//******************************************************************************
const useStyles = makeStyles((theme) => ({
  frame: {
    width: '100%'
  }
}));

//******************************************************************************
// component definition
//******************************************************************************
export default function GoogleDoc({serviceId}) {
  const classes = useStyles();
  const src = `https://drive.google.com/file/d/${serviceId}/preview`;

  return (
    <iframe src={src} title="Google file preview" frameBorder="0"
      width="560" height="320" className={classes.frame} />
  );
}

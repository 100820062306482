import React from 'react';
import { TextField } from '@mui/material';

//******************************************************************************
// component definition
//******************************************************************************
export default function ValueFieldText({text, update}) {
  const [value, setValue] = React.useState(text || '');
  const timer = React.useRef(0);

  React.useEffect(() => {
    return () => {
      clearTimeout(timer.current);
    }
  }, []);

  //----------------------------------------------------------------------------
  // funtions
  //----------------------------------------------------------------------------
  const handleChange = (event) => {
    setValue(event.target.value);
    clearTimeout(timer.current);
    timer.current = setTimeout(()=>{
      updateValue(event.target.value);
    }, 500);
  }

  const updateValue = (name) => {
    update({ name }).then(res=>{
      console.log(res);
    });
  }

  //----------------------------------------------------------------------------
  // component rendering
  //----------------------------------------------------------------------------
  return (
    <TextField label="Field Name" variant="outlined" fullWidth
      value={value} onChange={handleChange} />
  )
}

import React from 'react';
import { LinearProgress, Tooltip } from '@mui/material';
import TextareaAutosize from '@mui/material/TextareaAutosize';
import { makeStyles } from '@material-ui/core/styles';
import { useReactiveVar } from "@apollo/client";
import { doFocus } from '../../constants/cache';

//******************************************************************************
// style definition
//******************************************************************************
const useStyles = makeStyles((theme) => ({
  input: {
    width: '100%',
    border: 'none',
    resize: 'none',
    fontFamily: theme.typography.fontFamily,
    fontSize: '1rem',
    lineHeight: 1.5,
    '&:focus': {
      outline: 'none'
    }
  }
}));

//******************************************************************************
// component definition
//******************************************************************************
export default function ItemTextEdit({itemText, addItem, loading, editItem, setFocus}) {
  const doScroll = useReactiveVar(doFocus);
  const classes = useStyles();
  const [text, setText] = React.useState(itemText || "");
  const [changed, setChanged] = React.useState(false);
  const [added, setAdded] = React.useState(false);
  const [newline, setNewline] = React.useState(true);
  const timer = React.useRef(0);
  const ref = React.useRef(0);

  React.useEffect(() => {
    if (doScroll && added) {
      // console.log('scrollIntoView');
      ref.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
      setAdded(false);
      doFocus(false);
    }
  }, [doScroll, added]);

  React.useEffect(() => {
    return () => {
      clearTimeout(timer.current);
    }
  }, []);

  //----------------------------------------------------------------------------
  // funtions
  //----------------------------------------------------------------------------
  const update = () => {
    if (!loading) {
      clearTimeout(timer.current);
      if (changed) {
        editItem(text).then((res)=>{
          setChanged(false);
          ref.current.focus();
          // console.log(res);
        });
      }
    }
  }

  const onTextChange = (event) => {
    // console.log(window.getSelection().getRangeAt(0).getBoundingClientRect());
    // console.log(event.target.selectionStart);
    // setText(event.target.value.replaceAll(/[\n]+/g, ' '));
    setText(event.target.value);
    setChanged(true);
    if (editItem) {
      clearTimeout(timer.current);
      timer.current = setTimeout(update, 3000);
    }
  }

  const handleKeyDown = (event) => {
    switch (event.key) {
      case "Shift":
        setNewline(false);
        break;
      case "Enter":
        if (!newline) {
          event.preventDefault();
          if (addItem) {
            if (!loading) {
              doFocus(false);
              addItem(text).then(res=>{
                // console.log(res);
                setAdded(true);
                setText('');
                ref.current.focus();
                setTimeout(()=>{
                  ref.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
                }, 100);
              });
            }
          } else if (editItem) {
            update();
          }
        }
        break;
      default:
        break;
    }
    // if (event.key === 'Enter') {
    //   event.preventDefault();
    //   if (addItem) {
    //     if (!loading) {
    //       doFocus(false);
    //       addItem(text).then(res=>{
    //         // console.log(res);
    //         setAdded(true);
    //         setText('');
    //         ref.current.focus();
    //         setTimeout(()=>{
    //           ref.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
    //         }, 100);
    //       });
    //     }
    //   } else if (editItem) {
    //     update();
    //   }
    // }
  }

  const handleKeyUp = (event) => {
    // console.log(event.key);
    if (event.key === 'Shift') {
      setNewline(true);
    }
  }

  const handleBlur = () => {
    if (editItem) {
      update();
    }
    setFocus(false);
  }

  const handleFocus = () => {
    setFocus(true);
  }

  //----------------------------------------------------------------------------
  // component rendering
  //----------------------------------------------------------------------------
  return (
    <React.Fragment>
      { loading && <LinearProgress /> }
      <Tooltip title="Shift+Enter to submit">
        <TextareaAutosize ref={ref} className={classes.input} minRows={itemText? 1 : 3}
          value={text} onChange={onTextChange} onBlur={handleBlur} onFocus={handleFocus}
          onKeyDown={handleKeyDown} onKeyUp={handleKeyUp} disabled={loading} />
      </Tooltip>
    </React.Fragment>
  );
}

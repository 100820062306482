import React from 'react';
// import { Typography } from '@mui/material';
import GoogleDoc from './GoogleDoc';
import YouTube from './YouTube';
import { makeStyles } from '@material-ui/core/styles';
import { doFocus } from '../../constants/cache';

//******************************************************************************
// style definition
//******************************************************************************
const useStyles = makeStyles((theme) => ({
  link: {
    textDecoration: 'none',
    color: theme.palette.text.primary
  },
  title: {
    margin: 0,
    padding: '0 '+theme.spacing(1)
  },
  description: {
    padding: '0 '+theme.spacing(1),
  },
  image: {
    width: '100%'
  }
}));

//******************************************************************************
// component definition
//******************************************************************************
export default function LinkContent({link, setSignal}) {
  const classes = useStyles();
  const ref = React.useRef(0);
  // console.log(link);

  React.useEffect(()=>{
    if (link.service && setSignal) {
      setSignal(`${link._id} loaded`);
    }
  }, [link, setSignal])

  //----------------------------------------------------------------------------
  // funtions
  //----------------------------------------------------------------------------
  const onImgLoad = () => {
    if (ref.current.complete) {
      // console.log(link.title);
      doFocus(true);
      if (setSignal) {
        setSignal(`${link._id} loaded`);
      }
    }
  }

  //----------------------------------------------------------------------------
  // component rendering
  //----------------------------------------------------------------------------
  switch (link.service) {
    case 'google':
      return <GoogleDoc serviceId={link.serviceId} />
    case 'gmail':
      return (
        <a href={link.url} target="_blank" rel="noreferrer" className={classes.link}>
          <p>Gmail: {link.serviceId}</p>
        </a>
      );
    case 'youtube':
      return <YouTube serviceId={link.serviceId} />
    default:
      return (
        <a href={link.url} target="_blank" rel="noreferrer" className={classes.link}>
          <h3 className={classes.title}>{link.title}</h3>
          <div className={classes.description}>
            {link.description}
          </div>
          { link.image &&
            <img src={link.image} alt={classes.title} className={classes.image}
              ref={ref} onLoad={onImgLoad} />
          }
        </a>
      );
  }
}

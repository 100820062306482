import React from 'react';
import LinkIcon from '@mui/icons-material/Link';
import GoogleIcon from '@mui/icons-material/Google';
import EmailIcon from '@mui/icons-material/Email';
import YouTubeIcon from '@mui/icons-material/YouTube';

//******************************************************************************
// component definition
//******************************************************************************
export default function Icon({service}) {
  switch (service) {
    case 'google':
      return <GoogleIcon />
    case 'gmail':
      return <EmailIcon />
    case 'youtube':
      return <YouTubeIcon />
    default:
      return <LinkIcon />
  }
}

import React from 'react';
import InfiniteScroll from 'react-infinite-scroller';
import { List, LinearProgress } from '@mui/material';
import { useQuery } from "@apollo/client";
import TagTypeItem from './TagTypeItem';
import { TAG_TYPES } from '../../constants/gqls/tags';
const limit = 10;

//******************************************************************************
// component definition
//******************************************************************************
function TagTypesList() {
  const { loading, data, fetchMore } = useQuery(TAG_TYPES, { variables: {
    options: { limit, offset: 0, sort: [{ name: "ASC" }]}
  }});

  //----------------------------------------------------------------------------
  // funtions
  //----------------------------------------------------------------------------
  const loadMore = () => {
    fetchMore({ variables: {
      options: { limit, offset: data.tagTypes.length }
    }});
  }

  //----------------------------------------------------------------------------
  // component rendering
  //----------------------------------------------------------------------------
  if (loading) return <LinearProgress />;
  if (!data) return 'Network error.'
  if (data.tagTypesCount === 0) {
    return '**Empty list**'
  }

  return (
    <InfiniteScroll
        loadMore={loadMore}
        hasMore={data.tagTypesCount > data.tagTypes.length}
        loader={<LinearProgress key="loading" />}
    >
      <List>
        { data.tagTypes.map(tagType=>(
          <TagTypeItem key={tagType._id} tagType={tagType} />
        )) }
      </List>
    </InfiniteScroll>
  );
}

export default TagTypesList;

import React from 'react';
import { ListItemButton, ListItemText } from '@mui/material';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import ColumnDialog from './ColumnDialog';


//******************************************************************************
// component definition
//******************************************************************************
export default function ColumnItem({column, setColumn}) {
  const [open, setOpen] = React.useState(false);
  const [withFilter, setWithFilter] = React.useState(false);
  console.log(column);

  React.useEffect(() => {
    if (column.filter?.type === "Number" && column.filter.num1 && 
      (column.filter.matchType === "Equals" ||
      (column.filter.matchType === "Within range" && column.filter.num2))) 
    {
      setWithFilter(true);
    } else {
      setWithFilter(false)
    }
  }, [column.filter]);

  //----------------------------------------------------------------------------
  // funtions
  //----------------------------------------------------------------------------
  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  //----------------------------------------------------------------------------
  // component rendering
  //----------------------------------------------------------------------------
  return (
    <>
      <ListItemButton onClick={handleOpen}>
        <ListItemText primary={column.name} secondary={column.value} />
        { withFilter && 
          <FilterAltIcon />
        }
      </ListItemButton>
      <ColumnDialog column={column} setColumn={setColumn}
        open={open} handleClose={handleClose} />
    </>
  );
}

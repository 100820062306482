import React from 'react';
import { generatePath } from "react-router-dom";
import InfiniteScroll from 'react-infinite-scroller';
import { LinearProgress, List, ListItemButton, ListItemText } from '@mui/material';
import { useQuery } from "@apollo/client";
import { MY_PROFILE_fORMS } from '../../constants/gqls/documents';
import SiteMap from '../../constants/siteMap';
const limit = 10;


//******************************************************************************
// component definition
//******************************************************************************
export default function PersonInfoCard() {
  const { loading, data, fetchMore } = useQuery(MY_PROFILE_fORMS, {
    variables: { limit, offset: 0 }
  });

  //----------------------------------------------------------------------------
  // funtions
  //----------------------------------------------------------------------------
  const loadMore = () => {
    fetchMore({
      variables: {
        limit,
        offset: data.myProfileForms.length
      }
    });
  }


  //----------------------------------------------------------------------------
  // component rendering
  //----------------------------------------------------------------------------
  if (loading) return <LinearProgress />;
  if (!data) return 'Network error.'
  if (data.myProfileFormsCount === 0) return ''

  return (
    <InfiniteScroll
      loadMore={loadMore}
      useWindow={false}
      hasMore={data.myProfileFormsCount > data.myProfileForms.length}
      loader={<LinearProgress key="loading" />}
    >
      <List>
        {data.myProfileForms.map(form => (
          <ListItemButton key={form._id} component="a" href={generatePath(SiteMap.document, { id: form._id })}>
            <ListItemText primary={form.title} />
          </ListItemButton>
        ))}
      </List>
    </InfiniteScroll>
  );
}

import React from 'react';
import { LinearProgress, Table, TableRow, TableCell, TableHead, TableBody, TableContainer, TablePagination } from '@mui/material';
import { makeStyles } from '@material-ui/core/styles';
import { useQuery } from "@apollo/client";
import { VALUES } from '../../constants/gqls/dataValues';

const limit = 10;
const columnDefs = [{
  headerName: 'text',
  field: 'text'
}, {
  headerName: 'min',
  field: 'min'
}, {
  headerName: 'max',
  field: 'max'
}, {
  headerName: 'bool',
  field: 'bool'
}]

//******************************************************************************
// style definition
//******************************************************************************
const useStyles = makeStyles((theme) => ({
  root: {
    width: '540px'
  }
}));

//******************************************************************************
// component definition
//******************************************************************************
export default function ValuesList({valueFieldId}) {
  const classes = useStyles();
  const [page, setPage] = React.useState(0);
  const { data, loading, fetchMore } = useQuery(VALUES, { variables: {
    where: { field: { _id: valueFieldId }},
    options: { limit,
      offset: 0,
      sort: [{ _id: 'ASC' }]
    }
  }});

  //----------------------------------------------------------------------------
  // funtions
  //----------------------------------------------------------------------------
  // const loadMore = () => {
  //   fetchMore({ variables: {
  //     options: { limit,
  //       offset: data.values.length,
  //       sort: [{ _id: 'ASC' }]
  //     }
  //   }});
  // }

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    if (newPage >= data.values.length/limit) {
      fetchMore({ variables: {
        options: { limit,
          offset: data.values.length,
          sort: [{ _id: 'ASC' }]
        }
      }});
    }
  };

  //----------------------------------------------------------------------------
  // component rendering
  //----------------------------------------------------------------------------
  if (loading) return <LinearProgress />;
  if (!data) return 'Network error.'
  if (data.valuesCount === 0) {
    return 'No value.'
  }

  return (
    <div className={classes.root}>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              { columnDefs.map(col=>(
                <TableCell key={col.field}>{col.headerName}</TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            { data.values.slice(page*limit, (page+1)*limit).map(value=>(
              <TableRow key={value._id}>
                { columnDefs.map(col=>(
                  <TableCell key={col.field}>
                    {value[col.field]}
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination component="div"
        rowsPerPageOptions={[10]}
        rowsPerPage={limit}
        count={data.valuesCount}
        page={page}
        onPageChange={handleChangePage} />
    </div>
  );
}

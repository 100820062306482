import React from 'react';
import { ListItem, ListItemIcon, ListItemText } from '@mui/material';
import { makeStyles } from '@material-ui/core/styles';

//******************************************************************************
// style definition
//******************************************************************************
const useStyles = makeStyles((theme) => ({
  root: {
    '& .MuiListItemIcon-root': {
      minWidth: '32px',
    },
    '& svg' : {
      color: theme.palette.action.disabled,
    },
    height: '56px',
    position: 'relative'
  },
}));

//******************************************************************************
// component definition
//******************************************************************************
export default function InputOptionOtherItem({icon}) {
  const classes = useStyles();

  //----------------------------------------------------------------------------
  // component rendering
  //----------------------------------------------------------------------------
  return (
    <ListItem className={classes.root}>
      <ListItemIcon>
        {icon}
      </ListItemIcon>
      <ListItemText primary="Other" />
    </ListItem>
  )
}

export const tagTypeSum = '_id name bgColor textColor';
export const personSum = '_id name email roles isMe title';
export const personDetails = `${personSum} active request createdAt lastLogin imageUrl office phone position introduction title studyLevel isUGC`;
export const tagSum = `_id key tagable type { ${tagTypeSum} }`;
export const spaceSum = `_id name description myRole`;
export const actionDetails = `_id active promptTemplate variables editable type fire emailTemplateId`;
export const linkDetails = `link {
  _id url serviceId title description image logo service
}`;
export const valueSetDetails = `
  _id valueList {
    _id text field { _id name index toStr }
  } owners { ${personSum} }
`;
export const dataDetails = `
  _id value values boolValue options { _id text } 
  valueSetList { ${valueSetDetails} }
`;
export const valueFieldsDetails = `
  _id index name required multiline maxTextLength inputType options deletable
`;
export const inputDetails = `
  _id type required deletable other importFormId fieldKey displayValueFields defaultFieldValues
  profileKey { _id key }
  valueSetInput { _id 
    profileKey { _id key }
    valueFields { ${valueFieldsDetails} }
  }
  valueFields { ${valueFieldsDetails} }
  service { _id name }
  applicationFilterInputs { _id }
  hasFilterInput
  weeklyDayOptions weeklyHourOptions isDataFilter filterNot
  optionsConnection {
    edges { index node { _id text hide quota value quotaLeft(formId: $docId) }}
  }
`;

export const inputUpdateDetails = `
  _id type required deletable other importFormId fieldKey displayValueFields defaultFieldValues
  profileKey { _id key }
  valueSetInput { _id 
    profileKey { _id key }
    valueFields { ${valueFieldsDetails} }
  }
  valueFields { ${valueFieldsDetails} }
  service { _id name }
  applicationFilterInputs { _id }
  hasFilterInput
  weeklyDayOptions weeklyHourOptions isDataFilter filterNot
  optionsConnection {
    edges { index node { _id text hide quota value }}
  }
`;

export const emailTemplateDetails = `
  _id isSystem key description variables subject bcc html createdAt updatedAt
`;

export const serviceSessionSum = `
  _id year semester categories status hours hoursCompleted createdAt
  participantsConnection {
    edges {
      role node { ${personSum} }
    }
  }
`;

export const itemDetails = `
  _id type text editable
  tags { ${tagSum} } ${linkDetails} input { ${inputDetails} }
  preconditions { _id text }
`;

export const itemReadOnly = `
  _id type text editable
  tags { ${tagSum} } ${linkDetails} input { ${inputUpdateDetails} }
`;

export const sharedTo = `sharedToSpaceConnection {
    totalCount edges {
      access node {
        _id name
      }
    }
  } sharedToConnection {
  totalCount edges {
    access node {
      ${personSum}
    }
  }
}`;

export const docDetails = `
  _id scope title description createdAt updatedAt editable isOwner myRole isForm isProfileForm isReportForm inputEnabled formOpen formClose quota multipleSubmit myLastSub canSubmit quotaLeft
  submitPrompt { _id promptTemplate }
  createdBy { ${personSum} }
  tags { ${tagSum} }
  ownerPerson { ${personSum} }
  ownerSpace { _id name email }
  itemsConnection {
    totalCount edges {
      index node { ${itemDetails} }
    }
  }
  ${sharedTo}
`;

export const boardDetails = `
  boards {
    _id scope cards(options: $options) {
      _id type width height index
    }
  }
`;

export const spaceMembers = `
  membersConnection { totalCount }
`;

import { gql } from '@apollo/client';
import { emailTemplateDetails } from './fragments';

export const EMAIL_TEMPLATES = gql`
  query EmailTemplates($options: Email_templateOptions) {
    emailTemplatesCount
    emailTemplates(options: $options) {
      _id key description createdAt
    }
  }
`;

export const EMAIL_TEMPLATE = gql`
  query EmailTemplate($id: ID) {
    emailTemplate(id: $id) {
      ${emailTemplateDetails}
    }
  }
`;

export const CREATE_EMAIL_TEMPLATE = gql`
  mutation CreateEmailTemplate($input: [Email_templateCreateInput!]!) {
    createEmail_templates(input: $input) {
      emailTemplates {
        _id
      }
    }
  }
`;

export const UPDATE_EMAIL_TEMPLATE = gql`
  mutation UpdateEmailTemplate($id: ID!, $update: Email_templateUpdateInput!) {
    updateEmailTemplate(id: $id, update: $update) {
      emailTemplates {
        ${emailTemplateDetails}
      }
    }
  }
`;

export const DELETE_EMAIL_TEMPLATE = gql`
  mutation DeleteEmailTemplates($where: Email_templateWhere) {
    deleteEmail_templates(where: $where) {
      nodesDeleted
    }
  }
`;

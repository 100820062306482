import React from 'react';
import { Divider, Checkbox, Radio } from '@mui/material';
import { useMutation } from "@apollo/client";
import ValueFieldInput from './ValueFieldInput';
import FunctionBtn from './FunctionBtn';
import { UPDATE_VALUE } from '../../../constants/gqls/dataValues';

function getValue(valueSet, fieldId) {
  let values = valueSet.valueList.filter(v=>v.field._id===fieldId);
  if (values.length) {
    return values[0];
  }
  return null;
}

//******************************************************************************
// component definition
//******************************************************************************
export default function ValueSet({valueSet, valueFields, disabled, count, index, setIndex, dataId, addValueSet, removeValueSet, selectValueSet, selected}) {
  const [updateValue, { loading }] = useMutation(UPDATE_VALUE);
  const [checked, setChecked] = React.useState(false);
  // console.log(valueSet);

  React.useEffect(() => {
    if (selected) {
      setChecked(selected.includes(valueSet._id))
    }
  }, [selected, valueSet]);

  //----------------------------------------------------------------------------
  // funtions
  //----------------------------------------------------------------------------
  const update = (valueFieldId, value) => {
    return updateValue({ variables: {
      valueSetId: valueSet._id,
      valueFieldId, value
    }});
  };

  const handleChecked = (event) => {
    if (addValueSet && removeValueSet) {
      if (event.target.checked) {
        addValueSet(valueSet._id);
      } else {
        removeValueSet(valueSet._id);
      }
    }
  };

  const handleSelect = (event) => {
    if (selectValueSet) {
      selectValueSet(valueSet._id);
    }
  };

  //----------------------------------------------------------------------------
  // component rendering
  //----------------------------------------------------------------------------
  return (
    <div className='slideItem'>
      <Divider>
        { addValueSet && removeValueSet &&
          <Checkbox checked={checked} onChange={handleChecked} />
        }
        { selectValueSet &&
          <Radio checked={checked} onChange={handleSelect} />
        }
        <FunctionBtn valueSet={valueSet} dataId={dataId}
          count={count} 
          index={index} setIndex={setIndex}
          disabled={disabled || loading} />
      </Divider>
      <div>
        { [...valueFields].sort((a,b)=>a.index-b.index).map(valueField=>(
          <ValueFieldInput key={valueField._id} disabled={disabled || loading}
            valueField={valueField} 
            value={getValue(valueSet, valueField._id)}
            update={update} />
        ))}
      </div>
    </div>
  )
}

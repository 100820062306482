import React from 'react';
import { LinearProgress, Button, CardContent } from '@mui/material';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { useQuery } from "@apollo/client";
import { dynamicQuery } from '../../constants/gqls/dynamicQuery';
import DataTable from './DataTable';


//******************************************************************************
// component definition
//******************************************************************************
export default function DataNeo4jQuery({dataTable, setEditMode}) {
  const queryGQL = dynamicQuery(dataTable.whereType, dataTable.optionsType, dataTable.reportType, dataTable.queryFields);
  const { data, loading, error } = useQuery(queryGQL, { variables: {
    where: dataTable.where? JSON.parse(dataTable.where) : undefined
  }});
  // console.log(tableData);
  console.log(error);

  //----------------------------------------------------------------------------
  // component rendering
  //----------------------------------------------------------------------------
  if (loading) return <LinearProgress />;
  if (!data) return (
    <CardContent>
      <Button variant="outlined" startIcon={<ArrowBackIosIcon />}
        onClick={setEditMode}>
        Edit table
      </Button>
      <p>
        Query error! Please check table setting.
      </p>
    </CardContent>
  );

  return <DataTable 
    rawData={data[dataTable.reportType]}
    dataColumns={dataTable.dataTableColumns}
    setEditMode={setEditMode} />;
}

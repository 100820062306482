import React from 'react';
import { IconButton, Tooltip, Dialog, DialogTitle,
  DialogContent } from '@mui/material';
// import LoadingButton from '@mui/lab/LoadingButton';
import AddIcon from '@mui/icons-material/Add';
import { makeStyles } from '@material-ui/core/styles';
// import { useMutation } from "@apollo/client";
import ServiceFormSelect from './ServiceFormSelect';
import PaticipantsPairsInput from './PaticipantsPairsInput';
import StatusSelect from './StatusSelect';
// import { SERVICE_FORMS } from '../../constants/gqls/forms';
// import SiteMap from '../../constants/siteMap';

//******************************************************************************
// style definition
//******************************************************************************
const useStyles = makeStyles((theme) => ({
  dialog: {
    '& .MuiPaper-root': {
      width: '600px'
    }
  }
}));

//******************************************************************************
// component definition
//******************************************************************************
export default function AddBtn({service}) {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [importForm, setImportForm] = React.useState(null);
  const [status, setStatus] = React.useState('Created');
  // const [deleteServiceSession, { loading }] = useMutation(DELETE_SERVICE_SESSION);
  // console.log(importForm);

  //----------------------------------------------------------------------------
  // funtions
  //----------------------------------------------------------------------------
  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  // const onSubmitClick = () => {
  //   // deleteServiceSession({ variables: {
  //   //   id: serviceSessionId
  //   // }}).then(res=>{
  //   //   // goto
  //   //   navigate(-1, { replace: true });
  //   // });
  // }

  //----------------------------------------------------------------------------
  // component rendering
  //----------------------------------------------------------------------------
  return (
    <React.Fragment>
      <Tooltip title="Add new service session">
        <span>
          <IconButton onClick={handleOpen}>
            <AddIcon />
          </IconButton>
        </span>
      </Tooltip>
      <Dialog open={open} onClose={handleClose} className={classes.dialog}>
        <DialogTitle>
          Add service sessions for {service.name}
        </DialogTitle>
        <DialogContent>
          <ServiceFormSelect serviceId={service._id} onChange={setImportForm} value={importForm} />
          <StatusSelect onChange={setStatus} value={status} />
        </DialogContent>
        { importForm &&
          <PaticipantsPairsInput serviceId={service._id} importFormId={importForm._id} status={status} />
        }
      </Dialog>
    </React.Fragment>
  )
}

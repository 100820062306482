import React from 'react';
import { useNavigate } from "react-router-dom";
import { IconButton, Button, Tooltip, Dialog, DialogTitle,
  DialogContent, DialogContentText, DialogActions } from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import DeleteIcon from '@mui/icons-material/Delete';
import { useMutation } from "@apollo/client";
import { DELETE_CERT_TYPE } from '../../constants/gqls/certificates';
// import SiteMap from '../../constants/siteMap';

//******************************************************************************
// component definition
//******************************************************************************
export default function DeleteBtn({certType}) {
  const navigate = useNavigate();
  const [open, setOpen] = React.useState(false);
  const [deleteCertType, { loading }] = useMutation(DELETE_CERT_TYPE);

  //----------------------------------------------------------------------------
  // funtions
  //----------------------------------------------------------------------------
  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const onDeleteClick = () => {
    deleteCertType({ variables: {
      id: certType._id
    }}).then(res=>{
      // goto
      navigate(-1, { replace: true });
    });
  }

  //----------------------------------------------------------------------------
  // component rendering
  //----------------------------------------------------------------------------
  return (
    <React.Fragment>
      <Tooltip title="Delete Certificate Type">
        <IconButton onClick={handleOpen} disabled={certType && !certType.deletable}>
          <DeleteIcon />
        </IconButton>
      </Tooltip>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>
          Confirm Delete?
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            By deleting this certification type, related template and certificate requests will be deleted.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <LoadingButton variant="outlined"
            loading={loading} onClick={onDeleteClick}>
            Delete
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  )
}

import React from 'react';
import { Grid, Popover } from '@mui/material';
import { makeStyles } from '@material-ui/core/styles';
import AttendanceRate from '../Services/AttendanceRate';
import StudentPrivateInfo from './StudentPrivateInfo';
import ParticipantDeleteBtn from './ParticipantDeleteBtn';


//******************************************************************************
// style definition
//******************************************************************************
const useStyles = makeStyles((theme) => ({
  formLink: {
    color: theme.palette.primary.main,
    textDecoration: 'none',
    fontWeight: 'bold'
  },
  bold: {
    fontWeight: 'bold'
  },
  popover: {
    "& .MuiPaper-root": {
      borderRadius: '20px',
      backgroundColor: theme.palette.primary.pale,
    }
  }
}));

//******************************************************************************
// component definition
//******************************************************************************
export default function ParticipantsItem({role, participant, serviceSession, isLast, removeStudent, loading}) {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);

  //----------------------------------------------------------------------------
  // funtions
  //----------------------------------------------------------------------------
  const handleOpen = (event) => {
    if (removeStudent) {
      event.preventDefault();
      setAnchorEl(event.currentTarget);
    }
  }

  const handleClose = () => {
    setAnchorEl(null);
  }

  const handleDelete = () => {
    removeStudent(participant._id);
  }

  //----------------------------------------------------------------------------
  // component rendering
  //----------------------------------------------------------------------------
  if (role === 'Student') {
    return (
      <>
        <Grid container spacing={1}>
          <Grid item xs={12} md="auto">
            <span className={classes.bold} onContextMenu={handleOpen}>{participant.name}</span>
          </Grid>
          { participant.formPath &&
            <Grid item xs={12} md="auto">
              <a href={participant.formPath} className={classes.formLink}>
                Application Form
              </a>
            </Grid>
          }
          <Grid item xs={12} md="auto">
            <AttendanceRate 
              serviceId={serviceSession.service._id}
              personId={participant._id} />
          </Grid>
          <StudentPrivateInfo 
            studentId={participant._id} 
            refId={serviceSession._id} />
          {!isLast && 
            <Grid item xs={12}><hr/></Grid>
          }
        </Grid>
        <Popover open={Boolean(anchorEl)} 
          anchorEl={anchorEl} 
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'center',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'center',
            horizontal: 'right',
          }} className={classes.popover}>
            <ParticipantDeleteBtn handleDelete={handleDelete} loading={loading} />
        </Popover>
      </>     
    );
  }
  return (
    <div>{participant.name}</div>
  )
}

import React from 'react';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { CssBaseline } from '@mui/material';
import RequireAuth from './RequireAuth';
import RequireAdmin from './RequireAdmin';
import PagesCommt from '../components/PagesCommt';
import Login from '../pages/Login';
import Person from '../pages/Person';
import MyDocuments from '../pages/MyDocuments';
import SharedDocuments from '../pages/SharedDocuments';
import Document from '../pages/Document';
import FormData from '../pages/FormData';
import MyTags from '../pages/MyTags';
import TagsFilter from '../pages/TagsFilter';
import Spaces from '../pages/Spaces';
import Space from '../pages/Space';
import AdminUsers from '../pages/AdminUsers';
import AdminProfiles from '../pages/AdminProfiles';
import AdminTags from '../pages/AdminTags';
import AdminPages from '../pages/AdminPages';
import AdminEmails from '../pages/AdminEmails';
import EmailTemplate from '../pages/EmailTemplate';
import CertTypes from '../pages/CertTypes';
import CertType from '../pages/CertType';
import CertRequest from '../pages/CertRequest';
import CertCheck from '../pages/CertCheck';
import Services from '../pages/Services';
import Service from '../pages/Service';
import ServiceSession from '../pages/ServiceSession';
import ServiceSessionsData from '../pages/ServiceSessionsData';
import Courses from '../pages/Courses';
import Course from '../pages/Course';
import PageManage from '../pages/PageManage';
import PageView from '../pages/PageView';
import NotFount from '../pages/NotFount';
import DataAnalysis from '../pages/DataAnalysis';
import DataAnalysisTable from '../pages/DataAnalysisTable';
import SiteMap from '../constants/siteMap';


//******************************************************************************
// components definition
//******************************************************************************
const IndexRoute = () => {
  //----------------------------------------------------------------------------
  // component rendering
  //----------------------------------------------------------------------------
  return (
    <BrowserRouter>
      <CssBaseline />
      <Routes>
        <Route element={<PagesCommt />}>
          <Route path={SiteMap.home} element={
            <RequireAuth>
              <Person />
            </RequireAuth>
          } />
          <Route path={SiteMap.person} element={
            <RequireAuth>
              <Person />
            </RequireAuth>
          } />
          <Route path={SiteMap.myDocuments} element={
            <RequireAuth>
              <MyDocuments />
            </RequireAuth>
          } />
          <Route path={SiteMap.sharedDocuments} element={
            <RequireAuth>
              <SharedDocuments />
            </RequireAuth>
          } />
          <Route path={SiteMap.document} element={
            <RequireAuth>
              <Document />
            </RequireAuth>
          } />
          <Route path={SiteMap.formData} element={
            <RequireAuth>
              <FormData />
            </RequireAuth>
          } />
          <Route path={SiteMap.tags} element={
            <RequireAuth>
              <MyTags />
            </RequireAuth>
          } />
          <Route path={SiteMap.tagsFilter} element={
            <RequireAuth>
              <TagsFilter />
            </RequireAuth>
          } />
          <Route path={SiteMap.spaces} element={
            <RequireAuth>
              <Spaces />
            </RequireAuth>
          } />
          <Route path={SiteMap.space} element={
            <RequireAuth>
              <Space />
            </RequireAuth>
          } />
          <Route path={SiteMap.email_template} element={
            <RequireAuth>
              <EmailTemplate />
            </RequireAuth>
          } />
          <Route path={SiteMap.services} element={
            <RequireAuth>
              <Services />
            </RequireAuth>
          } />
          <Route path={SiteMap.service} element={
            <RequireAuth>
              <Service />
            </RequireAuth>
          } />
          <Route path={SiteMap.serviceSession} element={
            <RequireAuth>
              <ServiceSession />
            </RequireAuth>
          } />
          <Route path={SiteMap.serviceSessionsData} element={
            <RequireAuth>
              <ServiceSessionsData />
            </RequireAuth>
          } />
          <Route path={SiteMap.courses} element={
            <RequireAuth>
              <Courses />
            </RequireAuth>
          } />
          <Route path={SiteMap.course} element={
            <RequireAuth>
              <Course />
            </RequireAuth>
          } />
          <Route path={SiteMap.certificates} element={
            <RequireAuth>
              <CertTypes />
            </RequireAuth>
          } />
          <Route path={SiteMap.certificate} element={
            <RequireAuth>
              <CertType />
            </RequireAuth>
          } />
          <Route path={SiteMap.pageManage} element={
            <RequireAuth>
                <PageManage />
            </RequireAuth>
          } />
          <Route path={SiteMap.admin_users} element={
            <RequireAuth>
              <RequireAdmin>
                <AdminUsers />
              </RequireAdmin>
            </RequireAuth>
          } />
          <Route path={SiteMap.admin_profiles} element={
            <RequireAuth>
              <RequireAdmin>
                <AdminProfiles />
              </RequireAdmin>
            </RequireAuth>
          } />
          <Route path={SiteMap.admin_tags} element={
            <RequireAuth>
              <RequireAdmin>
                <AdminTags />
              </RequireAdmin>
            </RequireAuth>
          } />
          <Route path={SiteMap.admin_pages} element={
            <RequireAuth>
              <RequireAdmin>
                <AdminPages />
              </RequireAdmin>
            </RequireAuth>
          } />
          <Route path={SiteMap.admin_emails} element={
            <RequireAuth>
              <RequireAdmin>
                <AdminEmails />
              </RequireAdmin>
            </RequireAuth>
          } />
          <Route path={SiteMap.admin_data_analysis} element={
            <RequireAuth>
              <RequireAdmin>
                <DataAnalysis />
              </RequireAdmin>
            </RequireAuth>
          } />
          <Route path={SiteMap.admin_data_table_details} element={
            <RequireAuth>
              <RequireAdmin>
                <DataAnalysisTable />
              </RequireAdmin>
            </RequireAuth>
          } />
        </Route>
        <Route path={SiteMap.login} element={<Login />} />
        <Route path={SiteMap.pageView} element={<PageView />} />
        <Route path={SiteMap.certRequest} element={<CertRequest />} />
        <Route path={SiteMap.certCheck} element={<CertCheck />} />
        <Route path="*" element={<NotFount />} />
      </Routes>
    </BrowserRouter>
  );
};

export default IndexRoute;

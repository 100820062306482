import React from 'react';
// import clsx from 'clsx';
import { useSearchParams } from "react-router-dom";
import { ListItemButton, ListItemText } from '@mui/material';
import SupportAgentIcon from '@mui/icons-material/SupportAgent';
import { makeStyles } from '@material-ui/core/styles';
import FormSubFunctions from './FormSubFunctions';
import { DateTimeToString } from '../../constants/utils';

//******************************************************************************
// style definition
//******************************************************************************
const useStyles = makeStyles((theme) => ({
  item: {
    '&.MuiListItemButton-root': {
      background: (props) => props.selected? 'linear-gradient(90deg, '+ theme.palette.secondary.light +' 0%, rgba(255,255,255,0) 100%)' : 'unset'
    },
  },
  service: {
    color: theme.palette.primary.light,
    // '&.css-i4bv87-MuiSvgIcon-root': {
    //   position: 'absolute',
    //   color: theme.palette.primary.light,
    //   fontSize: '3rem'
    // }
  }
}));

//******************************************************************************
// component definition
//******************************************************************************
export default function FormSubItem({submission}) {
  const [searchParams, setSearchParams] = useSearchParams();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [selected, setSelected] = React.useState(false);
  const classes = useStyles({selected});
  // console.log(submission);

  React.useEffect(() => {
    if (searchParams.get("submission") === submission._id) {
      setSelected(true);
    } else {
      setSelected(false);
    }
  }, [searchParams, submission]);

  //----------------------------------------------------------------------------
  // funtions
  //----------------------------------------------------------------------------
  const handleClick = () => {
    if (!selected) {
      setSearchParams({ submission: submission._id });
    }
  }

  const handleEdit = () => {
    setSearchParams({
      submission: submission._id,
      action: 'editData'
    });
  }

  const onRightClick = (event) => {
    event.preventDefault();
    setAnchorEl(event.currentTarget);
  }

  const handleClose = () => {
    setAnchorEl(null);
  }

  //----------------------------------------------------------------------------
  // component rendering
  //----------------------------------------------------------------------------
  return (
    <React.Fragment>
      <ListItemButton className={classes.item}
        onClick={handleClick} onContextMenu={onRightClick}>
        <ListItemText primary={submission.ownerPerson?.name || "Guest"} />
        { submission.serviceSessionId &&
          <SupportAgentIcon className={classes.service} />
        }
        { DateTimeToString(submission.submittedTime) }
      </ListItemButton>
      <FormSubFunctions submission={submission}
        anchorEl={anchorEl} handleClose={handleClose}
        handleEdit={handleEdit} />
    </React.Fragment>
  );
}

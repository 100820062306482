import React from 'react';
import { CardContent, Typography, TextField, CardActions, Button } from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import SaveIcon from '@mui/icons-material/Save';
import { makeStyles } from '@material-ui/core/styles';
// import { useMutation } from "@apollo/client";
// import { CREATE_SPACE } from '../../constants/gqls/spaces';
import HtmlEditor from "../ContentEditor/HtmlEditor";

//******************************************************************************
// style definition
//******************************************************************************
const useStyles = makeStyles((theme) => ({
  content: {
    '&.MuiCardContent-root': {
      paddingTop: (props) => props.update ? 0 : theme.spacing(2)
    }
  },
  name: {
    '&.MuiTextField-root': {
      margin: theme.spacing(2),
      marginLeft: 0
    }
  },
  btn: {
    '&.MuiButton-root' : {
      margin: 'auto'
    }
  }
}));

//******************************************************************************
// component definition
//******************************************************************************
export default function SpaceInfoInputs({
  space, createSpace, updateSpace, saveThen, loading
}) {
  const classes = useStyles({ update: Boolean(updateSpace) });
  const [name, setName] = React.useState(space?.name || '');
  const [email, setEmail] = React.useState(space?.email || '');
  const [description, setDescription] = React.useState(space?.description || '');
  // const [createSpace, { loading }] = useMutation(CREATE_SPACE);

  //----------------------------------------------------------------------------
  // funtions
  //----------------------------------------------------------------------------
  const onNameChange = (event) => {
    setName(event.target.value);
  }

  const onEmailChange = (event) => {
    setEmail(event.target.value);
  }

  // const onDescriptionChange = (event) => {
  //   setDescription(event.target.value);
  // }

  const onDescriptionChange = (value) => {
    if (value !== description) {
      setDescription(value);
      // setChanged(true);
    }
  }

  const save = () => {
    if (name.trim() === '') {
      return;
    }

    if (createSpace) {
      createSpace({ variables: {
        name, description,
      }}).then(res => {
        // console.log(res);
        setName('');
        setDescription('');
        saveThen();
      });
    } else if (updateSpace) {
      updateSpace({ variables: {
        spaceId: space._id,
        update: {name, email, description,}
      }}).then(res => {
        saveThen();
      });
    }
  }

  const handleCancel = () => {
    setName(space.name);
    setDescription(space.description);
    saveThen();
  }

  //----------------------------------------------------------------------------
  // component rendering
  //----------------------------------------------------------------------------
  return (
    <React.Fragment>
      <CardContent className={classes.content}>
        { Boolean(createSpace) &&
          <Typography>
            Create a space to work together with your teammates.
          </Typography>
        }
        <TextField variant="outlined" className={classes.name}
          id="space-name" label="Space Name"
          value={name} onChange={onNameChange} disabled={loading} />
        <TextField variant="outlined" className={classes.name}
          id="space-email" type="email" label="Space Email"
          value={email} onChange={onEmailChange} disabled={loading} />
        <HtmlEditor html={space?.description || ''}
          onChange={onDescriptionChange} disabled={loading}
          placeholder="Description" />
      </CardContent>
      <CardActions>
        <Button variant="outlined" className={classes.btn} onClick={handleCancel}>
          Cancel
        </Button>
        <LoadingButton variant="contained" className={classes.btn}
          startIcon={<SaveIcon />} loading={loading} onClick={save}>
          Save
        </LoadingButton>
      </CardActions>
    </React.Fragment>
  )
}

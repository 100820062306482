import React from 'react';
import { IconButton, Tooltip } from '@mui/material';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';

//******************************************************************************
// component definition
//******************************************************************************
export default function UpBtn({itemId, index, sortItem}) {

  //----------------------------------------------------------------------------
  // funtions
  //----------------------------------------------------------------------------
  const handleClick = () => {
    sortItem(itemId, index-1).then(res=>{
      // console.log(res);
    });
  }

  //----------------------------------------------------------------------------
  // component rendering
  //----------------------------------------------------------------------------
  return (
    <Tooltip title="Move up" placement="left">
      <IconButton onClick={handleClick}>
        <ArrowUpwardIcon />
      </IconButton>
    </Tooltip>
  )
}

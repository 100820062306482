import { gql } from '@apollo/client';

export function dynamicQuery(whereType, optionsType, queryType, queryFields) {
  return gql`
    query AnalysisQuery($where: ${whereType}, $options: ${optionsType}) {
      ${queryType}(where: $where, options: $options) {
        ${queryFields}
      }
    }
  `;
}

export function freeSoloQuery(statement) {
  return gql`
    query ${statement}
  `;
}
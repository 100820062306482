import React from 'react';
import { IconButton, Tooltip, TextField, InputAdornment, Popover } from '@mui/material';
import LocalOfferIcon from '@mui/icons-material/LocalOffer';
import SearchIcon from '@mui/icons-material/Search';
import { makeStyles } from '@material-ui/core/styles';
import { useQuery, useMutation } from "@apollo/client";
import Tag from '../Tag';
import { TAGS_FOR_ADD, TAG_OBJECT } from '../../constants/gqls/tags';

//******************************************************************************
// style definition
//******************************************************************************
const useStyles = makeStyles((theme) => ({
  content: {
    padding: theme.spacing(2),
    width: '380px',
  },
  row: {
    width: '380px',
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    marginTop: theme.spacing(-2),
    "& button": {
      margin: theme.spacing(1)
    }
  }
}));

//******************************************************************************
// component definition
//******************************************************************************
export default function AddBtn({excludes, objId}) {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [search, setSearch] = React.useState('');
  const [tagObject, { loading }] = useMutation(TAG_OBJECT, {
    refetchQueries: [ TAGS_FOR_ADD ],
  });
  const { data } = useQuery(TAGS_FOR_ADD, { variables: {
    excludes, search
  }});
  const open = Boolean(anchorEl);

  //----------------------------------------------------------------------------
  // funtions
  //----------------------------------------------------------------------------
  const handleClose = () => {
    setSearch('');
    setAnchorEl(null);
  }

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const onSearchChange = (event) => {
    setSearch(event.target.value);
  }

  const addTag = (tagId) => {
    return tagObject({ variables: {
      tagId, objId
    }});
  };

  //----------------------------------------------------------------------------
  // component rendering
  //----------------------------------------------------------------------------
  return (
    <React.Fragment>
      <Tooltip title="Add tag">
        <IconButton color="primary" onClick={handleClick}>
          <LocalOfferIcon />
        </IconButton>
      </Tooltip>
      <Popover open={open} anchorEl={anchorEl} onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}>
        <div className={classes.content}>
          <TextField id="tag-search" autoFocus fullWidth label="Search" variant="standard"
            value={search} onChange={onSearchChange}
            InputProps={{ startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            )}} />
        </div>
        <div className={classes.row}>
          { data && data.tagsForAdd.map(tag=>(
            <Tag key={tag._id} text={tag.key} type={tag.type}
              tagId={tag._id} onTagClick={addTag} loading={loading} />
          ))}
        </div>
      </Popover>
    </React.Fragment>
  );
}

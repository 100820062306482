import React from 'react';
import { IconButton, ClickAwayListener } from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import DeleteBtn from './DeleteBtn';
import OwnershipBtn from './OwnershipBtn';
import ShareBtn from './ShareBtn';
import { makeStyles } from '@material-ui/core/styles';

//******************************************************************************
// style definition
//******************************************************************************
const useStyles = makeStyles((theme) => ({
  root: {
    position: 'absolute',
    top: theme.spacing(1),
    right: theme.spacing(1),
  }
}));

//******************************************************************************
// component definition
//******************************************************************************
export default function FunctionBtn({doc}) {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [prevent, setPrevent] = React.useState(false);

  //----------------------------------------------------------------------------
  // funtions
  //----------------------------------------------------------------------------
  const handleOpen = () => {
    setOpen(true);
  }

  const handleClose = () => {
    if (!prevent) {
      setOpen(false);
    }
  }

  //----------------------------------------------------------------------------
  // component rendering
  //----------------------------------------------------------------------------
  return (
    <div className={classes.root}>
      { open?
        <ClickAwayListener onClickAway={handleClose}>
          <div>
            { doc.isOwner &&
              <React.Fragment>
                <DeleteBtn docId={doc._id} />
                <OwnershipBtn doc={doc} />
              </React.Fragment>
            }
            <ShareBtn doc={doc} setPrevent={setPrevent} />
          </div>
        </ClickAwayListener>
        :
        <IconButton color="primary" onClick={handleOpen}>
          <MoreVertIcon />
        </IconButton>
      }
    </div>
  );
}

import React from 'react';
import { TextField, InputAdornment } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';

//******************************************************************************
// component definition
//******************************************************************************
export default function SearchInput({value, onChange}) {

  //----------------------------------------------------------------------------
  // funtions
  //----------------------------------------------------------------------------
  const onSearchChange = (event) => {
    onChange(event.target.value);
  }

  //----------------------------------------------------------------------------
  // component rendering
  //----------------------------------------------------------------------------
  return (
    <TextField fullWidth id="search" label="Search" variant="standard"
      value={value} onChange={onSearchChange}
      InputProps={{ startAdornment: (
        <InputAdornment position="start">
          <SearchIcon />
        </InputAdornment>
      )}} />
  );
}

import React from 'react';
import { gql } from '@apollo/client';
import InfiniteScroll from 'react-infinite-scroller';
import { useQuery } from "@apollo/client";
import { List, LinearProgress, Dialog, DialogTitle, DialogContent } from '@mui/material';
import ProfileKeyItem from './ProfileKeysListItem';
import ProfileKeyDetails from './ProfileKeyDetails';
const limit = 10;

const PROFILE_KEYS = gql`
  query ProfileKeys($where: ProfileKeyWhere, $options: ProfileKeyOptions) {
    profileKeys(where: $where, options: $options) {
      _id key attribute 
      valueFields { _id index name }
      input { _id hasData items { _id ownerDoc { _id title }}}
    }
    profileKeysCount(where: $where)
  }
`;

//******************************************************************************
// component definition
//******************************************************************************
function ProfileKeysList({ where }) {
  const [profileKey, setProfileKey] = React.useState(null);
  const { loading, data, fetchMore, refetch } = useQuery(PROFILE_KEYS, { variables: { 
    where,
    options: { 
      limit, 
      offset: 0,
      sort: { key: "ASC" } 
    }
  }});
  console.log(profileKey);

  //----------------------------------------------------------------------------
  // funtions
  //----------------------------------------------------------------------------
  const loadMore = () => {
    fetchMore({ variables: {
      options: { limit, offset: data.profileKeys.length }
    }});
  }

  const handleClose = () => {
    setProfileKey(null);
  }

  const deleteThen = () => {
    setProfileKey(null);
    refetch();
  }

  //----------------------------------------------------------------------------
  // component rendering
  //----------------------------------------------------------------------------
  if (loading) return 'Loading...';
  if (!data) return 'Network error.'
  if (data.profileKeysCount === 0) {
    return '**Empty list**'
  }

  return (
    <>
      <Dialog open={profileKey!==null} onClose={handleClose}>
        <DialogTitle>Profile Key Details</DialogTitle>
        <DialogContent>
          <ProfileKeyDetails profileKey={profileKey} deleteThen={deleteThen} />
        </DialogContent>
      </Dialog>
      <InfiniteScroll
          loadMore={loadMore}
          hasMore={data.profileKeysCount > data.profileKeys.length}
          loader={<LinearProgress key="loading" />}
      >
        <List>
          { data.profileKeys.map(profileKey=>(
            <ProfileKeyItem key={profileKey._id} 
              profileKey={profileKey}
              setProfileKey={setProfileKey} />
          )) }
        </List>
      </InfiniteScroll>
    </>
  );
}

export default ProfileKeysList;


import React from 'react';
import InfiniteScroll from 'react-infinite-scroller';
import { LinearProgress, List } from '@mui/material';
import { makeStyles } from '@material-ui/core/styles';
import { useQuery } from "@apollo/client";
import FormMyDataItem from './FormMyDataItem';
import { MY_SUBMISSIONS } from '../../constants/gqls/forms';
const limit = 10;

//******************************************************************************
// style definition
//******************************************************************************
const useStyles = makeStyles((theme) => ({
  list: {
    maxHeight: '480px',
    overflow: 'auto'
  }
}));

//******************************************************************************
// component definition
//******************************************************************************
export default function FormMyDataList({docId}) {
  const classes = useStyles();
  const { data, loading, fetchMore } = useQuery(MY_SUBMISSIONS, { variables: {
    docId, limit,
    offset: 0,
  }});

  //----------------------------------------------------------------------------
  // funtions
  //----------------------------------------------------------------------------
  const loadMore = () => {
    fetchMore({ variables: {
      offset: data.mySubmissions.length
    }});
  }

  //----------------------------------------------------------------------------
  // component rendering
  //----------------------------------------------------------------------------
  if (loading) return <LinearProgress />;
  if (!data) return 'Network error.'
  if (data.mySubmissionsCount === 0) {
    return 'No record...'
  }

  return (
    <div className={classes.list}>
      <InfiniteScroll
          loadMore={loadMore}
          useWindow={false}
          hasMore={data.mySubmissionsCount > data.mySubmissions.length}
          loader={<LinearProgress key="loading" />}
      >
        <List>
          { data.mySubmissions.map(submission=>(
            <FormMyDataItem key={submission._id} submission={submission} />
          )) }
        </List>
      </InfiniteScroll>
    </div>
  );
}

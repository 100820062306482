import React from 'react';
import { FormControl, FormGroup, FormControlLabel, 
  Checkbox, FormHelperText } from '@mui/material';
import { useQuery } from "@apollo/client";
import { PROFILE_KEYS } from '../../constants/gqls/profileKeys';

const properties = [{
  _id: "name",
  name: "Name (user property)"
}, {
  _id: "email",
  name: "Email (user property)"
}, {
  _id: "isUGC",
  name: "Is UGC student (user property)"
}];

function getLabel(profileKey) {
  let valueFields = profileKey.valueFields.map(vf=>vf.name);
  return (profileKey.key + (valueFields.length!==0? ` (value fields: ${valueFields.join(', ')})`: ''))
}

function selectErrCheck(selected) {
  let checkError = false;
  if (selected.length <= 1) {
    checkError = false;
  } else {
    for (let i = 0; i < selected.length; i++) {
      if (selected[i].valueFields.length !== 0) {
        checkError = true;
        break;
      }
    }
  }
  return checkError;
}

function reportErrCheck(selected) {
  // console.log(selected);
  let dataSetCount = 0, propteriesCount = 0, checkError = false;
  for (let i = 0; i < selected.length; i++) {
    if (selected[i].valueFields && selected[i].valueFields.length !== 0) {
      dataSetCount++;
      if (dataSetCount > 1) {
        checkError = true;
        break;
      }
    } else if (selected[i].__typename !== 'ProfileKey') {
      propteriesCount++;
    }
  }
  if (selected.length !== 0 && propteriesCount === selected.length) {
    checkError = true;
  }
  return checkError;
}

//******************************************************************************
// component definition
//******************************************************************************
function ProfileKeysSelector({ selected, setSelected, error, setError, forReport }) {
  const { loading, data } = useQuery(PROFILE_KEYS, { 
    variables: { options: { 
      sort: { key: "ASC" } 
    }
  }});
  // console.log(selected)

  //----------------------------------------------------------------------------
  // funtions
  //----------------------------------------------------------------------------
  // const loadMore = () => {
  //   fetchMore({ variables: {
  //     options: { limit, offset: data.profileKeys.length }
  //   }});
  // }

  const handleChange = (event) => {
    let newSelected = [];
    if (event.target.checked) {
      let checked = data.profileKeys.filter(pk=>pk._id === event.target.name)[0];
      if (!checked) {
        checked = properties.filter(pk=>pk._id === event.target.name)[0];
      }
      if (checked) {
        newSelected = [...selected, checked];
      }
    } else {
      newSelected = selected.filter(pk=>pk._id !== event.target.name);
    }
    setSelected(newSelected);
    setError(forReport? reportErrCheck(newSelected) : selectErrCheck(newSelected));
  };

  //----------------------------------------------------------------------------
  // component rendering
  //----------------------------------------------------------------------------
  if (loading) return 'Loading...';
  if (!data) return 'Network error.'
  if (data.profileKeys.length === 0) {
    return '**No profile keys yet**'
  }

  return (
      <FormControl fullWidth id="profile-keys-selector" 
        error={error}>
        <FormGroup>
          { forReport && properties.map(property=>
            <FormControlLabel key={property._id}
              control={
                <Checkbox checked={selected?.includes(property)}
                  onChange={handleChange}
                  name={property._id} />
              }
              label={property.name}
            />
          )}
          { data.profileKeys.map(profileKey=>
            <FormControlLabel key={profileKey._id}
              control={
                <Checkbox checked={selected?.includes(profileKey)}
                  onChange={handleChange}
                  name={profileKey._id} />
              }
              label={getLabel(profileKey)}
            />
          )}
        </FormGroup>
        <FormHelperText>{forReport ? 
          "Select no more than one profile key with value fields. And should not selete only the user property fields, because it will results in returning all existing users in the system (about 10k records)." 
          : 
          "Select one profile key with value fields or many profile keys without value fields."}
        </FormHelperText>
      </FormControl>
  );
}

export default ProfileKeysSelector;

import React from 'react';
import { Grid, IconButton, TextField } from '@mui/material';
// import { makeStyles } from '@material-ui/core/styles';
import SaveIcon from '@mui/icons-material/Save';
import EditIcon from '@mui/icons-material/Edit';
import EditOffIcon from '@mui/icons-material/EditOff';
import { useQuery, useMutation } from "@apollo/client";
import { GET_STUDENT_PRIVATE_INFO, UPDATE_STUDENT_PRIVATE_INFO } from '../../constants/gqls/studentPrivateInfo';


//******************************************************************************
// style definition
//******************************************************************************
// const useStyles = makeStyles((theme) => ({
//   formLink: {
//     color: theme.palette.primary.main,
//     textDecoration: 'none',
//     fontWeight: 'bold'
//   },
//   bold: {
//     fontWeight: 'bold'
//   }
// }));

//******************************************************************************
// component definition
//******************************************************************************
export default function StudentPrivateInfo({studentId, refId}) {
  // const classes = useStyles();
  const [preTestResult, setPreTestResult] = React.useState('');
  const [postTestResult, setPostTestResult] = React.useState('');
  const [changed, setChanged] = React.useState(false);
  const [editMode, setEditMode] = React.useState(false);
  const [updateInfo, { loading }] = useMutation(UPDATE_STUDENT_PRIVATE_INFO);
  const {data} = useQuery(GET_STUDENT_PRIVATE_INFO,
    { variables: { studentId, refId }}
  );
  // console.log(data);

  React.useEffect(() => {
    if (data && data.getStudentPrivateInfo) {
      setPreTestResult(data.getStudentPrivateInfo.preTestResult);
      setPostTestResult(data.getStudentPrivateInfo.postTestResult);
    }
  }, [data]);
  
  //----------------------------------------------------------------------------
  // funtions
  //----------------------------------------------------------------------------
  const handlePreChange = (event) => {
    setPreTestResult(event.target.value);
    setChanged(true)
  }

  const handlePostChange = (event) => {
    setPostTestResult(event.target.value);
    setChanged(true)
  }

  const handleEdit = () => {
    setEditMode(!editMode);
  }

  const handleSave = () => {
    if (changed) {
      updateInfo({
        variables: {
          type: 'serviceSession',
          studentId, refId, input: {
            preTestResult, postTestResult
          }
        }
      }).then(()=>{
        setEditMode(false);
      });
    }
  }

  //----------------------------------------------------------------------------
  // component rendering
  //----------------------------------------------------------------------------
  if (data && data.getStudentPrivateInfo) {
    return (
      <Grid item xs={12} container spacing={1} alignItems="center">
        <Grid item xs={12} md="auto">
          {data.getStudentPrivateInfo.confirmed ? 
            '[Confirmed]' : 'Not yet confirmed'
          }
        </Grid>
        <Grid item xs={6} md={4} lg={2}>
          { editMode? 
            <TextField label="Pre-test" size='small'
              value={preTestResult} onChange={handlePreChange}  />
            : "Pre-test: "+(data.getStudentPrivateInfo.preTestResult || 'N/A')
          }
        </Grid>
        <Grid item xs={6} md={4} lg={2}>
          { editMode? 
            <TextField label="Post-test" size='small'
              value={postTestResult} onChange={handlePostChange}  />
            : "Post-test: "+(data.getStudentPrivateInfo.postTestResult || 'N/A')
          }
        </Grid>
        { data.getStudentPrivateInfo.myRole !== 'owner' &&
          (editMode? 
            <>
              <Grid item xs={6} md="auto">
                <IconButton size="small" 
                  disabled={loading || !changed}
                  onClick={handleSave}>
                  <SaveIcon fontSize="small" />
                </IconButton>
              </Grid>
              <Grid item xs={6} md="auto">
                <IconButton size="small" onClick={handleEdit}>
                  <EditOffIcon fontSize="small" />
                </IconButton>
              </Grid> 
            </>
            : 
            <Grid item xs={6} md={4} lg={2}>
              <IconButton size="small" onClick={handleEdit}>
                <EditIcon fontSize="small" />
              </IconButton>
            </Grid> 
          )
        }
      </Grid>
    )
  }
}

import React from 'react';
import { Button, IconButton,
  DialogContent, DialogContentText, DialogActions } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { useQuery, useMutation } from "@apollo/client";
import PaticipantsPairSelect from './PaticipantsPairSelect';
import { SERVICE_FORM_SUBMISSIONS } from '../../constants/gqls/forms';
import { CREATE_SERVICE_SESSION, SERVICE_SESSIONS } from '../../constants/gqls/serviceSessions';

const newItem = {
  providers: [],
  clientSubs: [],
  meetingTime: null,
  serviceSession: null
};

//******************************************************************************
// component definition
//******************************************************************************
export default function PaticipantsPairsInput({serviceId, importFormId, status}) {
  const [paticipantsPairs, setPaticipantsPairs] = React.useState([{ ...newItem }]);
  const { data, loading:dataLoading } = useQuery(SERVICE_FORM_SUBMISSIONS, { variables: {
    docId: importFormId
  }});
  const [createServiceSession, { loading }] = useMutation(CREATE_SERVICE_SESSION, {
    refetchQueries: [ SERVICE_SESSIONS, SERVICE_FORM_SUBMISSIONS ],
  });
  // console.log(paticipantsPairs);

  //----------------------------------------------------------------------------
  // funtions
  //----------------------------------------------------------------------------
  const setProviders = (index) => {
    return (inProviders) => {
      let newValues = [...paticipantsPairs];
      let newValue = {...paticipantsPairs[index]};
      newValue.providers = inProviders;
      newValues[index] = newValue;
      setPaticipantsPairs(newValues);
    }
  }

  const setClientSubs = (index) => {
    return (inClientSubs) => {
      let newValues = [...paticipantsPairs];
      let newValue = {...paticipantsPairs[index]};
      newValue.clientSubs = inClientSubs;
      newValues[index] = newValue;
      setPaticipantsPairs(newValues);
    }
  }

  const setMeetingTime = (index) => {
    return (inMeetingTime) => {
      let newValues = [...paticipantsPairs];
      let newValue = {...paticipantsPairs[index]};
      newValue.meetingTime = inMeetingTime;
      newValues[index] = newValue;
      setPaticipantsPairs(newValues);
    }
  }

  const setServiceSession = (index, inServiceSession) => {
    // let newValues = [...paticipantsPairs];
    // let newValue = {...paticipantsPairs[index]};
    // newValue.serviceSession = inServiceSession;
    // newValues[index] = newValue;
    // setPaticipantsPairs(newValues);
    paticipantsPairs[index].serviceSession = inServiceSession;
  }

  const addPair = () => {
    let newValues = [...paticipantsPairs, { ...newItem }];
    setPaticipantsPairs(newValues);
  }

  const deletePair = (index) => {
    return () => {
      let newValues = [...paticipantsPairs];
      newValues.splice(index, 1);
      setPaticipantsPairs(newValues);
    }
  }

  const handleCreate = (index) => {
    // checking
    // console.log(index);
    if (index < paticipantsPairs.length) {
      // console.log(paticipantsPairs[index]);
      if (paticipantsPairs[index].providers &&
          paticipantsPairs[index].clientSubs &&
          paticipantsPairs[index].meetingTime &&
          status &&
          !paticipantsPairs[index].serviceSession) {
            // call
            createServiceSession({ variables: {
              serviceId,
              providerIds: paticipantsPairs[index].providers.map(prov=>prov._id),
              clientSubIds: paticipantsPairs[index].clientSubs.map(clt=>clt._id),
              meetingTime: paticipantsPairs[index].meetingTime,
              status,
            }}).then(res=>{
              // console.log(res);
              let serviceSessionId = res.data.createServiceSession._id;
              // console.log(serviceSessionId);
              setServiceSession(index, serviceSessionId);
              setTimeout(()=>{
                handleCreate(index+1);
              }, 500);
            });
      } else {
        handleCreate(index+1);
      }
    }
  }

  const onSubmitClick = () => {
    handleCreate(0);
  }

  //----------------------------------------------------------------------------
  // component rendering
  //----------------------------------------------------------------------------
  if (dataLoading) return 'Loading..'

  return (
    <>
      <DialogContent>
        <DialogContentText>
          Participants pairs:
          <IconButton onClick={addPair}>
            <AddIcon />
          </IconButton>
        </DialogContentText>
        { paticipantsPairs.map((paticipantsPair, index)=>(
          <PaticipantsPairSelect key={index}
            refFormSubs={data.submittedAll}
            paticipantsPair={paticipantsPair}
            setProviders={setProviders(index)}
            setClientSubs={setClientSubs(index)}
            setMeetingTime={setMeetingTime(index)}
            deletePair={deletePair(index)}
            loading={loading} />
        ))}
      </DialogContent>
      <DialogActions>
        <Button variant="outlined"
          disabled={loading} onClick={onSubmitClick}>
          Submit
        </Button>
      </DialogActions>
    </>
  );
}

import React from 'react';
import { Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions,
   Button } from '@mui/material';
import { makeStyles } from '@material-ui/core/styles';
import InputImportForm from './InputImportForm';
import ServiceInput from '../Services/ServiceInput';

//******************************************************************************
// style definition
//******************************************************************************
const useStyles = makeStyles((theme) => ({
  dialogText: {
    '&.MuiDialogContentText-root': {
      marginBottom: theme.spacing(2),
    }
  }
}));

//******************************************************************************
// component definition
//******************************************************************************
export default function InputImportFormDialog({open, handleClose, loading, importFormId, service, updateInput, importType, importInputs, disableServiceFormUpdate}) {
  const classes = useStyles();
  const [importFormValue, setImportFormValue] = React.useState(importFormId || '');
  const [serviceValue, setServiceValue] = React.useState(service || null);
  const [inputs, setInputs] = React.useState([]);
  const blockSubmit = !(serviceValue && (importFormValue || importType === 'selectStudents'))
  // console.log(importInputs);

  React.useEffect(() => {
    setInputs(importInputs || [])
  }, [importInputs]);

  //----------------------------------------------------------------------------
  // funtions
  //----------------------------------------------------------------------------
  const handleImportOK = () => {
    let inputObj = {
      type: importType,
      service: { connect: { where: { node: { _id: serviceValue._id }}}}
    }
    if (importType !== 'selectStudents') {
      inputObj.importForm = { connect: { where: { node: { _id: importFormValue }}}};
    }
    if (importType === 'applicationFilter') {
      inputObj.applicationFilterInputs = [{ 
        connect: { where: { node: { _id_IN: inputs}}},
        disconnect: { where: { node: { _id_NOT_IN: inputs}}},
      }];
    }
    updateInput(inputObj).then(res=>{
      handleClose();
    });
  };

  const handleInputsChange = (inInputs) => {
    updateInput({
      applicationFilterInputs: [{ 
        connect: { where: { node: { _id_IN: inInputs}}},
        disconnect: { where: { node: { _id_NOT_IN: inInputs}}},
      }]
    }).then(res=>{
      // console.log(res);
    });
  };

  //----------------------------------------------------------------------------
  // component rendering
  //----------------------------------------------------------------------------
  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>For service</DialogTitle>
      <DialogContent>
        <DialogContentText className={classes.dialogText}>
          {{
            tutoringHour: '"Tutoring Hour" input is for teachers to select tutoring hour from students\' registration data. Please define the related service and where the data should be imported from.',
            applicationFilter: '"Application Filter" input is for teachers to select student applications from students\' registration data. Please define the related service and where the data should be imported from.',
            selectStudents: '"Select Students" input is for teachers to select students for services which do not require student registration. Please define the related service.'
          }[importType]}
        </DialogContentText>
        <ServiceInput label="Service" value={serviceValue} disabled={loading || disableServiceFormUpdate}
          onChange={setServiceValue} />
        { importType !== 'selectStudents' && 
          <InputImportForm disabled={loading || disableServiceFormUpdate}
            importFormValue={importFormValue} 
            setImportFormValue={setImportFormValue}
            importInputs={inputs} 
            loading={loading}
            setInputs={importType==='applicationFilter'? 
              (disableServiceFormUpdate? handleInputsChange: setInputs) 
              : null} />
        }
      </DialogContent>
      { !disableServiceFormUpdate &&
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={handleImportOK} disabled={blockSubmit}>
            OK
          </Button>
        </DialogActions>
      }
    </Dialog>
  )
}

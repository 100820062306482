
import React from 'react';
// import { makeStyles } from '@material-ui/core/styles';
import Title from './Title';
import Description from './Description';
import Item from './Item';
import SubmitBtn from './SubmitBtn';
import { formInputEnabled } from '../../constants/cache';

//******************************************************************************
// style definition
//******************************************************************************
// const useStyles = makeStyles((theme) => ({
//   paper: {
//     position: 'relative',
//     padding: theme.spacing(2),
//     '&.MuiPaper-root': {
//       backdropFilter: 'blur(8px)',
//       backgroundColor: 'rgba(255, 255, 255, 0.8)'
//     }
//   },
// }));

//******************************************************************************
// component definition
//******************************************************************************
export default function DocView({ doc }) {
  // const classes = useStyles();
  const [items, setItems] = React.useState([]);

  React.useEffect(() => {
    formInputEnabled(doc.inputEnabled);
  }, [doc.inputEnabled]);

  React.useEffect(() => {
    let itemlist = [...doc.itemsConnection.edges];
    itemlist.sort((a, b) => a.index - b.index);
    setItems(itemlist);
  }, [doc.itemsConnection]);

  //----------------------------------------------------------------------------
  // component rendering
  //----------------------------------------------------------------------------
  return (
    <>
      <Title text={doc.title} />
      <Description text={doc.description} />
      {items.map(item =>
        <Item key={item.node._id} item={item.node}
          index={item.index} count={items.length} mode="page" />
      )}
      <SubmitBtn submitPrompt={doc.submitPrompt} showSubmitBtn={doc.isForm} docId={doc._id} pageMode={true} />
    </>
  );
}

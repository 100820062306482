import React from 'react';
import LoadingButton from '@mui/lab/LoadingButton';
import { useLazyQuery } from "@apollo/client";
import { VIEW_CERT_TEMPLATE } from "../../constants/gqls/certificates"

//******************************************************************************
// component definition
//******************************************************************************
export default function ViewTemplateBtn({certTypeId}) {
  const [getFileName, { loading }] = useLazyQuery(VIEW_CERT_TEMPLATE, {
    variables: { certTypeId },
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      console.log(data);
      let downloadLink = process.env.REACT_APP_DOWNLOAD_URI+data.viewCertTemplate;
      console.log(downloadLink);
      let link = document.createElement('a');
      link.download = '';
      link.href = downloadLink;
      link.click();
    }
  });

  //----------------------------------------------------------------------------
  // funtions
  //----------------------------------------------------------------------------
  const handleClick = () => {
    getFileName();
  };


  //----------------------------------------------------------------------------
  // component rendering
  //----------------------------------------------------------------------------
  return (
    <LoadingButton loading={loading} onClick={handleClick}>
      View template
    </LoadingButton>
  )
}

import React from 'react';
import { Button, IconButton, Dialog, DialogTitle,
  DialogContent, DialogContentText, DialogActions } from '@mui/material';
// import LoadingButton from '@mui/lab/LoadingButton';
import DeleteIcon from '@mui/icons-material/Delete';
import { useMutation } from "@apollo/client";
import { DELETE_ATTENDANCE, SERVICE_SESSION_ATTENDANCES } from '../../constants/gqls/attendances';
import { SERVICE_SESSION } from '../../constants/gqls/serviceSessions';
// import SiteMap from '../../constants/siteMap';

//******************************************************************************
// component definition
//******************************************************************************
export default function DeleteBtn({attendance}) {
  const [open, setOpen] = React.useState(false);
  const [deleteAttendance, { loading }] = useMutation(DELETE_ATTENDANCE, {
    refetchQueries: [ SERVICE_SESSION_ATTENDANCES, SERVICE_SESSION ],
  });

  //----------------------------------------------------------------------------
  // funtions
  //----------------------------------------------------------------------------
  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const onDeleteClick = () => {
    deleteAttendance({ variables: {
      id: attendance._id
    }});
  }

  //----------------------------------------------------------------------------
  // component rendering
  //----------------------------------------------------------------------------
  return (
    <>
      <IconButton onClick={handleOpen}>
        <DeleteIcon />
      </IconButton>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>
          Confirm Delete?
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            Delete this {attendance.submitted? 'SUBMITTED' : 'drafting'} attendance?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button variant="outlined"
            disabled={loading} onClick={onDeleteClick}>
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

import React from 'react';
import { useParams } from "react-router-dom";
import { LinearProgress, Container, Paper, CardHeader } from '@mui/material';
import { makeStyles } from '@material-ui/core/styles';
import { useQuery } from "@apollo/client";
import NotFount from './NotFount';
import DataTable from '../components/ServiceSessions/DataTable';
import { SERVICE_SESSIONS_DATA } from '../constants/gqls/serviceSessions';

//******************************************************************************
// style definition
//******************************************************************************
const useStyles = makeStyles((theme) => ({
  container: {
    paddingTop: theme.spacing(3)
  },
}));

//******************************************************************************
// component definition
//******************************************************************************
export default function ServiceSessionsData() {
  const classes = useStyles();
  const params = useParams();
  const { loading, data } = useQuery(SERVICE_SESSIONS_DATA, { variables: {
    serviceId: params.id,
    servicesWhere: { _id: params.id },
    year: params.year
  }});
  // console.log(data);


  //----------------------------------------------------------------------------
  // funtions
  //----------------------------------------------------------------------------

  //----------------------------------------------------------------------------
  // component rendering
  //----------------------------------------------------------------------------
  if (loading) return <LinearProgress />;
  if (!data) return 'Network error.'
  if (!data.services[0] || !data.services[0].myRole) return <NotFount />

  return (
    <Container maxWidth={false} className={classes.container}>
      <Paper>
        <CardHeader title={`${data.services[0].name} sessions data for year ${params.year}`} />
        <DataTable data={data} />
      </Paper>
    </Container>
  );
}

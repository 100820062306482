import { gql } from '@apollo/client';


export const UPLOAD_FILE = gql`
  mutation UploadFile($systemFileType: SystemFileType!, $relatedId: ID!) {
    uploadFile(systemFileType: $systemFileType, relatedId: $relatedId)
  }
`;

export const DELETE_FILE = gql`
  mutation deleteFile($fileId: ID!) {
    deleteFile(fileId: $fileId)
  }
`;

export const DOWNLOAD_TOKEN_CERT_TEMPLATE = gql`
  query CertTemplateDownloadToken($id: ID!) {
    certTemplateDownloadToken(id: $id)
  }
`;

export const SUB_UPLOAD_NOTICE = gql`
  subscription UploadNotice {
    uploadNotice {
      message notify relatedId error
    }
  }
`;

import React from 'react';
import { Button, Dialog, DialogContent, DialogTitle } from '@mui/material';
import { makeStyles } from '@material-ui/core/styles';
import DataSetsQuery from './DataSetsQuery';
import DataQuery from './DataQuery';
import ProfileDataTable from './ProfileDataTable';

//******************************************************************************
// style definition
//******************************************************************************
const useStyles = makeStyles((theme) => ({
  btn: {
    "&.MuiButton-root": {
      margin: 'auto',
      display: 'block'
    }
  },
}));

//******************************************************************************
// component definition
//******************************************************************************
export default function ViewDataBtn({columns, selected}) {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [dsProfileKeyId, setDsProfileKeyId] = React.useState(null);
  const [dataSetsWhere, setDataSetsWhere] = React.useState(null);
  const [pkIds, setPkIds] = React.useState([]);
  const [dataSets, setDataSets] = React.useState([]);
  const [pkData, setPkData] = React.useState({});
  // console.log(dataSets);
  // console.log(pkData);

  //----------------------------------------------------------------------------
  // funtions
  //----------------------------------------------------------------------------
  const handleData = () => {
    // console.log(columns);
    if (columns.length !== 0) {
      // get data set profile key
      let dataSetPK = selected.filter(item=>item.valueFields?.length)[0];
      if (dataSetPK) {
        setDsProfileKeyId(dataSetPK._id);
        // get where for data set query
        let dsWhere = {
          data: { input: { profileKey: { _id: dataSetPK._id }}},
        };
        let valueColsWithFilter = columns.filter(col=>col.type==='value' && col.filter);
        if (valueColsWithFilter.length) {
          // value filters
          dsWhere.AND = valueColsWithFilter.map(col=>{
            let filter = col.filter;
            if (filter.type === "Number") {
              let n = Number(filter.num1);
              let m = Number(filter.num2);
              if (filter.num1 !== '' && !isNaN(n)) {
                if (filter.matchType === 'Equals') {
                  return { values: {
                    field: { _id: col.valueField._id },
                    max_GTE: n,
                    min_LTE: n
                  }};
                } else if (filter.matchType === 'Within range' && filter.num2 !== '' && !isNaN(m)) {
                  let nums = [n,m].sort((a,b)=>a-b);
                  return { values: {
                    field: { _id: col.valueField._id },
                    OR: [{
                      min_LTE: nums[0],
                      max_GTE: nums[0],
                    }, {
                      min_GTE: nums[0],
                      max_LTE: nums[1],
                    }, {
                      min_LTE: nums[1],
                      max_GTE: nums[1],
                    }]
                  }};
                }
              }
            } else if (filter.type === 'Text') {
              // TODO:
            } else if (filter.type === 'Boolean') {
              // TODO:
            } 
            return {};
          });
        }
        setDataSetsWhere(dsWhere);
      } else {
        setDataSetsWhere(null);
        setDsProfileKeyId(null);
      }

      // for data
      let dataCols = columns.filter(col=>col.type==='data');
      if (dataCols.length !== 0) {
        setPkIds(dataCols.map(col=>col.profileKey._id))
      } else {
        setPkIds([]);
      }

      setOpen(true);
    }
  }

  const handleClose = () => {
    setOpen(false);
    setPkIds([]);
    setDataSetsWhere(null);
    setDsProfileKeyId(null);
  };

  const pushPkData = (profileKey, data) => {
    let newData = {...pkData};
    newData[profileKey] = data;
    setPkData(newData);
  };

  //----------------------------------------------------------------------------
  // component rendering
  //----------------------------------------------------------------------------
  return (
    <>
      <Button variant="outlined" className={classes.btn}
        onClick={handleData}>
        View Data
      </Button>
      <Dialog fullWidth maxWidth="xl" 
        open={open} onClose={handleClose}
      >
        <DialogTitle>Profile Data</DialogTitle>
        <DialogContent>
          { dataSetsWhere && 
            <DataSetsQuery where={dataSetsWhere} setDataSets={setDataSets} /> 
          }
          { pkIds.map(profileKeyId=>(
            <DataQuery key={profileKeyId}
              profileKeyId={profileKeyId} setData={pushPkData} /> 
          ))}
          <ProfileDataTable 
            columns={columns}
            profileKeyId={dsProfileKeyId}
            dataSets={dataSets}
            pkData={pkData} />
        </DialogContent>
      </Dialog>
    </>
  );
}

import React from 'react';
// import { CardContent, Typography } from '@mui/material';
import { useMutation } from "@apollo/client";
import { UPDATE_SPACE_INFO } from '../../constants/gqls/spaces';
import SpaceInfoInputs from '../Space/SpaceInfoInputs';

//******************************************************************************
// component definition
//******************************************************************************
export default function SpaceInfoEdit({space, exitEdit}) {
  const [updateSpaceInfo, { loading }] = useMutation(UPDATE_SPACE_INFO);

  //----------------------------------------------------------------------------
  // funtions
  //----------------------------------------------------------------------------

  //----------------------------------------------------------------------------
  // component rendering
  //----------------------------------------------------------------------------
  return (
    <SpaceInfoInputs
      space={space}
      updateSpace={updateSpaceInfo}
      saveThen={exitEdit}
      loading={loading}
    />
  );
}

import React from 'react';
import { Collapse, FormControl, RadioGroup, FormControlLabel, Radio, List,
  Dialog, DialogContent, DialogTitle } from '@mui/material';
// import LoadingButton from '@mui/lab/LoadingButton';
import { makeStyles } from '@material-ui/core/styles';
import { useMutation } from "@apollo/client";
import PersonalShare from './PersonalShare';
import PersonalShareItem from './PersonalShareItem';
import { UPDATE_DOCUMENT } from '../../constants/gqls/documents';

// const scopeOutput = (inScope) => {
//   let scope_list = [];
//   for (const name in inScope) {
//     if (inScope[name]) {
//       scope_list.push(name);
//     }
//   }
//   return scope_list;
// }

//******************************************************************************
// style definition
//******************************************************************************
const useStyles = makeStyles((theme) => ({
  dialog: {
    '& .MuiPaper-root': {
      maxWidth: '640px',
      width: '640px',
    }
  }
}));

//******************************************************************************
// component definition
//******************************************************************************
export default function ShareDialog({doc, open, handleClose}) {
  const classes = useStyles();
  // const [scope, setScope] = React.useState(doc.scope[0]);
  const [updateDocument, { loading }] = useMutation(UPDATE_DOCUMENT);

  const selectList = [{
      name: 'personal',
      description : 'Share with someone or a space',
      collapse: <PersonalShare doc={doc} />
    }, {
      name: 'system',
      description : 'Share to all system users',
      collapse: 'This will allow all system user to be able to view this document.'
    }, {
      name: 'public',
      description : 'Publish this document',
      collapse: 'This will allow non-login guest to view this document. You can apply for a public page to publish this document. The application form can be found under "Public Page" service tag.'
    }
  ];

  //----------------------------------------------------------------------------
  // funtions
  //----------------------------------------------------------------------------
  const handleChange = (event) => {
    // setScope(event.target.value);
    updateDocument({ variables: {
      docId: doc._id,
      update: { scope: [event.target.value] }
    }}).then(res=>{
      // console.log(res);
      // handleClose();
    });
  };

  // const onUpdateClick = () => {
  //   updateDocument({ variables: {
  //     where: { _id: doc._id },
  //     update: { scope: [scope] }
  //   }}).then(res=>{
  //     console.log(res);
  //     handleClose();
  //   });
  // }

  //----------------------------------------------------------------------------
  // component rendering
  //----------------------------------------------------------------------------
  return (
    <Dialog open={open} onClose={handleClose} className={classes.dialog}>
      <DialogTitle>
        Document sharing
      </DialogTitle>
      <DialogContent>
        <List>
          <PersonalShareItem entity={doc.ownerPerson || doc.ownerSpace} access="Owner" />
        </List>
        <FormControl variant="standard" fullWidth>
         <RadioGroup value={doc.scope[0]} onChange={handleChange}
          disabled={loading || !doc.isOwner}>
          { selectList.map(item=>(
            <div key={item.name}>
              <FormControlLabel value={item.name}
                control={<Radio />}
                label={item.description}
              />
              <Collapse in={item.name === doc.scope[0]} timeout="auto" unmountOnExit>
                {item.collapse}
              </Collapse>
            </div>
          ))}
         </RadioGroup>
       </FormControl>
      </DialogContent>
        {/* <DialogActions>
           <Button onClick={handleClose}>Cancel</Button>
           <LoadingButton variant="outlined"
             loading={loading} onClick={onUpdateClick}>
             Update
           </LoadingButton>
        </DialogActions> */}
       
    </Dialog>
  )
}

import React from 'react';
import { Checkbox, FormControlLabel } from '@mui/material';
import { makeStyles } from '@material-ui/core/styles';
import AttendanceRate from '../Services/AttendanceRate';
import { dataToString } from '../../constants/utils';

//******************************************************************************
// style definition
//******************************************************************************
const useStyles = makeStyles((theme) => ({
  textBox: {
    paddingLeft: theme.spacing(4)
  },
  valueText: {
    marginLeft: theme.spacing(3),
    fontWeight: 'bold',
    '& pre': {
      maxWidth: '680px',
      overflowY: 'auto'
    }
  }
}));

//******************************************************************************
// component definition
//******************************************************************************
export default function ApplicationFilterOption({option, values, serviceId, handleChange}) {
  const classes = useStyles();
  // console.log(option);

  //----------------------------------------------------------------------------
  // component rendering
  //----------------------------------------------------------------------------
  return (
    <div>
      <FormControlLabel 
        label={
          <p>
            {option.ownerPerson.email} <br />
            <AttendanceRate
              serviceId={serviceId}
              personId={option.ownerPerson._id} />
          </p>
        }
        control={
          <Checkbox checked={values?.includes(option._id)} 
            name={option._id} onChange={handleChange} />
        } />
      <div className={classes.textBox}>
        { option.dataItems.map(data=>(
          <div key={data._id}>
            <p>{data.input.profileKey?.key || data.input.itemText}</p> 
            <div className={classes.valueText}>
              <pre>{dataToString(data, '\n')}</pre>
            </div>
          </div>
        ))}
      </div>
    </div>
  )
}

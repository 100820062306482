import React from 'react';
import ServiceSessionWhereEdit from './ServiceSessionWhereEdit';

//******************************************************************************
// component definition
//******************************************************************************
export default function TableWhereEdit({reportType, where, setWhere, setChanged, disabled}) {

  switch (reportType) {
    case 'serviceSessions':
      return <ServiceSessionWhereEdit
                where={where} setWhere={setWhere}
                setChanged={setChanged}
                disabled={disabled} />
    default:
      return '';
  }
}

import React from 'react';
import { useQuery } from "@apollo/client";
import { DialogContent, Table, TableBody, TableRow, TableCell } from '@mui/material';
import { CERTIFICATE_BY_ID } from '../../constants/gqls/certificates';
import { makeStyles } from '@material-ui/core/styles';

//******************************************************************************
// style definition
//******************************************************************************
const useStyles = makeStyles((theme) => ({
  labelText: {
    color: theme.palette.secondary.main
  },
  cell: {
    width: '1%',
    whiteSpace: 'nowrap',
  },
}));

//******************************************************************************
// component definition
//******************************************************************************
export default function CertificateItemDetails({ certId }) {
  const classes = useStyles();
  const { data } = useQuery(CERTIFICATE_BY_ID, {
    variables: { id: certId }
  });
  // console.log(data);

  //----------------------------------------------------------------------------
  // component rendering
  //----------------------------------------------------------------------------
  return (
    <DialogContent>
      <Table>
        <TableBody>
          <TableRow>
            <TableCell className={classes.cell}>
              <span className={classes.labelText}>Request date:</span>
            </TableCell>
            <TableCell>
              {data?.certificateById.createdAt}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell className={classes.cell}>
              <span className={classes.labelText}>Status:</span>
            </TableCell>
            <TableCell>
              {data?.certificateById.status}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell className={classes.cell}>
              <span className={classes.labelText}>Issue date:</span>
            </TableCell>
            <TableCell>
              {data?.certificateById.issueDate}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell className={classes.cell}>
              <span className={classes.labelText}>Published:</span>
            </TableCell>
            <TableCell>
              {data?.certificateById.publish? 'Yes' : 'No'}
            </TableCell>
          </TableRow>
          { data?.certificateById.dataKeyValue.map(item=>(
            <TableRow key={item.key}>
              <TableCell className={classes.cell}>
                <span className={classes.labelText}>{item.key}:</span>
              </TableCell>
              <TableCell>
                {item.value}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </DialogContent>
  );
}

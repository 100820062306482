import React from 'react';
import { TextField, Autocomplete } from '@mui/material';
import { useQuery } from "@apollo/client";
import { SERVICE_SESSION_YEARS } from '../../constants/gqls/serviceSessions';

//******************************************************************************
// component definition
//******************************************************************************
export default function ServiceSessionYearSelect({value, onChange, disabled}) {
  const [inputValue, setInputValue] = React.useState('');
  const { data } = useQuery(SERVICE_SESSION_YEARS);


  //----------------------------------------------------------------------------
  // funtions
  //----------------------------------------------------------------------------
  const onValueChange = (event, newValue) => {
    // console.log(newValue);
    onChange(newValue);
  }

  const onInputChange = (event, newValue) => {
    setInputValue(newValue);
  }

  //----------------------------------------------------------------------------
  // component rendering
  //----------------------------------------------------------------------------
  return (
    <Autocomplete disabled={disabled}
      value={value} onChange={onValueChange}
      inputValue={inputValue} onInputChange={onInputChange}
      options={ data ? data.serviceSessionsYears : [] }
      renderInput={(params) => <TextField {...params}
        label="Year"
        variant="standard"
      />}
    />
  );
}

import React from 'react';
import { generatePath } from "react-router-dom";
import { ListItemButton, ListItemText } from '@mui/material';
import SiteMap from '../../constants/siteMap';

//******************************************************************************
// component definition
//******************************************************************************
export default function DocumentItem({doc}) {
  const docPath = generatePath(SiteMap.document, { id: doc._id });

  return (
    <ListItemButton component="a" href={docPath}>
      <ListItemText primary={doc.title} />
    </ListItemButton>
  );
}

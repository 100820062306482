import React from 'react';
import { FormControl, FormGroup } from '@mui/material';
import { useQuery } from "@apollo/client";
import { APPLICATION_SELECTED_DATA } from '../../constants/gqls/forms';
import ApplicationFilterOption from './ApplicationFilterOption';

//******************************************************************************
// component definition
//******************************************************************************
export default function ApplicationFilterDisabled({input, values}) {
  const { data } = useQuery(APPLICATION_SELECTED_DATA, { variables: {
    inputId: input._id,
    selected: values
  }});

  // console.log(input);
  // console.log(data);

  //----------------------------------------------------------------------------
  // component rendering
  //----------------------------------------------------------------------------
  if (!data || data.applicationSelectedData.length===0) {
    return <div></div>
  }
  return (
    <FormControl disabled>
      <FormGroup>
        { data.applicationSelectedData.map(option=>(
          <ApplicationFilterOption key={option._id}
            option={option} values={values}
            serviceId={input.service._id} />
        ))}
      </FormGroup>
    </FormControl>
  )
}

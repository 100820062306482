import React from 'react';
import { Button, Stack, Dialog, DialogTitle,
  DialogContent, DialogContentText, DialogActions } from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import DeleteIcon from '@mui/icons-material/Delete';
import { useMutation } from "@apollo/client";
import Tag from '../Tag';
import { DELETE_TAG, TAGS, MY_TAGS } from '../../constants/gqls/tags';

//******************************************************************************
// component definition
//******************************************************************************
export default function TagDelete({tag}) {
  const [open, setOpen] = React.useState(false);
  const [deleteTag, { loading }] = useMutation(DELETE_TAG, {
    refetchQueries: [ TAGS, MY_TAGS ],
  });

  //----------------------------------------------------------------------------
  // funtions
  //----------------------------------------------------------------------------
  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const deleteItem = () => {
    deleteTag({ variables: {
      where: { _id: tag._id }
    }}).then(res => {
      // console.log(res);
      handleClose();
    });
  }

  //----------------------------------------------------------------------------
  // component rendering
  //----------------------------------------------------------------------------
  return (
    <React.Fragment>
      <Button variant="outlined" startIcon={<DeleteIcon />} onClick={handleOpen}>
        Delete
      </Button>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>
          Confirm Delete?
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            By deleting this tag, all of its tag connections will be lost. Click delete button to delete tag.
          </DialogContentText>
          <Stack direction="row" alignItems="center" justifyContent="center">
            <Tag text={tag.key} type={tag.type} />
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <LoadingButton variant="outlined"
            loading={loading} onClick={deleteItem}>
            Delete
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}

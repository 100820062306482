import React from 'react';
import { List } from '@mui/material';
import ColumnItem from './ColumnItem';
import ViewDataBtn from './ViewDataBtn';


//******************************************************************************
// component definition
//******************************************************************************
export default function ReportColumns({selected}) {
  const [columns, setColumns] = React.useState([]);
  // const [theProfileKey, setTheProfileKey] = React.useState([]);
  // console.log(columns);

  React.useEffect(() => {
    let cols = [];
    let dataSet = selected.filter(item=>item.valueFields?.length)[0];
    let others = selected.filter(item=> !item.valueFields || item.valueFields.length === 0);
    others.forEach(pk=>{
      if (pk.__typename === "ProfileKey") {
        cols.push({
          id: pk._id,
          name: pk.key,
          value: `Profile key: ${pk.key}`,
          type: 'data',
          profileKey: pk,
        });
      } else {
        cols.push({
          id: pk._id,
          name: `${pk.name}`,
          value: `User property: ${pk._id}`,
          type: 'property'
        });
      }
    });
    // console.log(dataSet);
    if (dataSet) {
      dataSet.valueFields.forEach(vf=>{
        cols.push({
          id: vf._id,
          name: vf.name,
          value: `${dataSet.key}: ${vf.name}`,
          type: 'value',
          profileKey: dataSet,
          valueField: vf
        });
      });
      // setTheProfileKey(dataSet);
    } else {
      // setTheProfileKey(null);
    }
    setColumns(cols);
  }, [selected]);

  //----------------------------------------------------------------------------
  // funtions
  //----------------------------------------------------------------------------
  const setColumn = (inColId, filter) => {
    let newCols = [...columns].map(col=>{
      if (col.id === inColId) {
        return {...col, filter};
      } else {
        return col
      }
    });
    setColumns(newCols);
  }

  //----------------------------------------------------------------------------
  // component rendering
  //----------------------------------------------------------------------------
  return (
    <div>
      <h3>
        Columns:
      </h3>
      <List>
        { columns.map((col, i)=>(
          <ColumnItem key={i} column={col} setColumn={setColumn} />
        ))}
      </List>
      { columns.length !== 0 && 
        <ViewDataBtn columns={columns} selected={selected} />
      }
    </div>
  );
}

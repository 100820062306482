import React from 'react';
import InfiniteScroll from 'react-infinite-scroller';
import { LinearProgress, List } from '@mui/material';
import { useQuery } from "@apollo/client";
import { MY_PAGES } from '../../constants/gqls/pages';
import PageItem from '../Pages/PageItem';
const limit = 10;


//******************************************************************************
// component definition
//******************************************************************************
export default function MyPagesCard() {
  const { loading, data, fetchMore } = useQuery(MY_PAGES, {
    variables: { limit, offset: 0 }
  });

  //----------------------------------------------------------------------------
  // funtions
  //----------------------------------------------------------------------------
  const loadMore = () => {
    fetchMore({
      variables: {
        limit,
        offset: data.myPages.length
      }
    });
  }


  //----------------------------------------------------------------------------
  // component rendering
  //----------------------------------------------------------------------------
  if (loading) return <LinearProgress />;
  if (!data) return 'Network error.'
  if (data.myPagesCount === 0) return 'No records...'

  return (
    <InfiniteScroll
      loadMore={loadMore}
      useWindow={false}
      hasMore={data.myPagesCount > data.myPages.length}
      loader={<LinearProgress key="loading" />}
    >
      <List>
        {data.myPages.map(page => (
          <PageItem key={page._id} page={page} />
        ))}
      </List>
    </InfiniteScroll>
  );
}

import React from 'react';
import { useNavigate, generatePath } from "react-router-dom";
import { TextField } from '@mui/material';
import { makeStyles } from '@material-ui/core/styles';
import { useMutation } from "@apollo/client";
import { CREATE_EMAIL_TEMPLATE, EMAIL_TEMPLATES } from '../../constants/gqls/emailTemplates';
import SiteMap from '../../constants/siteMap';

//******************************************************************************
// style definition
//******************************************************************************
const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(2)
  },
}));

//******************************************************************************
// component definition
//******************************************************************************
export default function AddTemplate() {
  const classes = useStyles();
  const navigate = useNavigate();
  const [key, setKey] = React.useState('');
  const [createEmailTemplate, { loading }] = useMutation(CREATE_EMAIL_TEMPLATE, {
    refetchQueries: [ EMAIL_TEMPLATES ],
  });

  //----------------------------------------------------------------------------
  // funtions
  //----------------------------------------------------------------------------
  const onKeyChange = (event) => {
    setKey(event.target.value);
  }

  const handleKeyDown = (event) => {
    if (event.key === 'Enter' && key.trim() !== '') {
      createEmailTemplate({ variables: {
        input: [{ key }]
      }}).then(res => {
        // console.log(res);
        setKey('');
        let path = generatePath(SiteMap.email_template, {
          id: res.data.createEmail_templates.emailTemplates[0]._id
        });
        // console.log(path);
        navigate(path);
      });
    }
  }

  //----------------------------------------------------------------------------
  // component rendering
  //----------------------------------------------------------------------------
  return (
    <div className={classes.root}>
      <TextField id="email-template-key" label="New Email template"
        variant="outlined" fullWidth disabled={loading}
        value={key} onChange={onKeyChange} onKeyDown={handleKeyDown} />
    </div>
  );
}

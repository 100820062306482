import React from 'react';
import InfiniteScroll from 'react-infinite-scroller';
import { List, LinearProgress } from '@mui/material';
import { useQuery } from "@apollo/client";
import TagListItem from './TagListItem';
import { TAGS } from '../../constants/gqls/tags';
const limit = 10;

//******************************************************************************
// component definition
//******************************************************************************
export default function TagsList({where}) {
  const { loading, data, fetchMore } = useQuery(TAGS, { variables: { where,
    options: { limit, offset: 0, sort: [{ key: "ASC" }]}
  }});
  //----------------------------------------------------------------------------
  // funtions
  //----------------------------------------------------------------------------
  const loadMore = () => {
    fetchMore({ variables: {
      options: { limit, offset: data.tags.length, sort: [{ key: "ASC" }] }
    }});
  }

  //----------------------------------------------------------------------------
  // component rendering
  //----------------------------------------------------------------------------
  if (loading) return <LinearProgress />;
  if (!data) return 'Network error.'
  if (data.tagsCount === 0) {
    return '**Empty list**'
  }
  // console.log(data.tags.map(tag=>tag._id).join('\n'));

  return (
    <InfiniteScroll
        loadMore={loadMore}
        hasMore={data.tagsCount > data.tags.length}
        loader={<LinearProgress key="loading" />}
    >
      <List>
        { data.tags.map(tag=>(
          <TagListItem key={tag._id} tag={tag} />
        )) }
      </List>
    </InfiniteScroll>
  );
}

import React from 'react';

function findLinkEntities(contentBlock, callback, contentState) {
  contentBlock.findEntityRanges((character) => {
    const entityKey = character.getEntity();
    return (
      entityKey !== null &&
      contentState.getEntity(entityKey).getType() === 'LINK'
    );
  }, callback);
}

const Link = ({contentState, entityKey, children}) => {
  const {url} = contentState.getEntity(entityKey).getData();
  return (
    <a href={url} target="_blank" rel="noreferrer">
      {children}
    </a>
  );
};

const LinkDecorator = {
  strategy: findLinkEntities,
  component: Link,
};

export default LinkDecorator

import { gql } from '@apollo/client';
import { actionDetails } from './fragments';


export const DOCUMENT_ACTIONS = gql`
  query DocumentActions($docId: ID!) {
    documentActions(docId: $docId) {
      ${actionDetails}
    }
  }
`;

export const SERVICE_ACTIONS = gql`
  query ServiceActions($serviceId: ID!) {
    serviceActions(serviceId: $serviceId) {
      ${actionDetails}
    }
  }
`;

export const CERT_TYPE_ACTIONS = gql`
  query CertTypeActions($certTypeId: ID!) {
    certTypeActions(certTypeId: $certTypeId) {
      ${actionDetails}
    }
  }
`;

export const CREATE_ACTIONS = gql`
  mutation CreateAction($docId: ID, $serviceId: ID, $certTypeId: ID) {
    createAction(docId: $docId, serviceId: $serviceId, certTypeId: $certTypeId) {
      _id
    }
  }
`;

export const UPDATE_ACTION = gql`
  mutation UpdateAction($id: ID!, $update: ActionUpdateInput!) {
    updateAction(id: $id, update: $update) {
      actions {
        ${actionDetails}
      }
    }
  }
`;

export const DELETE_ACTION = gql`
  mutation DeleteAction($id: ID!) {
    deleteAction(id: $id)
  }
`;

import React from 'react';
import { IconButton, Tooltip } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';

//******************************************************************************
// component definition
//******************************************************************************
export default function EditBtn({changeMode}) {

  //----------------------------------------------------------------------------
  // component rendering
  //----------------------------------------------------------------------------
  return (
    <Tooltip title="Edit Certificate Type">
      <IconButton onClick={changeMode}>
        <EditIcon />
      </IconButton>
    </Tooltip>
  );
}

import React from 'react';
// import { CardActions } from '@mui/material';
// import { makeStyles } from '@material-ui/core/styles';
// import { useMutation } from "@apollo/client";
import DeleteBtn from './DeleteBtn';
import StaffStatusBtn from './StaffStatusBtn';
import ConfirmBtn from './ConfirmBtn';
import AddAttendanceBtn from './AddAttendanceBtn';
// import { UPDATE_SERVICE_SESSION } from '../../constants/gqls/serviceSessions';

//******************************************************************************
// style definition
//******************************************************************************
// const useStyles = makeStyles((theme) => ({
//
// }));

//******************************************************************************
// component definition
//******************************************************************************
export default function SessionFunctions({ session, handleUpdate, loading }) {
  // const classes = useStyles();
  // const [updateServiceSession, { loading }] = useMutation(UPDATE_SERVICE_SESSION);
  // console.log(session);

  //----------------------------------------------------------------------------
  // funtions
  //----------------------------------------------------------------------------
  // const handleUpdate = (inUpdate) => {
  //   return updateServiceSession({ variables: {
  //     id: session._id,
  //     inUpdate
  //   }});
  // }

  //----------------------------------------------------------------------------
  // component rendering
  //----------------------------------------------------------------------------
  return (
    <>
      { (
          (session.myRole==='manager' &&  session.status==='Created') 
          || 
          (session.myRole==='Student' && !session.confirmedByMe && session.status!=='Completed' && session.status!=='Closed')
          ||
          (session.myRole==='admin' && session.status!=='Closed') 
        ) 
        &&
        <ConfirmBtn handleUpdate={handleUpdate} loading={loading} />
      }
      { (session.myRole==='admin' || 
          session.myRole==='manager') && 
        (session.status==='Created' || session.status==='Closed') &&
        <DeleteBtn serviceSessionId={session._id} />
      }
      { (session.myRole==='Teacher' || 
          session.myRole==='admin' || 
          session.myRole==='manager') &&
        session.status !== 'Completed' &&
        <StaffStatusBtn session={session}
          handleUpdate={handleUpdate} loading={loading} />
      }
      { (session.myRole==='Teacher' || 
          session.myRole==='admin' || 
          session.myRole==='manager') && 
        session.canAddAttendance &&
        <AddAttendanceBtn serviceSessionId={session._id} />
      }
    </>
  );
}

import React from 'react';
import { generatePath } from "react-router-dom";
import { Button } from '@mui/material';
import SiteMap from '../../constants/siteMap';


//******************************************************************************
// component definition
//******************************************************************************
export default function ActionETBtn({templateId}) {
  const href = generatePath(SiteMap.email_template, { id: templateId });

  //----------------------------------------------------------------------------
  // component rendering
  //----------------------------------------------------------------------------
  return (
    <Button variant="outlined" component="a" href={href} target="_blank">
      Email Template
    </Button>
  );
}

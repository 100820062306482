import React from 'react';
import { TextField, Autocomplete } from '@mui/material';
import { useQuery } from "@apollo/client";
import { SERVICE_FORMS } from '../../constants/gqls/forms';

//******************************************************************************
// component definition
//******************************************************************************
export default function ServiceFormSelect({serviceId, onChange, value}) {
  const [inputValue, setInputValue] = React.useState('');
  const { data } = useQuery(SERVICE_FORMS, { variables: { serviceId }});
  // console.log(data);

  //----------------------------------------------------------------------------
  // funtions
  //----------------------------------------------------------------------------
  const onValueChange = (event, newValue) => {
    // console.log(newValue);
    onChange(newValue);
  }

  const onInputChange = (event, newValue) => {
    setInputValue(newValue);
  }

  //----------------------------------------------------------------------------
  // component rendering
  //----------------------------------------------------------------------------
  return (
    <Autocomplete
      value={value} onChange={onValueChange}
      inputValue={inputValue} onInputChange={onInputChange}
      getOptionLabel={option=>option.title}
      options={ data ? data.serviceForms : [] }
      renderInput={(params) => <TextField {...params}
        label="Select Form"
        variant="standard"
      />}
    />
  );
}

import React from 'react';
import PeopleInput from '../InputsCommt/PeopleInput';
import SelectStudentsDisabled from './SelectStudentsDisabled';


//******************************************************************************
// component definition
//******************************************************************************
export default function SelectStudents({values, disabled, updateData}) {
  // const { data } = useQuery(SEARCH_PEOPLE, { variables: {
  //   where: { _id_IN: values }
  // }});
  const [selected, setSelected] = React.useState([]);
  // console.log(disabled);

  //----------------------------------------------------------------------------
  // funtions
  //----------------------------------------------------------------------------
  const onStudentsChanged = (students) => {
    setSelected(students);
    console.log(students);
    if (updateData && students.length) {
      updateData({
        values: students.map(s=>s._id)
      }).then(res => {
        console.log(res);
      });
    }
  }

  //----------------------------------------------------------------------------
  // component rendering
  //----------------------------------------------------------------------------
  if (disabled) {
    return <SelectStudentsDisabled values={values} />
  }
  return (
    <PeopleInput multiple={true}
      role="student"
      value={selected}
      onChange={onStudentsChanged} />
  )
}

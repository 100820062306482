import React from 'react';
import { TextField } from '@mui/material';
import FileUpload from '../Files/FileUpload';


//******************************************************************************
// component definition
//******************************************************************************
export default function UploadInput({id, fileTypes, disabled, data, updateData}) {
  // const [value, setValue] = React.useState(data?.value || '');
  // const [changed, setChanged] = React.useState(false);

  // React.useEffect(() => {
  //   // console.log(data);
  //   setValue(data?.value || '');
  // }, [data]);

  //----------------------------------------------------------------------------
  // funtions
  //----------------------------------------------------------------------------
  // const update = () => {
  //   if (changed) {
  //     updateData({ value }).then(res => {
  //       // console.log(res);
  //       setChanged(false);
  //     })
  //   }
  // };

  // const handleChange = (event) => {
  //   setValue(event.target.value)
  //   setChanged(true);
  // }

  // const handleBlur = () => {
  //   update();
  // }

  //----------------------------------------------------------------------------
  // component rendering
  //----------------------------------------------------------------------------
  return (
    <FileUpload hasFile ={data?.files.length!==0}
      systemFileType="formUpload"
      relatedId={data?._id}
      fileTypes={fileTypes}
      disabled={disabled}>
    </FileUpload>
  )
}

import { ApolloClient, ApolloLink, split, HttpLink, from } from '@apollo/client';
// import { SubscriptionClient } from "subscriptions-transport-ws";
// import { WebSocketLink } from '@apollo/client/link/ws';
import { GraphQLWsLink } from '@apollo/client/link/subscriptions';
import { createClient } from 'graphql-ws';
import { onError } from "@apollo/client/link/error";
import { getMainDefinition } from '@apollo/client/utilities';
import { cache } from './cache';

// *****************************************************************************
// graphql client setting
// *****************************************************************************
// define http link
const httpLink = new HttpLink({
  uri: process.env.REACT_APP_API_URI
});

// add the authorization to the headers
const authLink = new ApolloLink((operation, forward) => {
  operation.setContext(({ headers }) => {
    let authToken = localStorage.getItem('loginToken');
    // console.log(authToken);
    return {
      headers: {
        ...headers,
        authorization: authToken? `Bearer ${authToken}` : null
      }
    }
  });
  return forward(operation);
});

// onError
// TODO:
const authFailLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors) {
    for (let err of graphQLErrors) {
      switch (err.extensions.code) {
        case 'UNAUTHENTICATED':
          // isAuthVar(false);
          break;
        default:
          break;
      }
    }
  }
  if (networkError) {
    console.log(`[Network error]: ${networkError}`);
  }
});

// define web socket link
const subscriptionClient = createClient({
  lazy: true,
  url: process.env.REACT_APP_SUB_URI,
  connectionParams: () => {
    const token = localStorage.getItem('loginToken');
    return {
      authorization: token ? `Bearer ${token}` : '',
    };
  },
});
const wsLink = new GraphQLWsLink(subscriptionClient);
// const subscriptionClient = new SubscriptionClient(process.env.REACT_APP_SUB_URI, {
//   lazy: true,
//   reconnect: true,
//   connectionParams: () => {
//     const token = localStorage.getItem('loginToken');
//     // console.log(token);
//
//     return {
//       authorization: token ? `Bearer ${token}` : '',
//     };
//   },
// });

// const wsLink = new WebSocketLink(subscriptionClient);

// split links
const link = split(
  ({ query }) => {
    const definition = getMainDefinition(query);
    return (
      definition.kind === 'OperationDefinition' &&
      definition.operation === 'subscription'
    );
  },
  wsLink,
  from([authFailLink, authLink, httpLink]),
);

// apollo client
// class ApolloClientWS extends ApolloClient {
//   constructor(props) {
//     super(props);
//
//     this.close = this.close.bind(this);
//   }
//
//   close(isForced) {
//     subscriptionClient.close(isForced);
//   }
// }

const client = new ApolloClient({ cache, link,
  connectToDevTools: true,
});

export default client;

import React from 'react';
import { IconButton, Tooltip } from '@mui/material';

//******************************************************************************
// component definition
//******************************************************************************
export default function StyleButton({onToggle, style, label, icon, disabled}) {

  //----------------------------------------------------------------------------
  // funtions
  //----------------------------------------------------------------------------
  const onClick = () => {
    onToggle(style);
  }

  //----------------------------------------------------------------------------
  // component rendering
  //----------------------------------------------------------------------------
  return (
    <Tooltip title={label}>
      <span>
        <IconButton color="primary" onClick={onClick} disabled={disabled}>
          {icon || label}
        </IconButton>
      </span>
    </Tooltip>
  );
}

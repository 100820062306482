import React from 'react';
import { IconButton, Popover, Stack } from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import ClearIcon from '@mui/icons-material/Clear';
import { makeStyles } from '@material-ui/core/styles';
import { useMutation } from "@apollo/client";
import { SORT_VALUE_KEYS } from '../../../constants/gqls/dataValues';

//******************************************************************************
// style definition
//******************************************************************************
const useStyles = makeStyles((theme) => ({
  popover: {
    "& .MuiPaper-root": {
      borderRadius: '20px',
      backgroundColor: theme.palette.primary.pale,
    }
  }
}));

//******************************************************************************
// component definition
//******************************************************************************
export default function FunctionBtn({valueField, count, handleDelete, loading}) {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [sortValueFields, { loading:sortLoading }] = useMutation(SORT_VALUE_KEYS);
  const open = Boolean(anchorEl);
  // console.log(count);

  //----------------------------------------------------------------------------
  // funtions
  //----------------------------------------------------------------------------
  const onFnClick = (event) => {
    setAnchorEl(event.currentTarget);
  }

  const handleClose = () => {
    setAnchorEl(null);
  }

  const handleUp = () => {
    sortValueFields({ variables: {
      valueFieldId: valueField._id,
      index: valueField.index-1
    }}).then(res=>{
      // console.log(res);
    });
  };

  const handleDown = () => {
    sortValueFields({ variables: {
      valueFieldId: valueField._id,
      index: valueField.index+1
    }}).then(res=>{
      // console.log(res);
    });
  };

  //----------------------------------------------------------------------------
  // component rendering
  //----------------------------------------------------------------------------
  return (
    <>
      <IconButton onClick={onFnClick}>
        <MoreVertIcon />
      </IconButton>
      <Popover open={open} anchorEl={anchorEl} onClose={handleClose}
      anchorOrigin={{
        vertical: 'center',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'center',
        horizontal: 'left',
      }} className={classes.popover}>
      <Stack>
        {valueField.index !== 0 &&
          <IconButton onClick={handleUp} disabled={loading || sortLoading}>
            <ArrowUpwardIcon />
          </IconButton>
        }
        {valueField.index !== (count - 1) &&
          <IconButton onClick={handleDown} disabled={loading || sortLoading}>
            <ArrowDownwardIcon />
          </IconButton>
        }
        <IconButton onClick={handleDelete} 
          disabled={loading || !valueField.deletable}>
          <ClearIcon />
        </IconButton>
      </Stack>
    </Popover>
    </>
        
  )
}

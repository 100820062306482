import React from 'react';
import InfiniteScroll from 'react-infinite-scroller';
import { List, LinearProgress } from '@mui/material';
import { useQuery } from "@apollo/client";
import SessionItem from './SessionItem';
import { SERVICE_SESSIONS } from '../../constants/gqls/serviceSessions';
const limit = 10;

//******************************************************************************
// component definition
//******************************************************************************
export default function SessionsList({serviceId}) {
  const { loading, data, fetchMore } = useQuery(SERVICE_SESSIONS, {
    variables: { serviceId, limit, offset: 0 }
  });
  // console.log(data);

  //----------------------------------------------------------------------------
  // funtions
  //----------------------------------------------------------------------------
  const loadMore = () => {
    fetchMore({ variables: {
      limit,
      offset: data.sessionsOfService.length
    }});
  }

  //----------------------------------------------------------------------------
  // component rendering
  //----------------------------------------------------------------------------
  if (loading) return <LinearProgress />;
  if (!data) return 'Network error.'
  if (data.sessionsOfServiceCount === 0) {
    return 'No session yet.';
  }

  return (
    <InfiniteScroll
        loadMore={loadMore}
        hasMore={data.sessionsOfServiceCount > data.sessionsOfService.length}
        loader={<LinearProgress key="loading" />}
    >
      <List>
        { data.sessionsOfService.map(session=>(
          <SessionItem key={session._id} session={session} />
        )) }
      </List>
    </InfiniteScroll>
  );
}

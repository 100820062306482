import React from 'react';
import { IconButton, Tooltip, Dialog, DialogTitle, DialogContent } from '@mui/material';
// import LoadingButton from '@mui/lab/LoadingButton';
import SettingsIcon from '@mui/icons-material/Settings';
// import { useMutation } from "@apollo/client";
// import { DOCUMENT } from '../../constants/gqls/documents';
import SetPreconditions from './SetPreconditions';

//******************************************************************************
// component definition
//******************************************************************************
export default function SettingBtn({item}) {
  const [open, setOpen] = React.useState(false);

  //----------------------------------------------------------------------------
  // funtions
  //----------------------------------------------------------------------------
  const handleClose = () => {
    setOpen(false);
  };

  const onSettingClick = () => {
    setOpen(true);
  }

  //----------------------------------------------------------------------------
  // component rendering
  //----------------------------------------------------------------------------
  return (
    <React.Fragment>
      <Tooltip title="Setting" placement="left">
        <IconButton onClick={onSettingClick}>
          <SettingsIcon />
        </IconButton>
      </Tooltip>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>
          Item Settings
        </DialogTitle>
        <DialogContent>
          <SetPreconditions item={item} />
        </DialogContent>
      </Dialog>
    </React.Fragment>
  )
}

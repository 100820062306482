import React from 'react';
// import clsx from 'clsx';
import { useSearchParams } from "react-router-dom";
// import { TextField } from '@mui/material';
import { useMutation, useReactiveVar } from "@apollo/client";
import { dataMap, formFilledCheck } from '../../constants/cache';
import { makeStyles } from '@material-ui/core/styles';
import ItemTextView from '../Items/ItemTextView';
import TextInput from './TextInput';
import UniqueKeyInput from './UniqueKeyInput';
import SwitchInput from './SwitchInput';
import RadioInput from './RadioInput';
import CheckboxInput from './CheckboxInput';
import DropdownInput from './DropdownInput';
import WeeklyHour from './WeeklyHour';
import WeeklyHourMultiple from './WeeklyHourMultiple';
import TutoringHour from './TutoringHour';
import ValueSetInput from './ValueSetInput';
import ValueSetsSelectedInput from './ValueSetsSelectedInput';
import ValueSetSingleInput from './ValueSetSingleInput';
import UploadInput from './UploadInput';
import ProfileDataQuery from './ProfileDataQuery';
import ValueSetSelectedDataQuery from './ValueSetSelectedDataQuery';
import { CREATE_DATA, UPDATE_DATA, SUBMISSION, PROFILE_DATA } from '../../constants/gqls/forms';
// import InputDataView from './InputDataView';
import ApplicationFilter from './ApplicationFilter';
import SelectStudents from './SelectStudents';

//******************************************************************************
// style definition
//******************************************************************************
const useStyles = makeStyles((theme) => ({
  root: {
    position: 'relative',
    paddingLeft: theme.spacing(2)
  },
  required: {
    position: 'absolute',
    color: theme.palette.error.main,
    fontWeight: 'bold',
    fontSize: '24px',
    left: 0,
    top: '-6px'
  },
  input: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(2),
  }
}));

//******************************************************************************
// component definition
//******************************************************************************
export default function InputItemView({item, pageMode, disabled, useProfileValue}) {
  const classes = useStyles();
  const [searchParams, setSearchParams] = useSearchParams();
  // const submissionId = useReactiveVar(currentSubmission);
  const dataMapVar = useReactiveVar(dataMap);
  const [inputDisabled, setDisabled] = React.useState(true);
  const [submissionId, setSubmissionId] = React.useState(null);
  const [profileId, setProfileId] = React.useState(null);
  const [data, setData] = React.useState(null);
  const [options, setOptions] = React.useState([]);
  const [authUpdateData, { loading: updateLoading }] = useMutation(UPDATE_DATA);
  const [authCreateData, { loading: createLoading }] = useMutation(CREATE_DATA, {
    refetchQueries: [ SUBMISSION, PROFILE_DATA ],
  });
  
  // console.log(submissionId);
  // console.log(options);
  // console.log(dataMapVar?.get(item.input._id));
  // console.log(data);
  React.useEffect(() => {
    if (item.input) {
      setData(dataMapVar?.get(item.input.profileKey?._id || item.input._id));
    }
  }, [dataMapVar, item]);

  React.useEffect(() => {
    setDisabled(updateLoading || createLoading || disabled || 
      (useProfileValue && !(item.input.profileKey || item.input.valueSetInput?.profileKey)));
  }, [updateLoading, createLoading, disabled, useProfileValue, item]);

  React.useEffect(() => {
    if (item.input) {
      let opts = [...item.input.optionsConnection.edges];
      opts.sort((a,b)=>a.index-b.index);
      opts = opts.filter(opt=>!opt.node.hide).map(opt=>({
        value: opt.node._id,
        label: opt.node.text,
        quotaLeft: opt.node.quotaLeft,
      }));
      if (item.type === 'filter' && item.input.filterNot && opts.length >= 2) {
        opts.push({
          value: 'filterNot',
          label: opts.length===2? 'Both' : "All",
        })
      }
      setOptions(opts);
    }
  }, [item]);

  React.useEffect(() => {
    if (searchParams.get("submission")) {
      setSubmissionId(searchParams.get("submission"));
    } else {
      setSubmissionId(null);
      if (pageMode && item.input.fieldKey) {
        let parmValue = searchParams.get(item.input.fieldKey);
        if (parmValue) {
          let parmUpdate = {};
          switch (item.input.type) {
            case "radio":
            case "checkbox":
            case "dropdown":
              parmUpdate.optionIds = parmValue.split(',');
              break;
            case "weeklyHourMulti":
              parmUpdate.values = parmValue.split(',');
              break;
            default:
              parmUpdate.value = parmValue;
              break;
          }
          updateData(parmUpdate);
        }
      }
    }
    if (searchParams.get("profile")) {
      setProfileId(searchParams.get("profile"));
    } else {
      setProfileId(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParams]);

  React.useEffect(() => {
    if (item.input) {
      let checked = true, old = {...formFilledCheck()};
      if (item.input.required && (
        !data || (
          data.options?.length === 0 && 
          !data.value && 
          !data.values?.length))) {
        checked = false;
      }
      if (data && data.options) {
        for (var i = 0; i < data.options.length; i++) {
          if (data.options[i].quotaLeft <= 0  && data.options[i].quotaLeft !== null && data.options[i].quotaLeft !== undefined) {
            checked = false;
          }
        }
      }
  
      old[item.input._id] = checked;
      formFilledCheck(old);
    }
  }, [data, item]);

  React.useEffect(() => {
    return () => {
      if (item.input) {
        let old = {...formFilledCheck()};
        old[item.input._id] = true;
        formFilledCheck(old);
      }
    }
  }, [item]);

  //----------------------------------------------------------------------------
  // funtions
  //----------------------------------------------------------------------------
  const setSubId = (subId) => {
    if (subId && !submissionId) {
      setSearchParams({submission: subId});
    }
  }

  const updateData = ({value, values, boolValue, optionIds}) => {
    if (!pageMode) {
      if (data) {
        return authUpdateData({ variables: {
          dataId: data._id,
          value, values, boolValue, optionIds
        }});
      } else {
        return authCreateData({ variables: {
          itemId: item._id,
          submissionId, value, values, boolValue, optionIds, profileId
        }}).then(res => {
          // console.log(res);
          let newSubId = res?.data?.authCreateData.submission[0]?._id;
          setSubId(newSubId);
          return res;
        })
      }
    } else {
      // for guest user
      return new Promise((resolve, reject) => {
        try {
          let map = new Map(dataMapVar);
          map.set(item.input._id, {
            __typename: 'Data',
            _id: item.input._id+'-data',
            input: item.input,
            options: optionIds?.map(optId=>({_id: optId})) || [],
            value, values, boolValue,
          });
          dataMap(map);
          // console.log(map);
          resolve(map);
        } catch (error) {
          reject(error);
        }
      });
    }
  }


  //----------------------------------------------------------------------------
  // component rendering
  //----------------------------------------------------------------------------
  if (!item.input) {
    return <div className={classes.root}>Error: input not found!</div>
  };

  return (
    <div className={classes.root}>
      { item.input.required &&
        <div className={classes.required}>*</div>
      }
      { item.input.profileKey &&
        <ProfileDataQuery 
          keyId={item.input.profileKey._id}
          profileId={profileId} />
      }
      { (item.input.type==="valueSetsSelected" || item.input.type==="valueSetSingle") &&
        <ValueSetSelectedDataQuery 
          inputId={item.input._id}
          profileId={profileId} />
      }
      <ItemTextView itemText={item.text} />
      <div className={classes.input}>
        {{
          shortText:
            <TextInput disabled={inputDisabled}
              id={item.input._id} data={data} updateData={updateData} />,
          uniqueKey:
            <UniqueKeyInput disabled={inputDisabled}
              id={item.input._id} data={data} updateData={updateData} />,
          paragraph:
            <TextInput disabled={inputDisabled} multiline
              id={item.input._id} data={data} updateData={updateData} />,
          switch:
            <SwitchInput disabled={inputDisabled}
              id={item.input._id} data={data} updateData={updateData} />,
          radio:
            <RadioInput disabled={inputDisabled}
              options={options} other={item.input.other}
              id={item.input._id} 
              data={data} updateData={updateData} />,
          checkbox:
            <CheckboxInput disabled={inputDisabled}
              options={options} other={item.input.other}
              id={item.input._id} data={data} updateData={updateData} />,
          dropdown:
            <DropdownInput disabled={inputDisabled}
              id={item.input._id} options={options}
              data={data} updateData={updateData} />,
          weeklyHour:
            <WeeklyHour disabled={inputDisabled}
              weeklyDayOptions={item.input.weeklyDayOptions}
              weeklyHourOptions={item.input.weeklyHourOptions}
              id={item.input._id} data={data} updateData={updateData} />,
          weeklyHourMulti:
            <WeeklyHourMultiple disabled={inputDisabled}
              weeklyDayOptions={item.input.weeklyDayOptions}
              weeklyHourOptions={item.input.weeklyHourOptions}
              id={item.input._id} data={data} updateData={updateData} />,
          tutoringHour:
            <TutoringHour disabled={inputDisabled}
              id={item.input._id} 
              value={data?.value} updateData={updateData} />,
          valueSets:
            <ValueSetInput disabled={inputDisabled}
              data={data} item={item}
              submissionId={submissionId}
              profileId={profileId}
              setSubId={setSubId} />,
          valueSetsSelected:
            <ValueSetsSelectedInput disabled={inputDisabled}
              data={data} item={item}
              profileId={profileId} />,
          valueSetSingle:
            <ValueSetSingleInput disabled={inputDisabled}
              data={data} item={item}
              profileId={profileId} />,
          applicationFilter:
            <ApplicationFilter disabled={inputDisabled}
              input={item.input}
              values={data?.values || []} updateData={updateData} />,
          selectStudents:
            <SelectStudents disabled={inputDisabled}
              values={data?.values || []}
              updateData={updateData} />,
          upload:
            <UploadInput disabled={inputDisabled}
              id={item.input._id} 
              fileTypes={item.input.fileTypes}
              data={data} 
              updateData={updateData} />,
        }[item.input.type]}
      </div>
    </div>
  );
}

import React from 'react';
import { useNavigate } from "react-router-dom";
import { Button, Dialog, DialogTitle,
  DialogContent, DialogContentText, DialogActions } from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import DeleteIcon from '@mui/icons-material/Delete';
import { useMutation } from "@apollo/client";
import { doRefetch } from '../../constants/cache';
import { DELETE_EMAIL_TEMPLATE } from '../../constants/gqls/emailTemplates';
import SiteMap from '../../constants/siteMap';

//******************************************************************************
// component definition
//******************************************************************************
export default function DeleteBtn({templateId}) {
  const navigate = useNavigate();
  const [open, setOpen] = React.useState(false);
  const [deleteEmailTemplate, { loading }] = useMutation(DELETE_EMAIL_TEMPLATE);

  //----------------------------------------------------------------------------
  // funtions
  //----------------------------------------------------------------------------
  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const onDeleteClick = () => {
    deleteEmailTemplate({ variables: {
      where: { _id: templateId}
    }}).then(res=>{
      // goto
      doRefetch(true);
      navigate(SiteMap.admin_emails, { replace: true });
    });
  }

  //----------------------------------------------------------------------------
  // component rendering
  //----------------------------------------------------------------------------
  return (
    <React.Fragment>
      <Button variant="outlined" startIcon={<DeleteIcon />} onClick={handleOpen}>
        Delete
      </Button>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>
          Confirm Delete?
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            Click delete button to confirm delete this email template.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <LoadingButton variant="outlined"
            loading={loading} onClick={onDeleteClick}>
            Delete
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  )
}

import React from 'react';
import { IconButton, Popover, TextField, MenuItem } from '@mui/material';
import SettingsIcon from '@mui/icons-material/Settings';
import { makeStyles } from '@material-ui/core/styles';
import { useMutation } from "@apollo/client";
import CardSettingInputs from './CardSettingInputs';
import { UPDATE_CARD, SORT_CARDS } from '../../constants/gqls/boards';

function getIndexOptions(cardsCount) {
  let options = [];
  for (var i = 0; i < cardsCount; i++) {
    options.push({
      name: i+1,
      value: i
    })
  }
  return options;
}

//******************************************************************************
// style definition
//******************************************************************************
const useStyles = makeStyles((theme) => ({
  size: {
      padding: theme.spacing(1),
      float: 'left'
  },
  star: {
    display: 'inline',
    padding: theme.spacing(1),
    lineHeight: '40px'
  },
  index: {
    padding: theme.spacing(1),
    float: 'left',
    borderLeft: '1px dashed '+theme.palette.divider
  }
}));

//******************************************************************************
// component definition
//******************************************************************************
export default function SettingBtn({card, setPrevent, board, refetchQueries}) {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [updateCard, { loading }] = useMutation(UPDATE_CARD);
  const [sortCards, { loading: sortLoading }] = useMutation(SORT_CARDS, { refetchQueries });
  const open = Boolean(anchorEl);
  const indexOptions = getIndexOptions(board.cards.length)

  //----------------------------------------------------------------------------
  // funtions
  //----------------------------------------------------------------------------
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    setPrevent(true);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setPrevent(false);
  };

  const onWidthChange = (event) => {
    updateCard({ variables: {
      where: { _id: card._id },
      update: { width: event.target.value }
    }}).then(res => {
      // console.log(res.data);
    });
  }

  const onHeightChange = (event) => {
    updateCard({ variables: {
      where: { _id: card._id },
      update: { height: event.target.value }
    }}).then(res => {
      // console.log(res.data);
    });
  }

  const onIndexChange = (event) => {
    sortCards({ variables: {
      boardId: board._id,
      cardId: card._id,
      index: event.target.value
    }}).then(res => {
      // console.log(res.data);
    });
  }


  //----------------------------------------------------------------------------
  // component rendering
  //----------------------------------------------------------------------------
  return (
    <React.Fragment>
      <IconButton onClick={handleClick}>
        <SettingsIcon />
      </IconButton>
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <div className={classes.size}>
          <CardSettingInputs loading={loading || sortLoading}
            width={card.width} onWidthChange={onWidthChange}
            height={card.height} onHeightChange={onHeightChange} />
        </div>
        <div className={classes.index}>
          <TextField id="card-index" select variant="outlined" size="small"
            label="Index" value={card.index} onChange={onIndexChange}
            disabled={loading || sortLoading}>
            {indexOptions.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.name}
              </MenuItem>
            ))}
          </TextField>
        </div>
      </Popover>
    </React.Fragment>
  );
}

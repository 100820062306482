import React from 'react';
import { Skeleton  } from '@mui/material';
import { useQuery } from "@apollo/client";
import { SERVICE_HOURS } from '../../constants/gqls/services';

//******************************************************************************
// component definition
//******************************************************************************
export default function ServiceHours({serviceId, personId}) {
  const { loading, data } = useQuery(SERVICE_HOURS, { variables: {
    serviceId, personId
  }});

  //----------------------------------------------------------------------------
  // component rendering
  //----------------------------------------------------------------------------
  if (loading) {
    return <Skeleton variant="text" />
  }

  if (data && data.serviceHours.length!==0 ) {
    return (
      <span>{
        [...data.serviceHours].sort((a,b)=>a<b?1:-1)[0]
      } hours completed</span>
    );
  }
  return '';
}

import React from 'react';
import clsx from 'clsx';
import { useSearchParams } from "react-router-dom";
import { ListItemButton, ListItemText } from '@mui/material';
import { makeStyles } from '@material-ui/core/styles';
import FormSubFunctions from './FormSubFunctions';
import { DateTimeToString } from '../../constants/utils';

//******************************************************************************
// style definition
//******************************************************************************
const useStyles = makeStyles((theme) => ({
  item: {
    '&.MuiListItemButton-root': {
      background: 'linear-gradient(90deg, '+ theme.palette.secondary.light +' 0%, rgba(255,255,255,0) 100%)'
    },
    // '&.MuiListItemButton-root:hover': {
    //   backgroundColor: theme.palette.secondary.light
    // }
  }
}));

//******************************************************************************
// component definition
//******************************************************************************
export default function FormMyDataItem({submission}) {
  const classes = useStyles();
  const [searchParams, setSearchParams] = useSearchParams();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [selected, setSelected] = React.useState(false);

  React.useEffect(() => {
    if (searchParams.get("submission") === submission._id) {
      setSelected(true);
    } else {
      setSelected(false);
    }
  }, [searchParams, submission]);

  //----------------------------------------------------------------------------
  // funtions
  //----------------------------------------------------------------------------
  const handleClick = () => {
    if (!selected) {
      setSearchParams({ submission: submission._id });
    }
  }

  const onRightClick = (event) => {
    event.preventDefault();
    setAnchorEl(event.currentTarget);
  }

  const handleClose = () => {
    setAnchorEl(null);
  }

  //----------------------------------------------------------------------------
  // component rendering
  //----------------------------------------------------------------------------
  return (
    <React.Fragment>
      <ListItemButton className={clsx(selected && classes.item)}
        onClick={handleClick} onContextMenu={onRightClick}>
        <ListItemText primary={DateTimeToString(submission.createdAt)} />
        {submission.submitted ? 'Submitted' : 'Drafting'}
      </ListItemButton>
      <FormSubFunctions submission={submission}
        anchorEl={anchorEl} handleClose={handleClose} />
    </React.Fragment>
  );
}

import React from 'react';
import { Typography, Grid, IconButton, Tooltip } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import TemplateVariableItem from './TemplateVariableItem';


//******************************************************************************
// component definition
//******************************************************************************
export default function TemplateVariables({variables, addVar, deleteVar, loading}) {
  const [changed, setChanged] = React.useState(false);
  const [msg, setMsg] = React.useState('');
  // console.log(varList);

  //----------------------------------------------------------------------------
  // funtions
  //----------------------------------------------------------------------------
  const onChange = (i, key, value) => {
    if (changed) {
    }
  };


  const setChangedTrue = () => {
    setChanged(true);
    setMsg('(editing...)');
  }

  //----------------------------------------------------------------------------
  // component rendering
  //----------------------------------------------------------------------------
  return (
    <Grid container spacing={1} alignItems="center">
      <Grid item container xs={12} alignItems="center">
        <Grid item xs="auto">
          <Typography>
            Variables:
          </Typography>
        </Grid>
        <Grid item xs="auto">
          <Tooltip title="Add new variable">
            <IconButton color="primary" component="span" size="small"
              onClick={addVar} disabled={loading}>
              <AddIcon fontSize="small" />
            </IconButton>
          </Tooltip>
        </Grid>
        <Grid item xs>
          <Typography>
            {msg}
          </Typography>
        </Grid>
      </Grid>
      { variables.map(variable=>(
        <TemplateVariableItem key={variable._id} 
          variable={variable}
          onUpdate={onChange}
          deleteVar={deleteVar}
          setChanged={setChangedTrue} loading={loading} />
      ))}
    </Grid>
  );
}

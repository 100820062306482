import React from 'react';
import { useMutation } from "@apollo/client";
import { ListItemButton, ListItemText, ListItemAvatar, Avatar, Dialog, DialogTitle, DialogActions, Button } from '@mui/material';
import PendingActionsIcon from '@mui/icons-material/PendingActions';
import DoneOutlineIcon from '@mui/icons-material/DoneOutline';
import BlockIcon from '@mui/icons-material/Block';
import { DELETE_CERTIFICATE, ISSUE_CERTIFICATE, UPDATE_CERTIFICATE, CERTIFICATES_BY_TYPE } from '../../constants/gqls/certificates';
import CertificateItemDetails from './CertificateItemDetails';
// import { makeStyles } from '@material-ui/core/styles';

//******************************************************************************
// style definition
//******************************************************************************
// const useStyles = makeStyles((theme) => ({
//
// }));

//******************************************************************************
// component definition
//******************************************************************************
export default function CertificateItem({ cert }) {
  // const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [deleteCertificate] = useMutation(DELETE_CERTIFICATE, {
    refetchQueries: [ CERTIFICATES_BY_TYPE ],
  });
  const [issueCertificate, { loading }] = useMutation(ISSUE_CERTIFICATE);
  const [updateCertificate, { loading:updateLoading }] = useMutation(UPDATE_CERTIFICATE);
  // console.log(cert);

  //----------------------------------------------------------------------------
  // funtions
  //----------------------------------------------------------------------------
  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleDelete = () => {
    deleteCertificate({ variables: { certId: cert._id }});
    setOpen(false);
  };

  const handleIssue = () => {
    issueCertificate({ variables: { certId: cert._id }});
  };

  const handleUpdate = (update) => {
    updateCertificate({ variables: { 
      certId: cert._id,
      update 
    }});
  };

  const handleReject = () => {
    handleUpdate({ status: 'rejected' });
  };

  //----------------------------------------------------------------------------
  // component rendering
  //----------------------------------------------------------------------------
  return (
    <>
      <ListItemButton onClick={handleOpen}>
        <ListItemAvatar>
          <Avatar>
            {{
              requesting: <PendingActionsIcon />,
              issued: <DoneOutlineIcon />,
              rejected: <BlockIcon />
            }[cert.status]}
          </Avatar>
        </ListItemAvatar>
        <ListItemText primary={cert.email} />
      </ListItemButton>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Details</DialogTitle>
        { open && 
          <CertificateItemDetails certId={cert._id} />
        }
        <DialogActions>
          { cert.status !== 'issued' &&
            <>
              <Button onClick={handleDelete} disabled={loading || updateLoading}>
                Delete
              </Button>
              { cert.status !== 'rejected' &&
                <Button onClick={handleReject} disabled={loading || updateLoading}>
                  Reject
                </Button>
              }
              <Button variant="contained"
                onClick={handleIssue} disabled={loading || updateLoading}>
                Issue
              </Button>
            </>
          }
        </DialogActions>
      </Dialog>
    </>
  );
}

import React from 'react';
import { IconButton, Button, Dialog, DialogTitle,
  DialogContent, DialogContentText, DialogActions } from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import BlockIcon from '@mui/icons-material/Block';
import { useMutation } from "@apollo/client";
import { UNDO_SUBMIT, SUBMISSIONS } from '../../constants/gqls/forms';

//******************************************************************************
// component definition
//******************************************************************************
export default function UndoSubmitBtn({subId}) {
  const [open, setOpen] = React.useState(false);
  const [undoSubmit, { loading }] = useMutation(UNDO_SUBMIT, {
    refetchQueries: [ SUBMISSIONS ]
  });

  //----------------------------------------------------------------------------
  // funtions
  //----------------------------------------------------------------------------
  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const onClick = () => {
    undoSubmit({ variables: { subId }}).then(res=>{
      // goto
      // console.log(res);
    });
  }

  //----------------------------------------------------------------------------
  // component rendering
  //----------------------------------------------------------------------------
  return (
    <React.Fragment>
      <IconButton onClick={handleOpen}>
        <BlockIcon />
      </IconButton>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>
          Confirm Undo submit?
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            Undo submit will return the submission to drafting state.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <LoadingButton variant="outlined"
            loading={loading} onClick={onClick}>
            Confirm
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  )
}

const themeOptions = {
  palette: {
    type: 'light',
    primary: {
      // main: '#abd1dc',
      main: '#82a9bc',
      dark: '#327d89',
      pale: '#dae5eb'
      // light: '#bbdae3',
      // contrastText: 'rgba(0, 0, 0, 0.87)'
    },
    secondary: {
      // main: '#ecd59f',
      main: '#a3856d',
      dark: '#5c452e',
      light: '#E0D3CB',
      // contrastText: 'rgba(0, 0, 0, 0.87)'
    },
    background: {
      default: '#f5f5f5'
    }
  },
  typography: {
    button: {
      textTransform: 'none'
    }
  }
};

export default themeOptions;

import React from 'react';
// import { TextField, Autocomplete, Chip } from '@mui/material';
import { useQuery } from "@apollo/client";
import { DATA } from '../../constants/gqls/dataValues';

//******************************************************************************
// component definition
//******************************************************************************
export default function DataQuery({profileKeyId, setData}) {
  const { data } = useQuery(DATA, { variables: { 
    where: { input: { profileKey: { _id: profileKeyId }}}
  }});
  // console.log(data);
  
  React.useEffect(() => {
    // console.log(data);
    if (data?.data) {
      setData(profileKeyId, data.data);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [profileKeyId, data]);

  //----------------------------------------------------------------------------
  // component rendering
  //----------------------------------------------------------------------------
  return (
    <div />
  );
}

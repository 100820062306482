import React from 'react';
import { ListItemButton, ListItemText, Collapse } from '@mui/material';
import { makeStyles } from '@material-ui/core/styles';
import ActionItemDetails from './ActionItemDetails';
import ActionFunctions from './ActionFunctions';

function actionTitle(action) {
  let type = action.type[0].toUpperCase()+action.type.slice(1);
  let fire = {
    onSubmit: 'form submitted',
    onIssued: 'cert issued',
    onUserTrigger: 'user trigger',
    onSessionCreate: 'new service session created',
    onSessionConfirm: 'service session confirmed',
    onSessionClose: 'service session closed',
    onSessionComplete: 'service session completed',
    onAttendanceSubmit: 'attendance taken',
  }[action.fire];
  return `${type} when ${fire}`;
}

//******************************************************************************
// style definition
//******************************************************************************
const useStyles = makeStyles((theme) => ({
  root: {
    position: 'relative',
    background: (props) => (props.open?
      'linear-gradient(165deg, '+ theme.palette.secondary.light +' 0%, rgba(255,255,255,0) 40%)'
      : 'none')
  },
  title: {
    '& .MuiListItemText-primary': {
      fontWeight: (props) => (props.open? 'bold' : 'unset'),
      color: (props) => (props.active? 'inherit' : theme.palette.text.disabled),
    }
  },
}));

//******************************************************************************
// component definition
//******************************************************************************
export default function ActionItem({action, scope}) {
  const [open, setOpen] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const classes = useStyles({open, active: action.active});
  // console.log(action);

  //----------------------------------------------------------------------------
  // funtions
  //----------------------------------------------------------------------------
  const handleClick = () => {
    setOpen(!open);
  };

  const handleRightClick = (event) => {
    event.preventDefault();
    setAnchorEl(event.currentTarget);
  }

  const handleClose = () => {
    setAnchorEl(null);
  }

  //----------------------------------------------------------------------------
  // component rendering
  //----------------------------------------------------------------------------
  return (
    <div className={classes.root}>
      <ListItemButton onClick={handleClick} onContextMenu={handleRightClick}>
        <ListItemText primary={actionTitle(action)} className={classes.title} />
      </ListItemButton>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <ActionItemDetails action={action} scope={scope} />
      </Collapse>
      <ActionFunctions action={action}
        anchorEl={anchorEl} handleClose={handleClose} />
    </div>
  );
}

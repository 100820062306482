import React from 'react';
import { Switch, FormControlLabel } from '@mui/material';


//******************************************************************************
// component definition
//******************************************************************************
export default function SwitchInput({id, disabled, data, updateData}) {

  //----------------------------------------------------------------------------
  // funtions
  //----------------------------------------------------------------------------
  const handleChange = (event) => {
    updateData({ 
      boolValue: event.target.checked
    }).then(res => {
      console.log(res);
    })
  };

  //----------------------------------------------------------------------------
  // component rendering
  //----------------------------------------------------------------------------
  return (
    <FormControlLabel disabled={disabled}
      control={
        <Switch checked={data? data.boolValue : false}
          onChange={handleChange}
          inputProps={{ 'aria-label': 'switch-'+id }} />
      } label={data?.boolValue? "Yes": "No"} />
  )
}

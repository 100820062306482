import React from 'react';
import { Typography, LinearProgress, Stack } from '@mui/material';
import { makeStyles } from '@material-ui/core/styles';
import { useQuery } from "@apollo/client";
import TagEdit from './TagEdit';
import TagDelete from './TagDelete';
import { TAG_DETAILS } from '../../constants/gqls/tags';

//******************************************************************************
// style definition
//******************************************************************************
const useStyles = makeStyles((theme) => ({
  root: {
    margin: theme.spacing(2),
  },
  description: {
    '&.MuiTypography-root': {
      marginBottom: theme.spacing(2)
    }
  },
  secondary: {
    '&.MuiTypography-root': {
      color: theme.palette.text.secondary,
      fontSize: '0.875rem'
    }
  }
}));

//******************************************************************************
// component definition
//******************************************************************************
export default function TagTypeItemDetails({id, isAdmin}) {
  const classes = useStyles();
  const { loading, data } = useQuery(TAG_DETAILS, { variables: {
    where: { _id: id }
  }});
  // console.log(data);

  //----------------------------------------------------------------------------
  // component rendering
  //----------------------------------------------------------------------------
  if (loading) return <LinearProgress />
  if (!data) return 'Network error.'
  if (!data.tags[0]) return 'NotFount'

  return (
    <div className={classes.root}>
      <Typography className={classes.description}>
        {data.tags[0].description}
      </Typography>
      { (isAdmin || data.tags[0].scope.includes('personal')) &&
        <Typography className={classes.secondary}>
          id: {data.tags[0]._id}
        </Typography>
      }
      <Typography className={classes.secondary}>
        scope: {data.tags[0].scope.join(',')}
      </Typography>
      <Typography className={classes.secondary}>
        Created by: {data.tags[0].createdBy.name}
        &nbsp;(roles: {data.tags[0].createdBy.roles.toString()})&nbsp;
        <i>{data.tags[0].createdBy.email}</i>
      </Typography>
      { (isAdmin || !data.tags[0].scope.includes('system')) &&
        <React.Fragment>
          <Typography className={classes.secondary}>
            Created at: {(new Date(data.tags[0].createdAt)).toLocaleString()}
          </Typography>
          <Typography className={classes.secondary}>
            Updated at: {
              data.tags[0].updatedAt ?
                (new Date(data.tags[0].updatedAt)).toLocaleString()
                : 'N/A'
            }
          </Typography>
          <Stack direction="row" justifyContent="center" alignItems="center" spacing={4}>
            <TagEdit tag={data.tags[0]} />
            <TagDelete tag={data.tags[0]} />
            {
              // <TagTypeEdit tagType={data.tagTypes[0]} />
              // <TagTypeDelete tagType={data.tagTypes[0]} />
            }
          </Stack>
        </React.Fragment>
      }
    </div>
  );
}

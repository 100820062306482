import React from 'react';
import { Button } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import TagForm from './TagForm';

//******************************************************************************
// component definition
//******************************************************************************
export default function TagTypeEdit({tag}) {
  const [open, setOpen] = React.useState(false);

  //----------------------------------------------------------------------------
  // funtions
  //----------------------------------------------------------------------------
  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  //----------------------------------------------------------------------------
  // component rendering
  //----------------------------------------------------------------------------
  return (
    <React.Fragment>
      <Button variant="contained" startIcon={<EditIcon />} onClick={handleOpen}>
        Edit
      </Button>
      <TagForm open={open} onClose={handleClose}
        tag={tag} defaultScope={tag.scope.join(',')} />
    </React.Fragment>
  );
}

import React from 'react';
import { Popover, IconButton } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { makeStyles } from '@material-ui/core/styles';
import { useMutation, useQuery } from "@apollo/client";
import UndoSubmitBtn from "./UndoSubmitBtn";
import { DELETE_SUBMISSION, MY_SUBMISSIONS, SUBMISSION } from '../../constants/gqls/forms';
import { DOCUMENT_ISFORM } from '../../constants/gqls/documents';
import { ME } from '../../constants/gqls/user';

//******************************************************************************
// style definition
//******************************************************************************
const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiPaper-root": {
      borderRadius: '20px',
      backgroundColor: theme.palette.primary.pale,
    }
  }
}));

//******************************************************************************
// component definition
//******************************************************************************
export default function FormSubFunctions({submission, anchorEl, handleClose, handleEdit}) {
  const classes = useStyles();
  const { data } = useQuery(ME);
  const [deleteSubmission, { loading }] = useMutation(DELETE_SUBMISSION, {
    refetchQueries: [ MY_SUBMISSIONS, SUBMISSION, DOCUMENT_ISFORM ],
  });
  const open = Boolean(anchorEl);
  const isAdmin = data?.me?.roles?.includes('admin');
  // console.log(submission);

  //----------------------------------------------------------------------------
  // funtions
  //----------------------------------------------------------------------------
  const onDeleteClick = () => {
    deleteSubmission({ variables: {
      subId: submission._id
    }}).then(res=>{
      //
    });
  }

  //----------------------------------------------------------------------------
  // component rendering
  //----------------------------------------------------------------------------
  if (submission?.submitted && !isAdmin) return '';

  return (
    <Popover open={open} anchorEl={anchorEl} onClose={handleClose}
      anchorOrigin={{
        vertical: 'center',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'center',
        horizontal: 'right',
      }} className={classes.root}
    >
      { !submission.submitted &&
        <IconButton onClick={onDeleteClick} disabled={loading}>
          <DeleteIcon />
        </IconButton>
      }
      { submission.submitted && isAdmin &&
        <>
          <IconButton onClick={handleEdit}>
            <EditIcon />
          </IconButton>
          { submission && !submission.serviceSessionId &&
            <UndoSubmitBtn subId={submission._id} />
          }
        </>
      }
    </Popover>
  )
}

import React from 'react';
import { generatePath } from "react-router-dom";
import { IconButton, Tooltip } from '@mui/material';
import SupportAgentIcon from '@mui/icons-material/SupportAgent';
import SiteMap from '../../constants/siteMap';


//******************************************************************************
// component definition
//******************************************************************************
export default function ServiceSessionCell({value}) {
  const href = value?
    generatePath(SiteMap.serviceSession, { id: value }) : '';


  //----------------------------------------------------------------------------
  // component rendering
  //----------------------------------------------------------------------------
  if (!value) return ''

  return (
    <Tooltip title="View service session">
      <IconButton color="primary" href={href}>
        <SupportAgentIcon />
      </IconButton>
    </Tooltip>
  );
}

import { gql } from '@apollo/client';
import { personSum } from './fragments';


export const SERVICES = gql`
  query Services($options: ServiceOptions) {
    services(options: $options) {
      _id name key
    }
    servicesCount
  }
`;

export const SEARCH_SERVICES = gql`
  query SearchServices($where: ServiceWhere, $options: ServiceOptions) {
    services(where: $where, options: $options) {
      _id name key
    }
  }
`;

export const MY_SERVICES = gql`
  query MyServices($personId: ID!) {
    myServices(personId: $personId) {
      _id key name 
    }
  }
`;

export const SERVICE = gql`
  query Service($where: ServiceWhere) {
    services(where: $where) {
      _id name key defaultHours defaultPGHours providerRoles clientRoles description serviceSessionsCount serviceSessionsYears myServiceHours myRole 
      myAttendanceRate {
        totalHours completedHours closedCount
      }
      managers { ${personSum} }
    }
  }
`;

export const ATTENDANCE_RATE = gql`
  query AttendanceRate($serviceId: ID!, $personId: ID!) {
    attendanceRate(serviceId: $serviceId, personId: $personId) {
      closedCount completedHours totalHours
    }
  }
`;

export const SERVICE_HOURS = gql`
  query ServiceHours($serviceId: ID!, $personId: ID!) {
    serviceHours(serviceId: $serviceId, personId: $personId)
  }
`;

export const CREATE_SERVICE = gql`
  mutation CreateServices($input: [ServiceCreateInput!]!) {
    createServices(input: $input) {
      services {
        _id name
      }
    }
  }
`;

export const UPDATE_SERVICE = gql`
  mutation UpdateService($id: ID!, $update: ServiceUpdateInput!) {
    updateService(id: $id, update: $update) {
      services { 
        _id name key description
        managers { _id }
      }
    }
  }
`;

export const DELETE_SERVICE = gql`
  mutation DeleteService($serviceId: ID!) {
    deleteService(serviceId: $serviceId)
  }
`;

import React from 'react';
import axios from 'axios';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@mui/material/TextField';

//******************************************************************************
// style definition
//******************************************************************************
const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    '& .MuiInputBase-root': {
      width: '80px',
    }
  },
  equals: {
    fontSize: '24px',
    fontWeight: 'bold',
    marginRight: theme.spacing(1)
  },
  text: {
    textAlign: 'center'
  }
}));

//******************************************************************************
// component definition
//******************************************************************************
export default function Captcha({setToken, value, setValue, params, loaded, setLoaded}) {
  const classes = useStyles();
  const [svgCaptcha, setCaptcha] = React.useState('');
  // const [value, setValue] = React.useState('');
  const timer = React.useRef(0);

  React.useEffect(() => {
    if (!loaded) {
      axios.get(process.env.REACT_APP_CAPTCHA_URI, { params }).then((res)=>{
        setCaptcha(res.data.captcha);
        setToken(res.data.token);
        // console.log(res);
        setLoaded(true);
        setValue('')
        timer.current = setTimeout(reloadCaptcha, 1000*60*60);
      }).catch((error) => {
        console.log(error.response);
      });
    }
    return () => {
      clearTimeout(timer.current);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loaded]);

  //----------------------------------------------------------------------------
  // funtions
  //----------------------------------------------------------------------------
  const reloadCaptcha = () => {
    setLoaded(false);
  };

  const handleChange = (event) => {
    setValue(event.target.value)
  }

  // const verify = () => {
  //   axios.get(process.env.REACT_APP_CAPTCHA_URI+'check', {
  //     params: { 
  //       text: value,
  //       token: captchaData.token
  //     }
  //   }).then((res)=>{
  //     setValue('');
  //     if (res.data.success) {
  //       // console.log(res.data);
  //       setToken(res.data.token);
  //       setCheckOK(true);
  //     } else {
  //       reloadCaptcha();
  //     }
  //   }).catch((error) => {
  //     console.log(error.response);
  //   });
  // }

  //----------------------------------------------------------------------------
  // component rendering
  //----------------------------------------------------------------------------
  return (
    <div>
      <div className={classes.root}>
        <div dangerouslySetInnerHTML={{ 
          __html: svgCaptcha
          }} onClick={reloadCaptcha} />
        <div className={classes.equals}>=</div>
        <TextField id="captcha-result" size="small" 
          variant="outlined" type='number'
          value={value} onChange={handleChange} />
      </div>
      <div className={classes.text}>Fill in the calculated answer before submit.</div>
    </div>
  );
}

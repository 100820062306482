import React from 'react';
import { Button, Dialog, DialogTitle,
  DialogContent, DialogContentText, DialogActions } from '@mui/material';
import HtmlEditor from "../ContentEditor/HtmlEditor";
import CloseBtn from './CloseBtn';

//******************************************************************************
// component definition
//******************************************************************************
export default function StaffStatusBtn({session, handleUpdate, loading}) {
  const [open, setOpen] = React.useState(false);
  const [note, setNote] = React.useState('');
  const completed = Boolean(session.hoursCompleted === session.hours)
  console.log(session);
  

  React.useEffect(() => {
    setNote(session.note)
  }, [session]);

  //----------------------------------------------------------------------------
  // funtions
  //----------------------------------------------------------------------------
  const openDialog = () => {
    setOpen(true);
  };

  const closeDialog = () => {
    setOpen(false);
  };

  const handleNoteChange = (value) => {
    if (value !== note && value !== '<p></p>') {
      setNote(value);
    }
  }

  const handleStatusChange = (status) => {
    handleUpdate({ status }).then(res=>{
      // console.log(res);
    });
  }

  const handleClose = () => {
    handleStatusChange('Closed');
  }

  const handleCloseOrComplete = () => {
    handleUpdate({
      status: completed ? 'Completed' : 'Closed',
      note
    }).then(res=>{
      // console.log(res);
      closeDialog();
    });
  }

  const handleOpen = () => {
    if (session.studentConfirmed || session.hoursCompleted !== 0) {
      handleStatusChange('Confirmed');
    } else {
      handleStatusChange('Created');
    }
  }

  //----------------------------------------------------------------------------
  // component rendering
  //----------------------------------------------------------------------------
  if (session.status === 'Created') {
    return (
      <CloseBtn disabled={loading} handleStatusClose={handleClose} />
    )
  }
  if (session.status === 'Confirmed') {
    return (
      <>
        <Button variant="outlined" disabled={loading} onClick={openDialog}>
          {completed ? 'Complete' : 'Close'}
        </Button>
        <Dialog open={open} onClose={closeDialog}>
          <DialogTitle>
            {completed ? 'Confirm complete' : 'Close reason'}
          </DialogTitle>
          <DialogContent>
            <DialogContentText>
              { completed ?
                'Please provide a report to complete this service session.'
                : 'Please note down the reason for closing a confirmed service session.'
              }
            </DialogContentText>
            <HtmlEditor placeholder="Report or remarks goes here"
              html={session.note} onChange={handleNoteChange}
              disabled={loading} />
          </DialogContent>
          <DialogActions>
            <Button onClick={closeDialog}>Cancel</Button>
            <Button variant="outlined"
              disabled={loading || !note} onClick={handleCloseOrComplete}>
              {completed ? 'Complete' : 'Close'}
            </Button>
          </DialogActions>
        </Dialog>
      </>
    )
  }
  return (
    <Button variant="outlined" disabled={loading} onClick={handleOpen}>
      Open
    </Button>
  )
}

import React from 'react';
import { IconButton, Tooltip, Button, Dialog, DialogTitle,
  DialogContent, DialogContentText, DialogActions } from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import DeleteIcon from '@mui/icons-material/Delete';
import { useMutation } from "@apollo/client";
import { DELETE_ITEM } from '../../constants/gqls/items';

//******************************************************************************
// component definition
//******************************************************************************
export default function DeleteBtn({itemId, inputDeleteBlock, sortItem}) {
  const [open, setOpen] = React.useState(false);
  const [deleteItem, { loading }] = useMutation(DELETE_ITEM);

  //----------------------------------------------------------------------------
  // funtions
  //----------------------------------------------------------------------------
  const handleClose = () => {
    setOpen(false);
  };

  const handleDelete = () => {
    deleteItem({ variables: {
      itemId: itemId
    }}).then(res=>{
      sortItem().then(res=>{
        // console.log(res);
      });
    });
  }

  const onDeleteClick = () => {
    // if (inputDeleteBlock) {
      setOpen(true);
    // } else {
    //   handleDelete();
    // }
  }

  //----------------------------------------------------------------------------
  // component rendering
  //----------------------------------------------------------------------------
  return (
    <React.Fragment>
      <Tooltip title="Delete item" placement="left">
        <IconButton onClick={onDeleteClick} disabled={loading}>
          <DeleteIcon />
        </IconButton>
      </Tooltip>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>
          {inputDeleteBlock? 'Dangerous update' : 'Confirm delete?'}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            {inputDeleteBlock? 
              "Your are trying to delete this item while someone has started filling in data here or it's imported to other form. Please clear all data and importing first."
              : "You are about to delete an item."
            }
          </DialogContentText>
        </DialogContent>
        { !inputDeleteBlock &&
          <DialogActions>
            <Button onClick={handleClose}>Cancel</Button>
            <LoadingButton variant="outlined"
              loading={loading} onClick={handleDelete}>
              Delete
            </LoadingButton>
          </DialogActions>
        }
      </Dialog>
    </React.Fragment>
  )
}

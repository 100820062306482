import React from 'react';
import { useParams } from "react-router-dom";
import { Container, LinearProgress } from '@mui/material';
import { makeStyles } from '@material-ui/core/styles';
import { useQuery } from "@apollo/client";
import NotFount from './NotFount';
import TagsShowcase from '../components/TagsShowcase';
import Board from '../components/Space/Board';
import SettingBtn from '../components/Space/SettingBtn';
import { SPACE } from '../constants/gqls/spaces';

//******************************************************************************
// style definition
//******************************************************************************
const useStyles = makeStyles((theme) => ({
  box: {
    marginTop: theme.spacing(1),
    marginLeft: theme.spacing(1)
  },
  container: {
    paddingTop: theme.spacing(1),
  },
}));

//******************************************************************************
// component definition
//******************************************************************************
export default function Space() {
  const classes = useStyles();
  const params = useParams();
  // const [setting, setSetting] = React.useState(false);
  const [board, setBoard] = React.useState(null);
  const [showBoard, setShowBoard] = React.useState(null);
  const { loading, data } = useQuery(SPACE, { variables: {
    where: { _id: params.id },
    options: {
      sort: [{ index: 'ASC' }]
    }
  }});
  // const [tagSpace, { loading:tagLoading }] = useMutation(TAG_SPACE);
  // console.log(data);

  //----------------------------------------------------------------------------
  // funtions
  //----------------------------------------------------------------------------
  // const addTag = (tagID) => {
  //   return tagSpace({ variables: {
  //     where: { _id: params.id },
  //     connect: { tagsRel: [{
  //       where: { node: { _id: tagID }}
  //     }]}
  //   }});
  // };
  //
  // const removeTag = (tagID) => {
  //   return tagSpace({ variables: {
  //     where: { _id: params.id },
  //     disconnect: { tagsRel: [{
  //       where: { node: { _id: tagID }}
  //     }]}
  //   }});
  // };

  //----------------------------------------------------------------------------
  // component rendering
  //----------------------------------------------------------------------------
  if (loading) return <LinearProgress />;
  if (!data) return 'Network error.'
  if (!data.spaces[0]) return <NotFount />

  return (
    <React.Fragment>
      <div className={classes.box}>
        <TagsShowcase objId={data.spaces[0]._id} tags={data.spaces[0].tags} />
      </div>
      <Container maxWidth="xl" className={classes.container}>
        <Board space={data.spaces[0]} showBoard={showBoard} setBoard={setBoard} refetchQueries={[SPACE]} />
      </Container>
      { data.spaces[0].myRole === 'Manager' &&
        <SettingBtn space={data.spaces[0]}
          showBoard={showBoard} setShowBoard={setShowBoard}
          board={board} refetchQueries={[SPACE]} />
      }
    </React.Fragment>
  );
}

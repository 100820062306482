import React from 'react';
import { Typography, Grid, IconButton, Tooltip } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import ActionVariableItem from './ActionVariableItem';

function toJSONString(list) {
  let obj = {};
  for (var i = 0; i < list.length; i++) {
    obj[list[i][0]] = list[i][1];
  }
  return JSON.stringify(obj);
}

function getValueOptions(scope) {
  if (scope==='form') {
    return [
      '[[owner.email]]',
      '[[sharedTo.email]]',
      '[[applicant.email]]',
      '[[applicant.name]]',
      '[[form.url]]',
    ];
  } else if (scope==='service') {
    return [
      '[[student.email]]',
      '[[student.name]]',
      '[[student.chineseNameSave]]',
      '[[teacher.email]]',
      '[[teacher.name]]',
      '[[teacher.chineseNameSave]]',
      '[[session.url]]',
      '[[session.year]]',
      '[[session.semester]]',
      '[[session.hours]]',
      '[[session.meetingTime]]',
      '[[session.categories]]',
    ];
  } else if (scope==='certType') {
    return [
      '[[certType.name]]',
      '[[certificate._id]]',
      '[[variables.email]]',
    ];
  }
}

//******************************************************************************
// component definition
//******************************************************************************
export default function ActionVariables({action, handleUpdate, loading, scope}) {
  const varList = Object.entries(JSON.parse(action.variables));
  const [changed, setChanged] = React.useState(false);
  const [msg, setMsg] = React.useState('');
  const valueOptions = getValueOptions(scope)
  // console.log(varList);

  //----------------------------------------------------------------------------
  // funtions
  //----------------------------------------------------------------------------
  const onChange = (i, key, value) => {
    if (changed) {
      let newList = [...varList];
      newList[i] = [key, value];
      let variables = toJSONString(newList);
      handleUpdate({ variables }).then(res=>{
        // console.log(res);
        setChanged(false);
        setMsg('(saved)');
      });
    }
  };

  const addVar = () => {
    let newList = [...varList, ['key', '']];
    let variables = toJSONString(newList);
    handleUpdate({ variables }).then(res=>{
      // console.log(res);
    });
  }

  const deleteVar = (i) => {
    let newList = [...varList];
    newList.splice(i, 1)
    let variables = toJSONString(newList);
    handleUpdate({ variables }).then(res=>{
      // console.log(res);
    });
  }

  const setChangedTrue = () => {
    setChanged(true);
    setMsg('(editing...)');
  }

  // const onKeyChange = (i, value) => {
  //   let newList = [...varList];
  //   newList[i] = [varList[i][0], value];
  //   let variables = toJSONString(newList);
  //   handleUpdate({ variables });
  // };

  //----------------------------------------------------------------------------
  // component rendering
  //----------------------------------------------------------------------------
  return (
    <Grid container spacing={1} alignItems="center">
      <Grid item container xs={12} alignItems="center">
        <Grid item xs="auto">
          <Typography>
            Variables:
          </Typography>
        </Grid>
        <Grid item xs="auto">
          <Tooltip title="Add new variable">
            <IconButton color="primary" component="span" size="small"
              onClick={addVar} disabled={loading}>
              <AddIcon fontSize="small" />
            </IconButton>
          </Tooltip>
        </Grid>
        <Grid item xs>
          <Typography>
            {msg}
          </Typography>
        </Grid>
      </Grid>
      { varList.map((variable, i)=>(
        <ActionVariableItem key={i} index={i}
          variable={variable} options={valueOptions}
          onUpdate={onChange} onDelete={deleteVar}
          setChanged={setChangedTrue} loading={loading} />
      ))}
    </Grid>
  );
}

import React from 'react';
import { TextField } from '@mui/material';
import { useLazyQuery } from "@apollo/client";
import { formFilledCheck } from '../../constants/cache';
import { UNIQUE_CHECK } from '../../constants/gqls/forms';


//******************************************************************************
// component definition
//******************************************************************************
/**
 * @typedef {Object} UniqueKeyInputProps
 * @property {string} id - input ID.
 * @property {boolean} disabled - is disabled.
 * @property {function} updateData - Handle value change.
 * @property {object} data - The input data object.
 */

/**
 * unique key input component.
 * @param {UniqueKeyInputProps} props - propertis
 */
export default function UniqueKeyInput({id, disabled, data, updateData}) {
  const [value, setValue] = React.useState(data?.value || '');
  const [changed, setChanged] = React.useState(false);
  const [message, setMessage] = React.useState('');
  const [uniqueCheck, { data:checkData }] = useLazyQuery(UNIQUE_CHECK, { fetchPolicy: "network-only" });
  const timer = React.useRef(0);
  // console.log(checkData);

  React.useEffect(() => {
    return () => {
      clearTimeout(timer.current);
    }
  }, []);

  React.useEffect(() => {
    // console.log(data);
    setValue(data?.value || '');
    if (data && uniqueCheck) {
      uniqueCheck({ variables: { dataId: data._id}}).then(res=>{
        // console.log(res);
      });
    }
  }, [data, uniqueCheck]);

  React.useEffect(() => {
    let checked = true, old = {...formFilledCheck()};

    if (checkData) {
      checked = checkData.uniqueCheck;
    }
    old[id] = checked;
    formFilledCheck(old);
    if (checked) {
      // console.log('checked true');
      setMessage('');
    } else {
      setMessage('It has been occupied, please enter another one.');
    }
  }, [checkData, id]);

  React.useEffect(() => {
    update();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  //----------------------------------------------------------------------------
  // funtions
  //----------------------------------------------------------------------------
  const update = React.useCallback(() => {
    if (changed) {
      clearTimeout(timer.current);
      timer.current = setTimeout(()=>{
        updateData({ value }).then(res => {
          // console.log(res);
          setChanged(false);
        })
      }, 600);
    }
  }, [changed, value, updateData]);

  const handleChange = React.useCallback((event) => {
    setValue(event.target.value)
    setChanged(true);
  }, [])

  

  //----------------------------------------------------------------------------
  // component rendering
  //----------------------------------------------------------------------------
  return (
    <TextField fullWidth id={'text-unique-'+id} variant="standard"
      disabled={disabled}
      value={value} onChange={handleChange}
      error={message !== ''} helperText={message} />
  )
}

import React from 'react';
import { LinearProgress, List } from '@mui/material';
import { useQuery } from "@apollo/client";
import { MY_SERVICES } from '../../constants/gqls/services';
import ServiceCardItem from '../Services/ServiceCardItem';
// const limit = 10;


//******************************************************************************
// component definition
//******************************************************************************
export default function MyServicesCard({person}) {
  // const needRefetch = useReactiveVar(doRefetch);
  const { loading, data } = useQuery(MY_SERVICES, {
    variables: { personId: person._id }
  });
  // console.log(data);

  // React.useEffect(() => {
  //   if (needRefetch) {
  //     // console.log('refetch');
  //     refetch();
  //     doRefetch(false);
  //   }
  // }, [needRefetch, refetch]);

  //----------------------------------------------------------------------------
  // funtions
  //----------------------------------------------------------------------------
  // const loadMore = () => {
  //   fetchMore({ variables: {
  //     limit,
  //     offset: data.myServiceSessions.length
  //   }});
  // }


  //----------------------------------------------------------------------------
  // component rendering
  //----------------------------------------------------------------------------
  if (loading) return <LinearProgress />;
  if (!data) return 'Network error.'

  return (
    <List>
      {data.myServices.map(service=>(
        <ServiceCardItem key={service._id}
          service={service} person={person} />
      ))}
    </List>
  );
}

import React from 'react';
import { Container, Paper, CardHeader } from '@mui/material';
import { makeStyles } from '@material-ui/core/styles';
import CreateBtn from '../components/DataAnalysis/CreateBtn';
import ImportBtn from '../components/DataAnalysis/ImportBtn';
import DataTableList from '../components/DataAnalysis/DataTableList';

//******************************************************************************
// style definition
//******************************************************************************
const useStyles = makeStyles((theme) => ({
  container: {
    paddingTop: theme.spacing(3)
  },
}));

//******************************************************************************
// component definition
//******************************************************************************
export default function DataAnalysis() {
  const classes = useStyles();

  //----------------------------------------------------------------------------
  // funtions
  //----------------------------------------------------------------------------


  //----------------------------------------------------------------------------
  // component rendering
  //----------------------------------------------------------------------------
  return (
    <Container maxWidth="xl" className={classes.container}>
      <Paper>
        <CardHeader title="Data Tables" action={
          <>
            <ImportBtn />
            <CreateBtn />
          </>
        } />
        <DataTableList />
      </Paper>
    </Container>
  );
}

import React from 'react';
import { Dialog, IconButton, Tab, Tabs, Tooltip } from '@mui/material';
import KeyIcon from '@mui/icons-material/Key';
import InputProfileKeyDialog from './InputProfileKeyDialog';
import InputFieldKeyDialog from './InputFieldKeyDialog';

//******************************************************************************
// component definition
//******************************************************************************
export default function InputKeyBtn({itemId, profileKey, fieldKey, updateInput, loading}) {
  const [open, setOpen] = React.useState(false);
  const [value, setValue] = React.useState(0);
  const [warnMsg, setWarnMsg] = React.useState(profileKey? true : false);
  // console.log(importFormId);

  //----------------------------------------------------------------------------
  // funtions
  //----------------------------------------------------------------------------
  const handleClose = () => {
    setWarnMsg(profileKey? true : false);
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  //----------------------------------------------------------------------------
  // component rendering
  //----------------------------------------------------------------------------
  return (
    <React.Fragment>
      <Tooltip color={ profileKey? "primary" : fieldKey? "secondary" : "default"}
        title={ 
          profileKey? "Profile key: "+profileKey.key 
          : fieldKey? "Field key: "+fieldKey : "Add key"
        }
      >
        <IconButton onClick={handleOpen}>
          <KeyIcon />
        </IconButton>
      </Tooltip>
      <Dialog open={open} onClose={handleClose}>
        <Tabs value={value} onChange={handleChange}>
          <Tab label="Profile Key" />
          <Tab label="Field Key" />
        </Tabs>
        { value===0 && 
          <InputProfileKeyDialog 
            handleClose={handleClose} 
            warnMsg={warnMsg} setWarnMsg={setWarnMsg}
            itemId={itemId} profileKey={profileKey} />
        }
        { value===1 &&
          <InputFieldKeyDialog 
            handleClose={handleClose} loading={loading} 
            value={fieldKey} updateInput={updateInput} />
        }
      </Dialog>
    </React.Fragment>
  )
}

import React from 'react';
import { generatePath } from "react-router-dom";
import { CardContent } from '@mui/material';
import TextFieldsIcon from '@mui/icons-material/TextFields';
// import { useQuery } from "@apollo/client";
import CardComm from './CardComm';
import ItemTextView from '../Items/ItemTextView';
// import { ITEM_PREV } from '../../constants/gqls/documents';
import SiteMap from '../../constants/siteMap';

//******************************************************************************
// component definition
//******************************************************************************
export default function TextCard({item, setSignal}) {
  // const { loading, data } = useQuery(ITEM_PREV, { variables: {
  //   where: { _id: itemId },
  // }});
  // const [goTo, setGoTo] = React.useState(null);
  const goTo = generatePath(SiteMap.document, {
    id: item.ownerDoc._id+'?item='+item._id
  });

  React.useEffect(() => {
    if (setSignal) {
      setSignal(`${item._id} loaded`);
    }
  }, [item, setSignal]);

  //----------------------------------------------------------------------------
  // component rendering
  //----------------------------------------------------------------------------
  // if (loading) return <LinearProgress />;
  // if (!data) return 'Network error.'

  return (
    <CardComm tags={item.tags}
      icon={<TextFieldsIcon />} goTo={goTo}>
      <CardContent>
        <ItemTextView itemText={item.text} />
      </CardContent>
    </CardComm>
  );
}

import React from 'react';
import { Typography, LinearProgress, Stack } from '@mui/material';
import { makeStyles } from '@material-ui/core/styles';
import { useQuery } from "@apollo/client";
import TagTypeEdit from './TagTypeEdit';
import TagTypeDelete from './TagTypeDelete';
import { TAG_TYPE_DETAILS } from '../../constants/gqls/tags';

//******************************************************************************
// style definition
//******************************************************************************
const useStyles = makeStyles((theme) => ({
  root: {
    margin: theme.spacing(2),
  },
  description: {
    '&.MuiTypography-root': {
      marginBottom: theme.spacing(2)
    }
  },
  secondary: {
    '&.MuiTypography-root': {
      color: theme.palette.text.secondary,
      fontSize: '0.875rem'
    }
  }
}));

//******************************************************************************
// component definition
//******************************************************************************
export default function TagTypeItemDetails({id}) {
  const classes = useStyles();
  const { loading, data } = useQuery(TAG_TYPE_DETAILS, { variables: {
    where: { _id: id },
    options: { limit: 10, offset: 0 }
  }});
  // console.log(data);

  //----------------------------------------------------------------------------
  // component rendering
  //----------------------------------------------------------------------------
  if (loading) return <LinearProgress />
  if (!data) return 'Network error.'
  if (!data.tagTypes[0]) return 'NotFount'

  return (
    <div className={classes.root}>
      <Typography className={classes.description}>
        {data.tagTypes[0].description}
      </Typography>
      <Typography className={classes.secondary}>
        Created by: {data.tagTypes[0].createdBy.name}
        &nbsp;(roles: {data.tagTypes[0].createdBy.roles.toString()})&nbsp;
        <i>{data.tagTypes[0].createdBy.email}</i>
      </Typography>
      <Typography className={classes.secondary}>
        Created at: {(new Date(data.tagTypes[0].createdAt)).toLocaleString()}
      </Typography>
      <Typography className={classes.secondary}>
        Updated at: {
          data.tagTypes[0].updatedAt ?
            (new Date(data.tagTypes[0].updatedAt)).toLocaleString()
            : 'N/A'
        }
      </Typography>
      <Stack direction="row" justifyContent="center" alignItems="center" spacing={4}>
        <TagTypeEdit tagType={data.tagTypes[0]} />
        <TagTypeDelete tagType={data.tagTypes[0]} />
      </Stack>
    </div>
  );
}

import React from 'react';
import { FormControlLabel, Checkbox } from '@mui/material';
import { scopeSelects } from '../../constants/utils';

//******************************************************************************
// component definition
//******************************************************************************
export default function ScopeSelect({value, onChange, disabled}) {

  //----------------------------------------------------------------------------
  // funtions
  //----------------------------------------------------------------------------
  const onScopeChange = (event) => {
    if (event.target.checked && !value.includes(event.target.name)) {
      onChange([...value, event.target.name]);
    } else if (!event.target.checked && value.includes(event.target.name)) {
      onChange(value.filter(ss=>ss!==event.target.name));
    }
  }

  //----------------------------------------------------------------------------
  // component rendering
  //----------------------------------------------------------------------------
  return (
    <div>
      { scopeSelects.map(ss=>(
        <FormControlLabel key={ss} control={
          <Checkbox checked={value.includes(ss)} onChange={onScopeChange}
            name={ss} disabled={disabled} />
        } label={ss} />
      ))}
    </div>
  );
}

import React from 'react';
import { useSearchParams } from "react-router-dom";
import { Stack } from '@mui/material';
import { useMutation } from "@apollo/client";
import { makeStyles } from '@material-ui/core/styles';
import { useReactiveVar } from "@apollo/client";
import TagsShowcase from '../TagsShowcase';
import FunctionBtn from '../Items/FunctionBtn';
import ItemContent from '../Items/ItemContent';
import { dataMap } from '../../constants/cache';
import { DELETE_DATA, SUBMISSION } from '../../constants/gqls/forms';
// import { UPDATE_ITEM } from '../../constants/gqls/documents';
// import { TAGS_FOR_ADD } from '../../constants/gqls/tags';

//******************************************************************************
// style definition
//******************************************************************************
const useStyles = makeStyles((theme) => ({
  row: {
    paddingTop: theme.spacing(2),
    position: 'relative'
  },
  avatar: {
    '&.MuiAvatar-root': {
      width: '36px',
      height: '36px'
    }
  },
  text: {
    marginTop: '4px'
  },
  // item: {
  //   backgroundColor: props => (
  //     props.focus? theme.palette.action.hover : 'unset'
  //   )
  // },
  focusBar: {
    position: 'absolute',
    top: 0,
    left: `-${theme.spacing(2)}px`,
    width: '3px',
    height: '100%',
    backgroundColor: theme.palette.secondary.light,
  }
}));

//******************************************************************************
// component definition
//******************************************************************************
export default function Item({ item, isFocus = false, mode, index, count, useProfileValue }) {
  const classes = useStyles();
  const ref = React.useRef(null);
  const dataMapVar = useReactiveVar(dataMap);
  const [focus, setFocus] = React.useState(false);
  const [editMode, setEditMode] = React.useState(false);
  // const [readMode, setReadMode] = React.useState(false);
  const [hideItem, setHideItem] = React.useState(false);
  const [submissionId, setSubmissionId] = React.useState(null);
  const [searchParams] = useSearchParams();
  const [authDeleteData] = useMutation(DELETE_DATA, {
    refetchQueries: [ SUBMISSION ],
  });
  // console.log(dataMapVar);

  React.useEffect(() => {
    if (isFocus) {
      ref.current.scrollIntoView({ block: 'center' });
    }
  }, [isFocus]);

  React.useEffect(() => {
    let eMode = item.editable && mode !== 'view' && mode !== 'page';
    // let rMode = Boolean(item.input?.profileKey) && !eMode;
    setEditMode(eMode);
    // setReadMode(rMode);
  }, [item, mode]);

  React.useEffect(() => {
    if (searchParams.get("submission")) {
      setSubmissionId(searchParams.get("submission"));
    } else {
      setSubmissionId(null);
    }
  }, [searchParams]);

  React.useEffect(() => {
    let isPre = (!editMode) && item.type !== 'filter' && item.preconditions.length !== 0;
    let precIncluded = false;
    if (isPre) {
      let options = dataMapVar ? dataMapVar.get('options') : [];
      for (var i = 0; i < item.preconditions.length; i++) {
        if (options.includes(item.preconditions[i]._id)) {
          precIncluded = true;
        }
      }
    }
    let hide = isPre && (!precIncluded);
    setHideItem(hide);
    if (hide) {
      if (submissionId && item.input && dataMapVar?.get(item.input._id)) {
        authDeleteData({ variables: {
          itemId: item._id,
          submissionId
        }}).then(res => {
          // console.log(res);
        });
      }
      // let checked = true, old = formFilledCheck();
      // if (old[item.input._id] === false) {
      //   old[item.input._id] = true;
      //   formFilledCheck(old);
      //   for (const property in old) {
      //     if (!old[property]) {
      //       checked = false;
      //     }
      //   }
      //   formFilledOK(checked);
      //   console.log(old);
      //   console.log(checked);
      // }
    }
  }, [item, editMode, dataMapVar, submissionId, authDeleteData]);

  //----------------------------------------------------------------------------
  // component rendering
  //----------------------------------------------------------------------------
  if (hideItem) {
    return null;
  }
  return (
    <div id={item._id} className={classes.row} ref={ref}>
      {mode !== 'page' &&
        <Stack direction="row" alignItems="center" spacing={2}>
          <FunctionBtn item={item} 
            index={index} 
            count={count} 
            editMode={editMode} />
          <TagsShowcase objId={item._id} tags={item.tags} />
        </Stack>
      }
      <ItemContent item={item} 
        setFocus={setFocus} 
        editMode={editMode}
        pageMode={mode === 'page'}
        useProfileValue={useProfileValue} />
      {(isFocus || focus) &&
        <div className={classes.focusBar} />
      }
    </div>
  );
}

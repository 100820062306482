import React from 'react';
import { useMutation } from "@apollo/client";
import { CREATE_SPACE } from '../../constants/gqls/spaces';
import SpaceInfoInputs from '../Space/SpaceInfoInputs';

//******************************************************************************
// component definition
//******************************************************************************
export default function SpaceAdd({saveThen}) {
  const [createSpace, { loading }] = useMutation(CREATE_SPACE);

  //----------------------------------------------------------------------------
  // component rendering
  //----------------------------------------------------------------------------
  return (
    <SpaceInfoInputs
      createSpace={createSpace}
      saveThen={saveThen}
      loading={loading}
    />
  )
}

import React from 'react';
import { generatePath } from "react-router-dom";
import { ListItemButton, ListItemText } from '@mui/material';
// import { makeStyles } from '@material-ui/core/styles';
import SiteMap from '../../constants/siteMap';

//******************************************************************************
// style definition
//******************************************************************************
// const useStyles = makeStyles((theme) => ({
//
// }));

//******************************************************************************
// component definition
//******************************************************************************
export default function SpaceItem({ space }) {
  // const classes = useStyles();
  // console.log(space);
  const href = generatePath(SiteMap.space, { id: space._id })

  //----------------------------------------------------------------------------
  // component rendering
  //----------------------------------------------------------------------------
  return (
    <ListItemButton component="a" href={href}>
      <ListItemText primary={space.name} />
      {space.myRole}
    </ListItemButton>
  );
}

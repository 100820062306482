import React from 'react';
import InfiniteScroll from 'react-infinite-scroller';
import { List, LinearProgress } from '@mui/material';
import { useQuery, useReactiveVar } from "@apollo/client";
import SpaceItem from './SpaceItem';
import { doRefetch } from '../../constants/cache';
import { MY_SPACES } from '../../constants/gqls/spaces';
const limit = 10;

//******************************************************************************
// component definition
//******************************************************************************
export default function MySpacesList() {
  const needRefetch = useReactiveVar(doRefetch);
  const { loading, data, fetchMore, refetch } = useQuery(MY_SPACES, {
    variables: { limit, offset: 0 }
  });
  // console.log(data);

  React.useEffect(() => {
    if (needRefetch) {
      // console.log('refetch');
      refetch();
      doRefetch(false)
    }
  }, [needRefetch, refetch]);

  //----------------------------------------------------------------------------
  // funtions
  //----------------------------------------------------------------------------
  const loadMore = () => {
    fetchMore({ variables: {
      limit,
      offset: data.mySpaces.length
    }});
  }

  //----------------------------------------------------------------------------
  // component rendering
  //----------------------------------------------------------------------------
  if (loading) return <LinearProgress />;
  if (!data) return 'Network error.'
  if (data.mySpacesCount === 0) {
    return 'No space yet. You can find one in the search section or create a new one.';
  }

  return (
    <InfiniteScroll
        loadMore={loadMore}
        hasMore={data.mySpacesCount > data.mySpaces.length}
        loader={<LinearProgress key="loading" />}
    >
      <List>
        { data.mySpaces.map(space=>(
          <SpaceItem key={space._id} space={space} />
        )) }
      </List>
    </InfiniteScroll>
  );
}

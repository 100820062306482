import React from 'react';
import { Editor, RichUtils, getDefaultKeyBinding } from 'draft-js';
import { makeStyles } from '@material-ui/core/styles';
import 'draft-js/dist/Draft.css';

function getBlockStyle(block) {
  switch (block.getType()) {
    case 'blockquote': return 'RichEditor-blockquote';
    default: return null;
  }
}

//******************************************************************************
// style definition
//******************************************************************************
const useStyles = makeStyles((theme) => ({
  box: {
    minHeight: (props) => props.readOnly? 'unset' : props.minHeight || 'unset',
    cursor: 'text',
  },
}));

//******************************************************************************
// component definition
//******************************************************************************
export default function MyEditor({editorState, setState, placeholder, minHeight, readOnly}) {
  const classes = useStyles({minHeight, readOnly});
  const ref = React.useRef(null);

  //----------------------------------------------------------------------------
  // funtions
  //----------------------------------------------------------------------------
  const handleKeyCommand = (command, eState) => {
    const newState = RichUtils.handleKeyCommand(eState, command);

    if (newState) {
      setState(newState);
      return 'handled';
    }
    return 'not-handled';
  }

  const mapKey = (e) => {
    if (e.keyCode === 9 /* TAB */) {
      const newEditorState = RichUtils.onTab(e, editorState, 4/* maxDepth */);
      if (newEditorState !== editorState) {
        setState(newEditorState);
      }
      return;
    }
    return getDefaultKeyBinding(e);
  }

  const focus = () => {
    ref.current.focus();
  }

  //----------------------------------------------------------------------------
  // component rendering
  //----------------------------------------------------------------------------
  return (
    <div className={classes.box} onClick={focus}>
      <Editor editorState={editorState} onChange={setState} spellCheck={true}
        ref={ref}
        handleKeyCommand={handleKeyCommand}
        keyBindingFn={mapKey}
        blockStyleFn={getBlockStyle}
        placeholder={placeholder}
        readOnly={readOnly} />
    </div>
  );
}

import React from 'react';
import { Grid, FormControl, InputLabel, Select, MenuItem, Button } from '@mui/material';
import { makeStyles } from '@material-ui/core/styles';
import { useMutation } from "@apollo/client";
import ActionPromptText from './ActionPromptText';
import ActionVariables from './ActionVariables';
import ActionETBtn from './ActionETBtn';
import { UPDATE_ACTION } from '../../constants/gqls/actions';
import TriggerEmailBtn from './TriggerEmailBtn';

function getFireOptions(scope) {
  if (scope==='form') {
    return [{
      name: 'Submit',
      value: 'onSubmit'
    }, {
      name: 'User Trigger',
      value: 'onUserTrigger'
    }];
  } else if (scope==='service') {
    return [{
      name: 'Session create',
      value: 'onSessionCreate'
    }, {
      name: 'Session confirm',
      value: 'onSessionConfirm'
    }, {
      name: 'Session close',
      value: 'onSessionClose'
    }, {
      name: 'Session complete',
      value: 'onSessionComplete'
    }, {
      name: 'Attendance submit',
      value: 'onAttendanceSubmit'
    }];
  } else if (scope==='certType') {
    return [{
      name: 'Submit',
      value: 'onSubmit'
    }, {
      name: 'Issued',
      value: 'onIssued'
    }];
  } 
}

//******************************************************************************
// style definition
//******************************************************************************
const useStyles = makeStyles((theme) => ({
  root: {
    padding: `0 ${theme.spacing(3)}px ${theme.spacing(1)}px`
  },
  actions: {
    display: 'flex',
    "& .MuiButton-root": {
      margin: 'auto'
    }
  },
}));

//******************************************************************************
// component definition
//******************************************************************************
export default function ActionItemDetails({action, scope}) {
  const classes = useStyles();
  const [updateAction, { loading }] = useMutation(UPDATE_ACTION);
  const fireOptions = getFireOptions(scope);
  // console.log(action);

  //----------------------------------------------------------------------------
  // funtions
  //----------------------------------------------------------------------------
  const handleUpdate = (update) => {
    return updateAction({ variables: {
      id: action._id,
      update
    }});
  }

  const handleTypeChange = (event) => {
    handleUpdate({ type: event.target.value });
  };

  const handleFireChange = (event) => {
    handleUpdate({ fire: event.target.value });
  };

  const handleActiveChange = () => {
    handleUpdate({ active: !action.active });
  };

  //----------------------------------------------------------------------------
  // component rendering
  //----------------------------------------------------------------------------
  return (
    <Grid container spacing={2} className={classes.root}>
      <Grid item xs={5}>
        <FormControl fullWidth disabled={loading}>
          <InputLabel>Action type</InputLabel>
          <Select id="action-type" label="Action type" size="small"
            value={action.type} onChange={handleTypeChange}
          >
            <MenuItem value="prompt">Prompt</MenuItem>
            <MenuItem value="email">Email</MenuItem>
          </Select>
        </FormControl>
      </Grid>
      <Grid item xs={7}>
        <FormControl fullWidth disabled={loading}>
          <InputLabel>Action fire when</InputLabel>
          <Select id="action-type" label="Action fire when" size="small"
            value={action.fire} onChange={handleFireChange}
          >
            { fireOptions.map(opt=>(
              <MenuItem key={opt.value} value={opt.value}>{opt.name}</MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>
      { action.type === 'prompt' &&
        <Grid item xs={12}>
          <ActionPromptText promptTemplate={action.promptTemplate}
            handleUpdate={handleUpdate} loading={loading} />
        </Grid>
      }
      { action.type === 'email' &&
        <Grid item xs={12}>
          <ActionVariables scope={scope} loading={loading}
            action={action} handleUpdate={handleUpdate} />
        </Grid>
      }
      <Grid item xs={12} className={classes.actions}>
        { action.type === 'email' &&
          <ActionETBtn templateId={action.emailTemplateId} />
        }
        { action.type === 'email' && action.fire === 'onUserTrigger' ?
          <TriggerEmailBtn action={action} />
          :
          <Button variant="outlined" onClick={handleActiveChange}>
            {action.active? 'Disable Action' : 'Enable Action'}
          </Button>
        }
      </Grid>
    </Grid>
  );
}

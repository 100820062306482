import React from 'react';
import { Grid } from '@mui/material';
import { useQuery } from "@apollo/client";
import FormSetting from './FormSetting';
import FormExport from './FormExport';
import FormActions from '../Actions/FormActions';
import FormInfo from './FormInfo';
import FormSubmissions from './FormSubmissions';
import FormMyData from './FormMyData';
import { DOCUMENT_ISFORM } from '../../constants/gqls/documents';
import { ME } from '../../constants/gqls/user';

function allowActions(inData) {
  let roles = inData?.me?.roles;
  if (roles && (roles.includes('admin') || roles.includes('staff'))) {
    return true;
  }
  return false;
}

//******************************************************************************
// component definition
//******************************************************************************
export default function FormArea({doc, mode}) {
  useQuery(DOCUMENT_ISFORM, { variables: {
    docId: doc._id
  }});
  const { data } = useQuery(ME);

  //----------------------------------------------------------------------------
  // funtions
  //----------------------------------------------------------------------------

  //----------------------------------------------------------------------------
  // component rendering
  //----------------------------------------------------------------------------
  if (!doc.isForm) return '';

  return (
    <Grid item xs={12} lg>
      { doc.editable &&
        <FormSetting doc={doc} mode={mode} isAdmin={data.me.roles.includes('admin')} />
      }
      { doc.editable && allowActions(data) &&
        <FormActions docId={doc._id} />
      }
      <FormInfo doc={doc} />
      { (doc.isProfileForm || doc.isReportForm) && mode === 'view' &&
        <FormExport docId={doc._id} myRole={doc.myRole} />
      }
      { doc.editable && 
        <FormSubmissions doc={doc} />
      }
      { !(doc.isProfileForm || doc.isReportForm) &&
        <FormMyData doc={doc} />
      }
    </Grid>
  );
}

import { gql } from '@apollo/client';


export const COURSES = gql`
  query Courses($options: CourseOptions) {
    courses(options: $options) {
      _id name code
    }
    coursesCount
  }
`;

export const COURSE = gql`
  query Courses($where: CourseWhere) {
    courses(where: $where) {
      _id code name section level nature description
    }
  }
`;

export const CREATE_COURSE = gql`
  mutation CreateCourses($input: [CourseCreateInput!]!) {
    createCourses(input: $input) {
      courses {
        _id
      }
    }
  }
`;

export const UPDATE_COURSE = gql`
  mutation UpdateCourses($where: CourseWhere, $update: CourseUpdateInput) {
    updateCourses(where: $where, update: $update) {
      courses {
        _id name code section level nature description
      }
    }
  }
`;

export const DELETE_COURSE = gql`
  mutation DeleteCourses($where: CourseWhere) {
    deleteCourses(where: $where) {
      nodesDeleted
    }
  }
`;

export const REQUEST_COURSES_IMPORT = gql`
  mutation RequestCoursesImport {
    requestCoursesImport
  }
`;

export const GET_CONST_OPTIONS = gql`
  query GetConstOptions($label: ConstLabel!, $attribute: ConstAtt!) {
    getConstOptions(label: $label, attribute: $attribute)
  }
`;
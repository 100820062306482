import React from 'react';
import InfiniteScroll from 'react-infinite-scroller';
import { LinearProgress, Container } from '@mui/material';
import Masonry from '@mui/lab/Masonry';
import { makeStyles } from '@material-ui/core/styles';
import { useQuery } from "@apollo/client";
import SpaceCard from './SpaceCard';
import PersonCard from './PersonCard';
import DocumentCard from './DocumentCard';
import TextCard from './TextCard';
import LinkCard from './LinkCard';
import InputCard from './InputCard';
import DataCard from './DataCard';
import { TAGGED_OBJECTS } from '../../constants/gqls/tags';
const limit = 10;

//******************************************************************************
// style definition
//******************************************************************************
const useStyles = makeStyles((theme) => ({
  container: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(3)
  },
}));

//******************************************************************************
// component definition
//******************************************************************************
export default function TagsFilterContent({tags}) {
  const classes = useStyles();
  const [signal, setSignal] = React.useState('');
  const { loading, data, fetchMore } = useQuery(TAGGED_OBJECTS, { variables: {
    // where: { _id_IN: tags }, tags, limit, offset: 0
    tags, limit, offset: 0
  }});
  // console.log(data);

  //----------------------------------------------------------------------------
  // funtions
  //----------------------------------------------------------------------------
  const loadMore = () => {
    fetchMore({ variables: {
      limit,
      offset: data.taggedObjects.length
    }});
  }

  //----------------------------------------------------------------------------
  // component rendering
  //----------------------------------------------------------------------------
  if (loading) return <LinearProgress />;

  return (
      <Container maxWidth="xl" className={classes.container}>
        <InfiniteScroll
          loadMore={loadMore}
          hasMore={data.taggedObjectsCount > data.taggedObjects.length}
          loader={<LinearProgress key="loading" />}
        >
          <Masonry columns={{sm: 1, md: 2, lg: 3, xl: 4}} spacing={2} signal={signal}>
            { data.taggedObjects.map(item=>{
              switch (item.nodeType) {
                case 'Document':
                  return (<DocumentCard key={item._id} doc={item} setSignal={setSignal} />);
                case 'Item':
                  switch (item.type) {
                    case 'text':
                      return (<TextCard key={item._id} item={item} setSignal={setSignal} />);
                    case 'link':
                      return (<LinkCard key={item._id} item={item} setSignal={setSignal} />);
                    case 'input':
                      return (<InputCard key={item._id} item={item} setSignal={setSignal} />);
                    default:
                      return (<div>{item.type}</div>);
                  }
                case 'Space':
                  return (<SpaceCard key={item._id} space={item} setSignal={setSignal} />);
                case 'Person':
                  return (<PersonCard key={item._id} person={item} setSignal={setSignal} />);
                case 'Data':
                  return (<DataCard key={item._id} data={item} setSignal={setSignal} />);
                default:
                  return (<div>{item.nodeType}</div>);
              }
            }) }
          </Masonry>
        </InfiniteScroll>
      </Container>
  );
}

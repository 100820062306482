import React from 'react';
import { IconButton, Tooltip } from '@mui/material';
import PersonIcon from '@mui/icons-material/Person';
import OwnershipDialog from '../DocumentShare/OwnershipDialog';

//******************************************************************************
// component definition
//******************************************************************************
export default function OwnershipBtn({doc}) {
  const [open, setOpen] = React.useState(false);

  //----------------------------------------------------------------------------
  // funtions
  //----------------------------------------------------------------------------
  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  //----------------------------------------------------------------------------
  // component rendering
  //----------------------------------------------------------------------------
  return (
    <React.Fragment>
      <Tooltip title="Document ownership">
        <IconButton onClick={handleOpen}>
          <PersonIcon />
        </IconButton>
      </Tooltip>
      <OwnershipDialog doc={doc} open={open} handleClose={handleClose} />
    </React.Fragment>
  )
}

import React from 'react';
// import { Button } from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import { useMutation } from "@apollo/client";
import { SPACE_MEMBERS, SPACE_CLEAR_MEMBERS } from '../../constants/gqls/spaces';


//******************************************************************************
// component definition
//******************************************************************************
export default function ClearMembersBtn({spaceId}) {
  const [clearMembers, { loading }] = useMutation(SPACE_CLEAR_MEMBERS, {
    refetchQueries: [ SPACE_MEMBERS ],
  });

  //----------------------------------------------------------------------------
  // funtions
  //----------------------------------------------------------------------------
  const handleClick = () => {
    clearMembers({ variables: { spaceId }})
  };

  //----------------------------------------------------------------------------
  // component rendering
  //----------------------------------------------------------------------------
  return (
    <LoadingButton variant="outlined" onClick={handleClick}
      loadingIndicator="Clearing …" loading={loading}>
      Clear members
    </LoadingButton>
  );
}

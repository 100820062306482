import React from 'react';
import { ListItemButton, ListItemAvatar, Avatar, Collapse } from '@mui/material';
import LocalOfferIcon from '@mui/icons-material/LocalOffer';
import { makeStyles } from '@material-ui/core/styles';
import TagTypeItemDetails from './TagTypeItemDetails';

//******************************************************************************
// style definition
//******************************************************************************
const useStyles = makeStyles((theme) => ({
  avatar: {
    "&.MuiAvatar-root": {
      color: props=>props.textColor,
      backgroundColor: props=>props.bgColor
    }
  },
  title: {
    fontWeight: 'bold',
  }
}));

//******************************************************************************
// component definition
//******************************************************************************
function TagTypeItem({ tagType }) {
  const classes = useStyles(tagType);
  const [open, setOpen] = React.useState(false);

  //----------------------------------------------------------------------------
  // component rendering
  //----------------------------------------------------------------------------
  const handleClick = () => {
    setOpen(!open);
  };

  //----------------------------------------------------------------------------
  // funtions
  //----------------------------------------------------------------------------
  return (
    <React.Fragment>
      <ListItemButton onClick={handleClick}>
        <ListItemAvatar>
          <Avatar className={classes.avatar}>
            <LocalOfferIcon />
          </Avatar>
        </ListItemAvatar>
        <p className={classes.title}>
          {tagType.name}
        </p>
      </ListItemButton>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <TagTypeItemDetails id={tagType._id} />
      </Collapse>
    </React.Fragment>
  );
}

export default TagTypeItem;

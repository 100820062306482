import React from 'react';
import { useQuery } from "@apollo/client";
import './App.css';
import IndexRoute from './routers/index_router';
import AdminNotic from './AdminNotic';
import { ME } from './constants/gqls/user';

function App() {
  const { data } = useQuery(ME);

  //----------------------------------------------------------------------------
  // component rendering
  //----------------------------------------------------------------------------
  return (
    <>
      <IndexRoute />
      { data?.me?.roles.includes('admin') && 
        <AdminNotic />
      }
    </>
  );
}

export default App;

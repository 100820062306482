import React from 'react';
import axios from 'axios';
import { Grid } from '@mui/material';
import { useMutation, useSubscription } from '@apollo/client';
import UploadBtn from '../InputsCommt/UploadBtn';
import { UPLOAD_FILE, SUB_UPLOAD_NOTICE } from '../../constants/gqls/files';

const maxFileSize = 5*1024*1024; // 5MB

const labels = {
  certTemplate: 'Upload Template',
  formTemplate: 'Upload Template',
  dataFile: 'Upload Data File',
  formUpload: 'Upload File'
};


//******************************************************************************
// component definition
//******************************************************************************
export default function FileUpload({ hasFile, systemFileType, relatedId, fileTypes, refetch, children, disabled }) {
  const [loading, setLoading] = React.useState(false);
  // const [uploaded, setUploaded] = React.useState(false);
  const [requestUpload] = useMutation(UPLOAD_FILE);

  useSubscription(SUB_UPLOAD_NOTICE, {
    // variables: { relatedId },
    onSubscriptionData: ({subscriptionData}) => {
      // console.log(subscriptionData.data);
      let notice = subscriptionData.data.uploadNotice;
      console.log(notice);
      if (relatedId === notice.relatedId) {
        setLoading(false);
        if (!notice.error) {
          refetch();
        }
      }
    }
  });

  //----------------------------------------------------------------------------
  // funtions
  //----------------------------------------------------------------------------
  const handleUpload = (event) => {
    let theFile = event.target.files[0];

    if (theFile.size < maxFileSize) {
      console.log(`file size: ${theFile.size}`);
      return;
    }

    if (fileTypes?.includes(theFile.type) || !fileTypes) {
      setLoading(true);
      requestUpload({ variables: {systemFileType, relatedId} }).then(res=>{
        let token = res.data.uploadFile;
        console.log(token);

        // Create an object of formData
        const formData = new FormData();

        // Update the formData object
        formData.append(
          "theFile",
          theFile,
          theFile.name
        );

        // Details of the uploaded file
        // console.log(theFile);

        // Request made to the backend api
        // Send formData object
        axios.post(process.env.REACT_APP_UPLOAD_URI + token, formData).then((res)=>{
          // setLoading(false);
        }).catch((error) => {
          console.log(error.response);
          setLoading(false);
        });
      });
    }
  }


  //----------------------------------------------------------------------------
  // component rendering
  //----------------------------------------------------------------------------
  return (
    <Grid container spacing={2} alignItems="center">
      <Grid item>
        { hasFile? children : <p>none</p> }
      </Grid>
      <Grid item>
        <UploadBtn label={labels[systemFileType]} 
          loading={loading}
          onChange={handleUpload}
          accept={fileTypes?.join(',')}
          disabled={disabled} />
      </Grid>
    </Grid>
    
  );
}

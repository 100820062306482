import React from 'react';
import { Button, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions } from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import DeleteIcon from '@mui/icons-material/Delete';
import { useMutation } from "@apollo/client";
import { DELETE_TAG_TYPE, TAG_TYPES } from '../../constants/gqls/tags';

//******************************************************************************
// component definition
//******************************************************************************
export default function TagTypeDelete({tagType}) {
  const [open, setOpen] = React.useState(false);
  const [deleteTagType, { loading }] = useMutation(DELETE_TAG_TYPE, {
    refetchQueries: [ TAG_TYPES ],
  });

  //----------------------------------------------------------------------------
  // funtions
  //----------------------------------------------------------------------------
  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const deleteItem = () => {
    deleteTagType({ variables: {
      where: { _id: tagType._id }
    }}).then(res => {
      // console.log(res);
      handleClose();
    });
  }

  //----------------------------------------------------------------------------
  // component rendering
  //----------------------------------------------------------------------------
  return (
    <React.Fragment>
      <Button variant="outlined" startIcon={<DeleteIcon />} onClick={handleOpen}>
        Delete
      </Button>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>
          Confirm Delete?
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            Click delete button to delete tag type: {tagType.name}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <LoadingButton variant="outlined"
            loading={loading} onClick={deleteItem}>
            Delete
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}

import React from 'react';
import { Grid, Checkbox, FormControl } from '@mui/material';
import { makeStyles } from '@material-ui/core/styles';

//******************************************************************************
// style definition
//******************************************************************************
const useStyles = makeStyles((theme) => ({
  row: {
    '@media only screen and (max-width: 500px)': {
      '& .MuiGrid-root.MuiGrid-item.MuiGrid-grid-xs-true': {
        paddingLeft: 0
      }
    }
  },
}));

//******************************************************************************
// component definition
//******************************************************************************
/**
 * @typedef {Object} OptionCheckboxProps
 * @property {string} day - The day string.
 * @property {string} hour - The hour string.
 * @property {function} handleChange - Handle value change.
 * @property {object} data - The input data object.
 */

/**
 * option checkbox component.
 * @param {OptionCheckboxProps} props - propertis
 */
function OptionCheckbox({day, hour, handleChange, data}) {
  const [checked, setChecked] = React.useState(false);
  const value = day+' => '+hour;
  // console.log(`values: ${data?.values}, value: ${value}, checked: ${checked}`);

  React.useEffect(() => {
    setChecked(data?.values?.includes(value) || false);
  }, [data, value]);

  return (
    <Grid item xs>
      <Checkbox checked={checked} 
        onChange={handleChange} 
        name={value} />
    </Grid>
  );
}

/**
 * @typedef {Object} WeeklyHourMultiplePorps
 * @property {boolean} disabled - is input disabled?
 * @property {object} data - The input data object.
 * @property {function} updateData - The data update function.
 * @property {[string]} weeklyDayOptions - Day options.
 * @property {[string]} weeklyHourOptions - Hour options.
 */

/**
 * weekly hour mulitple input component.
 * @param {WeeklyHourMultiplePorps} props - propertis
 */
export default function WeeklyHourMultiple({disabled, data, updateData, weeklyDayOptions, weeklyHourOptions}) {
  const classes = useStyles();
  // console.log(data);
  // console.log(weeklyDayOptions);

  //----------------------------------------------------------------------------
  // funtions
  //----------------------------------------------------------------------------
  const handleChange = (event) => {
    // console.log(event.target.name);
    let values = data? [...data.values] : [];
    if (event.target.checked) {
      values.push(event.target.name);
    } else {
      values = values.filter(item=>item!==event.target.name);
    }
    updateData({
      values
    }).then(res => {
      // console.log(res);
    });
  };

  //----------------------------------------------------------------------------
  // component rendering
  //----------------------------------------------------------------------------
  return (
    <div>
    <div>
      <Grid container spacing={2}>
        <Grid item xs={2} />
        { weeklyDayOptions.map(day=>
          <Grid item xs key={day} sx={{ overflow: 'hidden'}}>
            {day}
          </Grid>
        )}
      </Grid>
      <FormControl sx={{ width: '100%'}} disabled={disabled}>
      { weeklyHourOptions.map(hour=>(
        <Grid container spacing={2} alignItems="center" key={hour} className={classes.row}>
          <Grid item xs={2}>
            {hour}
          </Grid>
          { weeklyDayOptions.map(day=>
            <OptionCheckbox key={day}
              day={day} hour={hour}
              handleChange={handleChange}
              data={data} />
          )}
        </Grid>
      ))}
      </FormControl>
    </div>
    </div>
  )
}

import React from 'react';
import { Button, Dialog, DialogTitle, DialogContent, DialogActions } from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import AddIcon from '@mui/icons-material/Add';
import SaveIcon from '@mui/icons-material/Save';
import { makeStyles } from '@material-ui/core/styles';
import { useMutation } from "@apollo/client";
import PageNameInput from './PageNameInput';
import PeopleInput from '../InputsCommt/PeopleInput';
import { CREATE_PAGES, PAGES } from '../../constants/gqls/pages';

//******************************************************************************
// style definition
//******************************************************************************
const useStyles = makeStyles((theme) => ({
  dialog: {
    "& .MuiPaper-root": {
      minWidth: '480px'
    }
  }
}));

//******************************************************************************
// component definition
//******************************************************************************
export default function PageAdd() {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [name, setName] = React.useState('');
  const [nameCheck, setNameCheck] = React.useState(false);
  const [managers, setManagers] = React.useState([]);
  const [fieldCheck, setFieldCheck] = React.useState(false);
  const [createPages, { loading }] = useMutation(CREATE_PAGES, {
    refetchQueries: [ PAGES ],
  });

  React.useEffect(() => {
    if (setFieldCheck) {
      if (name.trim !== '' && nameCheck && managers !== null) {
        setFieldCheck(true);
      } else {
        setFieldCheck(false);
      }
    }
  }, [ setFieldCheck, name, managers, nameCheck ]);

  //----------------------------------------------------------------------------
  // funtions
  //----------------------------------------------------------------------------
  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

const handleCreate = () => {
  let mgrsIds = managers.map(mgr=>mgr._id);
  createPages({ variables: {
    input:[{
      name,
      managers: { connect: [{ where: { node: { _id_IN: mgrsIds }}}]}
    }]
  }}).then(res=>{
    setName('');
    setManagers([]);
    setOpen(false);
    // console.log(res);
  })
}

  //----------------------------------------------------------------------------
  // component rendering
  //----------------------------------------------------------------------------
  return (
    <React.Fragment>
      <Button variant="contained" color="secondary" startIcon={<AddIcon />}
        onClick={handleOpen}>
        New Page
      </Button>
      <Dialog open={open} onClose={handleClose} className={classes.dialog}>
        <DialogTitle>New Page</DialogTitle>
        <DialogContent>
          <PageNameInput 
            name={name} setName={setName}
            nameCheck={nameCheck} setNameCheck={setNameCheck}
            disabled={loading} />
          <PeopleInput label="Manager" multiple={true}
            value={managers} onChange={setManagers}
            disabled={loading} />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <LoadingButton variant="contained" startIcon={<SaveIcon />}
            loading={ loading } disabled={!fieldCheck}
            loadingPosition="start"
            onClick={handleCreate}>
            Save
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}

import React from 'react';
import { contrastColor } from 'contrast-color';
import { Button, TextField, Stack,
  Dialog, DialogTitle, DialogContent, DialogActions } from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import SaveIcon from '@mui/icons-material/Save';
import { makeStyles } from '@material-ui/core/styles';
import { useQuery, useMutation } from "@apollo/client";
import ColorPicker from '../InputsCommt/ColorPicker';
import Tag from '../Tag';
import { ME } from '../../constants/gqls/user';
import { CREATE_TAG_TYPE, UPDATE_TAG_TYPE, TAG_TYPES } from '../../constants/gqls/tags';

// const rgbaToString = (rgba) => {
//   if (typeof rgba === 'string') {
//     return rgba;
//   }
//   return `rgba(${rgba.r}, ${rgba.g}, ${rgba.b}, ${rgba.a})`
// }

//******************************************************************************
// style definition
//******************************************************************************
const useStyles = makeStyles((theme) => ({
  stack: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(3),
  },
  btn: {
    width: '180px'
  }
}));

//******************************************************************************
// component definition
//******************************************************************************
function TagTypeForm({open, onClose, tagType}) {
  const classes = useStyles();
  const [name, setName] = React.useState(tagType ? tagType.name : '');
  const [description, setDescription] = React.useState(tagType ? tagType.description : '');
  const [anchorElBg, setAnchorElBg] = React.useState(null);
  const [anchorElText, setAnchorElText] = React.useState(null);
  const [bgColor, setBgColor] = React.useState(tagType ? tagType.bgColor : '#FFF');
  const [textColor, setTextColor] = React.useState(tagType ? tagType.textColor : '#000');
  const bgPickerOpen = Boolean(anchorElBg);
  const textPickerOpen = Boolean(anchorElText);
  const { data } = useQuery(ME);
  const [createTagType, { loading:createLoading }] = useMutation(CREATE_TAG_TYPE, {
    refetchQueries: [ TAG_TYPES ],
  });
  const [updateTagType, { loading:updateLoading }] = useMutation(UPDATE_TAG_TYPE);
  // console.log(textColor);

  //----------------------------------------------------------------------------
  // funtions
  //----------------------------------------------------------------------------
  const onNameChange = (event) => {
    setName(event.target.value);
  }

  const onDecriptionChange = (event) => {
    setDescription(event.target.value);
  }

  const openBgPicker = (event) => {
    setAnchorElBg(event.currentTarget);
  };

  const closeBgPicker = () => {
    setAnchorElBg(null);
  };

  const openTextPicker = (event) => {
    setAnchorElText(event.currentTarget);
  };

  const closeTextPicker = () => {
    setAnchorElText(null);
  };

  const bgColorChang = (inColor) => {
    setBgColor(inColor);
    setTextColor(contrastColor({ bgColor: inColor.hex }));
  }

  const textColorChang = (inColor) => {
    setTextColor(inColor);
  }

  const save = () => {
    if (tagType) {
      updateTagType({ variables: {
        where: { _id: tagType._id },
        update: { name, description,
          bgColor: bgColor.toString(),
          textColor: textColor.toString(),
        }
      }}).then(res => {
        // console.log(res);
        onClose();
      });
    } else {
      createTagType({ variables: {
        input: [{ name, description,
          bgColor: bgColor.toString(),
          textColor: textColor.toString(),
          createdBy: {
            connect: {
              where: {
                node: {
                  _id: data.me._id
                }
              }
            }
          }
        }]
      }}).then(res => {
        // console.log(res);
        onClose();
      });
    }
  }

  //----------------------------------------------------------------------------
  // component rendering
  //----------------------------------------------------------------------------
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>{tagType? 'Edit':'New'} tag type</DialogTitle>
      <DialogContent>
        <TextField id="tag-type-name" fullWidth margin="dense"
          variant="standard" label="Name"
          value={name} onChange={onNameChange} />
        <TextField id="tag-type-description" multiline fullWidth margin="dense"
          variant="standard" rows={3} label="Description"
          value={description} onChange={onDecriptionChange}/>
        <Stack direction="row" justifyContent="space-between" className={classes.stack}>
          <Button variant="outlined" color="inherit"
            className={classes.btn} onClick={openBgPicker}>
            Background Color
          </Button>
          <Button variant="outlined" color="inherit"
            className={classes.btn} onClick={openTextPicker}>
            Text Color
          </Button>
        </Stack>
        <Stack direction="row" alignItems="center" justifyContent="center">
          <Tag text="Sample Tag" type={{
            bgColor: bgColor.toString(),
            textColor: textColor.toString()
          }} />
        </Stack>
        <ColorPicker open={bgPickerOpen} anchorEl={anchorElBg}
          handleClose={closeBgPicker}
          color={bgColor} colorChange={bgColorChang} />
        <ColorPicker alpha={true} open={textPickerOpen} anchorEl={anchorElText}
          handleClose={closeTextPicker}
          color={textColor} colorChange={textColorChang} />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <LoadingButton variant="contained" startIcon={<SaveIcon />}
          loading={createLoading || updateLoading} loadingPosition="start"
          onClick={save}>
          Save
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
}

export default TagTypeForm;

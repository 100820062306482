import React from 'react';
import { IconButton, Button, Tooltip, Dialog, DialogTitle,
  DialogContent, DialogContentText, DialogActions } from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import DeleteIcon from '@mui/icons-material/Delete';
import { useMutation } from "@apollo/client";
import { DELETE_FILE } from '../../constants/gqls/files';

//******************************************************************************
// component definition
//******************************************************************************
export default function FileDeleteBtn({fileId, refetch}) {
  const [open, setOpen] = React.useState(false);
  const [deleteFile, { loading }] = useMutation(DELETE_FILE);

  //----------------------------------------------------------------------------
  // funtions
  //----------------------------------------------------------------------------
  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const onDeleteClick = () => {
    deleteFile({ variables: {
      fileId
    }}).then(res=>{
      // console.log(res);
      refetch();
      setOpen(false);
    });
  }

  //----------------------------------------------------------------------------
  // component rendering
  //----------------------------------------------------------------------------
  return (
    <React.Fragment>
      <Tooltip title="Delete file">
        <span>
          <IconButton onClick={handleOpen} disabled={!fileId}>
            <DeleteIcon />
          </IconButton>
        </span>
      </Tooltip>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>
          Confirm Delete?
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            Click "Delete" to remove this file.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <LoadingButton variant="outlined"
            loading={loading} onClick={onDeleteClick}>
            Delete
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  )
}

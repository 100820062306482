import React from 'react';
// import { LinearProgress } from '@mui/material';
// import TextFieldsIcon from '@mui/icons-material/TextFields';
// import { useQuery } from "@apollo/client";
import CardComm from './CardComm';
import LinkIcon from '../Links/Icon';
import LinkContent from '../Links/LinkContent';
// import { ITEM_PREV } from '../../constants/gqls/documents';

//******************************************************************************
// component definition
//******************************************************************************
export default function LinkCard({item, setSignal}) {
  // const { loading, data } = useQuery(ITEM_PREV, { variables: {
  //   where: { _id: itemId },
  // }});
  // console.log(data);

  //----------------------------------------------------------------------------
  // component rendering
  //----------------------------------------------------------------------------
  // if (loading) return <LinearProgress />;
  // if (!data) return 'Network error.'

  return (
    <CardComm tags={item.tags} icon={
        <LinkIcon service={item.link.service} />
      }>
      <LinkContent link={item.link} setSignal={setSignal} />
    </CardComm>
  );
}

import React from 'react';
import { TextField, Autocomplete } from '@mui/material';
import { useQuery } from "@apollo/client";
import { TAG_TYPES_SEARCH } from '../../constants/gqls/tags';

//******************************************************************************
// component definition
//******************************************************************************
export default function TagTypeInput({value, onChange}) {
  const [inputValue, setInputValue] = React.useState(value? value.name : '');
  const { data } = useQuery(TAG_TYPES_SEARCH, { variables: {
    nameSearch: inputValue
  }});

  //----------------------------------------------------------------------------
  // funtions
  //----------------------------------------------------------------------------
  const onValueChange = (event, newValue) => {
    onChange(newValue);
  }

  const onInputChange = (event, newValue) => {
    setInputValue(newValue);
  }

  //----------------------------------------------------------------------------
  // component rendering
  //----------------------------------------------------------------------------
  return (
    <Autocomplete
      value={value} onChange={onValueChange}
      inputValue={inputValue} onInputChange={onInputChange}
      getOptionLabel={option=>option.name}
      isOptionEqualToValue={(option, value)=>option._id===value._id}
      options={ data ? data.tagTypeSearch : [] }
      renderInput={(params) => <TextField {...params}
        label="Tag Type"
        variant="standard"
      />}
    />
  );
}

import React from 'react';
import { Card, CardContent, List, Typography } from '@mui/material';
import { makeStyles } from '@material-ui/core/styles';
import LogoutBtn from './LogoutBtn';

//******************************************************************************
// style definition
//******************************************************************************
const useStyles = makeStyles((theme) => ({
  menu: {
    position: 'absolute',
    right: props=>props.open? 0 : 'unset',
    left: props=>props.open? 'unset' : '100vw',
    top: theme.mixins.toolbar.minHeight,
  },
  '@media (min-width:0px) and (orientation: landscape)': {
    menu: {
      top: theme.mixins.toolbar["@media (min-width:0px) and (orientation: landscape)"].minHeight
    }
  },
  '@media (min-width:600px)': {
    menu: {
      top: theme.mixins.toolbar['@media (min-width:600px)'].minHeight
    }
  },
  roles: {
    textAlign: 'right',
    color: theme.palette.text.secondary
  }
}));

//******************************************************************************
// component definition
//******************************************************************************
export default function UserMenu(props) {
  const classes = useStyles(props);

  //----------------------------------------------------------------------------
  // component rendering
  //----------------------------------------------------------------------------
  return (
    <div className={classes.menu}>
      <Card>
        <CardContent>
          <Typography>
            {props.me.name}
          </Typography>
          <Typography className={classes.roles}>
            {props.me.roles.toString()}
          </Typography>
        </CardContent>
        <List>
          <LogoutBtn />
        </List>
      </Card>
    </div>
  );
}

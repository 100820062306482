import React from 'react';
import { useLazyQuery } from "@apollo/client";
import { Autocomplete, TextField } from '@mui/material';
import { GET_CONST_OPTIONS } from "../../../constants/gqls/courses"


//******************************************************************************
// component definition
//******************************************************************************
export default function ValueFieldAutoComplete({valueField, disabled, update, inputRef, value, setText, timer}) {
  const [freeSolo, setFreeSolo] = React.useState(false);
  const [options, setSetOptions] = React.useState(valueField.options || []);
  const [getConstOptions] = useLazyQuery(GET_CONST_OPTIONS, {
    onCompleted: (data) => {
      console.log(data);
      setSetOptions(data.getConstOptions);
    }
  });

  React.useEffect(() => {
    setSetOptions(valueField.options || []);
    setFreeSolo(false);
    let [, label, attribute] = valueField.inputType.split('_');
    if (label === 'freeSolo') {
      setFreeSolo(true);
    } else {
      getConstOptions({ variables: { label, attribute } });
    }
  }, [valueField]);

  //----------------------------------------------------------------------------
  // funtions
  //----------------------------------------------------------------------------
  const handleChange = (event, newValue) => {
    if (newValue !== value) {
      setText(newValue);
      timer.current = setTimeout(()=>{
        update(valueField._id, newValue).then(res=>{
          inputRef.current.focus();
        });
      }, 1000);
    }
  };

  //----------------------------------------------------------------------------
  // component rendering
  //----------------------------------------------------------------------------
  return (
    <Autocomplete freeSolo={freeSolo} size="small"
      options={options}
      renderInput={(params) => <TextField {...params} inputRef={inputRef} label={valueField.name} />}
      value={value} disabled={disabled}
      onInputChange={handleChange}
    />
  )
}

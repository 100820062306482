import React from 'react';
import LinkIcon from '@mui/icons-material/Link';
import { makeStyles } from '@material-ui/core/styles';

//******************************************************************************
// style definition
//******************************************************************************
const useStyles = makeStyles((theme) => ({
  logo: {
    width: '24px',
    height: '24px',
  }
}));

//******************************************************************************
// component definition
//******************************************************************************
export default function Logo({logo}) {
  const classes = useStyles();
  const [imgErr, setImgErr] = React.useState(false);

  //----------------------------------------------------------------------------
  // funtions
  //----------------------------------------------------------------------------
  const onError = () => {
    setImgErr(true);
  }

  //----------------------------------------------------------------------------
  // component rendering
  //----------------------------------------------------------------------------
  if (imgErr) return <LinkIcon />

  return (
    <img src={logo} alt="link logo" className={classes.logo}
      onError={onError} />
  );
}

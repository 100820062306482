import React from 'react';
import { gql } from '@apollo/client';
import { Button } from '@mui/material';
import { useLazyQuery } from "@apollo/client";

//******************************************************************************
// gql
//******************************************************************************
const CERT_REQ_QUERY = gql`
  query Query($certId: ID!) {
    certificateSendToken(certId: $certId)
  }
`;

//******************************************************************************
// component definition
//******************************************************************************
export default function SendEmailReqBtn({certId}) {
  const [count, setCount] = React.useState(0);
  // const [count, setCount] = React.useState(0);
  // const countDown = React.useRef(0);
  // const count = React.useRef(0);
  // function handleCountDown() {
  //   console.log(count);
  //   setCount(count-1);
  // }

  const [certificateSendToken] = useLazyQuery(CERT_REQ_QUERY, { 
    variables: { certId },
    fetchPolicy: 'network-only',
    // onCompleted: (data) => {
    //   console.log(data);
    //   countDown.current = setInterval(handleCountDown, 1000);
    // }
  });

  React.useEffect(() => {
    console.log(count);
    let timeout = null;
    if (count>0) {
      timeout = setTimeout(()=>{
        setCount(count-1);
      }, 1000);
    } else {
      clearTimeout(timeout);
    }
    return () => {
      clearTimeout(timeout);
    }
  }, [count]);

  //----------------------------------------------------------------------------
  // funtions
  //----------------------------------------------------------------------------
  const handleSend = () => {
    setCount(60);
    certificateSendToken();
    // .then((res)=>{
    //   countDown.current = setInterval(handleCountDown, 1000);
    // });
    // certificateSendToken({ variables: { certId }}).then(res=>{
    //   console.log(res);
    //   countDown.current = setInterval(() => {
    //     setCount(count-1);
    //   }, 1000);
    // });
  }

  // const handleCountDown = () => {
  //   console.log(count);
  //   setCount(count-1);
  // }

  //----------------------------------------------------------------------------
  // component rendering
  //----------------------------------------------------------------------------
  return (
    <>
      <Button onClick={handleSend} disabled={count!==0}>
        {count===0 ? 'Send me email' : `Resend aftert ${count} seconds`}
      </Button>
    </>
  )
}

import React from 'react';
import { useNavigate, generatePath } from "react-router-dom";
import { TextField, CardContent, CardActions } from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import SaveIcon from '@mui/icons-material/Save';
import { makeStyles } from '@material-ui/core/styles';
import { useMutation } from "@apollo/client";
import { CREATE_DOCUMENT, MY_DOCUMENTS } from '../../constants/gqls/documents';
import { SPACE_DOCUMENTS } from '../../constants/gqls/spaces';
import SiteMap from '../../constants/siteMap';

//******************************************************************************
// style definition
//******************************************************************************
const useStyles = makeStyles((theme) => ({
  paper: {
    position: 'relative',
    overflow: 'hidden'
  },
  btn: {
    '&.MuiLoadingButton-root' : {
      margin: 'auto'
    }
  },
  content: {
    '&.MuiCardContent-root' : {
      paddingTop: 0
    }
  }
}));

//******************************************************************************
// component definition
//******************************************************************************
export default function DocAdd({spaceId}) {
  const classes = useStyles();
  const navigate = useNavigate();
  const [title, setTitle] = React.useState('');
  const [description, setDescription] = React.useState('');
  const [createDocument, { loading }] = useMutation(CREATE_DOCUMENT, {
    refetchQueries: [ spaceId? SPACE_DOCUMENTS : MY_DOCUMENTS ],
  });

  //----------------------------------------------------------------------------
  // funtions
  //----------------------------------------------------------------------------
  const onTitleChange = (event) => {
    setTitle(event.target.value);
  }

  const onDecriptionChange = (event) => {
    setDescription(event.target.value);
  }

  const save = () => {
    if (title.trim() === '') {
      return;
    }
    createDocument({ variables: {
      title, description, spaceId
    }}).then(res => {
      // console.log(res);
      setTitle('');
      setDescription('');
      let docPath = generatePath(SiteMap.document, { id: res.data.createDocument.documents[0]._id });
      // console.log(docPath);
      navigate(docPath);
    });
  }

  //----------------------------------------------------------------------------
  // component rendering
  //----------------------------------------------------------------------------
  return (
    <React.Fragment>
      <CardContent className={classes.content}>
        <TextField id="document-title" fullWidth margin="dense"
          variant="standard" label="Title"
          value={title} onChange={onTitleChange} />
        <TextField id="document-description" multiline fullWidth margin="dense"
          variant="standard" rows={3} label="Description"
          value={description} onChange={onDecriptionChange}/>
      </CardContent>
      <CardActions>
        <LoadingButton size="small" variant="contained" startIcon={<SaveIcon />}
          color="secondary" className={classes.btn}
          loading={loading} onClick={save}>
          Start
        </LoadingButton>
      </CardActions>
    </React.Fragment>
  );
}

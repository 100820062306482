import React from 'react';
import { Autocomplete, TextField, DialogContent } from '@mui/material';
import { makeStyles } from '@material-ui/core/styles';
import FilterNumber from './FilterNumber';

const types = ['Text', 'Number', 'Boolean'];

//******************************************************************************
// style definition
//******************************************************************************
const useStyles = makeStyles((theme) => ({
  div: {
    marginTop: theme.spacing(1),
    width: '540px'
  },
}));

//******************************************************************************
// component definition
//******************************************************************************
export default function FilterTab({column, setColumn}) {
  const classes = useStyles();
  const [type, setType] = React.useState(column.filter?.type || null);
  const [matchType, setMatchType] = React.useState(column.filter?.matchType || null);
  const [num1, setNum1] = React.useState(column.filter?.num1 || '');
  const [num2, setNum2] = React.useState(column.filter?.num2 || '');

  React.useEffect(() => {
    setColumn(column.id, {type, matchType, num1, num2});
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [column.id, type, matchType, num1, num2]);

  //----------------------------------------------------------------------------
  // funtions
  //----------------------------------------------------------------------------
  const handleTypeChange = (event, newValue) => {
    setType(newValue);
  };

  //----------------------------------------------------------------------------
  // component rendering
  //----------------------------------------------------------------------------
  return (
    <DialogContent>
      <div className={classes.div}>
        <Autocomplete id="filter-type"
          options={types}
          value={type} onChange={handleTypeChange}
          renderInput={(params)=>
            <TextField {...params} label="Filter type" />
          }
        />
      </div>
      <div className={classes.div}>
        { type==='Number' && 
          <FilterNumber 
            matchType={matchType} setMatchType={setMatchType}
            num1={num1} setNum1={setNum1}
            num2={num2} setNum2={setNum2} />
        }
      </div>
    </DialogContent>
  );
}

import React from 'react';
import clsx from 'clsx';
import { ListItem, ListItemIcon, IconButton, TextField, Tooltip } from '@mui/material';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import HideSourceIcon from '@mui/icons-material/HideSource';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import { makeStyles } from '@material-ui/core/styles';
import OptionQuotaBtn from './OptionQuotaBtn';

//******************************************************************************
// style definition
//******************************************************************************
const useStyles = makeStyles((theme) => ({
  root: {
    '& .MuiListItemIcon-root': {
      minWidth: '32px',
    },
    '& svg' : {
      color: theme.palette.action.disabled,
    },
    height: '56px',
    position: 'relative'
  },
  content: {
    flex: '1 1 auto'
  },
  indicator: {
    position: 'absolute',
    left: '-'+theme.spacing(1)+'px'
  },
  iconPlaceholder: {
    height: '48px',
    width: '48px',
  },
  iconActive: {
    '& svg': {
      color: theme.palette.secondary.main
    }
  },
  hidden: {
    color: theme.palette.action.disabled,
  }
}));

//******************************************************************************
// component definition
//******************************************************************************
export default function InputOptionItem({icon, option, setFocus, sortOptions, updateInput, loading, count}) {
  const classes = useStyles();
  const [optionText, setOptionText] = React.useState(option.node.text);
  const [hover, setHover] = React.useState(false);
  const [textFocus, setTextFocus] = React.useState(false);
  const [changed, setChanged] = React.useState(false);
  const isNotFirst = (option.index !== 0);
  const isNotLast = (option.index + 1 !== count);
  const isNotOnly = (count !== 1)
  // console.log(option.index, count, isNotLast);
  // console.log(option);

  //----------------------------------------------------------------------------
  // funtions
  //----------------------------------------------------------------------------
  const handleChange = (event) => {
    setOptionText(event.target.value);
    setChanged(true);
  }

  const onBlur = () => {
    setFocus(false);
    setTextFocus(false);
    setHover(false);
    setChanged(false)
  }

  const updateText = () => {
    updateInput({ options: [{
      where: { node: { _id: option.node._id }},
      update: { node: { text: optionText}}
    }]}).then(res=>{
      onBlur();
    });
  }

  const updateHide = () => {
    updateInput({ options: [{
      where: { node: { _id: option.node._id }},
      update: { node: { hide: !option.node.hide }}
    }]}).then(res=>{
      onBlur();
    });
  }

  const updateQuota = (quota) => {
    return updateInput({ options: [{
      where: { node: { _id: option.node._id }},
      update: { node: { quota }}
    }]});
  }

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      // TODO: editOption
    }
  }

  const handleBlur = () => {
    if (changed) {
      updateText();
    } else {
      onBlur();
    }
  }

  const handleFocus = () => {
    setFocus(true);
    setTextFocus(true);
  }

  const deleteOption = () => {
    updateInput({ options: [{ delete: [{ where: {
      node: { _id: option.node._id }
    }}]}]}, ()=>{
      sortOptions()
    });
  }

  const moveUp = () => {
    sortOptions(option.node._id, option.index-1)
  }

  const moveDown = () => {
    sortOptions(option.node._id, option.index+1)
  }

  const handleMouseEnter = () => {
    setHover(true);
  }

  const handleMouseLeave = () => {
    if (!textFocus) {
      setHover(false);
    }
  }

  //----------------------------------------------------------------------------
  // component rendering
  //----------------------------------------------------------------------------
  return (
    <ListItem className={classes.root}
      onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}
    >
      <ListItemIcon>
        {icon}
      </ListItemIcon>
      <div className={clsx(classes.content, option.node.hide && classes.hidden)}>
        { hover ?
          <TextField fullWidth size="small" disabled={loading}
            id="mc-item-edit" label="" variant="standard"
            value={optionText} onChange={handleChange}
            onKeyDown={handleKeyDown}
            onBlur={handleBlur} onFocus={handleFocus} />
          : option.node.text
        }
      </div>
      <div className={classes.iconPlaceholder}>
        { hover && isNotFirst &&
          <Tooltip title="Move up"><span>
            <IconButton onClick={moveUp} disabled={loading}>
              <ArrowUpwardIcon />
            </IconButton>
          </span></Tooltip>
        }
      </div>
      <div className={classes.iconPlaceholder}>
        { hover && isNotLast &&
          <Tooltip title="Move down"><span>
            <IconButton onClick={moveDown} disabled={loading}>
              <ArrowDownwardIcon />
            </IconButton>
          </span></Tooltip>
        }
      </div>
      <div className={clsx(classes.iconPlaceholder, option.node.quota && classes.iconActive)}>
        { (hover || option.node.quota) &&
          <OptionQuotaBtn value={option.node.quota} updateQuota={updateQuota} loading={loading} />
        }
      </div>
      <div className={clsx(classes.iconPlaceholder, option.node.hide && classes.iconActive)}>
        { (hover || option.node.hide) &&
          <Tooltip title={option.node.hide ? "Option is hidden" : "Hide option"}><span>
            <IconButton onClick={updateHide} disabled={loading}>
              <HideSourceIcon />
            </IconButton>
          </span></Tooltip>
        }
      </div>
      <div className={classes.iconPlaceholder}>
        { hover && isNotOnly &&
          <Tooltip title="Delete option"><span>
            <IconButton onClick={deleteOption} disabled={loading}>
              <DeleteOutlineIcon />
            </IconButton>
          </span></Tooltip>
        }
      </div>
      { hover &&
        <DragIndicatorIcon className={classes.indicator} />
      }
    </ListItem>
  )
}

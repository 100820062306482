import React from 'react';
import { AppBar, Toolbar, Typography, Link, IconButton } from '@mui/material';
import { makeStyles } from '@material-ui/core/styles';
import MenuIcon from '@mui/icons-material/Menu';
import UserActions from './UserActions';
import SiteMap from '../../constants/siteMap';

//******************************************************************************
// style definition
//******************************************************************************
const useStyles = makeStyles((theme) => ({
  bar: {
    zIndex: '' +(theme.zIndex.drawer + 1)+ ' !important'
  },
  link: {
    height: '40px',
  },
  logo: {
    height: '40px',
    marginRight: theme.spacing(2)
  },
  header: {
    flexGrow: 1,
    '& .MuiLink-root': {
      textDecoration: 'none'
    }
  }
}));

//******************************************************************************
// component definition
//******************************************************************************
function HeaderBar({handleDrawer}) {
  const classes = useStyles();

  //----------------------------------------------------------------------------
  // component rendering
  //----------------------------------------------------------------------------
  return (
    <AppBar position="fixed" className={classes.bar}>
      <Toolbar>
        <IconButton
          color="inherit"
          aria-label="open drawer"
          edge="start"
          onClick={handleDrawer}
          sx={{ mr: 2, display: { xs: 'block',sm: 'none' } }}
        >
          <MenuIcon />
        </IconButton>
        <Link href={SiteMap.home} className={classes.link}>
          <img alt="logo" src="/lc-logo.png" className={classes.logo} />
        </Link>
        <div className={classes.header}>
          <Typography sx={{ display: { xs: 'block', sm: 'none' }}} variant="h6">
            LCMIS
          </Typography>
          <Typography sx={{ display: { xs: 'none', sm: 'block' }}} variant="h6">
            Management Information System 
            {process.env.REACT_APP_NODE_ENV === 'development'? ' (***Testing***)': ''}
          </Typography>
        </div>
        <UserActions />
      </Toolbar>
    </AppBar>
  );
}

export default HeaderBar;

import React from 'react';
import TextFieldsIcon from '@mui/icons-material/TextFields';
import ListIcon from '@mui/icons-material/List';
import HistoryEduIcon from '@mui/icons-material/HistoryEdu';
import DriveFileRenameOutlineIcon from '@mui/icons-material/DriveFileRenameOutline';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import LinkIcon from '../Links/Icon';
import LinkLogo from '../Links/Logo';

//******************************************************************************
// component definition
//******************************************************************************
export default function Icon({type, link, logo}) {
  switch (type) {
    case 'text':
      return <TextFieldsIcon />
    case 'filter':
      return <FilterAltIcon />
    case 'input':
      return <DriveFileRenameOutlineIcon />
    case 'list':
      return <ListIcon />
    case 'document':
      return <HistoryEduIcon />
    case 'link':
      if (logo && link.logo) {
        return <LinkLogo logo={link.logo} />
      }
      return <LinkIcon service={link.service} />
    default:
      return ''
  }
}

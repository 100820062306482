import { gql } from '@apollo/client';


export const DATA_TABLES = gql`
  query DataTables($where: DataTableWhere, $options: DataTableOptions) {
    dataTables(where: $where, options: $options) {
      _id name
    }
    dataTablesCount(where: $where)
  }
`;

export const DATA_TABLE_DETAILS = gql`
  query DataTables($where: DataTableWhere) {
    dataTables(where: $where) {
      _id name reportType whereType optionsType queryFields where options
      dataFile { _id }
      dataTableColumns {
        _id name field code
      }
    }
  }
`;

export const CREATE_DATA_TABLE = gql`
  mutation CreateDataTables($input: [DataTableCreateInput!]!) {
    createDataTables(input: $input) {
      dataTables {
        _id name
      }
    }
  }
`;

export const UPDATE_DATA_TABLE = gql`
  mutation UpdateDataTables($where: DataTableWhere, $update: DataTableUpdateInput) {
    updateDataTables(where: $where, update: $update) {
      dataTables {
        name reportType whereType optionsType queryFields where options
        dataFile { _id path }
        dataTableColumns {
          _id name field
        }
      }
    }
  }
`;

export const DELETE_DATA_TABLE = gql`
  mutation DeleteDataTables($where: DataTableWhere, $delete: DataTableDeleteInput) {
    deleteDataTables(where: $where, delete: $delete) {
      nodesDeleted
    }
  }
`;

export const DELETE_DATA_TABLE_COLUMN = gql`
  mutation DeleteDataTableColumns($where: DataTableColumnWhere) {
    deleteDataTableColumns(where: $where) {
      nodesDeleted
    }
  }
`;

export const UPDATE_DATA_TABLE_COLUMN = gql`
  mutation UpdateDataTableColumns($where: DataTableColumnWhere, $update: DataTableColumnUpdateInput) {
    updateDataTableColumns(where: $where, update: $update) {
      dataTableColumns {
        _id name field code
      }
    }
  }
`;

import React from 'react';
import { useSearchParams } from "react-router-dom";
import { Button } from '@mui/material';
import { makeStyles } from '@material-ui/core/styles';
import { dataMap } from '../../constants/cache';

//******************************************************************************
// style definition
//******************************************************************************
const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    '& button': {
      margin: 'auto'
    }
  }
}));

//******************************************************************************
// component definition
//******************************************************************************
export default function NewInputBtn({doc}) {
  const classes = useStyles();
  const [, setSearchParams] = useSearchParams();

  //----------------------------------------------------------------------------
  // funtions
  //----------------------------------------------------------------------------
  const handleClick = () => {
    setSearchParams({ mode: 'clean' });
    dataMap(null);
  };

  //----------------------------------------------------------------------------
  // component rendering
  //----------------------------------------------------------------------------
  return (
    <div className={classes.root}>
      <Button variant="outlined" onClick={handleClick}>
        New draft
      </Button>
    </div>
  );
}

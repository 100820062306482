import { gql } from '@apollo/client';
// import { tagSum, boardDetails, personSum } from './fragments';

export const UPDATE_CARD = gql`
  mutation UpdateCard($where: CardWhere, $update: CardUpdateInput) {
    updateCards(where: $where, update: $update) {
      cards {
        _id width height
      }
    }
  }
`;

export const DELETE_CARD_FROM_BOARD = gql`
  mutation DeleteCardFromBoard($boardId: ID!, $cardId: ID!) {
    deleteCardFromBoard(boardId: $boardId, cardId: $cardId) {
      boards {
        _id cards { _id index }
      }
    }
  }
`;

export const SORT_CARDS = gql`
  mutation SortCards($boardId: ID!, $cardId: ID, $index: Int) {
    sortCards(boardId: $boardId, cardId: $cardId, index: $index) {
      boards {
        _id cards { _id index }
      }
    }
  }
`;

export const UPDATE_BOARD = gql`
  mutation UpdateBoards($where: BoardWhere, $create: BoardRelationInput) {
    updateBoards(where: $where, create: $create) {
      boards {
        _id cards {
          _id width height type index
        }
      }
    }
  }
`;

import React from 'react';
import { Container, Paper, CardHeader } from '@mui/material';
import { makeStyles } from '@material-ui/core/styles';
import { useQuery } from "@apollo/client";
import CreateBtn from '../components/Certificates/CreateBtn';
import CertTypesList from '../components/Certificates/CertTypesList';
import { ME } from '../constants/gqls/user';

//******************************************************************************
// style definition
//******************************************************************************
const useStyles = makeStyles((theme) => ({
  container: {
    paddingTop: theme.spacing(3)
  },
}));

//******************************************************************************
// component definition
//******************************************************************************
export default function CertTypes() {
  const classes = useStyles();
  const { data } = useQuery(ME);
  const [title, setTitle] = React.useState('');
  const [isAdmin, setIsAdmin] = React.useState(false);
  const [isStudent, setIsStudent] = React.useState(false);

  React.useEffect(() => {
    if (data?.me) {
      let roles = data.me.roles;
      setIsAdmin(roles.includes('admin'));
      if (roles.includes('student')) {
        setTitle("Request for certificates");
        setIsStudent(true);
      } else {
        setTitle("Certificate Types");
        setIsStudent(false);
      }
    }
  }, [data]);

  //----------------------------------------------------------------------------
  // funtions
  //----------------------------------------------------------------------------


  //----------------------------------------------------------------------------
  // component rendering
  //----------------------------------------------------------------------------
  return (
    <Container maxWidth="xl" className={classes.container}>
      <Paper>
        <CardHeader title={title} action={
            isAdmin && <CreateBtn />
          } />
        <CertTypesList isStudent={isStudent} />
      </Paper>
    </Container>
  );
}

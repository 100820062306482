import React from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { Button, Dialog, DialogTitle, TextField, DialogContent, DialogActions } from '@mui/material';
import { makeStyles } from '@material-ui/core/styles';

//******************************************************************************
// style definition
//******************************************************************************
const useStyles = makeStyles((theme) => ({
  dialog: {
    '& .MuiPaper-root': {
      minWidth: '600px',
      maxWidth: '800px',
    },
    '& .MuiDialogContent-root.css-ypiqx9-MuiDialogContent-root' : {
      paddingTop: theme.spacing(2)
    }
  }
}));

//******************************************************************************
// component definition
//******************************************************************************
export default function ExportBtn({dataTable}) {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [value] = React.useState(JSON.stringify(dataTable, null, 2));
  const [copied, setCopied] = React.useState(false);
  const timer = React.useRef(0);

  React.useEffect(() => {
    if (copied) {
      timer.current = setTimeout(()=>{
        setCopied(false);
      }, 5000);
    }
    return () => {
      clearTimeout(timer.current);
    }
  }, [copied]);

  //----------------------------------------------------------------------------
  // funtions
  //----------------------------------------------------------------------------
  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  //----------------------------------------------------------------------------
  // component rendering
  //----------------------------------------------------------------------------
  return (
    <>
      <Button variant="outlined" onClick={handleOpen}>
        Export
      </Button>
      <Dialog open={open} onClose={handleClose} className={classes.dialog}>
        <DialogTitle>
          Export table
        </DialogTitle>
        <DialogContent>
          <TextField id="table-export" label="JSON"
            multiline fullWidth disabled rows={24}
            value={value}
          />
        </DialogContent>
        <DialogActions>
          <CopyToClipboard text={value}
            onCopy={()=>setCopied(true)}>
            <Button disabled={copied}>
              { copied? "Copied!" : "Copy"}
            </Button>
          </CopyToClipboard>
        </DialogActions>
      </Dialog>
    </>
  )
}

import React from 'react';
import { useQuery } from "@apollo/client";
import { OPTIONS_IN_FORM } from '../../constants/gqls/forms';

//******************************************************************************
// component definition
//******************************************************************************
export default function OptionQuotaLeftQuery({formId}) {
  const { data } = useQuery(OPTIONS_IN_FORM, { variables: { formId }});
  // console.log(data);

  //----------------------------------------------------------------------------
  // component rendering
  //----------------------------------------------------------------------------
  return <div />;
}

import React from 'react';
import { useParams } from "react-router-dom";
import { Popover, Stack } from '@mui/material';
import { makeStyles } from '@material-ui/core/styles';
import { useMutation } from "@apollo/client";
import UpBtn from './UpBtn';
import DownBtn from './DownBtn';
import DeleteBtn from './DeleteBtn';
import ItemTypeBtn from './ItemTypeBtn';
import SettingBtn from './SettingBtn';
import { SORT_ITEMS } from '../../constants/gqls/documents';

//******************************************************************************
// style definition
//******************************************************************************
const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiPaper-root": {
      borderRadius: '20px',
      backgroundColor: theme.palette.primary.pale,
    }
  }
}));

//******************************************************************************
// component definition
//******************************************************************************
export default function ItemFunctions({ item, index, count, anchorEl, handleClose }) {
  const classes = useStyles();
  const params = useParams();
  const [sortItems] = useMutation(SORT_ITEMS);
  const open = Boolean(anchorEl);
  // console.log(item);

  //----------------------------------------------------------------------------
  // funtions
  //----------------------------------------------------------------------------
  const sortItem = (item, index) => {
    return sortItems({
      variables: {
        item, index,
        docId: params.id,
      }
    });
  }

  //----------------------------------------------------------------------------
  // component rendering
  //----------------------------------------------------------------------------
  return (
    <Popover open={open} anchorEl={anchorEl} onClose={handleClose}
      anchorOrigin={{
        vertical: 'center',
        horizontal: 'left',
      }}
      transformOrigin={{
        vertical: 'center',
        horizontal: 'right',
      }} className={classes.root}>
      <Stack>
        {index !== 0 &&
          <UpBtn itemId={item._id} index={index} sortItem={sortItem} />
        }
        {index !== (count - 1) &&
          <DownBtn itemId={item._id} index={index} sortItem={sortItem} />
        }
        {item.type === 'text' &&
          <ItemTypeBtn itemId={item._id} type="input" />
        }
        {item.type === 'input' &&
          <ItemTypeBtn itemId={item._id} 
            type="text" 
            inputDeleteBlock={item.input? !item.input.deletable : false} />
        }
        <SettingBtn item={item} />
        {item.type !== 'filter' &&
          <DeleteBtn itemId={item._id}
            inputDeleteBlock={item.input? !item.input.deletable : false}
            sortItem={sortItem} />
        }
      </Stack>
    </Popover>
  )
}

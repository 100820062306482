import React from 'react';
import { List, Button,
  Dialog, DialogContent, DialogTitle } from '@mui/material';
// import LoadingButton from '@mui/lab/LoadingButton';
import { makeStyles } from '@material-ui/core/styles';
import { useMutation } from "@apollo/client";
import { DOCUMENT, DOCUMENT_CHOWN } from '../../constants/gqls/documents';
import PersonalShareItem from './PersonalShareItem';
import PeopleOrSpaceInput from '../InputsCommt/PeopleOrSpaceInput';

//******************************************************************************
// style definition
//******************************************************************************
const useStyles = makeStyles((theme) => ({
  dialog: {
    '& .MuiPaper-root': {
      maxWidth: '640px',
      width: '640px',
    }
  },
  notice: {
    color: theme.palette.warning.main
  },
  btns: {
    display: 'flex',
    '& button': {
      margin: 'auto'
    }
  }
}));

//******************************************************************************
// component definition
//******************************************************************************
export default function OwnershipDialog({doc, open, handleClose}) {
  const classes = useStyles();
  const [newOwner, setNewOwner] = React.useState(null);
  const [noted, setNoted] = React.useState(false);
  // const [scope, setScope] = React.useState(doc.scope[0]);
  const [documentChown, { loading }] = useMutation(DOCUMENT_CHOWN, {
    refetchQueries: [ DOCUMENT ],
  });
  const owner = doc.ownerPerson || doc.ownerSpace;
  // console.log(newOwner);



  //----------------------------------------------------------------------------
  // funtions
  //----------------------------------------------------------------------------
  const chown = (event) => {
    // setScope(event.target.value);
    documentChown({ variables: {
      docId: doc._id,
      owner: newOwner._id
    }}).then(res=>{
      // console.log(res);
      onClose();
    });
  };

  const notedClicked = () => {
    setNoted(true);
  }

  const cancelClicked = () => {
    setNewOwner(null);
  }

  const onClose = () => {
    setNoted(false);
    handleClose()
  }

  //----------------------------------------------------------------------------
  // component rendering
  //----------------------------------------------------------------------------
  return (
    <Dialog open={open} onClose={onClose} className={classes.dialog}>
      <DialogTitle>
        Document ownership
      </DialogTitle>
      <DialogContent>
        <List>
          <PersonalShareItem entity={owner} access="Owner" />
        </List>
        <PeopleOrSpaceInput value={newOwner} onChange={setNewOwner} excludes={[owner._id]}
          label="Transfer ownership to" />
        { newOwner &&
          <React.Fragment>
            <p className={classes.notice}>
              After transfering the ownership, the new owner will have full control to this document. And you will lose access to it, unless you are in the sharing list.
            </p>
            { noted?
              <div className={classes.btns}>
                <Button variant="contained" onClick={chown} disabled={loading}>
                  Transfer ownership
                </Button>
              </div>
              :
              <div className={classes.btns}>
                <Button onClick={cancelClicked}>Cancel</Button>
                <Button variant="outlined" onClick={notedClicked}>Noted</Button>
              </div>
            }
          </React.Fragment>
        }
      </DialogContent>
    </Dialog>
  )
}

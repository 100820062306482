import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

//******************************************************************************
// style definition
//******************************************************************************
const useStyles = makeStyles((theme) => ({
  frame: {
    width: '100%'
  }
}));

//******************************************************************************
// component definition
//******************************************************************************
export default function YouTube({serviceId}) {
  const classes = useStyles();
  const src = `https://www.youtube.com/embed/${serviceId}`;

  return (
    <iframe src={src}
      title="YouTube video player" frameBorder="0"
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
      allowFullScreen
      width="560" height="320" className={classes.frame} />
  );
}

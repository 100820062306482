import React from 'react';
import InfiniteScroll from 'react-infinite-scroller';
import { List, LinearProgress } from '@mui/material';
import { useQuery } from "@apollo/client";
import DataTableItem from './DataTableItem';
import { DATA_TABLES } from '../../constants/gqls/dataAnalysis';
const limit = 10;

//******************************************************************************
// component definition
//******************************************************************************
export default function DataTableList() {
  const { loading, data, fetchMore } = useQuery(DATA_TABLES, {
    variables: { options: { limit, offset: 0 }}
  });

  //----------------------------------------------------------------------------
  // funtions
  //----------------------------------------------------------------------------
  const loadMore = () => {
    fetchMore({ variables: {
      limit,
      offset: data.dataTables.length
    }});
  }

  //----------------------------------------------------------------------------
  // component rendering
  //----------------------------------------------------------------------------
  if (loading) return <LinearProgress />;
  if (!data) return 'Network error.'
  if (data.dataTablesCount === 0) {
    return 'No table yet.';
  }

  return (
    <InfiniteScroll
        loadMore={loadMore}
        hasMore={data.dataTablesCount > data.dataTables.length}
        loader={<LinearProgress key="loading" />}
    >
      <List>
        { data.dataTables.map(dataTable=>(
          <DataTableItem key={dataTable._id} dataTable={dataTable} />
        )) }
      </List>
    </InfiniteScroll>
  );
}

import React from 'react';
// import { generatePath } from "react-router-dom";
import InfiniteScroll from 'react-infinite-scroller';
import { LinearProgress, List, Grid } from '@mui/material';
import { makeStyles } from '@material-ui/core/styles';
import { useQuery } from "@apollo/client";
import FormProfileItem from './FormProfileItem';
// import SiteMap from '../../constants/siteMap';
import { PROFILE_USER_LIST } from '../../constants/gqls/forms';
const limit = 10;

//******************************************************************************
// style definition
//******************************************************************************
const useStyles = makeStyles((theme) => ({
  list: {
    maxHeight: '480px',
    overflow: 'auto'
  }
}));

//******************************************************************************
// component definition
//******************************************************************************
export default function FormProfileList({docId, disabled}) {
  const classes = useStyles();
  // const dataPath = generatePath(SiteMap.formData, { id: docId });
  const { data, loading, fetchMore } = useQuery(PROFILE_USER_LIST, { variables: {
    docId, limit,
    offset: 0,
  }});
  // console.log(data);

  //----------------------------------------------------------------------------
  // funtions
  //----------------------------------------------------------------------------
  const loadMore = () => {
    console.log("loadMore.."+data.profileUserList.length);
    fetchMore({ variables: {
      offset: data.profileUserList.length
    }});
  }

  //----------------------------------------------------------------------------
  // component rendering
  //----------------------------------------------------------------------------
  if (loading) return <LinearProgress />;
  if (!data) return 'Network error.'
  if (data.profileUserCount === 0) {
    return 'No profiles.'
  }

  return (
    <>
      <Grid container justifyContent="space-between" alignItems="center">
        <Grid item>
          Total: { data.profileUserCount }
        </Grid>
        <Grid item>
          {/* <Button variant="outlined" href={dataPath} target="_blank" rel="noreferrer">
            Data Table
          </Button> */}
        </Grid>
      </Grid>
      <div className={classes.list}>
        <InfiniteScroll
            loadMore={loadMore}
            useWindow={false}
            hasMore={data.profileUserCount > data.profileUserList.length}
            loader={<LinearProgress key="loading" />}
        >
          <List>
            { data.profileUserList.map(profile=>(
              <FormProfileItem key={profile._id} profile={profile} disabled={disabled} />
            )) }
          </List>
        </InfiniteScroll>
      </div>
    </>
  );
}

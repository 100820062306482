import React from 'react';
import { generatePath } from "react-router-dom";
import { CardHeader, CardContent } from '@mui/material';
import RocketLaunchIcon from '@mui/icons-material/RocketLaunch';
import { makeStyles } from '@material-ui/core/styles';
import CardComm from './CardComm';
import HtmlEditor from "../ContentEditor/HtmlEditor";
import SiteMap from '../../constants/siteMap';

//******************************************************************************
// style definition
//******************************************************************************
const useStyles = makeStyles((theme) => ({
  content: {
    "&.MuiCardContent-root": {
      paddingTop: 0
    }
  }
}));

//******************************************************************************
// component definition
//******************************************************************************
export default function SpaceCard({space, setSignal}) {
  const classes = useStyles();
  const goTo = generatePath(SiteMap.space, { id: space._id });

  React.useEffect(() => {
    if (setSignal) {
      setSignal(`${space._id} loaded`);
    }
  }, [space, setSignal]);

  //----------------------------------------------------------------------------
  // component rendering
  //----------------------------------------------------------------------------
  // if (loading) return <LinearProgress />;
  // if (!data) return 'Network error.'

  return (
    <CardComm tags={space.tags}
      icon={<RocketLaunchIcon />} goTo={goTo}>
      <CardHeader title={space.name} />
      <CardContent className={classes.content}>
        <HtmlEditor html={space.description}
          placeholder="Space description" readOnly={true} />
      </CardContent>
    </CardComm>
  );
}

import React from 'react';
import { IconButton, Tooltip, Dialog, DialogTitle,
  DialogContent, DialogContentText } from '@mui/material';
// import LoadingButton from '@mui/lab/LoadingButton';
import TextFieldsIcon from '@mui/icons-material/TextFields';
import DriveFileRenameOutlineIcon from '@mui/icons-material/DriveFileRenameOutline';
import { useMutation } from "@apollo/client";
import { UPDATE_ITEM } from '../../constants/gqls/items';
import { DOCUMENT_ISFORM } from '../../constants/gqls/documents';

//******************************************************************************
// component definition
//******************************************************************************
export default function ItemTypeBtn({itemId, type, inputDeleteBlock}) {
  const [open, setOpen] = React.useState(false);
  const [updateItem, { loading }] = useMutation(UPDATE_ITEM, {
    refetchQueries: [ DOCUMENT_ISFORM ]
  });

  //----------------------------------------------------------------------------
  // funtions
  //----------------------------------------------------------------------------
  const handleClose = () => {
    setOpen(false);
  };

  const updateType = () => {
    updateItem({ variables: {
      itemId, type
    }});
  }

  const handleClick = () => {
    if (inputDeleteBlock) {
      setOpen(true);
    } else {
      updateType()
    }
  }

  //----------------------------------------------------------------------------
  // component rendering
  //----------------------------------------------------------------------------
  return (
    <React.Fragment>
      <Tooltip title={`Make it an ${type} item`} placement="left">
        <IconButton onClick={handleClick} disabled={loading}>
          {{
            text: <TextFieldsIcon />,
            input: <DriveFileRenameOutlineIcon />,
          }[type]}
        </IconButton>
      </Tooltip>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>
          Dangerous update
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            Your are trying to change this item type while someone has started filling in data here or it's imported to other form. Please clear all data and importing first.
          </DialogContentText>
        </DialogContent>
        {/* <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <LoadingButton variant="outlined"
            loading={loading} onClick={updateType}>
            Delete
          </LoadingButton>
        </DialogActions> */}
      </Dialog>
    </React.Fragment>
  )
}

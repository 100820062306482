import React from 'react';
import { TextField, Autocomplete, Chip, Avatar } from '@mui/material';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import RocketLaunchIcon from '@mui/icons-material/RocketLaunch';
import { useQuery } from "@apollo/client";
import { PEOPLE_OR_SPACE } from '../../constants/gqls/user';

//******************************************************************************
// component definition
//******************************************************************************
export default function PeopleOrSpaceInput({value, onChange, label, multiple, disabled, excludes}) {
  const [inputValue, setInputValue] = React.useState(value?
    `${value.name} (${value.email || "Space"})` : ''
  );
  const variables = {
    search: inputValue,
    excludes: multiple? value.map(item=>item._id) : excludes
  };

  const { data } = useQuery(PEOPLE_OR_SPACE, { variables });
  // console.log(variables);
  // console.log(value);


  //----------------------------------------------------------------------------
  // funtions
  //----------------------------------------------------------------------------
  const onValueChange = (event, newValue) => {
    // console.log(newValue);
    onChange(newValue);
  }

  const onInputChange = (event, newValue) => {
    setInputValue(newValue);
  }

  //----------------------------------------------------------------------------
  // component rendering
  //----------------------------------------------------------------------------
  return (
    <Autocomplete multiple={multiple} disabled={disabled}
      value={value} onChange={onValueChange}
      inputValue={inputValue} onInputChange={onInputChange}
      getOptionLabel={option=>`${option.name} (${option.email || "Space"})`}
      options={ data ? data.peopleOrSpaces : [] }
      renderInput={(params) => <TextField {...params}
        label={label}
        variant="standard"
      />}
      renderTags={(value, getTagProps) =>
        value.map((option, index) => (
          <Chip variant="outlined" label={option.name}
            avatar={
              <Avatar>
                {{
                  People: <AccountCircleIcon />,
                  Space: <RocketLaunchIcon />
                }[option.__typename]}
              </Avatar>
            }
            {...getTagProps({ index })} />
        ))
      }
    />
  );
}

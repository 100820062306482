import React from 'react';
import { TextField, Autocomplete, Chip } from '@mui/material';

//******************************************************************************
// component definition
//******************************************************************************
export default function ServiceFormSubSelect({refFormSubs, onChange, value}) {
  const [inputValue, setInputValue] = React.useState('');

  //----------------------------------------------------------------------------
  // funtions
  //----------------------------------------------------------------------------
  const onValueChange = (event, newValue) => {
    // console.log(newValue);
    onChange(newValue);
  }

  const onInputChange = (event, newValue) => {
    setInputValue(newValue);
  }

  //----------------------------------------------------------------------------
  // component rendering
  //----------------------------------------------------------------------------
  return (
    <Autocomplete multiple
      value={value} onChange={onValueChange}
      inputValue={inputValue} onInputChange={onInputChange}
      getOptionLabel={option=>`${option.ownerPerson.name} (${option.ownerPerson.studyLevel})`}
      getOptionDisabled={option=>Boolean(option.serviceSessionId)}
      options={refFormSubs}
      renderInput={(params) => <TextField {...params}
        label="Client applications"
        variant="standard"
      />}
      renderTags={(value, getTagProps) =>
        value.map((option, index) => (
          <Chip variant="outlined" label={option.ownerPerson.name} {...getTagProps({ index })} />
        ))
      }
    />
  );
}

import React from 'react';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import { EditorState, ContentState, RichUtils, CompositeDecorator,
  convertToRaw } from 'draft-js';
import FormatBoldIcon from '@mui/icons-material/FormatBold';
import FormatItalicIcon from '@mui/icons-material/FormatItalic';
import FormatUnderlinedIcon from '@mui/icons-material/FormatUnderlined';
import CodeIcon from '@mui/icons-material/Code';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import FormatListNumberedIcon from '@mui/icons-material/FormatListNumbered';
import Editor from './Editor';
import StyleButton from './StyleButton';
import LinkButtons from './LinkButtons';
import LinkDecorator from './LinkDecorator';
const decorator = new CompositeDecorator([LinkDecorator])

const INLINE_STYLES = [
  {label: 'Bold', style: 'BOLD', icon: <FormatBoldIcon />},
  {label: 'Italic', style: 'ITALIC', icon: <FormatItalicIcon />},
  {label: 'Underline', style: 'UNDERLINE', icon: <FormatUnderlinedIcon />},
  {label: 'Monospace', style: 'CODE', icon: <CodeIcon />},
];

const BLOCK_TYPES = [
  {label: 'H1', style: 'header-one'},
  {label: 'H2', style: 'header-two'},
  {label: 'H3', style: 'header-three'},
  {label: 'H4', style: 'header-four'},
  {label: 'H5', style: 'header-five'},
  {label: 'H6', style: 'header-six'},
  {label: 'UL', style: 'unordered-list-item', icon: <FormatListBulletedIcon />},
  {label: 'OL', style: 'ordered-list-item', icon: <FormatListNumberedIcon />},
];

//******************************************************************************
// component definition
//******************************************************************************
export default function HtmlEditor({ html, onChange, placeholder, readOnly, disabled }) {
  // console.log(html);
  const [editorState, setState] = React.useState(
    html ?
      EditorState.createWithContent(
        ContentState.createFromBlockArray(htmlToDraft(html)),
        decorator
      )
      : EditorState.createEmpty(decorator)
  );
  const timer = React.useRef(0);

  React.useEffect(() => {
    if (onChange) {
      clearTimeout(timer.current);
      timer.current = setTimeout(()=>{
        let rawData = convertToRaw(editorState.getCurrentContent());
        onChange(draftToHtml(rawData));
        // console.log(rawData);
        // console.log(editorState.getSelection())
        // console.log(window.getSelection()?.getRangeAt(0)?.getBoundingClientRect());
      }, 500);
    }
  }, [onChange, editorState]);

  React.useEffect(() => {
    if (readOnly) {
      html ?
      setState(EditorState.createWithContent(
        ContentState.createFromBlockArray(htmlToDraft(html)),
        decorator
      ))
      : setState(EditorState.createEmpty(decorator))
    }
  }, [html, readOnly]);

  React.useEffect(() => {
    return () => {
      clearTimeout(timer.current);
    }
  }, []);


  //----------------------------------------------------------------------------
  // funtions
  //----------------------------------------------------------------------------
  const onToggleInline = (style) => {
    setState(RichUtils.toggleInlineStyle(editorState, style));
  }

  const onToggleBlock = (style) => {
    setState(RichUtils.toggleBlockType(editorState, style));
  }

  //----------------------------------------------------------------------------
  // component rendering
  //----------------------------------------------------------------------------
  return (
    <div>
      { !readOnly &&
        <div>
          { BLOCK_TYPES.map(type=>(
            <StyleButton key={type.label}
              onToggle={onToggleBlock}
              style={type.style}
              label={type.label}
              icon={type.icon}
              disabled={disabled} />
          ))}
          { INLINE_STYLES.map(type=>(
            <StyleButton key={type.label}
              onToggle={onToggleInline}
              style={type.style}
              label={type.label}
              icon={type.icon}
              disabled={disabled} />
          ))}
          <LinkButtons
            editorState={editorState}
            setState={setState}
            disabled={disabled} />
        </div>
      }
      <Editor minHeight="120px" placeholder={placeholder} readOnly={readOnly}
        editorState={editorState} setState={setState} />
    </div>
  );
}

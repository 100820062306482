import React from 'react';
import { Button, Dialog, DialogTitle,
  DialogContent, DialogContentText, DialogActions } from '@mui/material';

//******************************************************************************
// component definition
//******************************************************************************
export default function CloseBtn({handleStatusClose, disabled}) {
  const [open, setOpen] = React.useState(false);

  //----------------------------------------------------------------------------
  // funtions
  //----------------------------------------------------------------------------
  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  //----------------------------------------------------------------------------
  // component rendering
  //----------------------------------------------------------------------------
  return (
    <React.Fragment>
      <Button variant="outlined" disabled={disabled} onClick={handleOpen}>
        Close
      </Button>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>
          Close session?
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            By closing this session, student will not be able to match with other teacher this round, and will have "session closed" record in his/her profile. An acknowledgement email will be sent to student after session closed.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button variant="outlined"
            disabled={disabled} onClick={handleStatusClose}>
            Close this session
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  )
}

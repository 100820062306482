import React from 'react';
import { useNavigate } from "react-router-dom";
import InfiniteScroll from 'react-infinite-scroller';
import { CardContent, LinearProgress } from '@mui/material';
import { useQuery, useReactiveVar } from "@apollo/client";
import { doRefetch } from '../../constants/cache';
import { makeStyles } from '@material-ui/core/styles';
import { MY_TAGGED_TAGS } from '../../constants/gqls/tags';
import Tag from '../Tag';
import SiteMap from '../../constants/siteMap';
const limit = 10;

//******************************************************************************
// style definition
//******************************************************************************
const useStyles = makeStyles((theme) => ({
  tags: {
    "& button": {
      margin: '0 6px 6px 0'
    }
  },
}));

//******************************************************************************
// component definition
//******************************************************************************
export default function MyTagsCard() {
  const navigate = useNavigate();
  const classes = useStyles();
  const needRefetch = useReactiveVar(doRefetch);
  const { loading, data, fetchMore, refetch } = useQuery(MY_TAGGED_TAGS, { variables: {
    limit, offset: 0
  }});
  // console.log(data);

  React.useEffect(() => {
    if (needRefetch) {
      // console.log('refetch');
      refetch();
      doRefetch(false)
    }
  }, [needRefetch, refetch]);

  //----------------------------------------------------------------------------
  // funtions
  //----------------------------------------------------------------------------
  const loadMore = () => {
    fetchMore({ variables: {
      limit,
      offset: data.myTaggedTags.length
    }});
  }

  const goFilter = (id) => {
    let params = new URLSearchParams({ tags: id });
    navigate(SiteMap.tagsFilter+'?'+params.toString());
  }

  //----------------------------------------------------------------------------
  // component rendering
  //----------------------------------------------------------------------------
  if (loading) return <LinearProgress />;
  if (!data) return 'Network error.'
  if (data.myTaggedTagsCount === 0) return 'No records...'

  return (
    <CardContent className={classes.tags}>
      <InfiniteScroll
        loadMore={loadMore}
        useWindow={false}
        hasMore={data.myTaggedTagsCount > data.myTaggedTags.length}
        loader={<LinearProgress key="loading" />}
      >
        { data.myTaggedTags.map(tag=>(
          <Tag key={tag._id} text={tag.key} type={tag.type}
            tagId={tag._id} onTagClick={goFilter} />
        )) }
      </InfiniteScroll>
    </CardContent>
  );
}

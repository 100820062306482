import React from 'react';
import InfiniteScroll from 'react-infinite-scroller';
import { CardContent, LinearProgress, List } from '@mui/material';
import { makeStyles } from '@material-ui/core/styles';
import { useQuery } from "@apollo/client";
import SearchInput from '../InputsCommt/SearchInput';
import SpaceItem from './SpaceItem';
import { SPACES } from '../../constants/gqls/spaces';
const limit = 10;

//******************************************************************************
// style definition
//******************************************************************************
const useStyles = makeStyles((theme) => ({
  content: {
    '&.MuiCardContent-root': {
      paddingTop: (props) => props.update ? 0 : theme.spacing(2)
    }
  },
  name: {
    '&.MuiTextField-root': {
      margin: `${theme.spacing(2)}px 0`
    }
  },
  btn: {
    '&.MuiButton-root' : {
      margin: 'auto'
    }
  }
}));

//******************************************************************************
// component definition
//******************************************************************************
export default function Search() {
  const classes = useStyles();
  const [search, setSearch] = React.useState('');
  const { loading, data, fetchMore } = useQuery(SPACES, { variables:{
    where: { OR: [
      {name_MATCHES: `(?i).*${search}.*` },
      {description_MATCHES: `(?i).*${search}.*` }
    ]},
    options: { limit, offset: 0 }
  }});
  // console.log(data);

  //----------------------------------------------------------------------------
  // funtions
  //----------------------------------------------------------------------------
  const loadMore = () => {
    fetchMore({ variables: { options: {
      limit,
      offset: data.spaces.length
    }}});
  }

  //----------------------------------------------------------------------------
  // component rendering
  //----------------------------------------------------------------------------
  return (
    <React.Fragment>
      <CardContent className={classes.content}>
        <SearchInput value={search} onChange={setSearch} />
      </CardContent>
      { loading? <LinearProgress />
        : (!data)? 'Network error.'
        : data.spaces.length === 0 ? 'No result.'
        :
        <InfiniteScroll
          loadMore={loadMore}
          hasMore={data.spacesCount > data.spaces.length}
          loader={<LinearProgress key="loading" />}
        >
          <List>
            { data.spaces.map(space=>(
              <SpaceItem key={space._id} space={space} />
            )) }
          </List>
        </InfiniteScroll>
      }
    </React.Fragment>
  )
}

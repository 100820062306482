import React from 'react';
import { Tooltip, TextField } from '@mui/material';
import { makeStyles } from '@material-ui/core/styles';

//******************************************************************************
// style definition
//******************************************************************************
const useStyles = makeStyles((theme) => ({
  title: {
    margin: "0 120px 0 0",
    color: theme.palette.primary.dark
  },
  box: {
    minHeight: '28px'
  }
}));

//******************************************************************************
// component definition
//******************************************************************************
export default function Title({text, loading = false, update = null}) {
  const classes = useStyles();
  const [edit, setEdit] = React.useState(false);
  const [title, setTitle] = React.useState(text);
  const [changed, setChanged] = React.useState(false);

  //----------------------------------------------------------------------------
  // funtions
  //----------------------------------------------------------------------------
  const toEdit = () => {
    if (update) {
      setEdit(true);
    }
  }

  const onTitleChange = (event) => {
    setTitle(event.target.value);
    setChanged(true);
  }

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      handleUpdate();
    }
  }

  const handleUpdate = () => {
    if (changed) {
      if (!loading) {
        update({title}).then(res=>{
          // console.log(res);
          setEdit(false);
          setChanged(false);
        });
      }
    } else {
      setEdit(false);
    }
  }

  //----------------------------------------------------------------------------
  // component rendering
  //----------------------------------------------------------------------------
  if (edit) return (
    <Tooltip title="Enter to submit">
      <TextField id="document-title" label="Title"
        autoFocus fullWidth margin="dense" variant="outlined"
        value={title} onChange={onTitleChange}
        onKeyDown={handleKeyDown} onBlur={handleUpdate} disabled={loading} />
    </Tooltip>
  );

  return (
    <Tooltip placement="left-start"
      title={ update? "Title (double click to edit)" : "Title"}>
      <div className={classes.box} onDoubleClick={toEdit}>
        <h3 className={classes.title}>{text}</h3>
      </div>
    </Tooltip>
  );
}

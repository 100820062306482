export const isValidUrl = (url) => {
  let urlRegx = /^(https?:\/\/)([\dA-Za-z.-]+)\.([a-z.]{2,6})(?::(\d+))?(?:\/([^?#]*))?(?:\?([^#]*))?(?:#(.*))?$/;
  return urlRegx.test(url);
};

export const DateTimeToString = (str) => {
  let d = new Date(str);
  return d.toLocaleDateString() +' '+ d.toLocaleTimeString()
};

export const DateToString = (str) => {
  let d = new Date(str);
  return d.toLocaleDateString()
};

const valueToString = (value) => {
  let trimed = value.text?.trim() || '';
  if (trimed === '' || !value.field.toStr) {
    return trimed;
  } else {
    let regx = new RegExp(`\\[\\[text\\]\\]`, 'g');
    return value.field.toStr.replace(regx, trimed);
  }
}

export const valueSetToString = (valueSet, viewValueFields) => {
  let values = [...valueSet.valueList];
  if (viewValueFields && viewValueFields.length!==0) {
    values = values.filter(v=>viewValueFields.includes(v.field._id));
  }
  let strs = values.sort((a,b)=>(
      a.field.index-b.field.index
    )).map(v=>valueToString(v)).filter(v=>v!=='');
  let vStr = '<span style="font-style: italic; color: #888;">[[fill in here]]</span>';

  if (strs.length) {
    vStr = strs.join(' ');
  }
    
  return vStr;
};

function sortWeeklyHours(inValues) {
  let weekdays = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday'];
  let weekdaysMap = {'Monday':0, 'Tuesday':1, 'Wednesday':2, 'Thursday':3, 'Friday':4};
  return inValues.map(v=>{
    let splited = v.split(' => ');
    let day = weekdaysMap[splited[0]];
    let hour = splited[1];
    return `${day} => ${hour}`
  }).sort().map(v=>{
    let splited = v.split(' => ');
    let day = weekdays[splited[0]];
    let hour = splited[1];
    return `${day} => ${hour}`
  });
}

export const dataToString = (data, inSeparator) => {
  // console.log({data, inSeparator});
  let textValue = '';
  let separator = inSeparator || ', ';
  if (data) {
    let values = data.options.map(option=>option.text);
    if (data.values?.length) {
      if (data.input.type==='weeklyHourMulti') {
        values = values.concat(sortWeeklyHours(data.values));
      } else {
        values = values.concat(data.values);
      }
    }
    if (data.value) {
      values.push(data.value);
    }
    if(typeof data.boolValue === 'boolean') {
      values.push(data.boolValue? 'Yes' : 'No');
    }
    textValue = values.join(separator);

    // if (data.options.length !== 0) {
    //   textValue = data.options.map(opt=>opt.text).join(separator);
    // } else if (data.values?.length) {
    //   let values = data.values;
    //   if (data.input.type==='weeklyHourMulti') {
    //     values = sortWeeklyHours(data.values);
    //   }
    //   textValue = values.join(separator);
    // } else if (data.value) {
    //   textValue = data.value;
    // } else if(typeof data.boolValue === 'boolean') {
    //   textValue = data.boolValue? 'Yes' : 'No'
    // }
  }
  return textValue;
};

export const extractHtmlContent = (html) => {
  return new DOMParser()
      .parseFromString(html, "text/html")
      .documentElement.textContent;
}

export const isTypeAutoComplete = (inType) => {
  const autoCompleteRgx = /^autoComplete/;
  return autoCompleteRgx.test(inType);
};

export const scopeSelects = ['system', 'personal'];
export const emailTemplatesFixed = ['Welcome'];


import React from 'react';
import { Grid } from '@mui/material';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import LoadingButton from '@mui/lab/LoadingButton';

//******************************************************************************
// component definition
//******************************************************************************
export default function UploadBtn({label, accept, onChange, loading, disabled}) {
  const inputRef = React.createRef();

  //----------------------------------------------------------------------------
  // funtions
  //----------------------------------------------------------------------------
  const handleClick = () => {
    inputRef.current.click();
  };


  //----------------------------------------------------------------------------
  // component rendering
  //----------------------------------------------------------------------------
  return (
    <Grid container justifyContent="space-around">
      <input style={{ display: 'none' }} ref={inputRef} type="file" required onChange={onChange} accept={accept}/>
      <LoadingButton loading={loading} 
        onClick={handleClick} 
        disabled={disabled}
        variant="outlined"
        startIcon={<CloudUploadIcon />}>
        {label}
      </LoadingButton>
    </Grid>
  )
}

import React from 'react';
import { Toolbar, List, IconButton, Divider } from '@mui/material';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import DashboardIcon from '@mui/icons-material/Dashboard';
import HistoryEduIcon from '@mui/icons-material/HistoryEdu';
import PeopleIcon from '@mui/icons-material/People';
import LocalOfferIcon from '@mui/icons-material/LocalOffer';
import RocketLaunchIcon from '@mui/icons-material/RocketLaunch';
import NewspaperIcon from '@mui/icons-material/Newspaper';
import MailIcon from '@mui/icons-material/Mail';
import SupportAgentIcon from '@mui/icons-material/SupportAgent';
import MenuBookIcon from '@mui/icons-material/MenuBook';
import AssessmentIcon from '@mui/icons-material/Assessment';
import ContactsIcon from '@mui/icons-material/Contacts';
// import WorkspacePremiumIcon from '@mui/icons-material/WorkspacePremium';
import MenuItem from './MenuItem';
import SiteMap from '../../constants/siteMap';

const menuList = [
  {
    icon: <DashboardIcon color="primary" />,
    text: 'Dashboard',
    href: SiteMap.home
  }, {
    icon: <HistoryEduIcon color="primary" />,
    text: 'Documents',
    subItems: [{
      text: 'My documents',
      href: SiteMap.myDocuments
    }, {
      text: 'Shared with me',
      href: SiteMap.sharedDocuments
    }]
  }, {
    icon: <LocalOfferIcon color="primary" />,
    text: 'My Tags',
    href: SiteMap.tags
  }, {
    icon: <RocketLaunchIcon color="primary" />,
    text: 'Spaces',
    href: SiteMap.spaces
  }, {
    icon: <SupportAgentIcon color="primary" />,
    text: 'Services',
    href: SiteMap.services
  }, {
    icon: <MenuBookIcon color="primary" />,
    text: 'Courses',
    href: SiteMap.courses
  // }, {
  //   icon: <WorkspacePremiumIcon color="primary" />,
  //   text: 'Certificates',
  //   href: SiteMap.certificates
  },
];

const adminMenuList = [{
  icon: <PeopleIcon color="primary" />,
  text: 'Manage Users',
  href: SiteMap.admin_users
}, {
  icon: <ContactsIcon color="primary" />,
  text: 'Profile Data',
  href: SiteMap.admin_profiles
}, {
  icon: <NewspaperIcon color="primary" />,
  text: 'Manage Pages',
  href: SiteMap.admin_pages
}, {
  icon: <LocalOfferIcon color="primary" />,
  text: 'Manage Tags',
  href: SiteMap.admin_tags
}, {
  icon: <MailIcon color="primary" />,
  text: 'Email Templates',
  href: SiteMap.admin_emails
}, {
  icon: <AssessmentIcon color="primary" />,
  text: 'Data analysis',
  href: SiteMap.admin_data_analysis
}];

//******************************************************************************
// component definition
//******************************************************************************
export default function Menu({ open, xs, isAdmin, handleDrawer }) {
  return (
    <div>
      <Toolbar />
      { !xs &&
        <div>
          <IconButton onClick={handleDrawer}>
            {open ? <ChevronLeftIcon /> : <ChevronRightIcon />}
          </IconButton>
        </div>
      }
      <Divider />
      <List>
        {menuList.map((item, index) => (
          <MenuItem key={index} item={item} open={xs? true: open} />
        ))}
      </List>
      { isAdmin &&
        <React.Fragment>
          <Divider />
          <List>
            {adminMenuList.map((item, index) => (
              <MenuItem key={index} item={item} open={xs? true: open} />
            ))}
          </List>
        </React.Fragment>
      }
    </div>
  );
}

import React from 'react';
import InfiniteScroll from 'react-infinite-scroller';
import { Container, LinearProgress } from '@mui/material';
import Masonry from '@mui/lab/Masonry';
import { makeStyles } from '@material-ui/core/styles';
import { useQuery, useReactiveVar } from "@apollo/client";
import DocAddCard from '../components/Documents/DocAddCard';
import DocCard from '../components/Documents/DocCard';
import { doRefetch } from '../constants/cache';
import { MY_DOCUMENTS } from '../constants/gqls/documents';
const limit = 10;

//******************************************************************************
// style definition
//******************************************************************************
const useStyles = makeStyles((theme) => ({
  container: {
    paddingTop: theme.spacing(3)
  },
}));

//******************************************************************************
// component definition
//******************************************************************************
export default function Documents() {
  const needRefetch = useReactiveVar(doRefetch);
  const classes = useStyles();
  // const [signal, setSignal] = React.useState('');
  const { loading, data, fetchMore, refetch } = useQuery(MY_DOCUMENTS, {
    variables: { limit, offset: 0 }
  });
  // console.log(data);

  React.useEffect(() => {
    if (needRefetch) {
      // console.log('refetch');
      refetch();
      doRefetch(false)
    }
  }, [needRefetch, refetch]);

  //----------------------------------------------------------------------------
  // funtions
  //----------------------------------------------------------------------------
  const loadMore = () => {
    fetchMore({ variables: {
      limit,
      offset: data.myDocuments.length
    }});
  }

  //----------------------------------------------------------------------------
  // component rendering
  //----------------------------------------------------------------------------
  if (loading) return <LinearProgress />;
  if (!data) return 'Network error.'

  return (
    <Container maxWidth="xl" className={classes.container}>
      <InfiniteScroll
        loadMore={loadMore}
        hasMore={data.myDocumentsCount > data.myDocuments.length}
        loader={<LinearProgress key="loading" />}
      >
        <Masonry columns={{sm: 1, md: 2, lg: 3, xl: 4}} spacing={2}>
          <DocAddCard />
          { data.myDocuments.map(doc=>(
            <DocCard key={doc._id} doc={doc} />
                // <DocumentCard docId={doc._id} />

            ))
          }
        </Masonry>
      </InfiniteScroll>
    </Container>
  );
}

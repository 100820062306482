import React from 'react';
import { Typography } from '@mui/material';

//******************************************************************************
// component definition
//******************************************************************************
export default function ItemTextView({itemText}) {

  //----------------------------------------------------------------------------
  // component rendering
  //----------------------------------------------------------------------------
  return (
    <React.Fragment>
      { itemText.split('\n').map((p, i)=>
        <Typography key={i}>
          {p}
        </Typography>
      )}
    </React.Fragment>
  );
}

import React from 'react';
import { useNavigate } from "react-router-dom";
import { Button, Dialog, DialogTitle,
  DialogContent, DialogContentText, DialogActions } from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import { useMutation } from "@apollo/client";
import { doRefetch } from '../../constants/cache';
import { DELETE_SPACE } from '../../constants/gqls/spaces';
import SiteMap from '../../constants/siteMap';

//******************************************************************************
// component definition
//******************************************************************************
export default function DeleteDialog({space, open, handleClose}) {
  const navigate = useNavigate();
  const [deleteSpace, { loading }] = useMutation(DELETE_SPACE);

  //----------------------------------------------------------------------------
  // funtions
  //----------------------------------------------------------------------------
  const onDeleteClick = () => {
    deleteSpace({ variables: {
      spaceId: space._id
    }}).then(res=>{
      // goto
      doRefetch(true);
      navigate(SiteMap.spaces, { replace: true });
    });
  }

  //----------------------------------------------------------------------------
  // component rendering
  //----------------------------------------------------------------------------
  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>
        Confirm Delete Space?
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          { space.docCount === 0 ?
            "A space only can be deleted when it doesn't own any documents. Click delete button to confirm delete."
            : "A space only can be deleted when it doesn't own any documents. Please transfer them to others or simply delete them."
          }
        </DialogContentText>
      </DialogContent>
      { space.docCount === 0 &&
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <LoadingButton variant="outlined"
            loading={loading} onClick={onDeleteClick}>
            Delete
          </LoadingButton>
        </DialogActions>
      }
    </Dialog>
  )
}

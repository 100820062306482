import React from 'react';
import { Grid, IconButton } from '@mui/material';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import ServiceFormSubSelect from './ServiceFormSubSelect';
import MeetingTimeInput from './MeetingTimeInput';
import PeopleInput from '../InputsCommt/PeopleInput';

//******************************************************************************
// component definition
//******************************************************************************
export default function PaticipantsPairSelect({refFormSubs, paticipantsPair, setProviders, setClientSubs, setMeetingTime, deletePair, loading}) {

  //----------------------------------------------------------------------------
  // component rendering
  //----------------------------------------------------------------------------
  if (paticipantsPair.serviceSession) {
    return (
      <p>
        Service session created: {paticipantsPair.serviceSession}
      </p>
    );
  }
  
  return (
    <Grid container spacing={2} justifyContent="center" alignItems="flex-end">
      <Grid item xs={6}>
        <PeopleInput multiple={true} 
          value={paticipantsPair.providers} onChange={setProviders}
          label="Service providers" disabled={loading} />
      </Grid>
      <Grid item xs={6}>
        <ServiceFormSubSelect refFormSubs={refFormSubs}
          onChange={setClientSubs} value={paticipantsPair.studentSub}
          disabled={loading} />
      </Grid>
      <Grid item xs={11}>
        <MeetingTimeInput value={paticipantsPair.meetingTime}
          onChange={setMeetingTime} loading={loading} />
      </Grid>
      <Grid item xs={1}>
        <IconButton onClick={deletePair} disabled={loading}>
          <DeleteOutlineIcon />
        </IconButton>
      </Grid>
    </Grid>
  );
}

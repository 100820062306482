import React from 'react';
import { Chip, Grid, Tooltip } from '@mui/material';
import { useQuery } from "@apollo/client";
import { SEARCH_PEOPLE } from '../../constants/gqls/user';


//******************************************************************************
// component definition
//******************************************************************************
export default function SelectStudentsDisabled({values}) {
  const { data } = useQuery(SEARCH_PEOPLE, { variables: {
    where: { _id_IN: values }
  }});
  // console.log(data);

  //----------------------------------------------------------------------------
  // component rendering
  //----------------------------------------------------------------------------
  if (!data || data.people.length===0) {
    return <div></div>
  }
  return (
    <Grid container spacing={1}>
      { data.people.map(student=>(
        <Grid item key={student._id}>
          <Tooltip title={student.email}>
            <Chip variant="outlined" label={student.name} />
          </Tooltip>
        </Grid>
      ))}
    </Grid>
  )
}

import React from 'react';
import { SpeedDial, SpeedDialAction, SpeedDialIcon } from '@mui/material';
import SettingsIcon from '@mui/icons-material/Settings';
import AddCardIcon from '@mui/icons-material/AddCard';
import DashboardIcon from '@mui/icons-material/Dashboard';
import { makeStyles } from '@material-ui/core/styles';
import SelectBoardDialog from '../BoardCards/SelectBoardDialog';
import AddCardDialog from '../BoardCards/AddCardDialog';

const actions = [
  { icon: <AddCardIcon />, name: 'Add card', open:'AddCard' },
  { icon: <DashboardIcon />, name: 'Select board', open:'SelectBoard' },
];

const getCardOptions = (boardScope) => {
  switch (boardScope) {
    case 'self':
      return [
        { name: 'My Tags', value: 'my-tags' },
        { name: 'Document Add', value: 'doc-add' },
        { name: 'My Documents', value: 'person-documents' },
        { name: 'My Profile', value: 'person-info' },
        { name: 'My Services', value: 'my-services' },
        { name: 'My Pages', value: 'my-pages' },
        { name: 'My Certificates', value: 'my-certificates' },
      ];
    case 'system':
      return [
        { name: 'My Profile', value: 'person-info' },
      ];
    case 'public':
      return [
        { name: 'My Profile', value: 'person-info' },
      ];
    default:
      return []
  }
}

//******************************************************************************
// style definition
//******************************************************************************
const useStyles = makeStyles((theme) => ({
  root: {
    "&.MuiSpeedDial-root": {
      // position: (props) => props.show ? 'relative' : 'fixed',
      position: 'fixed',
      bottom: theme.spacing(2),
      right: theme.spacing(2),
    //   borderRadius: '20px',
    //   height: (props) => props.show ? '200px' : '40px',
    //   width: (props) => props.show ? '200px' : '40px',
    //   transition: 'width 2s, height 2s'
    }
  }
}));

//******************************************************************************
// component definition
//******************************************************************************
export default function SettingBtn({personId, showBoard, setShowBoard, board, refetchQueries}) {
  const classes = useStyles();
  const [open, setOpen] = React.useState('');
  const cardOptions = getCardOptions(board?.scope);
  // console.log(cardOptions);

  //----------------------------------------------------------------------------
  // funtions
  //----------------------------------------------------------------------------
  const handleClose = () => {
    setOpen('');
  };

  const handleCLick = (inOpen) => {
    setOpen(inOpen);
  }

  //----------------------------------------------------------------------------
  // component rendering
  //----------------------------------------------------------------------------
  return (
    <React.Fragment>
      <SpeedDial className={classes.root}
        ariaLabel="Board setting"
        icon={<SpeedDialIcon icon={<SettingsIcon />} />}
      >
        {actions.map((action) => (
          <SpeedDialAction
            key={action.name}
            icon={action.icon}
            tooltipTitle={action.name}
            onClick={()=>handleCLick(action.open)}
          />
        ))}
      </SpeedDial>
      <AddCardDialog board={board} open={open==='AddCard'} handleClose={handleClose}
        cardOptions={cardOptions} refetchQueries={refetchQueries} />
      <SelectBoardDialog board={showBoard} setBoard={setShowBoard}
        open={open==='SelectBoard'} handleClose={handleClose}
        selfLabel="myself" />
    </React.Fragment>
  );
}

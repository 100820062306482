import React from 'react';
import { Button } from '@mui/material';
import InputImportFormDialog from './InputImportFormDialog';

//******************************************************************************
// component definition
//******************************************************************************
export default function InputImportFormBtn({importFormId, service, importType, loading, updateInput, importInputs}) {
  const [open, setOpen] = React.useState(false);
  // console.log(importFormId);

  //----------------------------------------------------------------------------
  // funtions
  //----------------------------------------------------------------------------
  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  //----------------------------------------------------------------------------
  // component rendering
  //----------------------------------------------------------------------------
  return (
    <React.Fragment>
      <Button onClick={handleOpen}>
        Service
      </Button>
      <InputImportFormDialog open={open} handleClose={handleClose}
        importFormId={importFormId}
        service={service}
        importType={importType}
        importInputs={importInputs}
        updateInput={updateInput}
        loading={loading}
        disableServiceFormUpdate={true} />
    </React.Fragment>
  )
}

import React from 'react';
import PropTypes from 'prop-types';
import { Drawer, Link } from '@mui/material';
import { useQuery } from "@apollo/client";
import { makeStyles } from '@material-ui/core/styles';
import Menu from './Menu';
import { ME } from '../../constants/gqls/user';

//******************************************************************************
// style definition
//******************************************************************************
const drawerWidth = '240px';

const useStyles = makeStyles((theme) => ({
  root: {
    width: props => props.open? drawerWidth : theme.spacing(7),
    flexShrink: 0,
    whiteSpace: 'nowrap',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    overflowX: 'hidden',
    '& .MuiPaper-root': {
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
      width: props => props.open? drawerWidth : theme.spacing(7),
      overflowX: 'hidden',
      boxShadow: '2px 0px 4px -1px rgba(0,0,0,0.2)',
      border: 'none'
    }
  },
  pc: {
    position: 'absolute',
    bottom: 0,
    left: 0,
    fontSize: '11px',
    paddingLeft: theme.spacing(1),
  }
}));

//******************************************************************************
// component definition
//******************************************************************************
function StyledDrawer(props) {
  const { open, ...other } = props;
  const classes = useStyles({open});
  return <Drawer className={classes.root} {...other} />;
}

function DrawerMenu({menuOpen, handleDrawer, window}) {
  const classes = useStyles();
  const { data } = useQuery(ME);
  const [sideOpen, setSideOpen] = React.useState(true);
  const container = (window !== undefined) ? () => window().document.body : undefined;
  const isAdmin = data?.me?.roles.includes('admin');

  //----------------------------------------------------------------------------
  // funtions
  //----------------------------------------------------------------------------
  const handleSideOpen = () => {
    setSideOpen(!sideOpen);
  };

  //----------------------------------------------------------------------------
  // component rendering
  //----------------------------------------------------------------------------
  return (
    <>
      <Drawer
        container={container}
        variant="temporary"
        open={menuOpen}
        onClose={handleDrawer}
        ModalProps={{
          keepMounted: true, // Better open performance on mobile.
        }}
        sx={{
          display: { xs: 'block', sm: 'none' },
          '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
        }}
      >
        <Menu xs={true}
          handleDrawer={handleDrawer}
          isAdmin={isAdmin} />
      </Drawer>
      <StyledDrawer variant="permanent" open={sideOpen} sx={{
        display: { xs: 'none', sm: 'block' }
      }}>
        <Menu open={sideOpen}
          handleDrawer={handleSideOpen}
          isAdmin={isAdmin} />
        <div className={classes.pc}>
          <p>
            <Link href="https://bupdpo.hkbu.edu.hk/policies-and-procedures/pps-pics/">
              Privacy Policy
            </Link >
            <br />
            © Copyright&nbsp;
            <Link href="https://lc.hkbu.edu.hk/">
              Language Centre
            </Link >
            ,&nbsp;
            <Link href="https://hkbu.edu.hk/">
            HKBU
            </Link >
          </p>
        </div>
      </StyledDrawer>
    </>
  );
}

DrawerMenu.propTypes = {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window: PropTypes.func,
};

export default DrawerMenu;

import React from 'react';
import { Skeleton  } from '@mui/material';
import { useQuery } from "@apollo/client";
import { ATTENDANCE_RATE } from '../../constants/gqls/services';

//******************************************************************************
// component definition
//******************************************************************************
export default function AttendanceRate({serviceId, personId}) {
  const { loading, data } = useQuery(ATTENDANCE_RATE, { variables: {
    serviceId, personId
  }});

  //----------------------------------------------------------------------------
  // component rendering
  //----------------------------------------------------------------------------
  if (loading) {
    return <Skeleton variant="text" />
  }

  if (data?.attendanceRate) {
    return (
      <span>
        Attendance rate:&nbsp;
        <strong>
          {data.attendanceRate.completedHours} / {data.attendanceRate.totalHours}
        </strong> 
        &nbsp;&nbsp; Percentage:&nbsp; 
        <strong>
        {(data.attendanceRate.completedHours*100/data.attendanceRate.totalHours).toFixed(2)}% 
        </strong> 
        &nbsp;&nbsp; Session closed:&nbsp; 
        <strong>
        {data.attendanceRate.closedCount}
        </strong>
      </span>
    );
  }
  return '';
}

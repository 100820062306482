import React from 'react';
import { useSearchParams } from "react-router-dom";
import { Accordion, AccordionSummary, Typography, AccordionDetails, Grid } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
// import { makeStyles } from '@material-ui/core/styles';
import { useQuery } from "@apollo/client";
import FileUpload from '../Files/FileUpload';
import FileDeleteBtn from '../Files/FileDeleteBtn';
import FormExportBtn from './FormExportBtn';
import { gql } from '@apollo/client';

const DOCUMENT = gql`
  query Documents($where: DocumentWhere) {
    documents(where: $where) {
      _id hasPdfTemplate pdfTemplate { _id uploader { _id name }}
    }
  }
`;

//******************************************************************************
// style definition
//******************************************************************************
// const useStyles = makeStyles((theme) => ({
//   row: {
//     paddingTop: theme.spacing(1)
//   },
//   dateTiem: {
//     minWidth: '50%'
//   },
//   actions: {
//     paddingTop: theme.spacing(1),
//     display: 'flex',
//     "& .MuiButton-root": {
//       margin: 'auto'
//     }
//   },
// }));

//******************************************************************************
// component definition
//******************************************************************************
export default function FormExport({docId, myRole}) {
  const [searchParams] = useSearchParams();
  // const classes = useStyles();
  const [profileId, setProfileId] = React.useState(null);
  const [open, setOpen] = React.useState(true);
  const { data, refetch } = useQuery(DOCUMENT, {
    variables: { where: { _id: docId } },
  });
  console.log(data);
  // console.log(Boolean(data?.documents[0]?.pdfTemplate));

  React.useEffect(() => {
    if (searchParams.get("profile")) {
      setProfileId(searchParams.get("profile"));
    } else {
      setProfileId(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParams]);

  //----------------------------------------------------------------------------
  // funtions
  //----------------------------------------------------------------------------
  const exportForm = () => {
    // TODO:
  }

  const handleClick = () => {
    setOpen(!open);
  };

  // const handleOTChange = (formOpen) => {
    
  // }

  //----------------------------------------------------------------------------
  // component rendering
  //----------------------------------------------------------------------------
  return (
    <Accordion expanded={open} onChange={handleClick}>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Typography>Export form</Typography>
      </AccordionSummary>
      <AccordionDetails>
        { (myRole === 'owner' || myRole === 'admin') &&
          <Grid container spacing={1} alignItems="center">
            <Grid item xs={12}>Template file: </Grid>
            <Grid item>
              <FileUpload hasFile={Boolean(data?.documents[0]?.pdfTemplate)}
                systemFileType="formTemplate"
                relatedId={docId}
                fileTypes={["text/html"]}
                refetch={refetch}>
                  Uploaded by {data?.documents[0]?.pdfTemplate?.uploader.name}
                </FileUpload>
            </Grid>
            <Grid item>
              <FileDeleteBtn fileId={data?.documents[0]?.pdfTemplate?._id} refetch={refetch} />
            </Grid>
          </Grid>
        }
        <div><p></p></div>
        <Grid container spacing={1} justifyContent="center" alignItems="center">
          <Grid item>
            <FormExportBtn formId={docId} 
              applicantId={profileId} 
              disabled={!data?.documents[0]?.hasPdfTemplate} />
          </Grid>
        </Grid>
      </AccordionDetails>
    </Accordion>
  );
}

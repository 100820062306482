import React from 'react';
import { Button } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import TagTypeForm from './TagTypeForm';

//******************************************************************************
// component definition
//******************************************************************************
function TagTypeAdd() {
  const [open, setOpen] = React.useState(false);

  //----------------------------------------------------------------------------
  // funtions
  //----------------------------------------------------------------------------
  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  //----------------------------------------------------------------------------
  // component rendering
  //----------------------------------------------------------------------------
  return (
    <React.Fragment>
      <Button variant="contained" color="secondary" startIcon={<AddIcon />}
        onClick={handleOpen}>
        New tag type
      </Button>
      <TagTypeForm open={open} onClose={handleClose} />
    </React.Fragment>
  );
}

export default TagTypeAdd;

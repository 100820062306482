import React from 'react';
import { Dialog, DialogTitle, Tabs, Tab } from '@mui/material';
import FormatValuesTab from './FormatValuesTab';
import FilterTab from './FilterTab';


//******************************************************************************
// component definition
//******************************************************************************
export default function ColumnDialog({column, setColumn, open, handleClose}) {
  const [value, setValue] = React.useState(0);
  const [tabs, setTabs] = React.useState([{ label: 'Filter' }]);

  React.useEffect(() => {
    if (column.type === 'value') {
      setTabs([{ label: 'Filter' }, { label: 'Format Values' }]);
    } else {
      setTabs([{ label: 'Filter' }]);
    }
  }, [column]);

  //----------------------------------------------------------------------------
  // funtions
  //----------------------------------------------------------------------------
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  //----------------------------------------------------------------------------
  // component rendering
  //----------------------------------------------------------------------------
  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>
        <Tabs value={value} onChange={handleChange}>
        { tabs.map((tab, i)=>(
            <Tab key={i} label={tab.label}/>
          ))}
        </Tabs>
      </DialogTitle>
      { tabs[value].label === 'Filter' &&
        <FilterTab column={column} setColumn={setColumn} />
      }
      { tabs[value].label === 'Format Values' &&
        <FormatValuesTab valueField={column.valueField} />
      }
    </Dialog>
  );
}

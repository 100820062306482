import React from 'react';
import { FormControl, FormLabel, FormControlLabel, RadioGroup, Radio } from '@mui/material';

//******************************************************************************
// component definition
//******************************************************************************
export default function SetFormNature({isProfileForm, isReportForm, onChange, loading}) {
  const [formNature, setFormNature] = React.useState('normalForm');

  React.useEffect(() => {
    if (isProfileForm) {
      setFormNature('profileForm');
    } else if (isReportForm) {
      setFormNature('reportForm');
    } else {
      setFormNature('normalForm')
    }
  }, [isProfileForm, isReportForm]);
  //----------------------------------------------------------------------------
  // funtions
  //----------------------------------------------------------------------------
  const handleChange = (event) => {
    switch (event.target.value) {
      case 'normalForm':
        onChange(false, false);
        break;
      case 'profileForm':
        onChange(true, false);
        break;
      case 'reportForm':
        onChange(false, true);
        break;
      default:
        break;
    }
  };

  //----------------------------------------------------------------------------
  // component rendering
  //----------------------------------------------------------------------------
  // return (
  //   <FormControlLabel labelPlacement="start" disabled={loading}
  //     control={<Switch checked={value} onChange={handleChange} />}
  //     label="Is this a profile form?"
  //   />
  // );

  return (
    <FormControl>
      <FormLabel id="form-nature">Form nature</FormLabel>
      <RadioGroup
        value={formNature}
        name="radio-buttons-group"
        onChange={handleChange}
      >
        <FormControlLabel value="normalForm" control={
          <Radio />
        } label="Normal form" />
        <FormControlLabel value="profileForm" control={
          <Radio />
        } label="Profile form" />
        <FormControlLabel value="reportForm" control={
          <Radio />
        } label="Report form" />
      </RadioGroup>
    </FormControl>
  );
}

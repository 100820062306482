import React from 'react';
import { generatePath } from "react-router-dom";
import { ListItemButton, ListItemText } from '@mui/material';
// import { makeStyles } from '@material-ui/core/styles';
import {DateToString} from '../../constants/utils';
import SiteMap from '../../constants/siteMap';

//******************************************************************************
// style definition
//******************************************************************************
// const useStyles = makeStyles((theme) => ({
// }));

//******************************************************************************
// component definition
//******************************************************************************
export default function MyCertificateItem({ cert }) {
  const href = generatePath(SiteMap.certCheck, { id: cert._id });

  //----------------------------------------------------------------------------
  // component rendering
  //----------------------------------------------------------------------------
  return (
    <ListItemButton component="a" href={href}>
      <ListItemText primary={`${cert.certType.name} (${DateToString(cert.createdAt)})`} />
    </ListItemButton>
  );
}

import React from 'react';
import { IconButton, Tooltip, Dialog, DialogTitle, CardContent, Button, Grid } from '@mui/material';
import SaveAltIcon from '@mui/icons-material/SaveAlt';
import { REQUEST_COURSES_IMPORT } from '../../constants/gqls/courses';
import UploadInput from '../InputsCommt/UploadInput';

//******************************************************************************
// component definition
//******************************************************************************
export default function CreateBtn() {
  const [open, setOpen] = React.useState(false);

  //----------------------------------------------------------------------------
  // funtions
  //----------------------------------------------------------------------------
  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  //----------------------------------------------------------------------------
  // component rendering
  //----------------------------------------------------------------------------
  return (
    <>
      <Tooltip title="Import courses">
        <IconButton onClick={handleOpen}>
          <SaveAltIcon />
        </IconButton>
      </Tooltip>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>
          Import Courses
        </DialogTitle>
        <CardContent>
          <h3>
            Instructions:
          </h3>
          <p>
            Data file should be in <strong>csv</strong> format. Here are data columns read by the system:
          </p>
          <ul>
            <li><strong>code</strong>: The course code. This is the key. Please make sure it's correct and MUST exist.</li>
            <li>name: course name</li>
            <li>section: 'Chinese', 'English', 'Putonghua/Mandarin' or 'Foreign Language' </li>
            <li>level: 'Undergraduate' or 'Postgraduate' </li>
            <li>nature: 'Core', 'Elective', 'General Education' or 'Supplementary' </li>
            <li>description </li>
          </ul>
          <Grid container alignItems="center" spacing={2}>
            <Grid item xs="auto">
              <h4>Template: </h4>
            </Grid>
            <Grid item xs="auto">
              <Button variant="outlined" href={process.env.REACT_APP_DOWNLOAD_URI+"courses_import.csv"}>
                courses_import.csv
              </Button>
            </Grid>
          </Grid>
          <UploadInput requestMutation={REQUEST_COURSES_IMPORT}
            apiName="requestCoursesImport"
            fileTypes={['text/csv']} />
        </CardContent>
      </Dialog>
    </>
  )
}

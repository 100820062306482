import React from 'react';
import InfiniteScroll from 'react-infinite-scroller';
import { Container, Paper, CardContent, List, LinearProgress } from '@mui/material';
import { makeStyles } from '@material-ui/core/styles';
import { useQuery } from "@apollo/client";
import SearchInput from '../components/InputsCommt/SearchInput';
import PeopleOrSpaceInput from '../components/InputsCommt/PeopleOrSpaceInput';
import PageAdd from '../components/Pages/PageAdd';
import PageItem from '../components/Pages/PageItem';
import { PAGES } from '../constants/gqls/pages';
const limit = 10;

//******************************************************************************
// style definition
//******************************************************************************
const useStyles = makeStyles((theme) => ({
  container: {
    paddingTop: theme.spacing(3)
  },
  addBox: {
    display: 'flex',
    '& button': {
      margin: 'auto',
    }
  }
}));

//******************************************************************************
// component definition
//******************************************************************************
export default function AdminPages() {
  const classes = useStyles();
  const [search, setSearch] = React.useState('');
  const [owner, setOwner] = React.useState(null);
  const [where, setWhere] = React.useState(null);
  const { loading, data, fetchMore } = useQuery(PAGES, { variables: {
    where, options: { limit, offset: 0, sort: { name: 'ASC' }},
  }});
  console.log(data);

  React.useEffect(() => {
    if (setWhere) {
      let match = `(?i).*${search.trim()}.*`;

      let where = {
        name_MATCHES: match
      };
      if (owner?.__typename === 'Person') {
        where.ownerPerson = { _id: owner._id }
      } else if (owner?.__typename === 'Space') {
        where.ownerSpace = { _id: owner._id }
      }

      setWhere(where);
    }
  }, [ setWhere, search, owner ]);

  //----------------------------------------------------------------------------
  // funtions
  //----------------------------------------------------------------------------
  const loadMore = () => {
    fetchMore({ variables: {
      offset: data.pages.length
    }});
  }

  //----------------------------------------------------------------------------
  // component rendering
  //----------------------------------------------------------------------------

  return (
    <Container maxWidth="xl" className={classes.container}>
      <Paper>
        <CardContent>
          <div className={classes.addBox}>
            <PageAdd />
          </div>
          <SearchInput value={search} onChange={setSearch} />
          <PeopleOrSpaceInput value={owner} onChange={setOwner} label="Owner" />
        </CardContent>
        { loading && <LinearProgress /> }
        { data &&
          <InfiniteScroll
            loadMore={loadMore}
            hasMore={data.pagesCount > data.pages.length}
            loader={<LinearProgress key="loading" />}
          >
            <List>
              { data.pages.map(page=>(
                <PageItem key={page._id} page={page} />
              ))}
            </List>
          </InfiniteScroll>
        }
      </Paper>
    </Container>
  );
}

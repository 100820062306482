import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Button, FormControl, Grid, InputLabel, MenuItem, Popover, Select } from '@mui/material';
import { useMutation } from "@apollo/client";
import { SORT_VALUE_SET } from '../../constants/gqls/dataValues';


//******************************************************************************
// style definition
//******************************************************************************
const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(1),
    width: '400px',
  },
}));

//******************************************************************************
// component definition
//******************************************************************************
export default function SortValueSets({anchorEl, onClose, data, valueFields}) {
  const classes = useStyles();
  const open = Boolean(anchorEl);
  const [valueFieldId, setValueFieldId] = React.useState('');
  const [asc, setAsc] = React.useState(true);
  const [sortValueSets, { loading }] = useMutation(SORT_VALUE_SET);

  //----------------------------------------------------------------------------
  // funtions
  //----------------------------------------------------------------------------
  const handleChange = (event) => {
    setValueFieldId(event.target.value);
  };

  const handleAscChange = (event) => {
    setAsc(!asc);
  };

  const handleSort = () => {
    sortValueSets({ variables: {
      dataId: data._id,
      by: valueFieldId,
      asc
    }}).then(res=>{
      onClose();
    });
  }

  //----------------------------------------------------------------------------
  // component rendering
  //----------------------------------------------------------------------------
  return (
    <Popover open={open} onClose={onClose}
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
      transformOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      disableRestoreFocus
    >
      <div className={classes.root}>
        <Grid container alignItems="center">
          <Grid item xs={8}>
            <FormControl size='small' fullWidth disabled={loading}>
              <InputLabel>Sort by</InputLabel>
              <Select label="Sort by"
                value={valueFieldId} onChange={handleChange}
              >
                { valueFields.map(vf=>(
                  <MenuItem key={vf._id} value={vf._id}>{vf.name}</MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={2}>
            <Button onClick={handleAscChange} disabled={loading}>
              {asc? "ASC" : "DESC"}
            </Button>
          </Grid>
          <Grid item xs={2}>
            <Button variant="outlined" onClick={handleSort}
              disabled={loading}>
              Sort
            </Button>
          </Grid>
        </Grid>
      </div>
    </Popover>
  );
}

import React from 'react';
import { Button, Dialog, DialogContent, IconButton } from '@mui/material';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import { makeStyles } from '@material-ui/core/styles';
import { useMutation } from "@apollo/client";
import ValueSet from './ValueSets/ValueSet';
import InputDataView from './InputDataView';
import { CREATE_VALUE_SET } from '../../constants/gqls/dataValues';
import { PROFILE_DATA } from '../../constants/gqls/forms';

//******************************************************************************
// style definition
//******************************************************************************
const useStyles = makeStyles((theme) => ({
  btnBox: {
    display: 'flex',
    '& button': {
      margin: 'auto'
    }
  },
  rMode: {
    backgroundColor: theme.palette.primary.pale,
    padding: theme.spacing(1),
    // paddingLeft: theme.spacing(2),
  },
  content: {
    '&.css-ypiqx9-MuiDialogContent-root': {
      overflow: 'hidden',
      width: '600px',
      maxWidth: '100%'
    }
  },
  slideBox: {
    display: 'flex',
    // whiteSpace: 'nowrap',
    transition: 'ease 600ms',
    // overflow: 'hidden',
    '& .slideItem': {
      // display: 'inline-block',
      minWidth: '100%',
      padding: '0 24px',
    },
  },
  slideBtn: {
    position: 'absolute',
    top: 0,
    height: '100%',
    display: 'flex',
    alignItems: 'center'
  },
  left: {
    left: 0
  },
  right: {
    right: 0
  }
}));


//******************************************************************************
// component definition
//******************************************************************************
export default function ValueSetInput({item, data, submissionId, profileId, setSubId, disabled}) {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [sortOpen, setSortOpen] = React.useState(false);
  const [index, setIndex] = React.useState(0);
  const [createValueSet, { loading }] = useMutation(CREATE_VALUE_SET, {
    refetchQueries: [ PROFILE_DATA ],
  });
  // const [value, setValue] = React.useState(data?.value || '');
  // console.log(data);

  // React.useEffect(() => {
  //   // console.log(data);
  //   setValue(data?.value || '');
  // }, [data]);

  //----------------------------------------------------------------------------
  // funtions
  //----------------------------------------------------------------------------
  const handleClickOpen = (i) => {
    if (!sortOpen) {
      setIndex(i)
      setOpen(true);
    }
  };

  const handleClose = () => {
    setOpen(false);
  };

  const goLeft = () => {
    if (index > 0) {
      setIndex(index-1);
    }
  };

  const goRight = () => {
    if (data && index < data.valueSetList.length) {
      setIndex(index+1);
    }
  };

  const handleCreate = () => {
    createValueSet({ variables: {
      itemId: item._id,
      dataId: data?._id,
      submissionId, profileId
    }}).then(res => {
      console.log(res);
      let newSubId = res?.data?.createValueSet.submission[0]?._id;
      setSubId(newSubId);
    })
  };

  //----------------------------------------------------------------------------
  // component rendering
  //----------------------------------------------------------------------------
 
  return (
    <div>
      <div className={classes.rMode} onClick={()=>handleClickOpen(0)}>
        <InputDataView valueFields={item.input.valueFields} 
          data={data} 
          openEdit={handleClickOpen}
          setSortOpen={setSortOpen} />
      </div>
      <Dialog open={open} onClose={handleClose}>
        <DialogContent className={classes.content}>
          <div className={classes.slideBox}
            style={{ transform: `translate3d(${-index * 100}%, 0, 0)` }}>
            { data? [...data.valueSetList].map((valueSet, i)=>(
              <ValueSet key={valueSet._id} 
                count={data.valueSetList.length} 
                index={i} setIndex={setIndex}
                valueSet={valueSet} disabled={disabled}
                valueFields={item.input.valueFields}
                dataId={data._id} />
            )) : ''}
            <div className={classes.btnBox + ' slideItem'}>
              <Button onClick={handleCreate} disabled={disabled || loading}>
                Add Item
              </Button>
            </div>
          </div>
          <div className={classes.slideBtn+' '+classes.left}>
            <IconButton onClick={goLeft} disabled={index===0}>
              <KeyboardArrowLeftIcon />
            </IconButton>
          </div>
          <div className={classes.slideBtn+' '+classes.right}>
            <IconButton onClick={goRight} disabled={!data || index===data.valueSetList.length}>
              <KeyboardArrowRightIcon />
            </IconButton>
          </div>
        </DialogContent>
      </Dialog>
    </div>
  );

  // return (
  //   <div>
  //     { data? [...data.valueSetList].map((valueSet, index)=>(
  //       <ValueSet key={valueSet._id} 
  //         count={data.valueSetList.length} index={index}
  //         valueSet={valueSet} disabled={disabled}
  //         valueFields={item.input.valueFields}
  //         dataId={data._id} />
  //     )) : ''}
  //     <div className={classes.btnBox}>
  //       <Button onClick={handleCreate} disabled={disabled || loading}>
  //         Add Item
  //       </Button>
  //     </div>
  //   </div>
  // )
}

import { gql } from '@apollo/client';
import { personSum } from './fragments';
const pageContent = `content { _id type title name }`;

export const PAGES = gql`
  query Pages($where: PageWhere, $options: PageOptions) {
    pages(where: $where, options: $options) {
      _id active name
      managers { _id name }
    }
    pagesCount(where: $where)
  }
`;

export const MY_PAGES = gql`
  query MyPages($offset: Int, $limit: Int) {
    myPages(offset: $offset, limit: $limit) {
      _id name active
    }
    myPagesCount
  }
`;

export const PAGE = gql`
  query Pages($where: PageWhere) {
    pages(where: $where) {
      _id active name role
      managers { ${personSum} }
      ${pageContent}
    }
  }
`;

export const PAGE_VIEW = gql`
  query Pages($where: PageWhere) {
    pages(where: $where) {
      _id active name role
      ${pageContent}
    }
  }
`;

export const PAGE_NAME_CHECK = gql`
  query PageNameCheck($name: String!) {
    pageNameCheck(name: $name)
  }
`;

export const CREATE_PAGES = gql`
  mutation CreatePages($input: [PageCreateInput!]!) {
    createPages(input: $input) {
      pages {
        _id name
      }
    }
  }
`;

export const UPDATE_PAGE = gql`
  mutation UpdatePage($id: ID!, $inUpdate: PageUpdateInput!) {
    updatePage(id: $id, inUpdate: $inUpdate) {
      pages {
        _id name active 
        managers { ${personSum}}
        ${pageContent}
      }
    }
  }
`;

import React from 'react';
import { useParams } from "react-router-dom";
import { IconButton, Tooltip } from '@mui/material';
// import { makeStyles } from '@material-ui/core/styles';
import { useMutation } from "@apollo/client";
import ItemIcon from './Icon';
import ItemFunctions from './ItemFunctions';
import { CREATE_ITEM } from '../../constants/gqls/items';
import { SORT_ITEMS } from '../../constants/gqls/documents';

//******************************************************************************
// style definition
//******************************************************************************
// const useStyles = makeStyles((theme) => ({
//   btn: {
//     '&.MuiButtonBase-root':{
//       border: props => props.readMode? 'solid 1px' : 'none'
//     }
//   },
// }));

//******************************************************************************
// component definition
//******************************************************************************
export default function FunctionBtn({item, index, count, editMode, setReadMode}) {
  // const classes = useStyles({readMode});
  const params = useParams();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const isProfileInput = Boolean(item.input?.profileKey);
  const [createItem] = useMutation(CREATE_ITEM);
  const [sortItems] = useMutation(SORT_ITEMS);
  // const timer = React.useRef(0);
  //
  // React.useEffect(() => {
  //   return () => {
  //     clearTimeout(timer.current);
  //   }
  // }, []);

  //----------------------------------------------------------------------------
  // funtions
  //----------------------------------------------------------------------------
  const onRightClick = (event) => {
    // clearTimeout(timer.current);
    if (editMode) {
      event.preventDefault();
      setAnchorEl(event.currentTarget);
    }
  }

  const onDoubleClick = (event) => {
    if (editMode) {
      // console.log(index);
      createItem({
        variables: {
          text: 'New item',
          docId: params.id,
          index
        }
      }).then(res=>{
        // console.log(res);
        sortItems({
          variables: {
            item: res.data.createItem._id, 
            index,
            docId: params.id,
          }
        });
      });
    }
  }

  // const onLeftClick = (event) => {
  //   if (item.input?.profileKey && !editMode) {
  //     setReadMode(!readMode);
  //   }
  // }

  // const onMouseDown = (event) => {
  //   if (item.editable && mode !== 'view') {
  //     let target = event.currentTarget;
  //     timer.current = setTimeout(()=>{
  //       setAnchorEl(target);
  //     }, 1000);
  //   }
  // }

  const handleClose = () => {
    setAnchorEl(null);
  }

  //----------------------------------------------------------------------------
  // component rendering
  //----------------------------------------------------------------------------
  return (
    <React.Fragment>
      <Tooltip title={isProfileInput? "Profile input" : item.type+" item"}>
        <IconButton 
          color={isProfileInput? "secondary" : "primary"}
          onContextMenu={onRightClick}
          onDoubleClick={onDoubleClick}>
          <ItemIcon type={item.type} link={item.link} logo={true} />
        </IconButton>
      </Tooltip>
      <ItemFunctions item={item} index={index} count={count}
        anchorEl={anchorEl} handleClose={handleClose} />
    </React.Fragment>
  );
}

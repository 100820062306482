import React from 'react';
import { useNavigate } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
// import bgpic from '../images/404.jpg';
import { Button, Card, CardContent, Container, Grid, Typography } from '@mui/material';
import FindInPageIcon from '@mui/icons-material/FindInPage';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import HomeIcon from '@mui/icons-material/Home';

//******************************************************************************
// style definition
//******************************************************************************
const useStyles = makeStyles((theme) => ({
  // bg: {
  //   background: `url(${bgpic})`,
  //   backgroundSize: 'cover',
  //   backgroundPosition: 'center',
  //   position: 'fixed',
  //   right: '0px',
  //   bottom: '0px',
  //   top: '0px',
  //   left: '0px',
  //   height: '100vh',
  // },
  container: {
    marginTop: '10%'
  },
  header: {
    color: theme.palette.primary.dark
  },
  icon: {
    "&.MuiSvgIcon-root": {
      height: '100%',
      width: '100%',
      color: theme.palette.primary.pale
    }
  }
}));

//******************************************************************************
// component definition
//******************************************************************************
export default function NotFount() {
  const classes = useStyles();
  const navigate = useNavigate();

  //----------------------------------------------------------------------------
  // funtions
  //----------------------------------------------------------------------------
  const goBack = () => {
    navigate(-1);
  }

  //----------------------------------------------------------------------------
  // component rendering
  //----------------------------------------------------------------------------
  return (
    <Container className={classes.container}>
      <Card>
        <CardContent>
          <Grid container spacing={2}>
            <Grid item xs={3}>
              <FindInPageIcon className={classes.icon} />
            </Grid>
            <Grid item xs={9}>
              <h1 className={classes.header}>Sorry...</h1>
              <Typography color="textSecondary">
                We can't find what you are looking for, or you don't have access to it.
              </Typography>
              <Typography color="textSecondary">
                You can go back or visit our home page.
              </Typography>
              <p></p>
              <Grid container spacing={2}>
                <Grid item>
                  <Button startIcon={<ArrowBackIcon />}
                    onClick={goBack} >
                    Back
                  </Button>
                </Grid>
                <Grid item>
                  <Button startIcon={<HomeIcon />} href='/'>
                    Home
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          
        </CardContent>
      </Card>
    </Container>
  );
}

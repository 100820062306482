import React from 'react';
import { FormControl, FormLabel, FormGroup, FormControlLabel, Checkbox, Grid } from '@mui/material';


function getTimeOption() {
  let options = [];
  for (var start = 8; start < 18; start++) {
    options.push(`${start}:00 ~ ${start+1}:00`);
    options.push(`${start}:30 ~ ${start+1}:30`);
  }
  return options;
}

const dayOptions = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
const timeOptions = getTimeOption();

//******************************************************************************
// component definition
//******************************************************************************
export default function WeeklyHourSetting({weeklyDayOptions, weeklyHourOptions, updateInput, loading}) {


  //----------------------------------------------------------------------------
  // funtions
  //----------------------------------------------------------------------------
  const handleDaysChange = (event) => {
    // console.log(event.target.name, event.target.checked);
    let newOptions;
    let oldOptions = weeklyDayOptions || [];
    if (event.target.checked) {
      newOptions = dayOptions.filter(opt=>(
        oldOptions.includes(opt) || opt === event.target.name
      ));
    } else {
      newOptions = dayOptions.filter(opt=>(
        oldOptions.includes(opt) && opt !== event.target.name
      ));
    }
    // console.log(newOptions);
    updateInput({
      weeklyDayOptions: newOptions
    });
  };

  const handleHoursChange = (event) => {
    let newOptions;
    let oldOptions = weeklyHourOptions || [];
    if (event.target.checked) {
      newOptions = timeOptions.filter(opt=>(
        oldOptions.includes(opt) || opt === event.target.name
      ));
    } else {
      newOptions = timeOptions.filter(opt=>(
        oldOptions.includes(opt) && opt !== event.target.name
      ));
    }
    // console.log(newOptions);
    updateInput({
      weeklyHourOptions: newOptions
    });
  };


  //----------------------------------------------------------------------------
  // component rendering
  //----------------------------------------------------------------------------
  return (

    <Grid container spacing={2}>
      <Grid item xs={4}>
        <FormControl component="fieldset" variant="standard">
          <FormLabel component="legend">Day options</FormLabel>
          <FormGroup>
            { dayOptions.map((option, i)=>
              <FormControlLabel key={i}
                control={
                  <Checkbox checked={weeklyDayOptions?.includes(option)}
                    onChange={handleDaysChange} name={option} />
                }
                label={option}
              />
            )}
          </FormGroup>
        </FormControl>
      </Grid>
      <Grid item xs={8}>
        <FormControl component="fieldset" variant="standard">
          <FormLabel component="legend">Hour options</FormLabel>
          <FormGroup>
            <Grid container>
              { timeOptions.map((option, i)=>
                <Grid item xs={6} key={i}>
                  <FormControlLabel
                    control={
                      <Checkbox checked={weeklyHourOptions?.includes(option)}
                        onChange={handleHoursChange} name={option} />
                    }
                    label={option}
                  />
                </Grid>
              )}
            </Grid>
          </FormGroup>
        </FormControl>
      </Grid>
    </Grid>
  )
}

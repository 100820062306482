import { gql } from '@apollo/client';
import { personSum, serviceSessionSum } from './fragments';


export const SERVICE_SESSIONS = gql`
  query SessionsOfService($serviceId: ID!, $offset: Int, $limit: Int) {
    sessionsOfService(serviceId: $serviceId, offset: $offset, limit: $limit) {
      ${serviceSessionSum}
    }
    sessionsOfServiceCount(serviceId: $serviceId)
  }
`;

export const SERVICE_SESSIONS_DATA = gql`
  query ServiceSessionsData($serviceId: ID!, $year: String!, $servicesWhere: ServiceWhere) {
    serviceSessionsByYear(serviceId: $serviceId, year: $year) {
      _id semester hoursCompleted hours status meetingTime
      providers { _id name email }
      users { _id name email }
      refFilterData {
        input { _id }
        options { _id text }
      }
    }
    services(where: $servicesWhere) {
      _id name myRole 
      serviceFormFilters(year: $year) { _id itemText }
    }
  }
`;

export const MY_SERVICE_SESSIONS = gql`
  query MyServiceSessions($serviceId: ID!, $offset: Int, $limit: Int) {
    myServiceSessions(serviceId: $serviceId, offset: $offset, limit: $limit) {
      ${serviceSessionSum}
    }
    myServiceSessionsCount(serviceId: $serviceId)
  }
`;


export const SERVICE_SESSION = gql`
  query ServiceSession($id: ID!) {
    serviceSession(id: $id) {
      _id createdAt year semester hours categories status myRole isProvider hoursCompleted confirmedByMe studentConfirmed removableStudentIds
      meetingTime location preTestResult postTestResult note canAddAttendance studentForms
      service { _id name key providerRoles }
      participantsConnection { edges { role node { ${personSum} }}}
    }
  }
`;

export const SERVICE_SESSION_YEARS = gql`
  query Query {
    serviceSessionsYears
  }
`;

export const UPDATE_SERVICE_SESSION = gql`
  mutation UpdateServiceSession($id: ID!, $inUpdate: ServiceSessionUpdateInput!) {
    updateServiceSession(id: $id, inUpdate: $inUpdate) {
      serviceSessions {
        _id status year semester note hours location preTestResult postTestResult meetingTime confirmedByMe canAddAttendance
        participantsConnection { edges { role node { ${personSum} }}}
      }
    }
  }
`;

export const DELETE_SERVICE_SESSION = gql`
  mutation DeleteServiceSession($id: ID!) {
    deleteServiceSession(id: $id)
  }
`;

// export const CREATE_SERVICE_SESSION = gql`
//   mutation CreateServiceSession($service: ID!, $teacher: ID!, $studentSub: ID!, $meetingTime: String!, $status: ServiceSessionStatus!) {
//     createServiceSession(service: $service, teacher: $teacher, studentSub: $studentSub, meetingTime: $meetingTime, status: $status) {
//       serviceSessions {
//         _id
//       }
//     }
//   }
// `;

export const CREATE_SERVICE_SESSION = gql`
  mutation CreateServiceSession($serviceId: ID!, $providerIds: [ID!]!, $clientSubIds: [ID!]!, $meetingTime: String!, $status: ServiceSessionStatus!) {
    createServiceSession(serviceId: $serviceId, providerIds: $providerIds, clientSubIds: $clientSubIds, meetingTime: $meetingTime, status: $status) {
      _id
    }
  }
`;

import React from 'react';
import { useNavigate } from "react-router-dom";
import { Button, Dialog, DialogTitle,
  DialogContent, DialogContentText, DialogActions } from '@mui/material';
// import LoadingButton from '@mui/lab/LoadingButton';
import { useMutation } from "@apollo/client";
import { DELETE_SERVICE_SESSION } from '../../constants/gqls/serviceSessions';
// import SiteMap from '../../constants/siteMap';

//******************************************************************************
// component definition
//******************************************************************************
export default function DeleteBtn({serviceSessionId}) {
  const navigate = useNavigate();
  const [open, setOpen] = React.useState(false);
  const [deleteServiceSession, { loading }] = useMutation(DELETE_SERVICE_SESSION);

  //----------------------------------------------------------------------------
  // funtions
  //----------------------------------------------------------------------------
  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const onDeleteClick = () => {
    deleteServiceSession({ variables: {
      id: serviceSessionId
    }}).then(res=>{
      // goto
      navigate(-1, { replace: true });
    });
  }

  //----------------------------------------------------------------------------
  // component rendering
  //----------------------------------------------------------------------------
  return (
    <React.Fragment>
      <Button onClick={handleOpen}>
        Delete
      </Button>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>
          Confirm Delete?
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            By deleting this service session, student application will be released to the waiting list.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button variant="outlined"
            disabled={loading} onClick={onDeleteClick}>
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  )
}

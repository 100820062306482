import React from 'react';
import { Button } from '@mui/material';

//******************************************************************************
// component definition
//******************************************************************************
export default function ConfirmBtn({handleUpdate, loading}) {

  //----------------------------------------------------------------------------
  // funtions
  //----------------------------------------------------------------------------
  const handleConfirm = () => {
    handleUpdate({
      status: 'Confirmed'
    }).then(res=>{
      console.log(res);
    });
  }

  //----------------------------------------------------------------------------
  // component rendering
  //----------------------------------------------------------------------------
  return (
    <Button variant="contained" disabled={loading} onClick={handleConfirm}>
      Confirm
    </Button>
  )
}

import React from 'react';
import { TextField } from '@mui/material';

//******************************************************************************
// component definition
//******************************************************************************
export default function ActionPromptText({promptTemplate, handleUpdate, loading}) {
  const [value, setValue] = React.useState(promptTemplate || '');
  const [changed, setChanged] = React.useState(false);


  //----------------------------------------------------------------------------
  // funtions
  //----------------------------------------------------------------------------
  const handleChange = (event) => {
    setValue(event.target.value)
    setChanged(true);
  }

  const handleBlur = (event) => {
    if (changed) {
      handleUpdate({ promptTemplate: value }).then(res => {
        setChanged(false);
      });;
    }
  };

  //----------------------------------------------------------------------------
  // component rendering
  //----------------------------------------------------------------------------
  return (
    <TextField id="action-prompt-text" label="Prompt text" size="small"
      variant="outlined" fullWidth multiline rows={4} disabled={loading}
      value={value} onChange={handleChange} onBlur={handleBlur} />
  );
}

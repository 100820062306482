import React from 'react';
import { Skeleton, IconButton } from '@mui/material';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import { useApolloClient, useQuery, useMutation } from "@apollo/client";
import UserMenu from './UserMenu';
import { isAuthVar } from '../../constants/cache';
import { ME, REFRESH_TOKEN } from '../../constants/gqls/user';

//******************************************************************************
// component definition
//******************************************************************************
export default function UserActions() {
  const client = useApolloClient();
  const [refreshToken] = useMutation(REFRESH_TOKEN);
  const { loading, data } = useQuery(ME);
  const [open, setOpen] = React.useState(false);
  const exp = localStorage.getItem('exp');
  // console.log(data);

  React.useEffect(() => {
    let timeLeft = exp - Date.now();
    // console.log(timeLeft);
    // console.log(timeLeft / (1000*60*60*24));

    if (timeLeft > 0 && (timeLeft / (1000*60*60*24)) < 1) {
      refreshToken().then(res => {
        // console.log(res.data.refreshToken);
        localStorage.setItem('exp', res.data.refreshToken.exp);
        localStorage.setItem('loginToken', res.data.refreshToken.token);
        // client.close(false);
      }, err => {
        console.log(err.message);
      });
    }
  }, [exp, refreshToken, client]);

  //----------------------------------------------------------------------------
  // funtions
  //----------------------------------------------------------------------------
  const handleOpen = () => {
    setOpen(true);
  }

  const handleClose = () => {
    setOpen(false);
  }

  //----------------------------------------------------------------------------
  // component rendering
  //----------------------------------------------------------------------------
  if (loading) return <Skeleton variant="circular" />
  if (!data) {
    console.log('Network error...');
    return '';
  };
  if (!data.me) {
    console.log('tokent expired...');
    isAuthVar(false);
    return '';
  }

  return (
    <ClickAwayListener onClickAway={handleClose}>
      <div>
        <IconButton onClick={handleOpen}>
          <AccountCircleIcon />
        </IconButton>
        <UserMenu open={open} me={data.me} />
      </div>
    </ClickAwayListener>
  );
}

import React from 'react';
import { IconButton, ClickAwayListener } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import SettingBtn from './SettingBtn';
import DeleteBtn from './DeleteBtn';

//******************************************************************************
// component definition
//******************************************************************************

export default function CardActions({edit, setEdit, card, board, refetchQueries}) {
  const [open, setOpen] = React.useState(false);
  const [prevent, setPrevent] = React.useState(false);

  //----------------------------------------------------------------------------
  // funtions
  //----------------------------------------------------------------------------
  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    if (!prevent) {
      setOpen(false);
    }
  };

  const handleEditClick = () => {
    setEdit(!edit);
  };

  //----------------------------------------------------------------------------
  // component rendering
  //----------------------------------------------------------------------------
  return (edit?
    <IconButton color="primary" onClick={handleEditClick}>
      <EditIcon />
    </IconButton>
    : open ?
    <ClickAwayListener onClickAway={handleClose}>
      <div>
        <DeleteBtn card={card} boardId={board._id} refetchQueries={refetchQueries} />
        <SettingBtn card={card} setPrevent={setPrevent} board={board} refetchQueries={refetchQueries} />
        { setEdit &&
          <IconButton onClick={handleEditClick}>
            <EditIcon />
          </IconButton>
        }
      </div>
    </ClickAwayListener>
    :
    <IconButton onClick={handleOpen}>
      <MoreVertIcon />
    </IconButton>
  );
}

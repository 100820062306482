import React from 'react';
import { Grid } from '@mui/material';
import SearchInput from '../InputsCommt/SearchInput';
// import PeopleInput from '../InputsCommt/PeopleInput';
import DateTimeInput from '../InputsCommt/DateTimeInput';

//******************************************************************************
// component definition
//******************************************************************************
export default function UsersListFilter({setWhere, tabWhere}) {
  const [search, setSearch] = React.useState('');
  const [createdFrom, setCreatedFrom] = React.useState(null);
  const [createdTill, setCreatedTill] = React.useState(null);
  const [lastLoginFrom, setLastLoginFrom] = React.useState(null);
  const [lastLoginTill, setLastLoginTill] = React.useState(null);

  React.useEffect(() => {
    if (setWhere) {
      let where = tabWhere? {...tabWhere} : {};
      let match = `(?i).*${search.trim()}.*`;
      where.OR = [{
        name_MATCHES: match
      }, {
        email_MATCHES: match
      }];

      if (createdFrom) {
        where.createdAt_GTE = createdFrom;
      }
      if (createdTill) {
        where.createdAt_LTE = createdTill;
      }
      if (lastLoginFrom) {
        where.lastLogin_GTE = lastLoginFrom;
      }
      if (lastLoginTill) {
        where.lastLogin_LTE = lastLoginTill;
      }

      setWhere(where);
      // console.log(where);
    }
  }, [
    setWhere, tabWhere, search,
    createdFrom, createdTill, lastLoginFrom, lastLoginTill
  ]);

  //----------------------------------------------------------------------------
  // funtions
  //----------------------------------------------------------------------------

  //----------------------------------------------------------------------------
  // component rendering
  //----------------------------------------------------------------------------
  return (
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <SearchInput value={search} onChange={setSearch} />
        </Grid>
        <Grid item xs={3}>
          <DateTimeInput id="tag-created-from" label="Created from"
            value={createdFrom} onChange={setCreatedFrom} />
        </Grid>
        <Grid item xs={3}>
          <DateTimeInput id="tag-created-till" label="Created till"
            value={createdTill} onChange={setCreatedTill} />
        </Grid>
        <Grid item xs={3}>
          <DateTimeInput id="tag-updated-from" label="Last login from"
            value={lastLoginFrom} onChange={setLastLoginFrom} />
        </Grid>
        <Grid item xs={3}>
          <DateTimeInput id="tag-updated-till" label="Last login till"
            value={lastLoginTill} onChange={setLastLoginTill} />
        </Grid>
      </Grid>
  );
}

import React from 'react';
import { generatePath } from "react-router-dom";
import { CardHeader, CardContent, Typography } from '@mui/material';
import PersonIcon from '@mui/icons-material/Person';
import { makeStyles } from '@material-ui/core/styles';
import CardComm from './CardComm';
import SiteMap from '../../constants/siteMap';

//******************************************************************************
// style definition
//******************************************************************************
const useStyles = makeStyles((theme) => ({
  content: {
    "&.MuiCardContent-root": {
      paddingTop: 0
    }
  }
}));

//******************************************************************************
// component definition
//******************************************************************************
export default function PersonCard({person, setSignal}) {
  const classes = useStyles();
  const goTo = generatePath(SiteMap.person, { id: person._id });

  React.useEffect(() => {
    if (setSignal) {
      setSignal(`${person._id} loaded`);
    }
  }, [person, setSignal]);

  //----------------------------------------------------------------------------
  // component rendering
  //----------------------------------------------------------------------------
  // if (loading) return <LinearProgress />;
  // if (!data) return 'Network error.'

  return (
    <CardComm tags={person.tags}
      icon={<PersonIcon />} goTo={goTo}>
      <CardHeader title={person.name} />
      <CardContent className={classes.content}>
        <Typography>
          Email: {person.email}
        </Typography>
        <Typography>
          Roles: {person.roles.join(', ')}
        </Typography>
      </CardContent>
    </CardComm>
  );
}

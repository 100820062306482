import React from 'react';
import { Button, Dialog, DialogContent, IconButton } from '@mui/material';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import { makeStyles } from '@material-ui/core/styles';
import { useMutation } from "@apollo/client";
import ValueSet from './ValueSets/ValueSet';
import InputDataView from './InputDataView';
import { ADD_VALUE_SET, REMOVE_VALUE_SET } from '../../constants/gqls/dataValues';
import { PROFILE_DATA } from '../../constants/gqls/forms';
import { valueSetSelectedRefetch } from '../../constants/cache';

//******************************************************************************
// style definition
//******************************************************************************
const useStyles = makeStyles((theme) => ({
  btnBox: {
    display: 'flex',
    '& button': {
      margin: 'auto'
    }
  },
  rMode: {
    position: 'relative',
    backgroundColor: theme.palette.primary.pale,
    padding: theme.spacing(1),
    // paddingLeft: theme.spacing(2),
  },
  content: {
    '&.css-ypiqx9-MuiDialogContent-root': {
      overflow: 'hidden',
      width: '600px',
      maxWidth: '100%'
    }
  },
  slideBox: {
    display: 'flex',
    // whiteSpace: 'nowrap',
    transition: 'ease 600ms',
    // overflow: 'hidden',
    '& .slideItem': {
      // display: 'inline-block',
      minWidth: '100%',
      padding: '0 24px',
    },
  },
  slideBtn: {
    position: 'absolute',
    top: 0,
    height: '100%',
    display: 'flex',
    alignItems: 'center'
  },
  left: {
    left: 0
  },
  right: {
    right: 0
  }
}));


//******************************************************************************
// component definition
//******************************************************************************
export default function ValueSetsSelectedInput({item, data, disabled}) {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [sortOpen, setSortOpen] = React.useState(false);
  const [index, setIndex] = React.useState(0);
  const [addValueSet, { loading: addLoading }] = useMutation(ADD_VALUE_SET, {
    refetchQueries: [ PROFILE_DATA ],
  });
  const [removeValueSet, { loading: removeLoading }] = useMutation(REMOVE_VALUE_SET);

  //----------------------------------------------------------------------------
  // funtions
  //----------------------------------------------------------------------------
  const handleClickOpen = (_, vsId) => {
    if (!sortOpen) {
      if (data?.getSelectedData && data.getSelectedData.valueSetList.length > 0) {
        if (vsId) {
          let vsIndex = 0;
          // search for index in value set data list
          for (let i = 0; i < data.getValueSetData.valueSetList.length; i++) {
            let vs = data.getValueSetData.valueSetList[i];
            if (vs._id === vsId) {
              vsIndex = i;
            }
          }
          setIndex(vsIndex);
          setOpen(true);
        }
      } else {
        setIndex(0);
        setOpen(true);
      }
    }
  };

  const handleClose = () => {
    setOpen(false);
  };

  const goLeft = () => {
    if (index > 0) {
      setIndex(index-1);
    }
  };

  const goRight = () => {
    if (data?.getValueSetData?.valueSetList && index < data.getValueSetData.valueSetList.length) {
      setIndex(index+1);
    }
  };

  const handleAdd = (valueSetId) => {
    addValueSet({ variables: {
      inputId: item.input._id,
      valueSetId
    }}).then(res => {
      // console.log(res);
      if ((!data?.getSelectedData) || (!data?.getValueSetData)) {
        console.log(item.input._id);
        valueSetSelectedRefetch(item.input._id);
      }
    })
  };

  const handleRemove = (valueSetId) => {
    removeValueSet({ variables: {
      inputId: item.input._id,
      valueSetId
    }}).then(res => {
      // console.log(res);
    })
  };

  //----------------------------------------------------------------------------
  // component rendering
  //----------------------------------------------------------------------------
 
  return (
    <div>
      <div className={classes.rMode} onClick={()=>handleClickOpen()}>
        <InputDataView valueFields={item.input.valueSetInput?.valueFields || []} 
          data={data?.getSelectedData} 
          openEdit={handleClickOpen}
          setSortOpen={setSortOpen} />
      </div>
      <Dialog open={open} onClose={handleClose}>
        <DialogContent className={classes.content}>
          <div className={classes.slideBox}
            style={{ transform: `translate3d(${-index * 100}%, 0, 0)` }}>
            { data?.getValueSetData? [...data.getValueSetData.valueSetList].map((valueSet, i)=>(
              <ValueSet key={valueSet._id} 
                count={data.getValueSetData.valueSetList.length} 
                index={i} setIndex={setIndex}
                valueSet={valueSet} disabled={disabled || addLoading || removeLoading}
                valueFields={item.input.valueSetInput?.valueFields || []}
                dataId={data.getValueSetData._id}
                addValueSet={handleAdd} removeValueSet={handleRemove}
                selected={data.getSelectedData?.valueSetList.map(vs=>vs._id) || []} />
            )) : ''}
            <div className={classes.btnBox + ' slideItem'}>
              <Button onClick={()=>handleAdd()} disabled={disabled || addLoading || removeLoading}>
                Add Item
              </Button>
            </div>
          </div>
          <div className={classes.slideBtn+' '+classes.left}>
            <IconButton onClick={goLeft} disabled={index===0}>
              <KeyboardArrowLeftIcon />
            </IconButton>
          </div>
          <div className={classes.slideBtn+' '+classes.right}>
            <IconButton onClick={goRight} disabled={!data?.getValueSetData || index===data.getValueSetData.valueSetList.length}>
              <KeyboardArrowRightIcon />
            </IconButton>
          </div>
        </DialogContent>
      </Dialog>
    </div>
  );

  // return (
  //   <div>
  //     { data? [...data.valueSetList].map((valueSet, index)=>(
  //       <ValueSet key={valueSet._id} 
  //         count={data.valueSetList.length} index={index}
  //         valueSet={valueSet} disabled={disabled}
  //         valueFields={item.input.valueFields}
  //         dataId={data._id} />
  //     )) : ''}
  //     <div className={classes.btnBox}>
  //       <Button onClick={handleCreate} disabled={disabled || loading}>
  //         Add Item
  //       </Button>
  //     </div>
  //   </div>
  // )
}

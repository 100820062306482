import React from 'react';
import InfiniteScroll from 'react-infinite-scroller';
import { List, CircularProgress } from '@mui/material';
import { useQuery } from "@apollo/client";
import UserItem from './UserItem';
import { PEOPLE } from '../../constants/gqls/user';
const limit = 10;

//******************************************************************************
// component definition
//******************************************************************************
function UsersList({ where }) {
  const { loading, data, fetchMore } = useQuery(PEOPLE, { variables: { where,
    options: { limit, offset: 0 }
  }});

  //----------------------------------------------------------------------------
  // funtions
  //----------------------------------------------------------------------------
  const loadMore = () => {
    fetchMore({ variables: {
      options: { limit, offset: data.people.length }
    }});
  }

  //----------------------------------------------------------------------------
  // component rendering
  //----------------------------------------------------------------------------
  if (loading) return 'Loading...';
  if (!data) return 'Network error.'
  if (data.peopleCount === 0) {
    return '**Empty list**'
  }

  return (
    <InfiniteScroll
        loadMore={loadMore}
        hasMore={data.peopleCount > data.people.length}
        loader={<CircularProgress key="loading" />}
    >
      <List>
        { data.people.map(user=>(
          <UserItem key={user._id} user={user} />
        )) }
      </List>
    </InfiniteScroll>
  );
}

export default UsersList;

import React from 'react';
// import { Card, CardHeader, CardContent, IconButton, Typography } from '@mui/material';
import { useQuery } from "@apollo/client";
import Card from '../BoardCards/Card';
import { ME } from '../../constants/gqls/user';


function getBoard(boards, role, me, showBoard) {
  function getBoardByScope(scope) {
    return boards.filter(item=>item.scope===scope)[0];
  }

  if (showBoard) {
    return getBoardByScope(showBoard);
  }

  if (role === 'Manager' || role === 'Member') {
    return getBoardByScope('self');
  }
  if (me) {
    return getBoardByScope('system');
  }
  return getBoardByScope('public');
}

//******************************************************************************
// component definition
//******************************************************************************
export default function Board({space, showBoard, setBoard, refetchQueries}) {
  // const classes = useStyles();
  const { data } = useQuery(ME);
  const board = data? getBoard(space.boards, space.myRole, data.me, showBoard) : null;
  // console.log(space.boards);

  React.useEffect(() => {
    if (board) {
      setBoard(board)
    }
  }, [board, setBoard]);

  //----------------------------------------------------------------------------
  // funtions
  //----------------------------------------------------------------------------

  //----------------------------------------------------------------------------
  // component rendering
  //----------------------------------------------------------------------------
  return (
    <React.Fragment>
      {board && board.cards.map(card=>(
        <Card key={card._id} space={space} card={card} board={board} refetchQueries={refetchQueries} />
      ))}
    </React.Fragment>
  );
}

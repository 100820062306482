import React from 'react';
import { Autocomplete, TextField, Grid } from '@mui/material';

const types = ['Equals', 'Within range'];

//******************************************************************************
// component definition
//******************************************************************************
export default function FilterNumber({matchType, setMatchType, num1, num2, setNum1, setNum2}) {

  //----------------------------------------------------------------------------
  // funtions
  //----------------------------------------------------------------------------
  const handleTypeChange = (event, newValue) => {
    setMatchType(newValue);
  };

  const handleNum1Change = (event) => {
    setNum1(event.target.value);
  }

  const handleNum2Change = (event) => {
    setNum2(event.target.value);
  }

  //----------------------------------------------------------------------------
  // component rendering
  //----------------------------------------------------------------------------
  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Autocomplete id="match-type"
          options={types}
          value={matchType} onChange={handleTypeChange}
          renderInput={(params)=>
            <TextField {...params} label="Match type" />
          }
        />
      </Grid>
      <Grid item xs={matchType==='Within range'? 6 : 12}>
        <TextField id="num1" 
          variant="outlined" fullWidth type="number"
          value={num1} onChange={handleNum1Change} />
      </Grid>
      { matchType === 'Within range' && 
        <Grid item xs={6}>
          <TextField id="num2" 
            variant="outlined" fullWidth type="number"
            value={num2} onChange={handleNum2Change} />
        </Grid>}
    </Grid>
  );
}

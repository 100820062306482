import React from 'react';
import { IconButton, Tooltip } from '@mui/material';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';

//******************************************************************************
// component definition
//******************************************************************************
export default function DownBtn({itemId, index, sortItem}) {

  //----------------------------------------------------------------------------
  // funtions
  //----------------------------------------------------------------------------
  const handleClick = () => {
    sortItem(itemId, index+1).then(res=>{
      // console.log(res);
    });
  }

  //----------------------------------------------------------------------------
  // component rendering
  //----------------------------------------------------------------------------
  return (
    <Tooltip title="Move down" placement="left">
      <IconButton onClick={handleClick}>
        <ArrowDownwardIcon />
      </IconButton>
    </Tooltip>
  )
}

import React from 'react';
import { Button, Popover, IconButton } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import { makeStyles } from '@material-ui/core/styles';
import { useMutation } from "@apollo/client";
import { CLICK_TAG } from '../constants/gqls/tags';

//******************************************************************************
// style definition
//******************************************************************************
const useStyles = makeStyles((theme) => ({
  tag: {
    "&.MuiButton-contained": {
      color: props=>props.textColor,
      backgroundColor: props=>props.bgColor,
      borderRadius: '16px'
    },
    "&.MuiButton-contained:hover": {
      backgroundColor: props=>props.bgColor,
    }
  },
  delete: {
    "& .MuiPaper-root": {
      borderRadius: '20px'
    }
  }
}));

//******************************************************************************
// component definition
//******************************************************************************
export default function Tag({type, onTagClick, text, tagId, removeTag, loading}) {
  const classes = useStyles(type || {
    bgColor: "#fff",
    textColor: "#000"
  });
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const [clickTag] = useMutation(CLICK_TAG);
  // console.log({textColor, bgColor});


  //----------------------------------------------------------------------------
  // funtions
  //----------------------------------------------------------------------------
  const onClick = () => {
    if (onTagClick && tagId && !open) {
      onTagClick(tagId);
      clickTag({ variables: { tagId: tagId }});
    }
  }

  const onRightClick = (event) => {
    if (removeTag) {
      event.preventDefault();
      setAnchorEl(event.currentTarget);
    }
  }

  const onDeleteClick = () => {
    removeTag(tagId);
  }

  const handleClose = () => {
    setAnchorEl(null);
  }

  //----------------------------------------------------------------------------
  // component rendering
  //----------------------------------------------------------------------------
  return (
    <React.Fragment>
      <Button size="small" variant="contained" className={classes.tag}
        onClick={onClick} onContextMenu={onRightClick}>
        {text}
      </Button>
      <Popover open={open} anchorEl={anchorEl} onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
         vertical: 'top',
         horizontal: 'center',
       }} className={classes.delete}>
        <IconButton onClick={onDeleteClick} disabled={loading}>
          <DeleteIcon />
        </IconButton>
      </Popover>
    </React.Fragment>
  )
}

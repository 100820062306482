import React from 'react';
import { useParams } from "react-router-dom";
import { LinearProgress, Container, Paper, CardHeader, Button } from '@mui/material';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { makeStyles } from '@material-ui/core/styles';
import { useQuery } from "@apollo/client";
import { DATA_TABLE_DETAILS } from '../constants/gqls/dataAnalysis';
import CreateOrEdit from '../components/DataAnalysis/CreateOrEdit';
import DataView from '../components/DataAnalysis/DataView';

//******************************************************************************
// style definition
//******************************************************************************
const useStyles = makeStyles((theme) => ({
  container: {
    paddingTop: theme.spacing(3)
  },
}));

//******************************************************************************
// component definition
//******************************************************************************
export default function DataAnalysisTable() {
  const classes = useStyles();
  const params = useParams();
  const [mode, setMode] = React.useState('edit');
  const [lockView, setLockView] = React.useState(true);
  const { loading, data, refetch } = useQuery(DATA_TABLE_DETAILS, { variables: { 
    where: { _id: params.id }
  }});
  // console.log(data);

  React.useEffect(() => {
    if (data) {
      setLockView(!data.dataTables[0].queryFields || data.dataTables[0].dataTableColumns.length===0)
    }
  }, [data]);

  //----------------------------------------------------------------------------
  // funtions
  //----------------------------------------------------------------------------
  const setEditMode = () => {
    setMode('edit');
  }

  const setViewMode = () => {
    setMode('view');
  }


  //----------------------------------------------------------------------------
  // component rendering
  //----------------------------------------------------------------------------
  if (loading) return <LinearProgress />;
  if (!data) return 'Network error.'

  return (
    <Container maxWidth="xl" className={classes.container}>
      { mode==='edit' && 
        <Paper>
          <CardHeader title={data.dataTables[0].name} 
            action={
              <Button variant="contained" endIcon={<ArrowForwardIosIcon />}
                onClick={setViewMode} disabled={lockView}>
                View data
              </Button>
            } />
          <CreateOrEdit dataTable={data.dataTables[0]} refetch={refetch} />
        </Paper>
      }
      { mode==='view' &&
        <Paper>
          <DataView dataTable={data.dataTables[0]} setEditMode={setEditMode} />
        </Paper>
      }
    </Container>
  );
}

import React from 'react';
import { TextField } from '@mui/material';
import { makeStyles } from '@material-ui/core/styles';
import ValueFieldAutoComplete from './ValueFieldAutoComplete';
import ValueFieldDate from './ValueFieldDate';
import { isTypeAutoComplete } from '../../../constants/utils'

//******************************************************************************
// style definition
//******************************************************************************
const useStyles = makeStyles((theme) => ({
  fieldBox: {
    paddingBottom: theme.spacing(1)
  },
}));

//******************************************************************************
// component definition
//******************************************************************************
export default function ValueFieldInput({value, valueField, disabled, update}) {
  const classes = useStyles();
  const [text, setText] = React.useState(value?.text || '');
  const [rows, setRows] = React.useState(1);
  const ref = React.useRef(null);
  const timer = React.useRef(0);
  // console.log(valueField);

  React.useEffect(() => {
    if (valueField.multiline) {
      let rowNum = 4;
      if (valueField.maxTextLength) {
        rowNum = Math.ceil(valueField.maxTextLength / 35);
        if (rowNum < 2) {
          rowNum = 2;
        } else if (rowNum > 12) {
          rowNum = 12;
        }
      }
      setRows(rowNum);
    }
  }, [valueField]);

  React.useEffect(() => {
    setText(value?.text || '')
  }, [value]);

  React.useEffect(() => {
    return () => {
      clearTimeout(timer.current);
    }
  }, []);

  //----------------------------------------------------------------------------
  // funtions
  //----------------------------------------------------------------------------
  const handleChange = (event) => {
    // console.log(valueField.maxTextLength, event.target.value.length);
    if (valueField.maxTextLength && event.target.value.length > valueField.maxTextLength) {
      return;
    }
    if (!valueField.multiline && event.target.value.length > 1000) {
      return;
    }
    setText(event.target.value);
    clearTimeout(timer.current);
    timer.current = setTimeout(()=>{
      update(valueField._id, event.target.value).then(res=>{
        ref.current.focus();
      });
    }, 1000);
  };

  //----------------------------------------------------------------------------
  // component rendering
  //----------------------------------------------------------------------------
  return (
    <div className={classes.fieldBox}>
      {isTypeAutoComplete(valueField.inputType) ?
        <ValueFieldAutoComplete 
          valueField={valueField}
          disabled={disabled}
          update={update}
          inputRef={ref}
          value={text} 
          setText={setText}
          timer={timer}
        />
        :
        valueField.inputType === 'date' ? 
        <ValueFieldDate label={valueField.name+(valueField.required? ' *':'')}
          value={text} 
          update={update} id={valueField._id}
          fullWidth={true} />
        :
        <TextField fullWidth variant="outlined" size="small"
          inputRef={ref}
          rows={rows}
          multiline={valueField.multiline} 
          placeholder={valueField.name}
          label={valueField.name+(valueField.required? ' *':'')+(valueField.maxTextLength? ' (max '+valueField.maxTextLength+' characters)':'')}
          value={text} onChange={handleChange} disabled={disabled} 
        />
      }
    </div>
  )
}

import React from 'react';
import { useParams } from "react-router-dom";
import { FormControl, InputLabel, Select, MenuItem, FormGroup, FormLabel, FormControlLabel, Checkbox } from '@mui/material';
import { makeStyles } from '@material-ui/core/styles';
import { useQuery } from "@apollo/client";
import { IMPORT_FORMS } from '../../constants/gqls/forms';

//******************************************************************************
// style definition
//******************************************************************************
const useStyles = makeStyles((theme) => ({
  ctrl: {
    '&.MuiFormControl-root': {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2),
    }
  }
}));

//******************************************************************************
// component definition
//******************************************************************************
export default function InputImportForm({disabled, loading,
  importFormValue, setImportFormValue, importInputs, setInputs}) {
  const classes = useStyles();
  const params = useParams();
  const [importedForm, setImportedForm] = React.useState(null);
  const { data } = useQuery(IMPORT_FORMS, { variables: {
    docId: params.id
  }});

  React.useEffect(() => {
    if (data && importFormValue) {
      setImportedForm(data.importForms.filter(f=>f._id===importFormValue)[0]);
    }
  }, [importFormValue, data]);
  // console.log(importInputs);

  //----------------------------------------------------------------------------
  // funtions
  //----------------------------------------------------------------------------
  const handleImportChange = (event) => {
    setImportFormValue(event.target.value);
  };

  const handleInputsChange = (event) => {
    if (event.target.checked) {
      setInputs([...importInputs, event.target.name]);
    } else {
      setInputs(importInputs.filter(v=>v!==event.target.name))
    }
  };

  //----------------------------------------------------------------------------
  // component rendering
  //----------------------------------------------------------------------------
  if (!data) return 'loading...'
  return (
    <>
      <FormControl disabled={disabled} fullWidth className={classes.ctrl}>
        <InputLabel>Student form</InputLabel>
        <Select label="Student form"
          value={importFormValue} onChange={handleImportChange}
        >
          {data.importForms.map(option=>
            <MenuItem key={option._id} value={option._id}>
              {option.title}
            </MenuItem>
          )}
        </Select>
      </FormControl>
      { importedForm && setInputs &&
        <FormControl disabled={loading}>
          <FormLabel>Select input item to import:</FormLabel>
          <FormGroup>
            { importedForm.nonDataFilterItems.map(item=>(
              <FormControlLabel key={item._id} label={item.text} control={
                <Checkbox name={item.input._id}
                  checked={importInputs.includes(item.input._id)} 
                  onChange={handleInputsChange}  />
              } />
            ))}
          </FormGroup>
        </FormControl>
      }
    </>
  )
}

import React from 'react';
import { Divider, Button, Grid, CardContent } from '@mui/material';
import UploadInput from '../InputsCommt/UploadInput';
import { REQUEST_USER_IMPORT } from '../../constants/gqls/user';
import ResetUGCBtn from './ResetUGCBtn';
import ClearUsersBtn from './ClearUsersBtn';


//******************************************************************************
// component definition
//******************************************************************************
export default function ImportInputs() {

  //----------------------------------------------------------------------------
  // component rendering
  //----------------------------------------------------------------------------
  return (
    <div>
      <UploadInput requestMutation={REQUEST_USER_IMPORT}
        apiName="requestUserImport"
        fileTypes={['text/csv']} />
      <CardContent>
        <Grid container spacing={2} alignItems="center" >
          <Grid item>
            <h3>
              Helpers:
            </h3>
          </Grid>
          <Grid item>
            <ResetUGCBtn />
          </Grid>
          <Grid item>
            <ClearUsersBtn />
          </Grid>
        </Grid>
      </CardContent>
      <Divider />
      <CardContent>
        <h3>
          Instructions:
        </h3>
        <p>
          Data file should be in <strong>csv</strong> format. Here are data columns read by the system:
        </p>
        <ul>
          <li><strong>email</strong>: This is the key. Please make sure it's correct and MUST exist.</li>
          <li>name </li>
          <li>chineseName </li>
          <li>active: By default, it's true, use "N" to disable the user. </li>
        </ul>
        <span>For teachers only:</span><br />
        <ul>
          <li>title: Prof / Dr / Mr / Ms </li>
          <li>position: Senior Lecturer / Lecturer I / ... </li>
        </ul>
        <span>For students only:</span><br />
        <ul>
          <li>studyLevel (UG or PG)</li>
          <li>faculty </li>
          <li>deptUnitCode </li>
          <li>studyProgram </li>
          <li>studyMode </li>
          <li>studyYear </li>
          <li>isUGC </li>
          <li>isLocal</li>
          <li>nonChineseSpeaking </li>
          <li>idExp </li>
        </ul>
        <h3>
          Key points:
        </h3>
        <ol>
          <li>All other fields can be absent and can be changed by uploading another csv file, but email is the key.</li>
          <li>To display the Chinese name correctly, save the csv file in <strong>UTF-8</strong>.</li>
          <li>Use "," as the separator.</li>
          <li>Date format: YYYY-MM-DD</li>
          <li>Use "Y" for true/yes, "N" for false/no.</li>
          <li>Column order doesn't matter.</li>
          <li>Use notepad to open and check the csv file before uploading.</li>
        </ol>
        <h3>
          Samples:
        </h3>
        <Grid container spacing={2}>
          <Grid item xs="auto">
            <Button variant="outlined" href={process.env.REACT_APP_DOWNLOAD_URI+"staffs.csv"}>
              staffs.csv
            </Button>
          </Grid>
          <Grid item xs="auto">
            <Button variant="outlined" href={process.env.REACT_APP_DOWNLOAD_URI+"students.csv"}>
              students.csv
            </Button>
          </Grid>
        </Grid>
      </CardContent>
    </div>
  );
}

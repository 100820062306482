import React from 'react';
import { Container, Paper, CardHeader } from '@mui/material';
import { makeStyles } from '@material-ui/core/styles';
import { useQuery } from "@apollo/client";
import CreateBtn from '../components/Services/CreateBtn';
import ServicesList from '../components/Services/ServicesList';
import { ME } from '../constants/gqls/user';

//******************************************************************************
// style definition
//******************************************************************************
const useStyles = makeStyles((theme) => ({
  container: {
    paddingTop: theme.spacing(3)
  },
}));

//******************************************************************************
// component definition
//******************************************************************************
export default function Services() {
  const { data } = useQuery(ME);
  const classes = useStyles();

  //----------------------------------------------------------------------------
  // funtions
  //----------------------------------------------------------------------------


  //----------------------------------------------------------------------------
  // component rendering
  //----------------------------------------------------------------------------
  return (
    <Container maxWidth="xl" className={classes.container}>
      <Paper>
        <CardHeader title="Services" action={
            data?.me?.roles.includes('admin') && <CreateBtn />
          } />
        <ServicesList />
      </Paper>
    </Container>
  );
}

import React from 'react';
import { TextField, Grid, IconButton } from '@mui/material';
import ClearIcon from '@mui/icons-material/Clear';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { useMutation } from "@apollo/client";
import { UPDATE_DATA_TABLE_COLUMN, DELETE_DATA_TABLE_COLUMN, DATA_TABLE_DETAILS } from '../../constants/gqls/dataAnalysis';

//******************************************************************************
// component definition
//******************************************************************************
export default function ColumnEdit({column, addColumn}) {
  const [name, setName] = React.useState(column?.name || '');
  const [field, setField] = React.useState(column?.field || '');
  const [code, setCode] = React.useState(column?.code || '');
  const [changed, setChanged] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [deleteColumn, { loading: delLoading }] = useMutation(DELETE_DATA_TABLE_COLUMN, {
    refetchQueries: [ DATA_TABLE_DETAILS ],
  });
  const [updateColumn, { loading: updateLoading }] = useMutation(UPDATE_DATA_TABLE_COLUMN);
  // console.log(value);

  React.useEffect(() => {
    setLoading(delLoading && updateLoading);
  }, [delLoading, updateLoading]);

  //----------------------------------------------------------------------------
  // funtions
  //----------------------------------------------------------------------------
  const onNameChange = (event) => {
    setName(event.target.value);
    setChanged(true);
  }

  const onFieldChange = (event) => {
    setField(event.target.value);
    setChanged(true);
  }

  const onCodeChange = (event) => {
    setCode(event.target.value);
    setChanged(true);
  }

  const save = () => {
    if (changed) {
      updateColumn({ variables: {
        where: { _id: column._id },
        update: { name, field, code }
      }}).then(res=>{
        // console.log(res);
      });
    }
  }

  const duplicate = () => {
    addColumn(column.name, column.field, column.code);
  }

  const remove = () => {
    deleteColumn({ variables: {
      where: { _id: column._id },
    }}).then(res=>{
      // console.log(res);
    });
  }

  //----------------------------------------------------------------------------
  // component rendering
  //----------------------------------------------------------------------------
  return (
    <Grid item xs={12} container spacing={1}>
      <Grid item xs={2}>
        <TextField fullWidth size="small" variant="outlined" label="Name"
          value={name} disabled={loading}
          onChange={onNameChange} onBlur={save} />
      </Grid>
      <Grid item xs={2}>
        <TextField fullWidth size="small" variant="outlined" label="Field"
          value={field} disabled={loading}
          onChange={onFieldChange} onBlur={save} />
      </Grid>
      <Grid item xs>
        <TextField fullWidth size="small" variant="outlined" label="Code"
          value={code} disabled={loading}
          onChange={onCodeChange} onBlur={save} />
      </Grid>
      <Grid item xs="auto">
        <IconButton onClick={duplicate} disabled={loading}>
          <ContentCopyIcon />
        </IconButton>
      </Grid>
      <Grid item xs="auto">
        <IconButton onClick={remove} disabled={loading}>
          <ClearIcon />
        </IconButton>
      </Grid>
    </Grid>
  );
}

import React from 'react';
import { Grid, Button, CardContent, FormControlLabel, Checkbox } from '@mui/material';
import DownloadIcon from '@mui/icons-material/Download';
import { makeStyles } from '@material-ui/core/styles';
import ServiceSessionCell from '../ServiceSessions/ServiceSessionCell';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import { dataToString } from '../../constants/utils';

function formatData(rawData, applicantAttributes) {
  if (!rawData) return [[],[]];

  // columns
  let columns = rawData.document.inputItems.map(item=>({
    headerName: item.text,
    field: item.input._id
  }));
  // let inputs = columns.map(item=>item.field);
  // console.log(inputs);
  columns.push({
    headerName: 'Submit time',
    width: 200,
    field: 'submittedTime',
    filter: 'date'
  });
  columns.push({
    headerName: 'Service Session',
    field: 'serviceSession',
    width: 150,
    cellRenderer: ServiceSessionCell
  });
  columns.unshift({
    headerName: 'Name',
    field: 'name'
  });
  if (applicantAttributes.email) {
    columns.push({
      headerName: 'Email',
      field: 'email'
    });
  }
  if (applicantAttributes.studyLevel) {
    columns.push({
      headerName: 'Study level',
      field: 'studyLevel'
    });
  }

  // rows
  let data = rawData.submittedAll.map(item=>{
    let dataItem = {
      id: item._id,
      name: item.ownerPerson?.name || 'Guest',
      email: item.ownerPerson?.email || 'N/A',
      studyLevel: item.ownerPerson?.studyLevel || 'N/A',
      submittedTime: new Date(item.submittedTime),
      serviceSession: item.serviceSessionId,
    };
    for (var i = 0; i < item.data.length; i++) {
      let inputId = item.data[i].input?._id || 'deleted';
      // let values = item.data[i].options.map(option=>option.text);
      // if (item.data[i].values) {
      //   values = values.concat(item.data[i].values);
      // }
      // if (item.data[i].value) {
      //   values.push(item.data[i].value);
      // }
      // dataItem[inputId] = values.join(';\n');
      dataItem[inputId] = dataToString(item.data[i], ';\n');
    }
    return dataItem;
  });

  return {columns, data};
}

//******************************************************************************
// style definition
//******************************************************************************
const useStyles = makeStyles((theme) => ({
  table: {
    width: '100%',
    height: 740
  },
  label: {
    marginRight: theme.spacing(2)
  }
}));

//******************************************************************************
// component definition
//******************************************************************************
export default function DataTable({data}) {
  const classes = useStyles();
  const gridRef = React.useRef();
  const [tableData, setData] = React.useState([[], []]);
  const [applicantAttributes, setApplicantAttributes] = React.useState({
    email: false,
    studyLevel: false,
  });
  // console.log(data);

  // never changes, so we can use useMemo
  const defaultColDef = React.useMemo(() => ({
    resizable: true,
    sortable: true,
    filter: true
  }), []);

  // when changes
  React.useEffect(() => {
    setData(formatData(data, applicantAttributes));
  }, [data, applicantAttributes]);

  //----------------------------------------------------------------------------
  // funtions
  //----------------------------------------------------------------------------
  const handleExport = () => {
    gridRef.current.api.exportDataAsCsv();
  }

  const handleChange = (event) => {
    setApplicantAttributes({
      ...applicantAttributes,
      [event.target.name]: event.target.checked,
    });
  };

  //----------------------------------------------------------------------------
  // component rendering
  //----------------------------------------------------------------------------
  return (
    <>
      <CardContent>
        <Grid container spacing={2} alignItems="center">
          <Grid item xs>
            <span className={classes.label}>Show applicant attributes:</span>
            <FormControlLabel
              control={
                <Checkbox checked={applicantAttributes.email}
                  onChange={handleChange} name="email" />
              }
              label="Email"
            />
            <FormControlLabel
              control={
                <Checkbox checked={applicantAttributes.studyLevel}
                  onChange={handleChange} name="studyLevel" />
              }
              label="Study Level"
            />
          </Grid>
          <Grid item xs="auto">
            <Button variant="contained" color="secondary"
              startIcon={<DownloadIcon />}
              onClick={handleExport}>
                Download CSV
            </Button>
          </Grid>
        </Grid>
      </CardContent>
      <div className={classes.table}>
        <AgGridReact className="ag-theme-alpine"
          ref={gridRef}
          animateRows="true"
          rowData={tableData.data}
          columnDefs={tableData.columns}
          defaultColDef={defaultColDef}
        />
      </div>
    </>
  );
}

import React from 'react';
import { FormControl, Select, MenuItem, Divider } from '@mui/material';


//******************************************************************************
// component definition
//******************************************************************************
export default function MemberRoleSelect({role, handleChange, disabled, remove}) {
  return (
    <FormControl variant="standard" size="small">
      <Select disabled={disabled}
        value={role}
        label="Role"
        onChange={handleChange}
      >
        <MenuItem value={'Manager'}>Manager</MenuItem>
        <MenuItem value={'Member'}>Member</MenuItem>
        { remove && <Divider /> }
        { remove &&
          <MenuItem value={'Remove'}>Remove</MenuItem>
        }
      </Select>
    </FormControl>
  );
}

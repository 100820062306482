import React from 'react';
import { useNavigate } from "react-router-dom";
import { ListItemButton, IconButton, Collapse, Tooltip } from '@mui/material';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import { makeStyles } from '@material-ui/core/styles';
import { useMutation } from "@apollo/client";
import TagItemDetails from './TagItemDetails';
import Tag from '../Tag';
import SiteMap from '../../constants/siteMap';
import { CLICK_TAG } from '../../constants/gqls/tags';

//******************************************************************************
// style definition
//******************************************************************************
const useStyles = makeStyles((theme) => ({
  count: {
    marginLeft: theme.spacing(3),
    color: theme.palette.text.secondary,
    fontSize: '0.875rem'
  },
  box: {
    position: 'relative',
    paddingLeft: (props) => props.filter ? '40px' : 0,
    "& .MuiIconButton-root": {
      position: 'absolute',
      top: '4px',
      left: 0
    }
  }
}));

//******************************************************************************
// component definition
//******************************************************************************
export default function TagListItem({ tag, filter }) {
  const classes = useStyles({filter});
  const navigate = useNavigate();
  const [open, setOpen] = React.useState(false);
  const isAdmin = typeof tag.taggedCount === 'number';
  const [clickTag] = useMutation(CLICK_TAG);
  // console.log(tag);

  //----------------------------------------------------------------------------
  // funtions
  //----------------------------------------------------------------------------
  const handleClick = () => {
    setOpen(!open);
  };

  const goFilter = () => {
    navigate(SiteMap.tagsFilter+'?tags='+tag._id);
    clickTag({ variables: { tagId: tag._id }});
  }

  //----------------------------------------------------------------------------
  // component rendering
  //----------------------------------------------------------------------------
  return (
    <div className={classes.box}>
      <ListItemButton onClick={handleClick}>
        <Tag text={tag.key} type={tag.type} />
        <span className={classes.count}>
          tagged count: {isAdmin? tag.taggedCount : tag.myTaggedCount}
        </span>
      </ListItemButton>
      { filter &&
        <Tooltip title="Tag filter" placement="left">
          <IconButton onClick={goFilter}>
            <FilterAltIcon />
          </IconButton>
        </Tooltip>
      }
      <Collapse in={open} timeout="auto" unmountOnExit>
        <TagItemDetails id={tag._id} isAdmin={isAdmin} />
      </Collapse>
    </div>
  );
}

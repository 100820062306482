import React from 'react';
import { useSearchParams } from "react-router-dom";
import { Accordion, AccordionSummary, Typography, AccordionDetails,
  Button, Grid, IconButton, Tooltip } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ClearIcon from '@mui/icons-material/Clear';
import { makeStyles } from '@material-ui/core/styles';
import { useMutation } from "@apollo/client";
import DateTimeInput from '../InputsCommt/DateTimeInput';
import FormQuota from './FormQuota';
import FormMultiSubmit from './FormMultiSubmit';
import SetFormNature from './SetFormNature';
import { UPDATE_DOCUMENT } from '../../constants/gqls/documents';

//******************************************************************************
// style definition
//******************************************************************************
const useStyles = makeStyles((theme) => ({
  row: {
    paddingTop: theme.spacing(1)
  },
  dateTiem: {
    minWidth: '50%'
  },
  actions: {
    paddingTop: theme.spacing(1),
    display: 'flex',
    "& .MuiButton-root": {
      margin: 'auto'
    }
  },
}));

//******************************************************************************
// component definition
//******************************************************************************
export default function FormSetting({doc, mode, isAdmin}) {
  const classes = useStyles();
  const [, setSearchParams] = useSearchParams();
  const [open, setOpen] = React.useState(true);
  // const [formOpen, setFormOpen] = React.useState(null);
  const [updateDocument, { loading }] = useMutation(UPDATE_DOCUMENT);

  //----------------------------------------------------------------------------
  // funtions
  //----------------------------------------------------------------------------
  const update = (update) => {
    return updateDocument({ variables: {
      docId: doc._id,
      update
    }});
  }

  const handleClick = () => {
    setOpen(!open);
  };

  const handleOTChange = (formOpen) => {
    // console.log(value.getSeconds(), value.getMilliseconds());
    formOpen.setSeconds(0);
    update({ formOpen })
  }

  const handleCTChange = (formClose) => {
    formClose.setSeconds(0);
    update({ formClose })
  }

  const handleQuotaChange = (quota) => {
    return update({ quota });
  }

  const handleMSChange = (multipleSubmit) => {
    update({ multipleSubmit });
  }

  const handleFormNatureChange = (isProfileForm, isReportForm) => {
    update({ isProfileForm, isReportForm });
  }

  const clearFormOpen = () => {
    update({ formOpen: null });
  }

  const clearFormClose = () => {
    update({ formClose: null });
  }

  const modeBtnClick = () => {
    if (mode === 'view') {
      setSearchParams({mode: 'edit'});
    } else {
      setSearchParams({mode: 'view'});
    }
  }

  //----------------------------------------------------------------------------
  // component rendering
  //----------------------------------------------------------------------------
  return (
    <Accordion expanded={open} onChange={handleClick}>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Typography>Form setting</Typography>
      </AccordionSummary>
      <AccordionDetails>
        { isAdmin &&
          <SetFormNature 
            isProfileForm={doc.isProfileForm} 
            isReportForm={doc.isReportForm}
            onChange={handleFormNatureChange} 
            loading={loading} />
        }
        { !doc.isProfileForm &&
          <Grid container spacing={1} alignItems="center">
            <Grid item xs className={classes.dateTiem}>
              <DateTimeInput id="form-open-time" label="Open time"
                value={doc.formOpen} onChange={handleOTChange} />
            </Grid>
            <Grid item xs="auto">
              <Tooltip title="Clear value">
                <IconButton onClick={clearFormOpen} disabled={loading}>
                  <ClearIcon />
                </IconButton>
              </Tooltip>
            </Grid>
            <Grid item xs className={classes.dateTiem}>
              <DateTimeInput id="form-close-time" label="Close time"
                value={doc.formClose} onChange={handleCTChange} />
            </Grid>
            <Grid item xs="auto">
              <Tooltip title="Clear value">
                <IconButton onClick={clearFormClose} disabled={loading}>
                  <ClearIcon />
                </IconButton>
              </Tooltip>
            </Grid>
            <Grid item xs={6}>
              <FormQuota quota={doc.quota} onChange={handleQuotaChange} loading={loading} />
            </Grid>
            <Grid item xs={6}>
              <FormMultiSubmit value={doc.multipleSubmit} onChange={handleMSChange} loading={loading} />
            </Grid>
          </Grid>
        }
        <div className={classes.actions}>
          <Button variant="outlined" onClick={modeBtnClick}>
            { mode === 'view' ? "Edit Form" : "View Form"}
          </Button>
        </div>
      </AccordionDetails>
    </Accordion>
  );
}

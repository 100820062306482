import React from 'react';
import { FormControlLabel, Checkbox } from '@mui/material';
const roles = ['admin']

//******************************************************************************
// component definition
//******************************************************************************
export default function RolesSelect({value, onChange, disabled}) {

  //----------------------------------------------------------------------------
  // funtions
  //----------------------------------------------------------------------------
  const onRolesChange = (event) => {
    if (event.target.checked && !value.includes(event.target.name)) {
      onChange([...value, event.target.name]);
    } else if (!event.target.checked && value.includes(event.target.name)) {
      onChange(value.filter(role=>role!==event.target.name));
    }
  }

  //----------------------------------------------------------------------------
  // component rendering
  //----------------------------------------------------------------------------
  return (
    <div>
      { roles.map(role=>(
        <FormControlLabel key={role} control={
          <Checkbox checked={value.includes(role)} onChange={onRolesChange}
            name={role} disabled={disabled} />
        } label={role} />
      ))}
    </div>
  );
}

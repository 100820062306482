import React from 'react';
// import queryString from 'query-string';
import { useSearchParams, useNavigate } from "react-router-dom";
import { makeStyles } from '@material-ui/core/styles';
import Filter from '../components/TagsFilter/Filter';
import Content from '../components/TagsFilter';
import SiteMap from '../constants/siteMap';

//******************************************************************************
// style definition
//******************************************************************************
const useStyles = makeStyles((theme) => ({
  box: {
    margin: theme.spacing(1),
  },
  container: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(3)
  },
}));

//******************************************************************************
// component definition
//******************************************************************************
export default function TagsFilter() {
  const classes = useStyles();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const tags = searchParams.get("tags")?.split(',');
  // const query = queryString.parse(location.search);

  React.useEffect(() => {
    if (!searchParams.get("tags")) {
      navigate(SiteMap.tags, { replace: true });
      return;
    }
  }, [searchParams, navigate]);

  //----------------------------------------------------------------------------
  // component rendering
  //----------------------------------------------------------------------------
  if (!searchParams.get("tags")) return '';

  return (
    <React.Fragment>
      <div className={classes.box}>
       <Filter tags={tags} />
      </div>
      <Content tags={tags} />
    </React.Fragment>
  );
}

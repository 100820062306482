import React from 'react';
import { IconButton, Tooltip, Dialog, DialogTitle  } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import CreateOrEdit from './CreateOrEdit';

//******************************************************************************
// component definition
//******************************************************************************
export default function CreateBtn() {
  const [open, setOpen] = React.useState(false);

  //----------------------------------------------------------------------------
  // funtions
  //----------------------------------------------------------------------------
  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  //----------------------------------------------------------------------------
  // component rendering
  //----------------------------------------------------------------------------
  return (
    <>
      <Tooltip title="Add new course">
        <IconButton onClick={handleOpen}>
          <AddIcon />
        </IconButton>
      </Tooltip>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>
          New Course
        </DialogTitle>
        <CreateOrEdit then={handleClose} isAdmin={true} />
      </Dialog>
    </>
  )
}

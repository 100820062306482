import React from 'react';
import { IconButton, ClickAwayListener } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import DeleteBtn from './DeleteBtn';
// import { makeStyles } from '@material-ui/core/styles';

//******************************************************************************
// style definition
//******************************************************************************
// const useStyles = makeStyles((theme) => ({
// }));

//******************************************************************************
// component definition
//******************************************************************************
export default function FunctionBtns({ attendance, editClicked }) {
  // const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  // console.log(attendance);

  //----------------------------------------------------------------------------
  // funtions
  //----------------------------------------------------------------------------

  const moreClicked = () => {
    setOpen(true);
  }

  const handleClickAway = () => {
    setOpen(false);
  }


  //----------------------------------------------------------------------------
  // component rendering
  //----------------------------------------------------------------------------
  if (open) {
    return (
      <ClickAwayListener onClickAway={handleClickAway}>
        <div>
          <DeleteBtn attendance={attendance} />
          { !attendance.submitted &&
            <IconButton onClick={editClicked}>
              <EditIcon />
            </IconButton>
          }
        </div>
      </ClickAwayListener>
    );
  }
  return (
    <IconButton onClick={moreClicked}>
      <MoreVertIcon />
    </IconButton>
  );
}

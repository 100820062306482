import React from 'react';
import { Button } from '@mui/material';
import { useMutation } from "@apollo/client";
import { SERVICE_SESSION_ATTENDANCES, CREATE_ATTENDANCE } from '../../constants/gqls/attendances';
import { SERVICE_SESSION } from '../../constants/gqls/serviceSessions';

//******************************************************************************
// component definition
//******************************************************************************
export default function AddAttendanceBtn({serviceSessionId}) {
  const [createAttendance, { loading }] = useMutation(CREATE_ATTENDANCE, {
    refetchQueries: [ SERVICE_SESSION_ATTENDANCES, SERVICE_SESSION ],
  });
  // console.log(serviceSessionId);

  //----------------------------------------------------------------------------
  // funtions
  //----------------------------------------------------------------------------
  const handleAdd = () => {
    console.log(serviceSessionId);
    createAttendance({ variables: {serviceSessionId}});
  }

  //----------------------------------------------------------------------------
  // component rendering
  //----------------------------------------------------------------------------
  return (
    <Button variant="contained" disabled={loading} onClick={handleAdd}>
      Add attendance
    </Button>
  )
}

import React from 'react';
import { Button, Dialog, DialogTitle, DialogContent, DialogActions,
  Table, TableBody, TableRow, TableCell } from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import ApprovalIcon from '@mui/icons-material/Approval';
import NotInterestedIcon from '@mui/icons-material/NotInterested';
// import { makeStyles } from '@material-ui/core/styles';

//******************************************************************************
// style definition
//******************************************************************************
// const useStyles = makeStyles((theme) => ({
//   btn: {
//     '& .MuiButtonBase-root':{
//       backgroundColor: theme.palette.secondary.main,
//       margin: 0
//     }
//   },
// }));

//******************************************************************************
// component definition
//******************************************************************************
function ActivateBtn({ user, activate, loading }) {
  const [open, setOpen] = React.useState(false);
  const label = user.active? 'Disable' : 'Activate';
  // console.log(user);

  //----------------------------------------------------------------------------
  // component rendering
  //----------------------------------------------------------------------------
  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const confirmActivate = () => {
    activate(true).then(res => {
      // console.log(res.data);
      setOpen(false);
    });
  }

  const confirmDisable = () => {
    activate(false).then(res => {
      // console.log(res.data);
      setOpen(false);
    });
  }

  //----------------------------------------------------------------------------
  // component rendering
  //----------------------------------------------------------------------------
  return (
    <React.Fragment>
      <Button variant="contained" color="secondary" startIcon={
        user.active? <NotInterestedIcon /> : <ApprovalIcon />
      } onClick={handleOpen}>
        {label}
      </Button>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>
          Confirm {label}
        </DialogTitle>
        <DialogContent>
          <Table>
            <TableBody>
              <TableRow>
                <TableCell component="th" scope="row">
                  Name
                </TableCell>
                <TableCell>{user.name}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell component="th" scope="row">
                  Email
                </TableCell>
                <TableCell>{user.email}</TableCell>
              </TableRow>
              { user.roles.includes('staff') &&
                <TableRow>
                  <TableCell component="th" scope="row">
                    Title
                  </TableCell>
                  <TableCell>{user.title}</TableCell>
                </TableRow>
              }
              { user.roles.includes('student') &&
                <>
                  <TableRow>
                    <TableCell component="th" scope="row">
                      Study Level
                    </TableCell>
                    <TableCell>{user.studyLevel}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell component="th" scope="row">
                      UGC-funded
                    </TableCell>
                    <TableCell>{user.isUGC? 'Yes' : 'No'}</TableCell>
                  </TableRow>
                </>
              }
              <TableRow>
                <TableCell component="th" scope="row">
                  Roles
                </TableCell>
                <TableCell>{user.roles.toString()}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell component="th" scope="row">
                  Account Created at
                </TableCell>
                <TableCell>{(new Date(user.createdAt)).toLocaleString()}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell component="th" scope="row">
                  Last Login
                </TableCell>
                <TableCell>
                  {user.lastLogin?
                    (new Date(user.lastLogin)).toLocaleString()
                    : 'N/A'
                  }
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <LoadingButton variant="outlined"
            loading={loading}
            onClick={user.active? confirmDisable : confirmActivate}>
            {label}
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}

export default ActivateBtn;

import React from 'react';
import { TextField, Autocomplete } from '@mui/material';

const options = ['Created', 'Confirmed', 'Closed'];

//******************************************************************************
// component definition
//******************************************************************************
export default function StatusSelect({onChange, value}) {
  const [inputValue, setInputValue] = React.useState('');

  //----------------------------------------------------------------------------
  // funtions
  //----------------------------------------------------------------------------
  const onValueChange = (event, newValue) => {
    // console.log(newValue);
    onChange(newValue);
  }

  const onInputChange = (event, newValue) => {
    setInputValue(newValue);
  }

  //----------------------------------------------------------------------------
  // component rendering
  //----------------------------------------------------------------------------
  return (
    <Autocomplete
      value={value} onChange={onValueChange}
      inputValue={inputValue} onInputChange={onInputChange}
      getOptionLabel={option=>option}
      options={options}
      renderInput={(params) => <TextField {...params}
        label="Status"
        variant="standard"
      />}
    />
  );
}

import React from 'react';
import { Autocomplete, DialogContent, Grid, TextField } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { useMutation } from "@apollo/client";
import ValuesList from './ValuesList';
import { FORMAT_VALUES, VALUES } from '../../constants/gqls/dataValues';

const preSetOptions = [{
  name: "Year",
  type: "number",
  rgxStr: "[0-9]{4}"
}]

const types = ['number', 'text', 'matchTrue', 'matchFalse']

//******************************************************************************
// component definition
//******************************************************************************
export default function FormatValuesTab({valueField}) {
  const [type, setType] = React.useState(null);
  const [rgxStr, setRgxStr] = React.useState('');
  const [formatValues, { loading }] = useMutation(FORMAT_VALUES, {
    refetchQueries: [ VALUES ],
  });

  //----------------------------------------------------------------------------
  // funtions
  //----------------------------------------------------------------------------
  const onPreSetChange = (event, newValue) => {
    console.log(newValue);
    setType(newValue.type);
    setRgxStr(newValue.rgxStr);
  }

  const onTypeChange = (event, newValue) => {
    setType(newValue);
  }

  const onRgxStrChange = (event) => {
    setRgxStr(event.target.value);
  }

  const onFormatClick = () => {
    if (type && rgxStr) {
      formatValues({ variables: {
        valueFieldId: valueField._id,
        type, rgxStr
      }}).then((res)=>{
        console.log(res);
      });
    }
  }

  //----------------------------------------------------------------------------
  // component rendering
  //----------------------------------------------------------------------------
  return (
      <DialogContent>
        <Grid container spacing={2} alignItems="center" sx={{marginTop: 1}}>
          <Grid item xs={6}>
            <Autocomplete id="pre-set"
              options={preSetOptions}
              onChange={onPreSetChange}
              getOptionLabel={opt=>opt.name}
              renderInput={(params)=>
                <TextField {...params} label="Pre-set Options" />
              }
            />
          </Grid>
          <Grid item xs={6}>
            <Autocomplete id="pre-set"
              options={types}
              value={type}
              onChange={onTypeChange}
              renderInput={(params)=>
                <TextField {...params} label="Format Type" />
              }
            />
          </Grid>
          <Grid item xs={10}>
            <TextField fullWidth id="regx-string" label="Regex String"
              value={rgxStr} onChange={onRgxStrChange} />
          </Grid>
          <Grid item xs={2}>
            <LoadingButton variant="outlined"
              onClick={onFormatClick} loading={loading}>
              Format
            </LoadingButton>
          </Grid>
        </Grid>
        <ValuesList valueFieldId={valueField._id} />
      </DialogContent>
  );
}

import React from 'react';
import { DialogContent, DialogActions, DialogContentText, 
  TextField, Button } from '@mui/material';
import { makeStyles } from '@material-ui/core/styles';

//******************************************************************************
// style definition
//******************************************************************************
const useStyles = makeStyles((theme) => ({
  dialogText: {
    '&.MuiDialogContentText-root': {
      marginBottom: theme.spacing(2),
    }
  }
}));

//******************************************************************************
// component definition
//******************************************************************************
export default function InputFieldKeyDialog({value, updateInput, loading, handleClose}) {
  const classes = useStyles();
  const [fieldKey, setFieldKey] = React.useState(value || '');
  const [changed, setChanged] = React.useState(false);

  //----------------------------------------------------------------------------
  // funtions
  //----------------------------------------------------------------------------
  const update = () => {
    if (changed) {
      updateInput({ fieldKey: fieldKey }, ()=>{
        setChanged(false);
      }).then(res=>{
        handleClose();
      });
    }
  };

  const handleChange = (event) => {
    setFieldKey(event.target.value);
    setChanged(true);
  };

  //----------------------------------------------------------------------------
  // component rendering
  //----------------------------------------------------------------------------
  return (
    <>
      <DialogContent>
        <DialogContentText className={classes.dialogText}>
          Field key for inputs in a public form. Input value can be auto filled by query params, so space in between is not allowed. For example, if field key is "ref_no", value can be filled using link: https://lcmis.hkbu.edu.hk/pages/page_name?ref_no=value
        </DialogContentText>
        <TextField fullWidth disabled={loading}
          id="input-field-key" label="Field Key" variant="outlined"
          value={fieldKey} onChange={handleChange} />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Cancel</Button>
        <Button onClick={update} disabled={loading}>
          Save
        </Button>
      </DialogActions>
    </>
  )
}

import React from 'react';
import InfiniteScroll from 'react-infinite-scroller';
import { List, LinearProgress } from '@mui/material';
import { useQuery, useReactiveVar } from "@apollo/client";
import { doRefetch } from '../../constants/cache';
import { MY_DOCUMENTS } from '../../constants/gqls/documents';
import DocumentItem from '../Documents/DocumentItem';
const limit = 10;

//******************************************************************************
// component definition
//******************************************************************************
export default function PersonDocumentsCard({spaceId}) {
  const needRefetch = useReactiveVar(doRefetch);
  const { loading, data, fetchMore, refetch } = useQuery(MY_DOCUMENTS, {
    variables: { limit, offset: 0 }
  });

  React.useEffect(() => {
    if (needRefetch) {
      // console.log('refetch');
      refetch();
      doRefetch(false)
    }
  }, [needRefetch, refetch]);

  //----------------------------------------------------------------------------
  // funtions
  //----------------------------------------------------------------------------
  const loadMore = () => {
    fetchMore({ variables: {
      limit,
      offset: data.myDocuments.length
    }});
  }

  //----------------------------------------------------------------------------
  // component rendering
  //----------------------------------------------------------------------------
  if (loading) return <LinearProgress />;
  if (!data) return 'Network error.'
  if (data.myDocumentsCount === 0) return 'No records...'

  return (
    <InfiniteScroll
      loadMore={loadMore}
      useWindow={false}
      hasMore={data.myDocumentsCount > data.myDocuments.length}
      loader={<LinearProgress key="loading" />}
    >
      <List>
        { data.myDocuments.map(item=>(
          <DocumentItem key={item._id} doc={item} />
        )) }
      </List>
    </InfiniteScroll>
  );
}

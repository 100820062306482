import React from 'react';
import { Table, TableBody, TableCell, TableRow } from '@mui/material';
import ProfileKeyDeleteBtn from './ProfileKeyDeleteBtn';
import siteMap from '../../constants/siteMap';

//******************************************************************************
// component definition
//******************************************************************************
function ProfileKeyDetails({ profileKey, deleteThen }) {

  //----------------------------------------------------------------------------
  // funtions
  //----------------------------------------------------------------------------

  //----------------------------------------------------------------------------
  // component rendering
  //----------------------------------------------------------------------------
  if (!profileKey) return '';

  return (
    <Table>
      <TableBody>
        <TableRow>
          <TableCell>
            <span>Key:</span>
          </TableCell>
          <TableCell>
            {profileKey.key}
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell>
            <span>Fields:</span>
          </TableCell>
          <TableCell>
            {profileKey.valueFields.map(field=>
              <div key={field._id}>{field.name}</div>
            )}
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell>
            <span>Attribute:</span>
          </TableCell>
          <TableCell>
            {profileKey.attribute}
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell>
            <span>Forms:</span>
          </TableCell>
          <TableCell>
            {profileKey.input.items.length? 
              profileKey.input.items.map(item=>
                <div key={item._id}>
                  <a target='_blank' href={siteMap.document.replace(':id', item.ownerDoc._id)}>
                    {item.ownerDoc.title}
                  </a>
                </div>
              ) 
            :
            <ProfileKeyDeleteBtn profileKeyId={profileKey._id}
              deleteRelatedInput={true}
              then={deleteThen} />
            }
          </TableCell>
        </TableRow>
      </TableBody>
    </Table>
  );
}

export default ProfileKeyDetails;


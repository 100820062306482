import React from 'react';
import { ListItemButton, ListItemText, ListItemIcon, Tooltip } from '@mui/material';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import { makeStyles } from '@material-ui/core/styles';

//******************************************************************************
// style definition
//******************************************************************************
const useStyles = makeStyles((theme) => ({
  item: {
    "&.MuiListItemButton-root": {
      paddingLeft: theme.spacing(3)
    }
  }
}));

//******************************************************************************
// component definition
//******************************************************************************
export default function MenuSubItem({ subItem, open }) {
  const classes = useStyles();

  //----------------------------------------------------------------------------
  // component rendering
  //----------------------------------------------------------------------------
  return (
    <ListItemButton component="a" href={subItem.href} className={classes.item}>
      <ListItemIcon>
        { open ? <ArrowRightIcon /> :
          <Tooltip title={subItem.text} placement="right">
            <ArrowRightIcon />
          </Tooltip>
        }
      </ListItemIcon>
      <ListItemText primary={subItem.text} />
    </ListItemButton>
  );
}

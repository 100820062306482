import React from 'react';
import { List } from '@mui/material';
// import { makeStyles } from '@material-ui/core/styles';
import { useMutation } from "@apollo/client";
import PersonalShareInputs from './PersonalShareInputs';
import PersonalShareItem from './PersonalShareItem';
import { SHARE_DOCUMENT } from '../../constants/gqls/documents';

//******************************************************************************
// style definition
//******************************************************************************
// const useStyles = makeStyles((theme) => ({
//
// }));

//******************************************************************************
// component definition
//******************************************************************************
export default function PersonalShare({doc}) {
  // const classes = useStyles();
  const [shareDocument, { loading }] = useMutation(SHARE_DOCUMENT);
  // console.log(doc.sharedToConnection);

  //----------------------------------------------------------------------------
  // funtions
  //----------------------------------------------------------------------------
  const shareTo = (share, access, then) => {
    shareDocument({ variables: {
      docId: doc._id,
      share, access
    }}).then(res=>{
      // console.log(res);
      if (then) {
        then();
      }
    });
  }

  const shareRemove = (unShare) => {
    shareDocument({ variables: {
      docId: doc._id,
      unShare,
    }}).then(res=>{
      // console.log(res);
    });
  }

  // const shareAccess = (peopleId, access) => {
  //   shareDocument({ variables: {
  //     where: { _id: doc._id },
  //     connect: { sharedTo: {
  //       edge: { access },
  //       where: {
  //         node: { _id: peopleId }
  //       }
  //     }}
  //   }}).then(res=>{
  //     // console.log(res);
  //   });
  // }


  //----------------------------------------------------------------------------
  // component rendering
  //----------------------------------------------------------------------------
  return (
    <div>
      { doc.isOwner &&
        <PersonalShareInputs shareTo={shareTo} loading={loading} />
      }
      <List>
        {doc.sharedToSpaceConnection.edges.map(item=>(
          <PersonalShareItem key={item.node._id}
            entity={item.node} access={item.access}
            shareRemove={shareRemove} shareTo={shareTo}
            disabled={!doc.isOwner} />
        ))}
        {doc.sharedToConnection.edges.map(item=>(
          <PersonalShareItem key={item.node._id}
            entity={item.node} access={item.access}
            shareRemove={shareRemove} shareTo={shareTo}
            disabled={!doc.isOwner} />
        ))}
        {
        //   <OwnerItem />
        // {sharedList.map(item=>(
        //   <SharedItem />
        // ))}
      }
      </List>
    </div>
  )
}

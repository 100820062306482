import React from 'react';
import {useLocation } from "react-router-dom";
import { List, ListItemButton, ListItemText, ListItemIcon, Tooltip, Collapse } from '@mui/material';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import MenuSubItem from './MenuSubItem';

//******************************************************************************
// component definition
//******************************************************************************
function MenuItem({ item, open }) {
  const location = useLocation();
  const [collapse, setCollapse] = React.useState(
    item.subItems && item.subItems.map(subItem=>subItem.href).includes(location.pathname) ? true : false
  );

  //----------------------------------------------------------------------------
  // funtions
  //----------------------------------------------------------------------------
  const handleClick = () => {
    setCollapse(!collapse);
  };

  //----------------------------------------------------------------------------
  // component rendering
  //----------------------------------------------------------------------------
  return (
    <div>
      <ListItemButton component="a" href={item.href} onClick={handleClick}>
        <ListItemIcon>
          {open ? item.icon :
            <Tooltip title={item.text} placement="right">
              {item.icon}
            </Tooltip>
          }
        </ListItemIcon>
        <ListItemText primary={item.text} />
        {item.subItems && (collapse ? <ExpandLess /> : <ExpandMore />)}
      </ListItemButton>
      { item.subItems &&
        <Collapse in={collapse} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            { item.subItems.map((subItem, index) =>(
              <MenuSubItem key={index} subItem={subItem} open={open} />
            ))}
          </List>
        </Collapse>
      }
    </div>
  );
}

export default MenuItem;

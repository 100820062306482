import React from 'react';
import { Button, TextField, Stack,
  Dialog, DialogTitle, DialogContent, DialogActions } from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import SaveIcon from '@mui/icons-material/Save';
// import { makeStyles } from '@material-ui/core/styles';
import { useMutation, useQuery } from "@apollo/client";
import TagTypeInput from '../InputsCommt/TagTypeInput';
import ScopeSelect from '../InputsCommt/ScopeSelect';
import Tag from '../Tag';
import { ME } from '../../constants/gqls/user';
import { CREATE_SYS_TAG, CREATE_PERSONAL_TAG, UPDATE_TAG,
  TAGS, MY_TAGS } from '../../constants/gqls/tags';

//******************************************************************************
// style definition
//******************************************************************************
// const useStyles = makeStyles((theme) => ({
//   stack: {
//     marginTop: theme.spacing(2),
//     marginBottom: theme.spacing(3),
//   },
//   btn: {
//     width: '180px'
//   }
// }));

//******************************************************************************
// component definition
//******************************************************************************
export default function TagForm({open, onClose, defaultScope, tag}) {
  // const classes = useStyles();
  const { data } = useQuery(ME);
  const [key, setKey] = React.useState(tag ? tag.key : '');
  const [description, setDescription] = React.useState(tag ? tag.description : '');
  const [tagType, setTagType] = React.useState(tag ? tag.type : null);
  const [scope, setScope] = React.useState(tag ? tag.scope : [defaultScope]);
  const [createSysTag, { loading:createSysLoading }] = useMutation(CREATE_SYS_TAG, {
    refetchQueries: [ TAGS ],
  });
  const [createPersonalTag, { loading:createLoading }] = useMutation(CREATE_PERSONAL_TAG, {
    refetchQueries: [ MY_TAGS ],
  });
  const [updateSysTag, { loading:updateLoading }] = useMutation(UPDATE_TAG);
  const isAdmin = (data && data.me) ? data.me.roles.includes('admin') : false;
  // console.log(isAdmin);

  //----------------------------------------------------------------------------
  // funtions
  //----------------------------------------------------------------------------
  const onKeyChange = (event) => {
    setKey(event.target.value);
  }

  const onDecriptionChange = (event) => {
    setDescription(event.target.value);
  }

  // const onScopeChange = (event) => {
  //   setScope(event.target.value);
  // }

  // const onTypeChange = (type) => {
  //   setTagType(type);
  // }

  const save = () => {
    if (key.trim() === '') {
      return 0;
    }
    if (tag) {
      let type = undefined;
      if (tagType) {
        type = {
          connect: { where:{
            node:{ _id: tagType._id }
          }},
          disconnect: { where:{
            node:{ _id_NOT: tagType._id }
          }}
        }
      }
      updateSysTag({ variables: {
        where: { _id: tag._id },
        update: { key, description,
          scope: scope,
          type,
        }
      }}).then(res => {
        // console.log(res);
        onClose();
      });
    } else {
      if (defaultScope === 'personal') {
        createPersonalTag({ variables: {
          key, description
        }}).then(res => {
          // console.log(res);
          onClose();
        });
      } else {
        createSysTag({ variables: {
          key, description, scope,
          type: tagType._id,
        }}).then(res => {
          // console.log(res);
          onClose();
        });
      }
    }
  }

  //----------------------------------------------------------------------------
  // component rendering
  //----------------------------------------------------------------------------
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>{(tag? 'Edit':'New')+' '+defaultScope} tag</DialogTitle>
      <DialogContent>
        <TextField id="tag-key" fullWidth margin="dense"
          variant="standard" label="Key"
          value={key} onChange={onKeyChange} />
        <TextField id="tag-description" multiline fullWidth margin="dense"
          variant="standard" rows={3} label="Description"
          value={description} onChange={onDecriptionChange}/>
        <ScopeSelect value={scope} onChange={setScope} disabled={!isAdmin} />
        { isAdmin && scope.includes('system') &&
          <TagTypeInput value={tagType} onChange={setTagType} />
        }
        <p>Preview:</p>
        <Stack direction="row" alignItems="center" justifyContent="center">
          <Tag text={key || "key value"} type={tagType} />
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <LoadingButton variant="contained" startIcon={<SaveIcon />}
          loading={createSysLoading || updateLoading || createLoading }
          loadingPosition="start"
          onClick={save}>
          Save
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
}

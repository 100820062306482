import React from 'react';
import { useParams, generatePath } from "react-router-dom";
import { Container, Paper, LinearProgress, IconButton, CardHeader, CardContent } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import { makeStyles } from '@material-ui/core/styles';
import { useQuery } from "@apollo/client";
import SessionDetails from '../components/ServiceSessions/SessionDetails';
import SSAttendancesList from '../components/Attendances/SSAttendancesList';
import NotFount from './NotFount';
import { SERVICE_SESSION } from '../constants/gqls/serviceSessions';
import SiteMap from '../constants/siteMap';

//******************************************************************************
// style definition
//******************************************************************************
const useStyles = makeStyles((theme) => ({
  container: {
    paddingTop: theme.spacing(3)
  },
  secondPaper: {
    borderTop: '1px dashed '+theme.palette.primary.light
  },
  link: {
    color: theme.palette.primary.dark,
    textDecoration: 'none'
  }
}));

//******************************************************************************
// component definition
//******************************************************************************
export default function ServiceSession() {
  const classes = useStyles();
  const params = useParams();
  const [editMode, setEditMode] = React.useState(false);
  const { loading:queryLoading, data } = useQuery(SERVICE_SESSION, { variables: {
    id: params.id
  }});
  // console.log(data);


  //----------------------------------------------------------------------------
  // funtions
  //----------------------------------------------------------------------------
  const editClicked = () => {
    setEditMode(!editMode);
  }


  //----------------------------------------------------------------------------
  // component rendering
  //----------------------------------------------------------------------------
  if (queryLoading) return <LinearProgress />;
  if (!data) return 'Network error.'
  if (!data.serviceSession) return <NotFount />

  return (
    <Container maxWidth="xl" className={classes.container}>
      <Paper>
        <CardHeader title={
            <span>
              <a className={classes.link}
                href={generatePath(SiteMap.service, { 
                  key: data.serviceSession.service.key || data.serviceSession.service._id 
                })}>
                  {data.serviceSession.service.name}
              </a> Session
            </span>
          }
          action={(data.serviceSession.myRole === 'Teacher' || data.serviceSession.myRole === 'manager' || data.serviceSession.myRole === 'admin')?
            <IconButton onClick={editClicked}>
              <EditIcon />
            </IconButton>
            : null
          } />
        <SessionDetails serviceSession={data.serviceSession}
          editMode={editMode} editClicked={editClicked} />
      </Paper>
      { data.serviceSession.status !== 'Created' &&
        <Paper className={classes.secondPaper}>
          <CardHeader title="Attendances" />
          <CardContent>
            <SSAttendancesList serviceSessionId={data.serviceSession._id}
              sParticipants={data.serviceSession.participantsConnection.edges}
              isProvider={data.serviceSession.isProvider}
              providerRoles={data.serviceSession.service.providerRoles}
              myRole={data.serviceSession.myRole} />
          </CardContent>
        </Paper>
      }
    </Container>
  );
}

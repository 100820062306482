import React from 'react';
import { Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions,
   Button } from '@mui/material';
import { useMutation } from "@apollo/client";
import { makeStyles } from '@material-ui/core/styles';
import ValueField from './ValueField';
import { CREATE_VALUE_FIELD } from '../../../constants/gqls/dataValues';

//******************************************************************************
// style definition
//******************************************************************************
const useStyles = makeStyles((theme) => ({
  dialog: {
    '& .MuiPaper-root': {
      width: '600px'
    }
  },
  dialogText: {
    '&.MuiDialogContentText-root': {
      marginBottom: theme.spacing(2),
    }
  }
}));

//******************************************************************************
// component definition
//******************************************************************************
export default function InputValueSettingDialog({open, handleClose, inputId, valueFields }) {
  const classes = useStyles();
  const [createValueField, { loading }] = useMutation(CREATE_VALUE_FIELD);
  // const { data }  = useQuery(QUERY_VALUE_KEYS, { variables: {
  //   where: { _id: inputId },
  //   options: { sort: { index: 'ASC' }}
  // }});

  //----------------------------------------------------------------------------
  // funtions
  //----------------------------------------------------------------------------
  const handleCreate = () => {
    createValueField({ variables: { inputId }}).then(res=>{
      // console.log(res);
    });
  };

  //----------------------------------------------------------------------------
  // component rendering
  //----------------------------------------------------------------------------
  return (
    <Dialog open={open} onClose={handleClose} className={classes.dialog}>
      <DialogTitle>Velue Fields</DialogTitle>
      <DialogContent>
        <DialogContentText className={classes.dialogText}>
          Define field names of this data set.
        </DialogContentText>
        { valueFields.map(valueField=>(
          <ValueField key={valueField._id} 
            valueField={valueField} 
            count={valueFields.length}
            inputId={inputId} />
        ))}
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCreate} disabled={loading}>
          Add Field
        </Button>
      </DialogActions>
    </Dialog>
  )
}

import React from 'react';
import DataNeo4jQuery from './DataNeo4jQuery';


//******************************************************************************
// component definition
//******************************************************************************
export default function DataView({dataTable, setEditMode}) {

  //----------------------------------------------------------------------------
  // component rendering
  //----------------------------------------------------------------------------
  if (dataTable.reportType === 'CSV file') return 'CSV file';
  return <DataNeo4jQuery dataTable={dataTable} setEditMode={setEditMode} />
}

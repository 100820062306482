import React from 'react';
import { Grid, TextField, IconButton, Autocomplete } from '@mui/material';
import ClearIcon from '@mui/icons-material/Clear';

// const valueOptions = [
//   '[[owner.email]]',
//   '[[applicant.email]]',
//   '[[applicant.name]]'
// ];

function isVar(value) {
  let varRegex = /^\[\[(.*\..*)\]\]$/;
  let varMatch = value.match(varRegex);
  if (varMatch) {
    return varMatch[1];
  }
  return null;
}

//******************************************************************************
// component definition
//******************************************************************************
export default function ActionVariableItem({variable, index, onUpdate, setChanged, loading, options, onDelete}) {
  const [key, setKey] = React.useState(variable[0]);
  const [value, setValue] = React.useState(variable[1]);
  const keyNotEditable = (key === 'from' || key === 'to');
  // console.log(key, value);

  //----------------------------------------------------------------------------
  // funtions
  //----------------------------------------------------------------------------
  const handleKeyChange = (event) => {
    // console.log('handleKeyChange');
    setKey(event.target.value);
    setChanged();
  };

  const handleValueChange = (event, newValue) => {
    // console.log('handleValueChange');
    // console.log(newValue, value);
    if (newValue !== value) {
      setValue(newValue);
      if (key!=='to' && key!=='from' && key!=='cc') {
        let newKey = isVar(newValue);
        if (newKey) {
          setKey(newKey);
        }
      }
      setChanged();
    }
  };

  const handleUpdate = () => {
    onUpdate(index, key, value);
  };

  const handleDelete = () => {
    onDelete(index);
  }

  //----------------------------------------------------------------------------
  // component rendering
  //----------------------------------------------------------------------------
  return (
    <React.Fragment>
      <Grid item xs={4}>
        <TextField fullWidth size="small" variant="outlined" label="Key"
          value={key} disabled={keyNotEditable || loading}
          onChange={handleKeyChange} onBlur={handleUpdate} />
      </Grid>
      <Grid item xs={7}>
        <Autocomplete freeSolo id={"action-var-"+key} size="small"
          options={options}
          renderInput={(params) => <TextField {...params} label="value" />}
          value={value} disabled={loading}
          onInputChange={handleValueChange} onBlur={handleUpdate}
        />
      </Grid>
      { !keyNotEditable &&
        <Grid item xs={1}>
          <IconButton size="small" onClick={handleDelete}>
            <ClearIcon fontSize="inherit" />
          </IconButton>
        </Grid>
      }
    </React.Fragment>
  );
}

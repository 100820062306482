import React from 'react';
import { Navigate, useLocation } from "react-router-dom";
import { useReactiveVar } from '@apollo/client';
import { isAuthVar } from '../constants/cache';
import SiteMap from '../constants/siteMap';

//******************************************************************************
// component definition
//******************************************************************************
function RequireAuth({ children }) {
  const location = useLocation();
  const isAuth = useReactiveVar(isAuthVar);
  // console.log(isAuth);

  if (!isAuth) {
    // Redirect them to the /login page, but save the current location they were
    // trying to go to when they were redirected. This allows us to send them
    // along to that page after they login, which is a nicer user experience
    // than dropping them off on the home page.
    return <Navigate to={SiteMap.login} state={{ from: location }} />;
  }

  return children;
}

export default RequireAuth;

import React from 'react';
import { useParams } from "react-router-dom";
import { FormControl, FormLabel, FormGroup, FormControlLabel, FormHelperText,
  Checkbox, CircularProgress  } from '@mui/material';
import { useMutation, useQuery } from "@apollo/client";
// import { DOCUMENT } from '../../constants/gqls/documents';
import { UPDATE_ITEM } from '../../constants/gqls/items';
import { OPTIONS_IN_FORM } from '../../constants/gqls/forms';

function getOptions(optionsInForm, item) {
  console.log(optionsInForm);
  console.log(item);
  let itemOptions = item.input?.optionsConnection.edges.map(opt=>opt.node._id) || [];
  let options = optionsInForm.filter(opt=>!itemOptions.includes(opt._id));
  return options;
}

//******************************************************************************
// component definition
//******************************************************************************
export default function SetPreconditions({item}) {
  const params = useParams();
  const [options, setOptions] = React.useState([]);
  const { data, loading:queryLoading } = useQuery(OPTIONS_IN_FORM, {
    variables: { formId: params.id }
  });
  const [updateItem, { loading }] = useMutation(UPDATE_ITEM);
  const preconditions = item.preconditions? item.preconditions.map(opt=>opt._id) : [];

  React.useEffect(() => {
    if (data) {
      setOptions(getOptions(data.optionsInForm, item));
    }
  }, [data, item]);

  //----------------------------------------------------------------------------
  // funtions
  //----------------------------------------------------------------------------
  const handleUpdate = (event) => {
    let newlist = [...preconditions];
    if (event.target.checked) {
      newlist.push(event.target.name);
    } else {
      newlist = newlist.filter(optId=>optId!==event.target.name);
    }
    updateItem({ variables: {
      itemId: item._id,
      preconditions: newlist
    }}).then(res=>{
      console.log(res);
    });
  }

  //----------------------------------------------------------------------------
  // component rendering
  //----------------------------------------------------------------------------
  return (
    <FormControl component="fieldset" variant="standard" disabled={loading}>
      <FormLabel component="legend">Precondition options</FormLabel>
      { data?
        <FormGroup>
        { options.map(option=>
          <FormControlLabel key={option._id}
            control={
              <Checkbox checked={preconditions.includes(option._id)}
                onChange={handleUpdate} name={option._id} />
            }
            label={option.text}
          />
        )}
        </FormGroup>
        : queryLoading?
        <CircularProgress  /> : <FormHelperText>Fetch data failed.</FormHelperText>
      }
    </FormControl>
  )
}

import React from 'react';
import { FormControl, InputLabel, Select, MenuItem } from '@mui/material';


function getTimeOption() {
  let options = [];
  for (var start = 8; start < 18; start++) {
    options.push(`${start}:00 ~ ${start+1}:00`);
    options.push(`${start}:30 ~ ${start+1}:30`);
  }
  return options;
}

const timeOptions = getTimeOption();

//******************************************************************************
// component definition
//******************************************************************************
export default function TimeInput({value, handleChange, loading}) {

  //----------------------------------------------------------------------------
  // component rendering
  //----------------------------------------------------------------------------
  return (
    <FormControl fullWidth disabled={loading}>
      <InputLabel>Select time</InputLabel>
      <Select label="Select time"
        value={value} onChange={handleChange}
      >
        { timeOptions.map(option=>
          <MenuItem key={option} value={option}>
            {option}
          </MenuItem>
        )}
      </Select>
    </FormControl>
  )
}

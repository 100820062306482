import React from 'react';
import { useNavigate } from "react-router-dom";
import { ListItemButton, ListItemIcon, ListItemText, Dialog,
  DialogTitle, DialogContent, DialogActions, Button } from '@mui/material';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import { useApolloClient } from '@apollo/client';
import { isAuthVar } from '../../constants/cache';
import SiteMap from '../../constants/siteMap';

//******************************************************************************
// component definition
//******************************************************************************
export default function LogoutBtn() {
  const client = useApolloClient();
  const navigate = useNavigate();
  const [open, setOpen] = React.useState(false);

  //----------------------------------------------------------------------------
  // funtions
  //----------------------------------------------------------------------------
  const handleClose = ()=>{
    setOpen(false);
  };

  const handleOpen = ()=>{
    setOpen(true);
  };

  const logout = () => {
    localStorage.removeItem('exp');
    localStorage.removeItem('loginToken');
    isAuthVar(false);
    setOpen(false);
    // client.close();
    client.clearStore();
    navigate(SiteMap.home, { replace: true });
  }

  //----------------------------------------------------------------------------
  // component rendering
  //----------------------------------------------------------------------------
  return (
    <React.Fragment>
      <ListItemButton onClick={handleOpen}>
        <ListItemIcon>
          <ExitToAppIcon />
        </ListItemIcon>
        <ListItemText primary="Logout" />
      </ListItemButton>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>
          Confirm Logout
        </DialogTitle>
        <DialogContent>
          Click logout to exit.
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Stay</Button>
          <Button variant="outlined"
            onClick={logout}>
            Logout
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}

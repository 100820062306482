import React from 'react';
import { FormControl, InputLabel, Select, MenuItem, Grid } from '@mui/material';
import { useQuery, useReactiveVar } from "@apollo/client";
import { dataMap, formFilledCheck } from '../../constants/cache';
import { TUT_HOUR_IMPORTED_DATA } from '../../constants/gqls/forms';


function getOptions(importedData, inFilterData) {
  // console.log(importedData);
  // console.log(inFilterData);
  
  if (!importedData) {
    return [];
  }
  // initial options
  let options = importedData.weeklyDayOptions.map(item=>({
    label: item,
    disabled: true,
    timeOptions: []
  }));

  // deal with filterNot value
  let dataFilterCount = importedData.dataFilterCount;
  let filterNotOpts = [];
  let filterData = [];
  // find the filterNot value and do not include it in filterData
  for (var i = 0; i < inFilterData.length; i++) {
    if (inFilterData[i].value === 'filterNot') {
      let fnOpts = inFilterData[i].input.options.map(opt=>opt._id);
      filterNotOpts.push(...fnOpts);
      dataFilterCount -= 1;
    } else {
      filterData.push(inFilterData[i]);
    }
  }
  // console.log(filterNotOpts);
  
  
  // remove the filterNot options from importedData.dataItems
  // let dataItems = [];
  // for (let i = 0; i < importedData.dataItems.length; i++) {
  //   dataItems.push({
  //     filterOptionIds: importedData.dataItems[i].filterOptionIds.filter(id=>!filterNotOpts.includes(id)),
  //     value: importedData.dataItems[i].value
  //   });
  // }


  // check filterData
  if (filterData.length !== dataFilterCount) {
    return options;
  }
  let optObj = {};
  for (var j = 0; j < importedData.dataItems.length; j++) {
    let filterOptionIds = importedData.dataItems[j].filterOptionIds;
    let filterDataMatchs = filterData.filter(data=>filterOptionIds.includes(data.options[0]._id));
    if (filterDataMatchs.length === dataFilterCount) {
      let [dayValue, timeValue] = importedData.dataItems[j].value.split(' => ');
      if (!optObj[dayValue]) {
        optObj[dayValue] = { timeObj: {} };
      }
      optObj[dayValue].timeObj[timeValue] = { value: importedData.dataItems[j].value }
    }
  }
  for (var k = 0; k < options.length; k++) {
    let optItem = optObj[options[k].label];
    options[k].disabled = !optItem;
    if (optItem) {
      options[k].timeOptions = importedData.weeklyHourOptions.map(item=>(
        optItem.timeObj[item]?
          { label: item,  disabled: false, value: optItem.timeObj[item].value }
          : { label: item,  disabled: true, value: '' }
      ));
    }
  }
  return options;
}

// function getTimeOptions(swesImportedData, day, filterData, thisValue) {
//   if (!swesImportedData) {
//     return [];
//   }
//   // initial options
//   let options = swesImportedData.weeklyHourOptions.map(item=>({label: item, disabled: true}));
//   // check filterData
//   if (filterData.length !== swesImportedData.dataFilterCount || !day) {
//     return options;
//   }
//   let optObj = {};
//   for (var i = 0; i < swesImportedData.dataItems.length; i++) {
//     let filterDataMatchs = filterData.filter(data=>(
//       swesImportedData.dataItems[i].filterOptionIds.includes(data.options[0]._id) &&
//       swesImportedData.dataItems[i].value.includes(day)
//     ));
//     if (filterDataMatchs.length === swesImportedData.dataFilterCount) {
//       let [_, timeValue] = swesImportedData.dataItems[i].value.split(' => ');
//       optObj[timeValue] = true;
//     }
//   }
//   for (var i = 0; i < options.length; i++) {
//     options[i].disabled = !optObj[options[i].label];
//     options[i].value = day+' => '+options[i].label;
//   }
//   return options;
// }

//******************************************************************************
// component definition
//******************************************************************************
export default function TutoringHour({id, disabled, value, updateData}) {
  const dataMapVar = useReactiveVar(dataMap);
  const { data } = useQuery(TUT_HOUR_IMPORTED_DATA, { variables: {
    inputId: id,
  }});
  const [options, setOptions] = React.useState([]);
  // const [dayOptions, setDayOptions] = React.useState([]);
  const [dataOptions, setDataOptions] = React.useState([]);
  const [day, setDay] = React.useState('');
  

  // console.log(id);
  // console.log(data);
  // console.log(filterData);
  // console.log(options);

  // React.useEffect(() => {
  //   if (data) {
  //     let oldCheck = formFilledCheck();
  //     let [dayOpts, dataOpts, valueFound] = getOptions(data.swesImportedData, filterData, value);
  //     setDayOptions(dayOpts);
  //     setDataOptions(dataOpts);
  //     if (valueFound) {
  //       setDay(value.split(' => ')[0]);
  //       oldCheck[id] = true;
  //       formFilledCheck(oldCheck);
  //       let checked = true;
  //       for (const property in oldCheck) {
  //         if (!oldCheck[property]) {
  //           checked = false;
  //         }
  //       }
  //       formFilledOK(checked);
  //     } else {
  //       setDay(dayOpts[0]);
  //       oldCheck[id] = false;
  //       formFilledCheck(oldCheck);
  //       formFilledOK(false);
  //     }
  //   }
  // }, [data, filterData, value, id]);

  React.useEffect(() => {
    if (data) {
      let filterData = dataMapVar?.get('data-filter') || [];
      let opts = getOptions(data.tutHourImportedData, filterData);
      setOptions(opts);
    }
  }, [data, dataMapVar]);

  React.useEffect(() => {
    if (day && options.length !== 0) {
      let opts = options.filter(opt=>opt.label===day)[0]?.timeOptions;
      setDataOptions(opts || []);
    }
  }, [day, options]);

  React.useEffect(() => {
    let oldCheck = {...formFilledCheck()};
    let valueFound = false;
    if (value) {
      let [dayValue, ] = value.split(' => ');
      let opts = options.filter(opt=>opt.label===dayValue)[0]?.timeOptions;
      if (opts) {
        valueFound = opts.filter(opt=>opt.value===value)[0];
        if (valueFound) {
          setDay(dayValue);
          oldCheck[id] = true;
          formFilledCheck(oldCheck);
        }
      }
    }
    if (!valueFound) {
      oldCheck[id] = false;
      formFilledCheck(oldCheck);
    }
  }, [id, value, options]);

  // React.useEffect(() => {
  //   if (value) {
  //     setDay(value.split(' => ')[0])
  //   }
  // }, [value]);

  //----------------------------------------------------------------------------
  // funtions
  //----------------------------------------------------------------------------
  const handleDayChange = (event) => {
    setDay(event.target.value);
  };

  const handleChange = (event) => {
    updateData({
      value: event.target.value
    }).then(res => {
      // console.log(res);
    });
  };

  //----------------------------------------------------------------------------
  // component rendering
  //----------------------------------------------------------------------------
  return (
    <Grid container spacing={2}>
      <Grid item xs={5}>
        <FormControl fullWidth id={id} disabled={disabled}>
          <InputLabel>Select week day</InputLabel>
          { <Select id={'tutoring-day-'+id} label="Select week day"
              value={day} onChange={handleDayChange}
            >
              { options.map(option=>
                <MenuItem key={option.label} value={option.label} disabled={option.disabled}>
                  {option.label}
                </MenuItem>
              )}
            </Select>
          }
        </FormControl>
      </Grid>
      <Grid item xs={7}>
        <FormControl fullWidth id={id} disabled={disabled}>
          <InputLabel>Select time</InputLabel>
          { <Select id={'tutoring-hour-'+id} label="Select time"
              value={value || ''} onChange={handleChange}
            >
              { dataOptions.map((option, i)=>
                <MenuItem key={i} value={option.value} disabled={option.disabled}>
                  {option.label}
                </MenuItem>
              )}
            </Select>
          }
        </FormControl>
      </Grid>
      { value &&
        <Grid item xs={12}>
          Selected: {value}
        </Grid>
      }
    </Grid>
  )
}

import React from 'react';
import { Popover, IconButton } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import { makeStyles } from '@material-ui/core/styles';
import { useMutation } from "@apollo/client";
import { DELETE_ACTION, DOCUMENT_ACTIONS, SERVICE_ACTIONS, CERT_TYPE_ACTIONS } from '../../constants/gqls/actions';


//******************************************************************************
// style definition
//******************************************************************************
const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiPaper-root": {
      borderRadius: '20px',
      backgroundColor: theme.palette.primary.pale,
    }
  }
}));

//******************************************************************************
// component definition
//******************************************************************************
export default function ActionFunctions({action, anchorEl, handleClose}) {
  const classes = useStyles();
  const [deleteAction, { loading }] = useMutation(DELETE_ACTION, {
    refetchQueries: [ DOCUMENT_ACTIONS, SERVICE_ACTIONS, CERT_TYPE_ACTIONS ],
  });
  const open = Boolean(anchorEl);

  //----------------------------------------------------------------------------
  // funtions
  //----------------------------------------------------------------------------
  const handleDelete = () => {
    deleteAction({ variables: { id: action._id }});
  };

  //----------------------------------------------------------------------------
  // component rendering
  //----------------------------------------------------------------------------
  return (
    <Popover open={open} anchorEl={anchorEl} onClose={handleClose}
      anchorOrigin={{
        vertical: 'center',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'center',
        horizontal: 'right',
      }} className={classes.root}
    >
      <IconButton onClick={handleDelete} disabled={loading}>
        <DeleteIcon />
      </IconButton>
    </Popover>
  )
}

import React from 'react';
import { generatePath } from "react-router-dom";
import { ListItemButton, ListItemText } from '@mui/material';
// import { makeStyles } from '@material-ui/core/styles';
import SiteMap from '../../constants/siteMap';

//******************************************************************************
// style definition
//******************************************************************************
// const useStyles = makeStyles((theme) => ({
// }));

//******************************************************************************
// component definition
//******************************************************************************
export default function PageItem({ page }) {
  const href = generatePath(SiteMap.pageManage, { id: page._id });

  //----------------------------------------------------------------------------
  // component rendering
  //----------------------------------------------------------------------------
  return (
    <ListItemButton component="a" href={href}>
      <ListItemText primary={page.name} />
    </ListItemButton>
  );
}

import React from 'react';
// import { makeStyles } from '@material-ui/core/styles';
import { Button } from '@mui/material';
// import { useQuery, useMutation } from "@apollo/client";
// import { VALUE_SET_INPUTS, UPDATE_INPUT } from '../../../constants/gqls/items';
import ValueSetInputDialog from './ValueSetInputDialog';

//******************************************************************************
// style definition
//******************************************************************************
// const useStyles = makeStyles((theme) => ({
//   dialog: {
//     '& .MuiPaper-root': {
//       width: '600px'
//     },
//   }
// }));

//******************************************************************************
// component definition
//******************************************************************************
export default function ValueSetInputBtn({type, valueSetInput, displayValueFields, defaultFieldValues, updateInput, loading}) {
  // const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  // const [options, setOptions] = React.useState([]);
  // const { data } = useQuery(VALUE_SET_INPUTS);
  // const [updateInput, { loading }] = useMutation(UPDATE_INPUT);
  // console.log(input);

  //----------------------------------------------------------------------------
  // funtions
  //----------------------------------------------------------------------------
  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  // const handleChange = (event) => {
  //   updateInput({ variables: {
  //     inputId: input._id,
  //     update: {
  //       valueSetInput: { 
  //         connect: { where: {
  //           node: { _id: event.target.value}
  //         }},
  //         disconnect: { where: {
  //           node: { _id_NOT: event.target.value}
  //         }}
  //       }
  //     }
  //   }}).then(res=>{
  //     // console.log(res);
  //   });
  // };

  //----------------------------------------------------------------------------
  // component rendering
  //----------------------------------------------------------------------------
  return (
    <React.Fragment>
      <Button onClick={handleOpen}>{valueSetInput?.profileKey?.key || 'Set profile input'}</Button>
      <ValueSetInputDialog open={open} handleClose={handleClose}
        valueSetInput={valueSetInput}
        type={type}
        displayValueFields={displayValueFields}
        defaultFieldValues={defaultFieldValues}
        loading={loading} 
        updateInput={updateInput} /> 
    </React.Fragment>
  )
}

import React from 'react';
import { Outlet } from "react-router-dom";
import { Toolbar } from '@mui/material';
import { makeStyles } from '@material-ui/core/styles';
import HeaderBar from './HeaderBar';
import DrawerMenu from './DrawerMenu';

//******************************************************************************
// style definition
//******************************************************************************
const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex'
  },
  main: {
    flexGrow: 1,
    paddingBottom: theme.spacing(6),
  }
}));

//******************************************************************************
// component definition
//******************************************************************************
function PagesCommt() {
  const classes = useStyles();
  const [menuOpen, setMemuOpen] = React.useState(false);

  //----------------------------------------------------------------------------
  // funtions
  //----------------------------------------------------------------------------
  const handleDrawer = () => {
    setMemuOpen(!menuOpen);
  };

  //----------------------------------------------------------------------------
  // component rendering
  //----------------------------------------------------------------------------
  return (
    <div className={classes.root}>
      <HeaderBar handleDrawer={handleDrawer} />
      <DrawerMenu menuOpen={menuOpen} handleDrawer={handleDrawer} />
      <main className={classes.main}>
        <Toolbar />
        <Outlet />
      </main>
    </div>
  );
}

export default PagesCommt;

import React from 'react';
import { Button, Dialog, DialogContent, IconButton } from '@mui/material';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import { makeStyles } from '@material-ui/core/styles';
import { useMutation } from "@apollo/client";
import ValueSet from './ValueSets/ValueSet';
import InputDataView from './InputDataView';
import { REMOVE_VALUE_SET, SELECT_VALUE_SET } from '../../constants/gqls/dataValues';
import { PROFILE_DATA } from '../../constants/gqls/forms';
import { valueSetSelectedRefetch } from '../../constants/cache';

//******************************************************************************
// style definition
//******************************************************************************
const useStyles = makeStyles((theme) => ({
  btnBox: {
    display: 'flex',
    '& button': {
      margin: 'auto'
    }
  },
  rMode: {
    position: 'relative',
    backgroundColor: theme.palette.primary.pale,
    padding: theme.spacing(1),
    // paddingLeft: theme.spacing(2),
  },
  content: {
    '&.css-ypiqx9-MuiDialogContent-root': {
      overflow: 'hidden',
      width: '600px',
      maxWidth: '100%'
    }
  },
  slideBox: {
    display: 'flex',
    // whiteSpace: 'nowrap',
    transition: 'ease 600ms',
    // overflow: 'hidden',
    '& .slideItem': {
      // display: 'inline-block',
      minWidth: '100%',
      padding: '0 24px',
    },
  },
  slideBtn: {
    position: 'absolute',
    top: 0,
    height: '100%',
    display: 'flex',
    alignItems: 'center'
  },
  left: {
    left: 0
  },
  right: {
    right: 0
  }
}));


//******************************************************************************
// component definition
//******************************************************************************
export default function ValueSetSingleInput({item, data, disabled}) {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [btnDisabled, setBtnDisabled] = React.useState(false);
  const [viewValueFields, setViewValueFields] = React.useState([]);
  const [index, setIndex] = React.useState(0);
  const [selectValueSet, { loading: selectLoading }] = useMutation(SELECT_VALUE_SET, {
    refetchQueries: [ PROFILE_DATA ],
  });
  const [removeValueSet, { loading: removeLoading }] = useMutation(REMOVE_VALUE_SET);
  // console.log(viewValueFields);

  React.useEffect(() => {
    setBtnDisabled(disabled || selectLoading || removeLoading)
  }, [disabled, selectLoading, removeLoading]);

  React.useEffect(() => {
    let valueFields = item.input.valueSetInput?.valueFields.map(vf=>vf._id) || [];
    let displayValueFields = item.input.displayValueFields || valueFields;
    // if (item.input.displayValueFields) {
      setViewValueFields(valueFields.filter(vf => displayValueFields.includes(vf)));
    // } else {
    //   setViewValueFields(valueFields);
    // }
  }, [item.input]);

  // console.log(item);
  // console.log(data);

  //----------------------------------------------------------------------------
  // funtions
  //----------------------------------------------------------------------------
  const handleClickOpen = (_, vsId) => {
    if (data?.getSelectedData && data.getSelectedData.valueSetList.length > 0) {
      if (vsId) {
        let vsIndex = 0;
        // search for index in value set data list
        for (let i = 0; i < data.getValueSetData.valueSetList.length; i++) {
          let vs = data.getValueSetData.valueSetList[i];
          if (vs._id === vsId) {
            vsIndex = i;
          }
        }
        setIndex(vsIndex);
        setOpen(true);
      }
    } else {
      setIndex(0);
      setOpen(true);
    }
  };

  const handleClose = () => {
    setOpen(false);
  };

  const goLeft = () => {
    if (index > 0) {
      setIndex(index-1);
    }
  };

  const goRight = () => {
    if (data?.getValueSetData?.valueSetList && index < data.getValueSetData.valueSetList.length) {
      setIndex(index+1);
    }
  };

  const handleRemove = (valueSetId) => {
    removeValueSet({ variables: {
      inputId: item.input._id,
      valueSetId
    }}).then(res => {
      // console.log(res);
    })
  };

  const handleSelect = (valueSetId) => {
    selectValueSet({ variables: {
      inputId: item.input._id,
      valueSetId
    }}).then(res => {
      // console.log(res);
      if ((!data?.getSelectedData) || (!data?.getValueSetData)) {
        valueSetSelectedRefetch(item.input._id);
      }
    })
  };

  //----------------------------------------------------------------------------
  // component rendering
  //----------------------------------------------------------------------------
 
  return (
    <div>
      <div className={classes.rMode} onClick={()=>handleClickOpen()}>
        <InputDataView valueFields={item.input.valueSetInput?.valueFields || []} 
          viewValueFields={viewValueFields}
          singleMode={true}
          data={data?.getSelectedData} 
          openEdit={handleClickOpen} />
      </div>
      <Dialog open={open} onClose={handleClose}>
        <DialogContent className={classes.content}>
          <div className={classes.slideBox}
            style={{ transform: `translate3d(${-index * 100}%, 0, 0)` }}>
            { data?.getValueSetData? [...data.getValueSetData.valueSetList].map((valueSet, i)=>(
              <ValueSet key={valueSet._id} 
                count={data.getValueSetData.valueSetList.length} 
                index={i} setIndex={setIndex}
                valueSet={valueSet} disabled={btnDisabled}
                valueFields={item.input.valueSetInput?.valueFields || []}
                dataId={data.getValueSetData._id}
                removeValueSet={handleRemove}
                selectValueSet={handleSelect}
                selected={data.getSelectedData?.valueSetList.map(vs=>vs._id) || []} />
            )) : ''}
            <div className={classes.btnBox + ' slideItem'}>
              <Button onClick={()=>handleSelect()} disabled={btnDisabled}>
                Add Item
              </Button>
            </div>
          </div>
          <div className={classes.slideBtn+' '+classes.left}>
            <IconButton onClick={goLeft} disabled={index===0}>
              <KeyboardArrowLeftIcon />
            </IconButton>
          </div>
          <div className={classes.slideBtn+' '+classes.right}>
            <IconButton onClick={goRight} disabled={!data?.getValueSetData || index===data.getValueSetData.valueSetList.length}>
              <KeyboardArrowRightIcon />
            </IconButton>
          </div>
        </DialogContent>
      </Dialog>
    </div>
  );
}

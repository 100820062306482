import React from 'react';
import { IconButton, Dialog, DialogTitle, DialogContent, List } from '@mui/material';
import PersonAddAltIcon from '@mui/icons-material/PersonAddAlt';
import CloseIcon from '@mui/icons-material/Close';
import { makeStyles } from '@material-ui/core/styles';
import { useMutation } from "@apollo/client";
import PeopleInput from '../../InputsCommt/PeopleInput';
import { SHARE_VALUE_SET } from '../../../constants/gqls/dataValues';
import PersonOrSpaceItem from '../../Person/PersonOrSpaceItem';

//******************************************************************************
// style definition
//******************************************************************************
const useStyles = makeStyles((theme) => ({
  dialog: {
    "& .MuiPaper-root": {
      width: '600px'
    }
  }
}));

//******************************************************************************
// component definition
//******************************************************************************
export default function ShareBtn({valueSet, disabled}) {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [shareValueSet, { loading }] = useMutation(SHARE_VALUE_SET);
  // console.log(count);

  //----------------------------------------------------------------------------
  // funtions
  //----------------------------------------------------------------------------
  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleAdd = (inPerson) => {
    shareValueSet({ variables: {
      valueSetId: valueSet._id,
      personId: inPerson._id
    }}).then(res=>{
      console.log(res);
    });
  };

  const handleRemove = (personId) => {
    shareValueSet({ variables: {
      valueSetId: valueSet._id,
      personId,
      remove: true
    }}).then(res=>{
      console.log(res);
    });
  };


  //----------------------------------------------------------------------------
  // component rendering
  //----------------------------------------------------------------------------
  return (
    <>
      <IconButton onClick={handleOpen} disabled={disabled}>
        <PersonAddAltIcon />
      </IconButton>
      <Dialog open={open} onClose={handleClose} className={classes.dialog}>
        <DialogTitle>
          Share Value Set Item
        </DialogTitle>
        <DialogContent>
          <PeopleInput onChange={handleAdd}
            label="Select share person" disabled={loading} />
          <List>
            { valueSet.owners.map(owner=>(
              <PersonOrSpaceItem entity={owner} key={owner._id}>
                { owner.isMe? "Me" 
                  : 
                  <IconButton disabled={valueSet.owners.length===1}
                    onClick={()=>handleRemove(owner._id)}
                  >
                    <CloseIcon />
                  </IconButton>
                }
                
              </PersonOrSpaceItem>
            ))}
          </List>
        </DialogContent>
      </Dialog>
    </>
        
  )
}

import React from 'react';
import { generatePath } from "react-router-dom";
import { ListItemButton, ListItemText } from '@mui/material';
import { makeStyles } from '@material-ui/core/styles';
import SiteMap from '../../constants/siteMap';
import { DateToString } from '../../constants/utils';

function getParticipantsStr(edges) {
  let participants = {};
  for (var i = 0; i < edges.length; i++) {
    if (participants[edges[i].role]) {
      participants[edges[i].role].push(edges[i].node.name);
    } else {
      participants[edges[i].role] = [edges[i].node.name];
    }
  }
  let pNames = [], uNames = [];
  if (participants.Teacher) {
    pNames = pNames.concat(participants.Teacher);
  }
  if (participants.Student) {
    uNames = uNames.concat(participants.Student);
  }
  return `${pNames.join(', ')} => ${uNames.join(', ')}`;
}

//******************************************************************************
// style definition
//******************************************************************************
const useStyles = makeStyles((theme) => ({
  warnText: {
    color: theme.palette.warning.main
  }
}));

//******************************************************************************
// component definition
//******************************************************************************
export default function SessionItem({ session }) {
  const classes = useStyles();
  const href = generatePath(SiteMap.serviceSession, { id: session._id });
  const participantsStr = getParticipantsStr(session.participantsConnection.edges);
  const moreThan3day = ((new Date())-(new Date(session.createdAt))) > (1000*60*60*24*3);

  //----------------------------------------------------------------------------
  // component rendering
  //----------------------------------------------------------------------------
  return (
    <ListItemButton component="a" href={href}>
      <ListItemText primary={
        `${session.service? session.service.name+' ':''}[${session.year} S${session.semester}] ${session.categories.join(' ')} : ${participantsStr}`
      } />
      {session.status !== 'Confirmed'? session.status : `${session.hoursCompleted} / ${session.hours}`
      }
      {session.status === 'Created' && moreThan3day ? 
        <span className={classes.warnText}>
          &nbsp;{`(${DateToString(session.createdAt)})`}
        </span>
        : ''
      }
    </ListItemButton>
  );
}

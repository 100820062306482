import React from 'react';
import { ListItem, ListItemAvatar, Avatar, ListItemText } from '@mui/material';
import PersonIcon from '@mui/icons-material/Person';
import RocketLaunchIcon from '@mui/icons-material/RocketLaunch';


//******************************************************************************
// component definition
//******************************************************************************
export default function PersonOrSpaceItem({entity, children}) {

  //----------------------------------------------------------------------------
  // component rendering
  //----------------------------------------------------------------------------
  return (
    <ListItem>
      <ListItemAvatar>
        <Avatar>
          {{
            Person: <PersonIcon />,
            Space: <RocketLaunchIcon />
          }[entity.__typename]}
        </Avatar>
      </ListItemAvatar>
      <ListItemText primary={entity.name} secondary={entity.email || 'Space'} />
      {children}
   </ListItem>
  )
}

import React from 'react';
import { Grid, Select, MenuItem, Button } from '@mui/material';
// import { makeStyles } from '@material-ui/core/styles';
import PeopleOrSpaceInput from '../InputsCommt/PeopleOrSpaceInput';


//******************************************************************************
// style definition
//******************************************************************************
// const useStyles = makeStyles((theme) => ({
//
// }));

//******************************************************************************
// component definition
//******************************************************************************
export default function PersonalShareInputs({shareTo, loading}) {
  // const classes = useStyles();
  const [sharedWith, setSharedWith] = React.useState([]);
  const [access, setAccess] = React.useState('Viewer');
  // console.log(sharedWith);

  //----------------------------------------------------------------------------
  // funtions
  //----------------------------------------------------------------------------
  const handleChange = (event) => {
    setAccess(event.target.value);
  };

  const handleCancel = () => {
    setSharedWith([]);
    setAccess('Viewer');
  };

  const handleUpdate = () => {
    shareTo(sharedWith.map(item=>item._id), access, handleCancel);
  };

  //----------------------------------------------------------------------------
  // component rendering
  //----------------------------------------------------------------------------
  return (
    <Grid container spacing={2} justifyContent="center">
      <Grid item xs={sharedWith.length > 0 ? 10 : 12}>
        {/*<PeopleInput value={sharedWith} onChange={setSharedWith}
          label="" multiple={true} disabled={loading} />*/}
        <PeopleOrSpaceInput value={sharedWith} onChange={setSharedWith}
          label="" multiple={true} disabled={loading} />
      </Grid>
      { sharedWith.length > 0 &&
        <React.Fragment>
          <Grid item xs={2}>
            <Select
              value={access}
              label="Access"
              onChange={handleChange}
            >
              <MenuItem value={'Viewer'}>Viewer</MenuItem>
              <MenuItem value={'Editor'}>Editor</MenuItem>
            </Select>
          </Grid>
          <Grid item>
            <Button variant="outlined" onClick={handleCancel}>Cancel</Button>
          </Grid>
          <Grid item>
            <Button variant="contained" onClick={handleUpdate} disabled={loading}>
              OK
            </Button>
          </Grid>
        </React.Fragment>
      }
    </Grid>
  )
}

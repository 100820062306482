import React from 'react';
import { useParams } from "react-router-dom";
import { useMutation } from "@apollo/client";
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import FormSharedList from '../Forms/FormSharedList'
import { USER_TRIGGER_EMAIL } from '../../constants/gqls/forms';


//******************************************************************************
// component definition
//******************************************************************************
export default function TriggerEmailBtn({action}) {
  const params = useParams();
  const to = JSON.parse(action.variables).to;
  const [sendEmail] = useMutation(USER_TRIGGER_EMAIL);
  const [open, setOpen] = React.useState(false);
  const [message, setMessage] = React.useState(null);
  // console.log(to);

   //----------------------------------------------------------------------------
  // funtions
  //----------------------------------------------------------------------------
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setMessage(null);
  };

  const handleSend = () => {
    sendEmail({ variables: { actionId: action._id }}).then((res)=>{
      // console.log(res);
      setMessage(res.data.sendUserTriggerEmail);
    });;
  };

  //----------------------------------------------------------------------------
  // component rendering
  //----------------------------------------------------------------------------
  return (
    <>
      <Button variant="outlined" onClick={handleClickOpen}>
        Send Now
      </Button>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>
          {message? "Action respone" : "Confirm email send?"}
        </DialogTitle>
        <DialogContent>
          { message? 
            <DialogContentText>{message}</DialogContentText>
            :
            <>
              <DialogContentText>
                Email will be sending to the following list. 
              </DialogContentText>
              { to==='[[sharedTo.email]]' ?
                <FormSharedList docId={params.id} disabled={true} />
                : to
              }
            </>
          }
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>{message? "Close" : "Cancel"}</Button>
          {!message && <Button onClick={handleSend}>Send</Button>}
        </DialogActions>
      </Dialog>
    </>
  );
}

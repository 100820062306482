import React from 'react';
import { FormControl, FormGroup, FormControlLabel, Checkbox, TextField } from '@mui/material';

function toValues(data) {
  // console.log(data);
  if (!data) {
    return [];
  }
  let values = data.options.map(option=>option._id);
  if (data.value) {
    values.push('other');
  }
  // console.log(values);
  return values;
}

//******************************************************************************
// component definition
//******************************************************************************
export default function CheckboxInput({id, options, other, disabled, data, updateData}) {
  const [values, setValues] = React.useState([]);
  const [text, setText] = React.useState(data?.value || '');
  const [changed, setChanged] = React.useState(false);
  // console.log(values);

  React.useEffect(() => {
    // console.log(data);
    setText(data?.value || '');
    setValues(toValues(data))
  }, [data]);

  //----------------------------------------------------------------------------
  // funtions
  //----------------------------------------------------------------------------
  const handleChange = (event) => {
    let optionIds, value = undefined;
    if (event.target.checked) {
      optionIds = [...values, event.target.name];
      if (event.target.name === 'other') {
        setValues(optionIds);
        return 0;
      }
    } else {
      optionIds = values.filter(item=>item !== event.target.name);
      if (event.target.name === 'other') {
        setValues(optionIds);
        value = '';
      }
    }
    updateData({ optionIds, value }).then(res => {
      // console.log(res);
    });
  };

  const handleTextChange = (event) => {
    setText(event.target.value);
    setChanged(true)
  };

  const handleBlur = () => {
    if (changed) {
      updateData({ value: text }).then(res => {
        // console.log(res);
        setChanged(false);
      })
    }
  }

  //----------------------------------------------------------------------------
  // component rendering
  //----------------------------------------------------------------------------
  return (
    <FormControl fullWidth id={id} disabled={disabled}>
      <FormGroup>
        { options.map(option=>
          <FormControlLabel key={option.value}
            control={ 
              <Checkbox checked={values.includes(option.value)}
                onChange={handleChange}
                name={option.value} />
            }
            label={option.label + (option.quotaLeft !== null && option.quotaLeft !== undefined ? ` (quota left: ${option.quotaLeft})` : '')}
            disabled={disabled || (option.quotaLeft<=0 && option.quotaLeft !== null && option.quotaLeft !== undefined)}
          />
        )}
        { other &&
          <FormControlLabel
            control={
              <Checkbox checked={values.includes('other')} onChange={handleChange} name="other" />
            }
            label="Other"
          />
        }
        { values.includes('other') &&
          <TextField fullWidth id={'other-'+id}
            variant="standard" disabled={disabled}
            value={text} onChange={handleTextChange} onBlur={handleBlur} />
        }
      </FormGroup>
    </FormControl>
  )
}

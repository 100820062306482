import React from 'react';
import InfiniteScroll from 'react-infinite-scroller';
import { List, LinearProgress } from '@mui/material';
import { useQuery } from "@apollo/client";
import CertTypeItem from './CertTypeItem';
import { CERT_TYPES } from '../../constants/gqls/certificates';
const limit = 10;

//******************************************************************************
// component definition
//******************************************************************************
export default function CertTypesList({isStudent}) {
  const { loading, data, fetchMore } = useQuery(CERT_TYPES, {
    variables: { 
      limit, 
      offset: 0
    }
  });

  //----------------------------------------------------------------------------
  // funtions
  //----------------------------------------------------------------------------
  const loadMore = () => {
    fetchMore({ variables: {
      limit,
      offset: data.certTypesList.length
    }});
  }

  //----------------------------------------------------------------------------
  // component rendering
  //----------------------------------------------------------------------------
  if (loading) return <LinearProgress />;
  if (!data) return 'Network error.'
  if (data.certTypesCount === 0) {
    return 'No certificate type yet';
  }

  return (
    <InfiniteScroll
        loadMore={loadMore}
        hasMore={data.certTypesCount > data.certTypesList.length}
        loader={<LinearProgress key="loading" />}
    >
      <List>
        { data.certTypesList.map(certType=>(
          <CertTypeItem key={certType._id} 
            certType={certType} 
            isStudent={isStudent} />
        )) }
      </List>
    </InfiniteScroll>
  );
}

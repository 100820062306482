import React from 'react';
import { Button, Dialog, DialogTitle,
  DialogContent, DialogContentText, DialogActions } from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import { useMutation, useQuery } from '@apollo/client';
import { RESET_ISUGC, COUNT_ISUGC } from '../../constants/gqls/user';
  

//******************************************************************************
// component definition
//******************************************************************************
export default function ResetUGCBtn() {
  const [open, setOpen] = React.useState(false);
  const { data } = useQuery(COUNT_ISUGC);
  const [resetIsUGC, { loading }] = useMutation(RESET_ISUGC, {
    refetchQueries: [ COUNT_ISUGC ],
  });

  //----------------------------------------------------------------------------
  // funtions
  //----------------------------------------------------------------------------
  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleReset = () => {
    resetIsUGC().then(res=>{
      // console.log(res);
      setOpen(false);
    });
  }

  //----------------------------------------------------------------------------
  // component rendering
  //----------------------------------------------------------------------------
  return (
    <>
      <Button onClick={handleOpen}>
        Reset isUGC
      </Button>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>
          Confirm reset?
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            You are going to reset all current UGC students' isUGC value to false.
            <br />Total: {data? data.countIsUGC : '...'}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <LoadingButton variant="outlined"
            loading={loading}
            onClick={handleReset}>
            Reset
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </>
  );
}

import React from 'react';
import { Accordion, AccordionSummary, Typography, AccordionDetails, Button } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { makeStyles } from '@material-ui/core/styles';
import { useMutation, useQuery } from "@apollo/client";
import ActionsList from './ActionsList';
import { CREATE_ACTIONS, DOCUMENT_ACTIONS } from '../../constants/gqls/actions';

//******************************************************************************
// style definition
//******************************************************************************
const useStyles = makeStyles((theme) => ({
  content: {
    '&.MuiAccordionDetails-root': {
      padding: `0 0 ${theme.spacing(2)}px`
    }
  },
  actions: {
    display: 'flex',
    "& .MuiButton-root": {
      margin: 'auto'
    }
  },
}));

//******************************************************************************
// component definition
//******************************************************************************
export default function FormActions({docId}) {
  const classes = useStyles();
  const [open, setOpen] = React.useState(true);
  const [createAction, { loading }] = useMutation(CREATE_ACTIONS, {
    refetchQueries: [ DOCUMENT_ACTIONS ],
  });
  const actionsQuery = useQuery(DOCUMENT_ACTIONS,
    { variables: { docId }}
  );

  //----------------------------------------------------------------------------
  // funtions
  //----------------------------------------------------------------------------
  const handleClick = () => {
    setOpen(!open);
  };

  const createBtnClick = () => {
    createAction({ variables: { docId }});
  };

  //----------------------------------------------------------------------------
  // component rendering
  //----------------------------------------------------------------------------
  return (
    <Accordion expanded={open} onChange={handleClick}>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Typography>Actions</Typography>
      </AccordionSummary>
      <AccordionDetails className={classes.content}>
        <div className={classes.actions}>
          <Button variant="outlined" onClick={createBtnClick} disabled={loading}>
            Add New Action
          </Button>
        </div>
        <ActionsList actionsQuery={actionsQuery} scope="form" />
      </AccordionDetails>
    </Accordion>
  );
}

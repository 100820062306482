import React from 'react';
import { useNavigate } from "react-router-dom";
import { Paper, CardActions } from '@mui/material';
import { makeStyles } from '@material-ui/core/styles';
import Tag from '../Tag';

//******************************************************************************
// style definition
//******************************************************************************
const useStyles = makeStyles((theme) => ({
  paper: {
    position: 'relative',
    cursor: 'pointer',
    overflow: 'hidden'
  },
  content: {
    zIndex: 5,
    position: 'relative'
  },
  icon: {
    zIndex: 4,
    position: 'absolute',
    right: '-12px',
    top: '-12px',
    // backgroundColor: theme.palette.action.selected,
    "& svg": {
      color: theme.palette.secondary.light,
      width: '120px',
      height: '120px',
    }
  },
}));

//******************************************************************************
// component definition
//******************************************************************************
export default function CardComm({tags, icon, goTo, children}) {
  const classes = useStyles();
  const navigate = useNavigate();

  //----------------------------------------------------------------------------
  // funtions
  //----------------------------------------------------------------------------
  const onClick = () => {
    if (goTo) {
      navigate(goTo);
    }
  }

  //----------------------------------------------------------------------------
  // component rendering
  //----------------------------------------------------------------------------
  return (
    <Paper className={classes.paper} onClick={onClick}>
      <div className={classes.icon}>
        {icon}
      </div>
      <div className={classes.content}>
        {children}
        <CardActions>
          {tags.map(tag=>(
            <Tag key={tag._id} text={tag.key} type={tag.type} />
          ))}
        </CardActions>
      </div>
    </Paper>
  );
}

import React from 'react';
import { TextField } from '@mui/material';


//******************************************************************************
// component definition
//******************************************************************************
export default function InputOptionAdd({addOption, setFocus, loading}) {
  const [optionText, setOptionText] = React.useState('');

  //----------------------------------------------------------------------------
  // funtions
  //----------------------------------------------------------------------------
  const handleChange = (event) => {
    setOptionText(event.target.value);
  }

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      addOption(optionText).then(res=>{
        setOptionText('');
      })
    }
  }

  const handleBlur = () => {
    setFocus(false);
  }

  const handleFocus = () => {
    setFocus(true);
  }

  //----------------------------------------------------------------------------
  // component rendering
  //----------------------------------------------------------------------------
  return (
    <TextField fullWidth size="small" disabled={loading}
      id="mc-item-add" label="Add Option" variant="standard"
      value={optionText} onChange={handleChange}
      onKeyDown={handleKeyDown}
      onBlur={handleBlur} onFocus={handleFocus} />
  )
}

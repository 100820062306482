import React from 'react';
import { useNavigate, useLocation, useSearchParams } from "react-router-dom"
import { Typography, Divider, Card, CardContent, Link } from '@mui/material';
// import LoadingButton from '@mui/lab/LoadingButton';
// import GoogleIcon from '@mui/icons-material/Google';
import { makeStyles } from '@material-ui/core/styles';
import { useApolloClient, useQuery } from '@apollo/client';
// import GoogleLogin from 'react-google-login';
import { isAuthVar } from '../constants/cache';
import TestLogin from '../components/Login/TestLogin';
import SSOidLoginLoading from '../components/Login/SSOidLoginLoading';
import SSOidLoginBtn from '../components/Login/SSOidLoginBtn';
import { ME } from '../constants/gqls/user';
import bgpic from '../images/bg.png';

function getFrom(inState) {
  let from = '/'
  try {
    let state = JSON.parse(inState)
    from = state.from;
  } catch (e) {
    console.log(e);
  } finally {
    return from;
  }
}

//******************************************************************************
// style definition
//******************************************************************************
const useStyles = makeStyles((theme) => ({
  bg: {
    background: `url(${bgpic})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    position: 'fixed',
    right: '0px',
    bottom: '0px',
    top: '0px',
    left: '0px',
    height: '100vh',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    '& .MuiCard-root': {
      backdropFilter: 'blur(8px)',
      backgroundColor: 'rgba(255, 255, 255, 0.7)'
    }
  },
  content: {
    textAlign: 'center',
    '& .MuiDivider-root': {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(2),
    }
  },
  title: {
    textDecoration: 'none',
    fontFamily: 'Asap-Regular',
    fontWeight: 'bold',
    fontSize: '22px',
    color: theme.palette.primary.dark
  },
  btn: {
    margin: 'auto !important'
  },
  logo: {
    maxWidth: '90%'
  },
  pc: {
    position: 'absolute',
    bottom: 0,
    right: 0,
    textAlign: 'right',
    color: '#fff',
    fontSize: '14px',
    paddingRight: theme.spacing(2),
  }
}));

//******************************************************************************
// component definition
//******************************************************************************
function Login() {
  const [searchParams, setSearchParams] = useSearchParams();
  const classes = useStyles();
  const navigate = useNavigate();
  const location = useLocation();
  const client = useApolloClient();
  // const [prompt, setPrompt] = React.useState('select_account');
  const [errMessage, setErrMessage] = React.useState(null);
  const { data } = useQuery(ME);
  // const [login, { loading }] = useMutation(LOGIN);
  const code = searchParams.get("code");
  const state = searchParams.get("state");
  const from = location.state?.from?.pathname || getFrom(state);
  // console.log(location);
  // console.log(from);

  React.useEffect(() => {
    if (data && data.me) {
      // console.log(from);
      navigate(from, { replace: true });
    } else {
      // console.log(data);
    }
  }, [data, from, navigate]);

  //----------------------------------------------------------------------------
  // funtions
  //----------------------------------------------------------------------------
  // const responseGoogleSuccess = (response) => {
  //   setErrMessage(null);
  //   const code = response.code;
  //
  //   login({ variables: { code }}).then(res => {
  //     // console.log(res.data);
  //     if (!res.data.signIn.hasRefreshToken) {
  //       setPrompt('consent');
  //     }  else {
  //       // client.clearStore();
  //       localStorage.setItem('exp', res.data.signIn.exp);
  //       localStorage.setItem('loginToken', res.data.signIn.token);
  //       // console.log(localStorage.getItem('loginToken'));
  //       // console.log(res.data.signIn.token);
  //       isAuthVar(true);
  //       // console.log('reload');
  //       // window.location.reload();
  //       // client.close();
  //       client.resetStore();
  //       // refetch();
  //       // console.log(from);
  //       // navigate(from, { replace: true });
  //     }
  //   }, err => {
  //     console.log(err);
  //     setErrMessage(err.message);
  //   });
  // };

  // const responseGoogleFail = (response) => {
  //   console.log(response);
  // }

  const successResponse = React.useCallback((res) => {
    localStorage.setItem('exp', res.exp);
    localStorage.setItem('loginToken', res.token);
    isAuthVar(true);
    client.resetStore();
  }, [client]);

  const errorResponse = React.useCallback((msg) => {
    setErrMessage(msg);
    setSearchParams({ state: JSON.stringify({ from }) });
  }, [from, setSearchParams])


  //----------------------------------------------------------------------------
  // component rendering
  //----------------------------------------------------------------------------
  return (
    <React.Fragment>
      <div className={classes.bg}>
        <Card>
          <CardContent className={classes.content}>
            <div className={classes.title}>LCMIS</div>
            <div>
              <img className={classes.logo} alt="logo" src="/lc-logo.png" />
            </div>
            <Typography color="textSecondary">
              Language Centre Management Information System
            </Typography>
            <Divider />
            {prompt === 'consent' ?
              <Typography color="textSecondary">
                Somehow we lost your refresh token or it has expired.
                Please login again to restore the refresh token.
              </Typography>
              :
              <Typography color="textSecondary">
                Only permitted users will be able to login.
              </Typography>
            }
            {errMessage &&
              <Typography color="error">
                {errMessage}
              </Typography>
            }
          </CardContent>

          {/*
            <CardActions>
              <GoogleLogin
                clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}
                scope={"https://www.googleapis.com/auth/calendar"}
                accessType="offline"
                responseType="code"
                prompt={prompt}
                onSuccess={responseGoogleSuccess}
                onFailure={responseGoogleFail}
                disabled={loading}
                render={(renderProps) => (
                  <LoadingButton variant="contained" color="secondary"
                    startIcon={<GoogleIcon />}
                    className={classes.btn}
                    onClick={renderProps.onClick}
                    loading={renderProps.disabled}
                    loadingPosition="start">
                    Login with Google
                  </LoadingButton>
                )} />
              </CardActions>
              */}
          {process.env.REACT_APP_NODE_ENV === 'development' &&
            <TestLogin successResponse={successResponse} setErrMessage={setErrMessage} />
          }
          {code ?
            <SSOidLoginLoading
              successResponse={successResponse}
              errorResponse={errorResponse}
              code={code} from={from} />
            : process.env.REACT_APP_NODE_ENV !== 'development' && <SSOidLoginBtn state={JSON.stringify({ from })} />
          }


        </Card>
        <div className={classes.pc}>
          <p>
            <Link href="https://bupdpo.hkbu.edu.hk/policies-and-procedures/pps-pics/">
              Privacy Policy
            </Link >
            <br />
            © Copyright&nbsp;
            <Link href="https://lc.hkbu.edu.hk/">
              Language Centre
            </Link >
            ,&nbsp;
            <Link href="https://www.hkbu.edu.hk/">
            HKBU
            </Link >
          </p>
        </div>
      </div>
    </React.Fragment>
  );
}

export default Login;

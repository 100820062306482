import React from 'react';
import { Paper, CardHeader, IconButton, Tooltip } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { makeStyles } from '@material-ui/core/styles';
import { useMutation, useQuery } from "@apollo/client";
import ActionsList from './ActionsList';
import { CREATE_ACTIONS, SERVICE_ACTIONS } from '../../constants/gqls/actions';

//******************************************************************************
// style definition
//******************************************************************************
const useStyles = makeStyles((theme) => ({
  paper: {
    borderTop: '1px dashed '+theme.palette.primary.light
  },
  actions: {
    display: 'flex',
    "& .MuiButton-root": {
      margin: 'auto'
    }
  },
}));

//******************************************************************************
// component definition
//******************************************************************************
export default function ServiceActions({serviceId}) {
  const classes = useStyles();
  // const [open, setOpen] = React.useState(true);
  const [createAction, { loading }] = useMutation(CREATE_ACTIONS, {
    refetchQueries: [ SERVICE_ACTIONS ],
  });
  const actionsQuery = useQuery(SERVICE_ACTIONS,
    { variables: { serviceId }}
  );

  //----------------------------------------------------------------------------
  // funtions
  //----------------------------------------------------------------------------
  // const handleClick = () => {
  //   setOpen(!open);
  // };

  const createBtnClick = () => {
    createAction({ variables: { serviceId }});
  };

  //----------------------------------------------------------------------------
  // component rendering
  //----------------------------------------------------------------------------
  return (
    <Paper className={classes.paper}>
      <CardHeader title="Actions" action={
          <Tooltip title="Add new action">
            <span>
              <IconButton onClick={createBtnClick} disabled={loading}>
                <AddIcon />
              </IconButton>
            </span>
          </Tooltip>
        } />
      <ActionsList actionsQuery={actionsQuery} scope="service" />
    </Paper>
  );
}

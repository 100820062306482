import React from 'react';
import { Typography, Select, MenuItem, Divider, FormControl } from '@mui/material';
import PersonOrSpaceItem from '../Person/PersonOrSpaceItem';
// import { makeStyles } from '@material-ui/core/styles';


//******************************************************************************
// style definition
//******************************************************************************
// const useStyles = makeStyles((theme) => ({
//
// }));

//******************************************************************************
// component definition
//******************************************************************************
export default function PersonalShareItem({entity, access, shareRemove, shareTo, disabled}) {
  // const classes = useStyles();
  // console.log(entity.__typename);

  //----------------------------------------------------------------------------
  // funtions
  //----------------------------------------------------------------------------
  const handleChange = (event) => {
    switch (event.target.value) {
      case 'Remove':
        shareRemove([entity._id])
        break;
      default:
        shareTo([entity._id], event.target.value)
    }
  };

  //----------------------------------------------------------------------------
  // component rendering
  //----------------------------------------------------------------------------
  return (
    <PersonOrSpaceItem entity={entity}>
      { (access === 'Owner' || disabled) ?
        <Typography variant="body2" color="text.secondary">
          {access}
        </Typography>
        :
        <FormControl variant="standard" size="small">
          <Select disabled={disabled}
            value={access}
            label="Access"
            onChange={handleChange}
          >
            <MenuItem value={'Viewer'}>Viewer</MenuItem>
            <MenuItem value={'Editor'}>Editor</MenuItem>
            <Divider />
            <MenuItem value={'Remove'}>Remove</MenuItem>
          </Select>
        </FormControl>
      }
    </PersonOrSpaceItem>
  )
}

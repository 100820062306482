import React from 'react';
import { ListItemText, ListItemButton } from '@mui/material';

//******************************************************************************
// component definition
//******************************************************************************
function ProfileKeyItem({profileKey, setProfileKey }) {

  //----------------------------------------------------------------------------
  // funtions
  //----------------------------------------------------------------------------
  const handleClick = () => {
    setProfileKey(profileKey);
  }

  //----------------------------------------------------------------------------
  // component rendering
  //----------------------------------------------------------------------------
  return (
    <ListItemButton onClick={handleClick}>
      <ListItemText>{profileKey.key}</ListItemText>
    </ListItemButton>
  );
}

export default ProfileKeyItem;


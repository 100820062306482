import { gql } from '@apollo/client';
import { tagSum, boardDetails, spaceSum, spaceMembers } from './fragments';

export const MY_SPACES = gql`
  query MySpaces($offset: Int!, $limit: Int!) {
    mySpaces(offset: $offset, limit: $limit) {
      ${spaceSum}
    }
    mySpacesCount
  }
`;

export const SPACES = gql`
  query Query($where: SpaceWhere, $options: SpaceOptions) {
    spaces(where: $where, options: $options) {
      ${spaceSum}
    }
    spacesCount(where: $where)
  }
`;

export const SPACE = gql`
  query Space($where: SpaceWhere, $options: CardOptions) {
    spaces(where: $where) {
      ${spaceSum} email mgrCount docCount tags { ${tagSum} } ${boardDetails} ${spaceMembers}
    }
  }
`;

export const SPACE_MEMBERS = gql`
  query SpaceMembers($spaceId: ID!, $offset: Int, $limit: Int) {
    spaceMembers(spaceId: $spaceId, offset: $offset, limit: $limit) {
      role _id name email
    }
  }
`;

export const CREATE_SPACE = gql`
  mutation CreateSpace($name: String!, $description: String) {
    createSpace(name: $name, description: $description) {
      _id
    }
  }
`;

export const UPDATE_SPACE_INFO = gql`
  mutation UpdateSpaceInfo($spaceId: ID!, $update: SpaceUpdateInput!) {
    updateSpaceInfo(spaceId: $spaceId, update: $update) {
      _id name email description
    }
  }
`;

export const UPDATE_SPACE_MEMBERS = gql`
  mutation UpdateSpaceMembers($spaceId: ID!, $add: [ID], $remove: [ID], $role: SpaceRole) {
    updateSpaceMembers(spaceId: $spaceId, add: $add, remove: $remove, role: $role) {
      _id mgrCount myRole ${spaceMembers}
    }
  }
`;

export const SPACE_CLEAR_MEMBERS = gql`
  mutation ClearMembers($spaceId: ID!) {
    clearMembers(spaceId: $spaceId) {
      _id ${spaceMembers}
    }
  }
`;

export const SPACE_IMPORT_UGC = gql`
  mutation SpaceImportUGC($spaceId: ID!) {
    spaceImportUGC(spaceId: $spaceId) {
      _id ${spaceMembers}
    }
  }
`;

// export const TAG_SPACE = gql`
//   mutation TagSpace($where: SpaceWhere, $connect: SpaceConnectInput, $disconnect: SpaceDisconnectInput) {
//     updateSpaces(where: $where, connect: $connect, disconnect: $disconnect) {
//       spaces {
//         _id tags { ${tagSum} }
//       }
//     }
//   }
// `;

export const DELETE_SPACE = gql`
  mutation DeleteSpace($spaceId: ID!) {
    deleteSpace(spaceId: $spaceId)
  }
`;

export const SPACE_DOCUMENTS = gql`
  query SpaceDocuments($spaceId: ID!, $offset: Int!, $limit: Int!) {
    spaceDocuments(spaceId: $spaceId, offset: $offset, limit: $limit) {
      _id title
    }
    spaceDocumentsCount(spaceId: $spaceId)
  }
`;

import React from 'react';
import { IconButton, Button, Popover } from '@mui/material';
import PersonAddAltIcon from '@mui/icons-material/PersonAddAlt';
// import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
// import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import { makeStyles } from '@material-ui/core/styles';
import { useMutation } from "@apollo/client";
import ShareBtn from "./ShareBtn";
import { DELETE_VALUE_SET } from '../../../constants/gqls/dataValues';

//******************************************************************************
// style definition
//******************************************************************************
const useStyles = makeStyles((theme) => ({
  popover: {
    "& .MuiPaper-root": {
      borderRadius: '20px',
      backgroundColor: theme.palette.primary.pale,
    }
  }
}));

//******************************************************************************
// component definition
//******************************************************************************
export default function FunctionBtn({valueSet, index, disabled, dataId}) {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [deleteValueSet, { loading }] = useMutation(DELETE_VALUE_SET);
  // const [sortValueSets, { loading:sortLoading }] = useMutation(SORT_VALUE_SET);
  const open = Boolean(anchorEl);
  // const loading = deleteLoading || sortLoading;
  // console.log(count);

  //----------------------------------------------------------------------------
  // funtions
  //----------------------------------------------------------------------------
  const onFnClick = (event) => {
    setAnchorEl(event.currentTarget);
  }

  const handleClose = () => {
    setAnchorEl(null);
  }

  // const handleUp = () => {
  //   let newIndex = index-1;
  //   sortValueSets({ variables: {
  //     dataId, 
  //     valueSetId: valueSet._id,
  //     index: newIndex
  //   }}).then(res=>{
  //     console.log(res);
  //     setIndex(newIndex);
  //     handleClose();
  //   });
  // };

  // const handleDown = () => {
  //   let newIndex = index+1;
  //   sortValueSets({ variables: {
  //     dataId,
  //     valueSetId: valueSet._id,
  //     index: newIndex
  //   }}).then(res=>{
  //     console.log(res);
  //     // setIndex(index-1);
  //     console.log(index);
  //     console.log(newIndex);
  //     handleClose();
  //   });
  // };

  const handleDelete = () => {
    deleteValueSet({ variables: {
      dataId,
      valueSetId: valueSet._id
    }}).then(res=>{
      console.log(res);
    });
  };

  //----------------------------------------------------------------------------
  // component rendering
  //----------------------------------------------------------------------------
  return (
    <>
      <Button startIcon={valueSet.owners.length > 1? <PersonAddAltIcon /> : null}
        onClick={onFnClick} disabled={disabled}>
        Item: {index+1}
      </Button>
      <Popover open={open} anchorEl={anchorEl} onClose={handleClose}
        anchorOrigin={{
          vertical: 'center',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'center',
          horizontal: 'left',
        }} className={classes.popover}
      >
        <ShareBtn valueSet={valueSet} disabled={loading} />
        {/* {index !== 0 &&
          <IconButton onClick={handleUp} disabled={loading}>
            <ArrowUpwardIcon />
          </IconButton>
        }
        {index !== (count - 1) &&
          <IconButton onClick={handleDown} disabled={loading}>
            <ArrowDownwardIcon />
          </IconButton>
        } */}
        <IconButton onClick={handleDelete} 
          disabled={loading}>
          <DeleteOutlineIcon />
        </IconButton>
      </Popover>
    </>
        
  )
}

import React from 'react';
import { TextField, MenuItem } from '@mui/material';
import { makeStyles } from '@material-ui/core/styles';

//******************************************************************************
// style definition
//******************************************************************************
const useStyles = makeStyles((theme) => ({
  input: {
    '&.MuiTextField-root': {
      width: '70px'
    }
  },
  star: {
    display: 'inline',
    padding: theme.spacing(1),
    lineHeight: '40px'
  }
}));

//******************************************************************************
// component definition
//******************************************************************************
export default function CardSettingInputs({width, height, onWidthChange, onHeightChange, loading}) {
  const classes = useStyles();

  //----------------------------------------------------------------------------
  // component rendering
  //----------------------------------------------------------------------------
  return (
    <React.Fragment>
      <TextField id="card-width" select variant="outlined" size="small"
        label="Width" value={width} onChange={onWidthChange}
        disabled={loading}
        className={classes.input}>
        {[1,2,3,4].map((option) => (
          <MenuItem key={option} value={option}>
            {option}
          </MenuItem>
        ))}
      </TextField>
      <div className={classes.star}>*</div>
      <TextField id="card-height" select variant="outlined" size="small"
        label="Height" value={height} onChange={onHeightChange}
        disabled={loading}
        className={classes.input}>
        {[1,2,3,4].map((option) => (
          <MenuItem key={option} value={option}>
            {option}
          </MenuItem>
        ))}
      </TextField>
    </React.Fragment>
  );
}

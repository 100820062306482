import React from 'react';
import { useNavigate } from "react-router-dom";
import { Button, Dialog, DialogTitle,
  DialogContent, DialogContentText, DialogActions } from '@mui/material';
// import LoadingButton from '@mui/lab/LoadingButton';
import { useMutation } from "@apollo/client";
import { DELETE_PROFILE_KEY } from '../../constants/gqls/profileKeys';
// import SiteMap from '../../constants/siteMap';

//******************************************************************************
// component definition
//******************************************************************************
export default function ProfileKeyDeleteBtn({profileKeyId, deleteRelatedInput, then}) {
  const [open, setOpen] = React.useState(false);
  const [deleteProfileKey, { loading }] = useMutation(DELETE_PROFILE_KEY);

  //----------------------------------------------------------------------------
  // funtions
  //----------------------------------------------------------------------------
  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const onDeleteClick = () => {
    deleteProfileKey({ variables: {
      profileKeyId, 
      deleteRelatedInput
    }}).then(res=>{
      // console.log(res);
      handleClose();
      then(res);
    });
  }

  //----------------------------------------------------------------------------
  // component rendering
  //----------------------------------------------------------------------------
  return (
    <React.Fragment>
      <Button onClick={handleOpen}>
        Remove profile key
      </Button>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>
          Confirm Remove profile key?
        </DialogTitle>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button variant="outlined"
            disabled={loading} onClick={onDeleteClick}>
            Remove
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  )
}

import React from 'react';
import axios from 'axios';
import { makeStyles } from '@material-ui/core/styles';
import { useMutation } from '@apollo/client';
import FileDropBox from './FileDropBox';
import UploadBtn from './UploadBtn';

//******************************************************************************
// style definition
//******************************************************************************
const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(2)
  },
  space: {
    height: theme.spacing(2)
  }
}));

//******************************************************************************
// component definition
//******************************************************************************
export default function UploadInput({requestMutation, apiName, variables, fileTypes}) {
  const classes = useStyles();
  const [loading, setLoading] = React.useState(false);
  const [requestUpload] = useMutation(requestMutation);
  // console.log({variables});

  //----------------------------------------------------------------------------
  // funtions
  //----------------------------------------------------------------------------
  const handleUpload = (theFile) => {
    if (fileTypes.includes(theFile.type)) {
      setLoading(true);
      requestUpload({ variables }).then(res=>{
        let token = res.data[apiName];
        // console.log(token);

        // Create an object of formData
        const formData = new FormData();

        // Update the formData object
        formData.append(
          "theFile",
          theFile,
          theFile.name
        );

        // Details of the uploaded file
        // console.log(theFile);

        // Request made to the backend api
        // Send formData object
        axios.post(process.env.REACT_APP_UPLOAD_URI + token, formData).then((res)=>{
          setLoading(false);
        }).catch((error) => {
          console.log(error.response);
          setLoading(false);
        });
      });
    }
  }

  const onFileChange = (event) => {
    let theFile = event.target.files[0];
    handleUpload(theFile);
  };

  const onFileDrop = (item, monitor) => {
    if (monitor) {
      let theFile = monitor.getItem().files[0];
      handleUpload(theFile);
    }
  }


  //----------------------------------------------------------------------------
  // component rendering
  //----------------------------------------------------------------------------
  return (
    <div className={classes.root}>
      <FileDropBox onDrop={onFileDrop} loading={loading} />
      <div className={classes.space} />
      <UploadBtn label="Import" loading={loading}
        onChange={onFileChange}
        accept={fileTypes?.join(',')}/>
    </div>
  );
}

import { gql } from '@apollo/client';
import { personSum } from './fragments';


export const CERT_TYPES = gql`
  query CertTypes($limit: Int, $offset: Int) {
    certTypesList(limit: $limit, offset: $offset) {
      _id name myRole
    }
    certTypesCount
  }  
`;

export const CERT_TYPE = gql`
  query CertType($id: ID!) {
    certType(id: $id) {
      _id createdAt publish name description myRole issuedCertCount requestFormTitle requestFormDescription
      managers { ${personSum} }
      variables {
        _id key format valueType
      }
      pdfTemplate { _id }
      hasTemplate deletable
    }
  }
`;

export const VIEW_CERT_TEMPLATE = gql`
  query Query($certTypeId: ID!) {
    viewCertTemplate(certTypeId: $certTypeId)
  }
`;

export const CERTIFICATES_BY_TYPE = gql`
  query CertificatesByType($certTypeId: ID!, $offset: Int, $limit: Int) {
    certificatesByType(certTypeId: $certTypeId, offset: $offset, limit: $limit) {
      _id email status
    }
    certificatesByTypeCount(certTypeId: $certTypeId)
  }
`;

export const MY_CERTIFICATES = gql`
  query MyCertificates($limit: Int, $offset: Int) {
    myCertificates(limit: $limit, offset: $offset) {
      _id createdAt certType { _id name }
    }
    myCertificatesCount
  }  
`;

export const CERTIFICATE_BY_ID = gql`
  query CertificateById($id: ID!) {
    certificateById(id: $id) {
      _id createdAt email status publish issueDate
      dataKeyValue { key value }
    }
  }
`;

export const CREATE_CERT_TYPE = gql`
  mutation CreateCertTypes($input: [CertTypeCreateInput!]!) {
    createCertTypes(input: $input) {
      certTypes {
        _id name
      }
    }
  }
`;

export const UPDATE_CERT_TYPE = gql`
  mutation UpdateCertType($certTypeId: ID!, $update: CertTypeUpdateInput!) {
    updateCertType(certTypeId: $certTypeId, update: $update) {
      _id createdAt publish name description myRole issuedCertCount requestFormTitle requestFormDescription
      managers { ${personSum} }
    }
  }
`;

export const DELETE_CERT_TYPE = gql`
  mutation DeleteCertTypes($where: CertTypeWhere) {
    deleteCertTypes(where: $where) {
      nodesDeleted
    }
  }
`;

export const UPDATE_TEMPLATE_VAR_FOR_CT = gql`
  mutation UpdateTemplateVarForCT($where: CertTypeWhere, $create: CertTypeRelationInput, $delete: CertTypeDeleteInput) {
    updateCertTypes(where: $where, create: $create, delete: $delete) {
      certTypes {
        _id variables { _id key valueType format }
      }
    }
  }
`;

export const DELETE_CERT_TEMPLATE = gql`
  mutation DeleteCertTemplate($certTypeId: ID!) {
    deleteCertTemplate(certTypeId: $certTypeId) {
      _id hasTemplate
    }
  }
`;

export const DELETE_CERTIFICATE = gql`
  mutation DeleteCertificate($certId: ID!) {
    deleteCertificate(certId: $certId)
  }
`;

export const ISSUE_CERTIFICATE = gql`
  mutation IssueCertificate($certId: ID!) {
    issueCertificate(certId: $certId) {
      _id issueDate status
    }
  }
`;

export const  UPDATE_CERTIFICATE = gql`
  mutation UpdateCertificate($certId: ID!, $update: CertificateUpdateInput!) {
    updateCertificate(certId: $certId, update: $update) {
      _id status
      dataKeyValue {  key value }
    }
  }
`;

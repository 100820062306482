import React from 'react';
import { EditorState, RichUtils } from 'draft-js';
import { IconButton, Tooltip, TextField, Button, Dialog,
  DialogTitle, DialogContent, DialogActions } from '@mui/material';
import LinkIcon from '@mui/icons-material/Link';
import LinkOffIcon from '@mui/icons-material/LinkOff';
import { makeStyles } from '@material-ui/core/styles';

//******************************************************************************
// style definition
//******************************************************************************
const useStyles = makeStyles((theme) => ({
  dialog: {
    '& .MuiPaper-root': {
      width: theme.breakpoints.values.sm
    }
  },
}));

//******************************************************************************
// component definition
//******************************************************************************
export default function LinkButtons({editorState, setState, disabled}) {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [url, setUrl] = React.useState('');

  //----------------------------------------------------------------------------
  // funtions
  //----------------------------------------------------------------------------
  const handleClose = () => { setOpen(false); };

  const onUrlChange = (event) => {
    setUrl(event.target.value);
  }

  const onLinkClick = () => {
    let selection = editorState.getSelection();
    if (!selection.isCollapsed()) {
      let contentState = editorState.getCurrentContent();
      let startKey = editorState.getSelection().getStartKey();
      let startOffset = editorState.getSelection().getStartOffset();
      let blockWithLinkAtBeginning = contentState.getBlockForKey(startKey);
      let linkKey = blockWithLinkAtBeginning.getEntityAt(startOffset);
      if (linkKey) {
        let linkInstance = contentState.getEntity(linkKey);
        setUrl(linkInstance.getData().url);
      }
      setOpen(true);
    }
  }

  const onConfirmClick = () => {
    let contentState = editorState.getCurrentContent();
    let contentStateWithEntity = contentState.createEntity(
      'LINK', 'MUTABLE', { url }
    );
    let entityKey = contentStateWithEntity.getLastCreatedEntityKey();
    const newEditorState = EditorState.set(editorState, { currentContent: contentStateWithEntity });
    setState(RichUtils.toggleLink(
      newEditorState,
      newEditorState.getSelection(),
      entityKey
    ));
    setOpen(false);
    setUrl('');
  }

  const onOffClick = () => {
    let selection = editorState.getSelection();
    if (!selection.isCollapsed()) {
      setState(RichUtils.toggleLink(editorState, selection, null));
    }
  }

  //----------------------------------------------------------------------------
  // component rendering
  //----------------------------------------------------------------------------
  return (
    <React.Fragment>
      <Tooltip title="Hyper Link">
        <span>
          <IconButton color="primary" onClick={onLinkClick} disabled={disabled}>
            <LinkIcon />
          </IconButton>
        </span>
      </Tooltip>
      <Tooltip title="Link Off">
        <span>
          <IconButton color="primary" onClick={onOffClick} disabled={disabled}>
            <LinkOffIcon />
          </IconButton>
        </span>
      </Tooltip>
      <Dialog open={open} onClose={handleClose} className={classes.dialog}>
        <DialogTitle>Hyper Link URL</DialogTitle>
        <DialogContent>
          <TextField autoFocus fullWidth margin="dense" variant="standard"
            id="url" label="URL"
            value={url} onChange={onUrlChange} />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={onConfirmClick}>OK</Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}

import React from 'react';
import { useQuery } from "@apollo/client";
import { useReactiveVar } from "@apollo/client";
import { dataMap, valueSetSelectedRefetch } from '../../constants/cache';
import { VALUE_SET_SELECTED_DATA } from '../../constants/gqls/forms';

//******************************************************************************
// component definition
//******************************************************************************
export default function ValueSetSelectedDataQuery({inputId, profileId}) {
  const { data, refetch } = useQuery(VALUE_SET_SELECTED_DATA, { variables: { inputId, profileId }});
  const valueSetSelectedRefetchVar = useReactiveVar(valueSetSelectedRefetch);
  // console.log(data);

  React.useEffect(() => {
    // console.log(data);
    if (data) {
      if (data) {
        let map = new Map(dataMap());
        map.set(inputId, data);
        dataMap(map);
      }
    }
    // console.log(dataMap());
  }, [data, inputId]);

  React.useEffect(() => {
    if (valueSetSelectedRefetchVar === inputId) {
      // console.log(inputId, 'reload');
      refetch();
    }
  }, [valueSetSelectedRefetchVar]);

  //----------------------------------------------------------------------------
  // component rendering
  //----------------------------------------------------------------------------
  return <div />;
}

import React from 'react';
import { LinearProgress, Paper } from '@mui/material';
import { makeStyles } from '@material-ui/core/styles';
import { useQuery } from "@apollo/client";
import DocView from '../Document/DocView';
import { DOCUMENT_VIEW } from '../../constants/gqls/documents';

//******************************************************************************
// style definition
//******************************************************************************
const useStyles = makeStyles((theme) => ({
  paper: {
    position: 'relative',
    padding: theme.spacing(2),
    '&.MuiPaper-root': {
      backdropFilter: 'blur(8px)',
      backgroundColor: 'rgba(255, 255, 255, 0.8)'
    }
  },
}));


//******************************************************************************
// component definition
//******************************************************************************
export default function DocumentPage({ docId }) {
  const classes = useStyles();
  const { loading, data } = useQuery(DOCUMENT_VIEW, { variables: { docId } });
  // console.log(data);

  //----------------------------------------------------------------------------
  // component rendering
  //----------------------------------------------------------------------------
  if (loading) return <LinearProgress />;
  if (!data) return <p>'Network error.'</p>

  return (
    <Paper square className={classes.paper}>
      {data.document ?
        <DocView doc={data.document} />
        :
        <p>Content not found or you are not allow to view the content.</p>
      }
    </Paper>
  );
}

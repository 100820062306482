import React from 'react';
import { generatePath } from "react-router-dom";
import { ListItemButton, ListItemText } from '@mui/material';
// import { makeStyles } from '@material-ui/core/styles';
import SiteMap from '../../constants/siteMap';

//******************************************************************************
// style definition
//******************************************************************************
// const useStyles = makeStyles((theme) => ({
//
// }));

//******************************************************************************
// component definition
//******************************************************************************
export default function CertTypeItem({ certType, isStudent }) {
  // const classes = useStyles();
  // console.log(isStudent);
  const path = isStudent? SiteMap.certRequest : SiteMap.certificate;
  const href = generatePath(path, { id: certType._id });

  //----------------------------------------------------------------------------
  // component rendering
  //----------------------------------------------------------------------------
  return (
    <ListItemButton component="a" href={href}>
      <ListItemText primary={certType.name} />
      { certType.myRole === 'manager' && 'Manage'}
    </ListItemButton>
  );
}

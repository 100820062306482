import React from 'react';
import { useParams } from "react-router-dom";
import { Container, Paper, LinearProgress, CardHeader, CardContent } from '@mui/material';
import { makeStyles } from '@material-ui/core/styles';
import { useQuery } from "@apollo/client";
import CreateOrEdit from '../components/Services/CreateOrEdit';
import FunctionBtn from '../components/Services/FunctionBtn';
import ServiceYears from '../components/Services/ServiceYears';
import ServiceActions from '../components/Actions/ServiceActions';
import AddBtn from '../components/ServiceSessions/AddBtn';
import SessionsList from '../components/ServiceSessions/SessionsList';
import NotFount from './NotFount';
import { SERVICE } from '../constants/gqls/services';

//******************************************************************************
// style definition
//******************************************************************************
const useStyles = makeStyles((theme) => ({
  container: {
    paddingTop: theme.spacing(3)
  },
  secondPaper: {
    borderTop: '1px dashed '+theme.palette.primary.light
  }
}));

//******************************************************************************
// component definition
//******************************************************************************
export default function Service() {
  const classes = useStyles();
  const params = useParams();
  const [mode, setMode] = React.useState('view');
  const { loading, data } = useQuery(SERVICE, { variables: {
    where: { OR: [
      { _id: params.key },
      { key: params.key }
    ]},
    // sort: [{ edge: { index: 'ASC' }}]
  }});

  //----------------------------------------------------------------------------
  // funtions
  //----------------------------------------------------------------------------


  //----------------------------------------------------------------------------
  // component rendering
  //----------------------------------------------------------------------------
  if (loading) return <LinearProgress />;
  if (!data) return 'Network error.'
  if (data.services.length === 0) return <NotFount />

  return (
    <Container maxWidth="xl" className={classes.container}>
      <Paper>
        <CardHeader title={data.services[0].name} action={
          data.services[0].myRole?
          <FunctionBtn serviceId={data.services[0]._id}
            mode={mode} setMode={setMode}
            delable={data.services[0].serviceSessionsCount===0} />
          : null
        } />
        <CreateOrEdit service={data.services[0]}
          setMode={setMode} mode={mode} isAdmin={data.services[0].myRole === 'admin'} />
        { data.services[0].myServiceHours.length!==0 && 
          <CardContent>
            <h4>My service hours:</h4>
            {data.services[0].myServiceHours.map((str, i)=>(
              <p key={i}>{str} hours completed.</p>
            ))}
          </CardContent>
        }
        { data.services[0].myAttendanceRate && 
          <CardContent>
            <h4>My attendance Rate:</h4>
            <p>
              Hours: {data.services[0].myAttendanceRate.completedHours} / {data.services[0].myAttendanceRate.totalHours}
            </p>
            <p>
              Percentage: {(data.services[0].myAttendanceRate.completedHours*100/data.services[0].myAttendanceRate.totalHours).toFixed(2)}%
            </p>
            <p>
              Session closed: {data.services[0].myAttendanceRate.closedCount}
            </p>
          </CardContent>
        }
      </Paper>
      { data.services[0].myRole &&
        <>
          <ServiceYears service={data.services[0]} />
          <ServiceActions serviceId={data.services[0]._id} />
        </>
      }
      <Paper className={classes.secondPaper}>
        <CardHeader title="Service Sessions" action={
          data.services[0].myRole?
          <AddBtn service={data.services[0]} />
          : null
        } />
        <SessionsList serviceId={data.services[0]._id} />
      </Paper>
    </Container>
  );
}

import React from 'react';
import { useNavigate, generatePath } from "react-router-dom";
import { ListItemButton } from '@mui/material';
import SiteMap from '../../constants/siteMap';
import { makeStyles } from '@material-ui/core/styles';

//******************************************************************************
// style definition
//******************************************************************************
const useStyles = makeStyles((theme) => ({
  key: {
    fontWeight: 'bold',
  },
  description: {
    color: theme.palette.text.secondary,
  }
}));

//******************************************************************************
// component definition
//******************************************************************************
export default function TemplateListItem({template}) {
  const classes = useStyles();
  const navigate = useNavigate();

  //----------------------------------------------------------------------------
  // funtions
  //----------------------------------------------------------------------------
  const goTo = () => {
    let path = generatePath(SiteMap.email_template, {
      id: template._id
    });
    navigate(path);
  }

  //----------------------------------------------------------------------------
  // component rendering
  //----------------------------------------------------------------------------
  return (
    <ListItemButton onClick={goTo}>
      <span className={classes.key}>
        {template.key}:
      </span>&nbsp;
      <span className={classes.description}>
        {template.description}
      </span>
    </ListItemButton>
  );
}

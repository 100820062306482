import React from 'react';
import { TextField } from '@mui/material';


//******************************************************************************
// component definition
//******************************************************************************
export default function TextInput({id, multiline, disabled, data, updateData}) {
  const [value, setValue] = React.useState(data?.value || '');
  const [changed, setChanged] = React.useState(false);

  React.useEffect(() => {
    // console.log(data);
    setValue(data?.value || '');
  }, [data]);

  //----------------------------------------------------------------------------
  // funtions
  //----------------------------------------------------------------------------
  const update = () => {
    if (changed) {
      updateData({ value }).then(res => {
        // console.log(res);
        setChanged(false);
      })
    }
  };

  const handleChange = (event) => {
    setValue(event.target.value)
    setChanged(true);
  }

  const handleBlur = () => {
    update();
  }

  //----------------------------------------------------------------------------
  // component rendering
  //----------------------------------------------------------------------------
  return (
    <TextField fullWidth id={'text-'+id}
      variant={multiline ? "outlined" : "standard"} rows={multiline? 4 : 1}
      multiline={multiline} disabled={disabled}
      value={value} onChange={handleChange} onBlur={handleBlur} />
  )
}

import React from 'react';
import { Grid, Button, CardContent } from '@mui/material';
import DownloadIcon from '@mui/icons-material/Download';
import { makeStyles } from '@material-ui/core/styles';
import { dataToString } from '../../constants/utils';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';

function formatPkData(pkData) {
  let data = {};
  for (const [key, values] of Object.entries(pkData)) {
    for (let i = 0; i < values.length; i++) {
      let item = values[i];
      if (data[item.ownerPerson._id]) {
        data[item.ownerPerson._id][key] = dataToString(item);
      } else {
        data[item.ownerPerson._id] = { ...item.ownerPerson };
        data[item.ownerPerson._id][key] = dataToString(item);
      }
    }
  }
  return data;
}

function formatData(inColumns, profileKeyId, dataSets, pkData) {
  console.log(pkData);
  // columns
  let tableColumns = inColumns.map(col=>({
    headerName: col.name,
    field: col.id,
  }));

  // get properties columns
  let pCols = inColumns.filter(col=>col.type==='property');

  // get profile key data columns
  let dCol = inColumns.filter(col=>col.type==='data');

  // rows
  let data = [];
  if (profileKeyId) {
    // with value fields
    data = dataSets.map(item=>{
      let dataItem = {};
      for (let i = 0; i < item.values.length; i++) {
        let value = item.values[i];
        dataItem[value.field._id] = value.text;
      }
      if (pCols.length !== 0) {
        for (let i = 0; i < pCols.length; i++) {
          let col = pCols[i];
          dataItem[col.id] = item.owners.map(owner=>owner[col.id]).join(' & ');
        }
      }
      if (dCol.length !== 0) {
        for (let i = 0; i < dCol.length; i++) {
          let col = dCol[i];
          let vSet = new Set(item.owners.map(owner=>{
            return pkData[owner._id]? pkData[owner._id][col.id] : null
          }).filter(v=>v));
          dataItem[col.id] = Array.from(vSet).join(' & ');
        }
      }
      return dataItem;
    });
  } else {
    // when profile key data only, no value fields
    for (const [, value] of Object.entries(pkData)) {
      data.push(value);
    }
  }
  console.log(data);

  return {columns: tableColumns, data};
}

//******************************************************************************
// style definition
//******************************************************************************
const useStyles = makeStyles((theme) => ({
  table: {
    width: '100%',
    height: 740
  },
  label: {
    marginRight: theme.spacing(2)
  }
}));

//******************************************************************************
// component definition
//******************************************************************************
export default function ProfileDataTable({columns, profileKeyId, dataSets, pkData}) {
  const classes = useStyles();
  const gridRef = React.useRef();
  const [tableData, setData] = React.useState([[], []]);
  // console.log(pkData);

  // never changes, so we can use useMemo
  const defaultColDef = React.useMemo(() => ({
    resizable: true,
    sortable: true,
    filter: true
  }), []);

  // when changes
  React.useEffect(() => {
    setData(formatData(columns, profileKeyId, dataSets, formatPkData(pkData)));
  }, [columns, profileKeyId, dataSets, pkData]);

  //----------------------------------------------------------------------------
  // funtions
  //----------------------------------------------------------------------------
  const handleExport = () => {
    gridRef.current.api.exportDataAsCsv();
  }

  //----------------------------------------------------------------------------
  // component rendering
  //----------------------------------------------------------------------------
  return (
    <>
      <CardContent>
        <Grid container spacing={2} alignItems="center">
          <Grid item xs>
            <div />
          </Grid>
          <Grid item xs="auto">
            <Button variant="contained" color="secondary"
              startIcon={<DownloadIcon />}
              onClick={handleExport}>
                Download CSV
            </Button>
          </Grid>
        </Grid>
      </CardContent>
      <div className={classes.table}>
        <AgGridReact className="ag-theme-alpine"
          ref={gridRef}
          animateRows="true"
          rowData={tableData.data}
          columnDefs={tableData.columns}
          defaultColDef={defaultColDef}
        />
      </div>
    </>
  );
}

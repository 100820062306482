import React from 'react';
import { useParams } from "react-router-dom";
import { Container, Paper, LinearProgress } from '@mui/material';
import { makeStyles } from '@material-ui/core/styles';
import { useQuery } from "@apollo/client";
import NotFount from './NotFount';
import TemplateForm from '../components/EmailTemplates/TemplateForm';
import { EMAIL_TEMPLATE } from '../constants/gqls/emailTemplates';

//******************************************************************************
// style definition
//******************************************************************************
const useStyles = makeStyles((theme) => ({
  container: {
    paddingTop: theme.spacing(3)
  },
  paper: {
    padding: theme.spacing(2)
  }
}));

//******************************************************************************
// component definition
//******************************************************************************
export default function EmailTemplate() {
  const params = useParams();
  const classes = useStyles();
  const { loading, data } = useQuery(EMAIL_TEMPLATE, { variables: {
    // where: { _id: params.id }
    id: params.id
  }});

  //----------------------------------------------------------------------------
  // component rendering
  //----------------------------------------------------------------------------
  if (loading) return <LinearProgress />;
  if (!data) return 'Network error.'
  if (!data.emailTemplate) return <NotFount />

  return (
    <Container maxWidth="xl" className={classes.container}>
      <Paper className={classes.paper}>
        <TemplateForm template={data.emailTemplate} />
      </Paper>
    </Container>
  );
}

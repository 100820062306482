import React from 'react';
import { TextField, Autocomplete, Chip, Tooltip } from '@mui/material';
import { useQuery } from "@apollo/client";
import { SEARCH_PEOPLE } from '../../constants/gqls/user';

const getWhere = (search, roles_INCLUDES) => {
  let match = `(?i).*${search || ''}.*`;
  let OR = [
    { name_MATCHES: match },
    { email_MATCHES: match }
  ];
  if (roles_INCLUDES) {
    return {
      roles_INCLUDES, OR
    }
  }
  return { OR }
}

//******************************************************************************
// component definition
//******************************************************************************
export default function PeopleInput({value, onChange, label, multiple, role, disabled}) {
  const [inputValue, setInputValue] = React.useState(value?
    `${value.name} (${value.email})` : ''
  );
  const { data } = useQuery(SEARCH_PEOPLE, { variables: {
    where: getWhere(inputValue, role),
    options: { 
      limit: 10,
      sort: { active: 'ASC' } 
    }
  }});
  // console.log(inputValue);

  //----------------------------------------------------------------------------
  // funtions
  //----------------------------------------------------------------------------
  const onValueChange = (event, newValue) => {
    // console.log(newValue);
    onChange(newValue);
  }

  const onInputChange = (event, newValue) => {
    setInputValue(newValue);
  }

  //----------------------------------------------------------------------------
  // component rendering
  //----------------------------------------------------------------------------
  return (
    <Autocomplete multiple={multiple} disabled={disabled}
      value={value} onChange={onValueChange}
      inputValue={inputValue} onInputChange={onInputChange}
      getOptionLabel={option=>`${option.name} (${option.email})`}
      options={ data ? data.people : [] }
      renderInput={(params) => <TextField {...params}
        label={label}
        variant="standard"
      />}
      renderTags={(value, getTagProps) =>
        value.map((option, index) => (
          <Tooltip title={option.email} key={option._id}>
            <Chip variant="outlined" label={option.name} {...getTagProps({ index })} />
          </Tooltip>
        ))
      }
    />
  );
}

import React from 'react';
import { Container, Paper, Tabs, Tab, CardContent } from '@mui/material';
import { makeStyles } from '@material-ui/core/styles';
import TagTypeAdd from '../components/TagsMgt/TagTypeAdd';
import TagAdd from '../components/TagsMgt/TagAdd';
import TagTypesList from '../components/TagsMgt/TagTypesList';
import TagsList from '../components/TagsMgt/TagsList';
import TagsListFilter from '../components/TagsMgt/TagsListFilter';

//******************************************************************************
// style definition
//******************************************************************************
const useStyles = makeStyles((theme) => ({
  container: {
    paddingTop: theme.spacing(3)
  },
}));

const tabs = [{
  label: 'Tag Types',
  description: 'Here will list all tags types. Press add button above to add one.',
  btn: <TagTypeAdd />,
  list: () => <TagTypesList />
}, {
  label: 'Tags',
  description: 'Here will list all tags. You can search for existing tags, or press add button above to add a system tag.',
  btn: <TagAdd scope="system" />,
  filter: true,
  list: (where) => <TagsList where={where} />
}]

//******************************************************************************
// component definition
//******************************************************************************
function AdminTags() {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);
  const [where, setWhere] = React.useState(null);

  //----------------------------------------------------------------------------
  // funtions
  //----------------------------------------------------------------------------
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  //----------------------------------------------------------------------------
  // component rendering
  //----------------------------------------------------------------------------
  return (
    <Container maxWidth="xl" className={classes.container}>
      <Paper>
        <Tabs value={value} onChange={handleChange}>
          { tabs.map((tab, i)=>(
            <Tab key={i} label={tab.label}/>
          )) }
        </Tabs>
        <CardContent>
          {tabs[value].btn}
          <p>{tabs[value].description}</p>
          { tabs[value].filter &&
            <TagsListFilter setWhere={setWhere} />
          }
        </CardContent>
        {tabs[value].list(where)}
      </Paper>
    </Container>
  );
}

export default AdminTags;

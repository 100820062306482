import React from 'react';
import { Autocomplete, TextField } from '@mui/material';
import { useQuery } from "@apollo/client";
import { PROFILE_KEYS } from '../../constants/gqls/profileKeys';
const limit = 10;

function getLabel(profileKey) {
  // console.log(profileKey);
  if (profileKey?.__typename !== 'ProfileKey') {
    return '';
  }
  let valueFields = profileKey.valueFields.map(vf=>vf.name);
  return (profileKey.key + (valueFields.length!==0? ` (value fields: ${valueFields.join(', ')})`: ''))
}

//******************************************************************************
// component definition
//******************************************************************************
function ProfileKeyVSDropdown({ selected, setSelected }) {
  const [inputValue, setInputValue] = React.useState('');
  const { data } = useQuery(PROFILE_KEYS, { variables: { 
    where: {
      key_MATCHES: '(?i).*'+inputValue+'.*' ,
      input: { valueFieldsAggregate: { count_GT: 0 }}
    },
    options: { 
      limit, 
      sort: { key: "ASC" } 
    }
  }});
  // console.log(data)

  //----------------------------------------------------------------------------
  // funtions
  //----------------------------------------------------------------------------
  const onValueChange = (event, newValue) => {
    console.log(newValue);
    setSelected(newValue);
  }

  const onInputChange = (event, newValue) => {
    console.log(inputValue)
    setInputValue(newValue || '');
  }

  //----------------------------------------------------------------------------
  // component rendering
  //----------------------------------------------------------------------------
  return (
    <Autocomplete
      value={selected} onChange={onValueChange}
      inputValue={inputValue} onInputChange={onInputChange}
      getOptionLabel={getLabel}
      options={ data?.profileKeys || []}
      renderInput={(params) => 
        <TextField {...params} label="Select Profile Key" />
      }
    />
  );
}

export default ProfileKeyVSDropdown;

import React from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions,
  Button, TextField, MenuItem } from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import { makeStyles } from '@material-ui/core/styles';
import { useMutation } from "@apollo/client";
import CardSettingInputs from '../BoardCards/CardSettingInputs';
import { UPDATE_BOARD } from '../../constants/gqls/boards';

//******************************************************************************
// style definition
//******************************************************************************
const useStyles = makeStyles((theme) => ({
  row: {
    padding: `${theme.spacing(1)}px 0`,
    width: '280px'
  }
}));

//******************************************************************************
// component definition
//******************************************************************************
export default function AddCardDialog({board, open, handleClose, cardOptions, refetchQueries}) {
  const classes = useStyles();
  const [type, setType] = React.useState('');
  const [width, setWidth] = React.useState(1);
  const [height, setHeight] = React.useState(1);
  const [updateBoard, { loading }] = useMutation(UPDATE_BOARD, { refetchQueries });

  //----------------------------------------------------------------------------
  // funtions
  //----------------------------------------------------------------------------
  const onTypeChange = (event) => {
    setType(event.target.value);
  }

  const onWidthChange = (event) => {
    setWidth(event.target.value);
  }

  const onHeightChange = (event) => {
    setHeight(event.target.value);
  }

  const onAddClick = () => {
    if (type !== '') {
      updateBoard({ variables: {
        where: { _id: board._id },
        create: { cards: [{ node: {
          type, width, height,
          index: board.cards.length
        }}]}
      }}).then(res=>{
        // console.log(res.data);
        handleClose();
      });
    }
  }

  //----------------------------------------------------------------------------
  // component rendering
  //----------------------------------------------------------------------------
  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>
        Add Card
      </DialogTitle>
      <DialogContent>
        <div className={classes.row}>
          <TextField id="card-type" fullWidth select variant="outlined"
            label="Type" value={type} onChange={onTypeChange}
            disabled={loading}>
            {cardOptions.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.name}
              </MenuItem>
            ))}
          </TextField>
        </div>
        <div className={classes.row}>
          <CardSettingInputs loading={loading}
            width={width} onWidthChange={onWidthChange}
            height={height} onHeightChange={onHeightChange} />
        </div>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Cancel</Button>
        <LoadingButton variant="outlined"
          loading={loading} onClick={onAddClick}>
          Add
        </LoadingButton>
      </DialogActions>
    </Dialog>
  )
}

import React from 'react';
import { TextField } from '@mui/material';

//******************************************************************************
// component definition
//******************************************************************************
export default function TemplateVarInput({templateVar, data, updateData, disabled}) {
  const value = data? data[templateVar.key.toLowerCase()] || '' : '';
 
  //----------------------------------------------------------------------------
  // funtions
  //----------------------------------------------------------------------------
  const onValueChanged = (event) => {
    updateData(templateVar.key.toLowerCase(), event.target.value);
  }

  //----------------------------------------------------------------------------
  // component rendering
  //----------------------------------------------------------------------------
  return (
    <TextField fullWidth label={templateVar.key} 
      value={value} onChange={onValueChanged}
      disabled={disabled} />
  );
}
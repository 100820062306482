import React from 'react';
import { CardContent } from '@mui/material';
import ProfileKeyVSDropdown from './ProfileKeyVSDropdown';
import ValuetoStringInput from './ValuetoStringInput';

//******************************************************************************
// component definition
//******************************************************************************
export default function VStoStringTap() {
  const [profileKey, setProfileKey] = React.useState(null);
  const [editId, setEditId] = React.useState(null);
  // console.log(profileKey);

  //----------------------------------------------------------------------------
  // funtions
  //----------------------------------------------------------------------------

  //----------------------------------------------------------------------------
  // component rendering
  //----------------------------------------------------------------------------
  return (
    <CardContent>
      <ProfileKeyVSDropdown selected={profileKey} setSelected={setProfileKey} />
      { profileKey?.valueFields.map(valueField=>(
        <ValuetoStringInput key={valueField._id} 
          valueField={valueField}
          editId={editId} setEditId={setEditId} />
      ))}
    </CardContent>
  );
}

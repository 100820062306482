import React from 'react';
import { FormControlLabel, Switch } from '@mui/material';

//******************************************************************************
// component definition
//******************************************************************************
export default function InputOtherSetting({value, updateInput, setFocus, loading}) {

  //----------------------------------------------------------------------------
  // funtions
  //----------------------------------------------------------------------------
  const handleChange = (event) => {
    let update = {
      other: event.target.checked
    }
    if (event.target.checked) {
      update.isDataFilter = false;
    }
    updateInput(update);
    // updateInput({
    //   other: event.target.checked
    // });
  };

  const handleBlur = () => {
    setFocus(false);
  }

  const handleFocus = () => {
    setFocus(true);
  }

  //----------------------------------------------------------------------------
  // component rendering
  //----------------------------------------------------------------------------
  return (
    <FormControlLabel disabled={loading}
      control={
        <Switch checked={value} onChange={handleChange}
          onBlur={handleBlur} onFocus={handleFocus} />
      }
      label={"Include \"Other\""} labelPlacement="start"
    />
  )
}

import React from 'react';
import { LinearProgress } from '@mui/material';
import { useMutation } from '@apollo/client';
import { LOGIN } from '../../constants/gqls/user';

//******************************************************************************
// component definition
//******************************************************************************
export default function SSOidLoginLoading({successResponse, errorResponse, code, from}) {
  const [login] = useMutation(LOGIN);
  // console.log(login);

  React.useEffect(() => {
    // console.log('do login');
    login({ variables: { code }}).then(res=>{
      successResponse(res.data.signIn);
    }, err=>{
      errorResponse(err.message);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [code]);

  //----------------------------------------------------------------------------
  // funtions
  //----------------------------------------------------------------------------

  //----------------------------------------------------------------------------
  // component rendering
  //----------------------------------------------------------------------------
  return (
    <LinearProgress />
  );
}

import React from 'react';
import { generatePath } from "react-router-dom";
import { Button } from '@mui/material';
import { makeStyles } from '@material-ui/core/styles';
import SiteMap from '../../constants/siteMap';

//******************************************************************************
// style definition
//******************************************************************************
const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    marginTop: theme.spacing(4),
    paddingTop: theme.spacing(2),
    '& .MuiButton-root': {
      margin: 'auto'
    }
  }
}));

//******************************************************************************
// component definition
//******************************************************************************
export default function ServiceSessionBtn({serviceSessionId}) {
  const classes = useStyles();
  const href = generatePath(SiteMap.serviceSession, { id: serviceSessionId });

  //----------------------------------------------------------------------------
  // component rendering
  //----------------------------------------------------------------------------
  return (
    <div className={classes.root}>
      <Button variant="outlined" href={href}>
        To service session
      </Button>
    </div>
  );
}

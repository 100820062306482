import React from 'react';
import { gql } from '@apollo/client';
import { useParams } from "react-router-dom";
import { Container, Paper, LinearProgress, CardHeader, CardContent, CardActions } from '@mui/material';
import { makeStyles } from '@material-ui/core/styles';
import { useQuery } from "@apollo/client";
import NotFount from './NotFount';
// import { CERT_TYPE } from '../constants/gqls/certificates';
import TemplateVarInput from '../components/TemplateVars/TemplateVarInput';
import SubmitBtn from '../components/Certificates/SubmitBtn';
import { personSum } from '../constants/gqls/fragments';
import { formFilledOK } from '../constants/cache';

//******************************************************************************
// gql
//******************************************************************************
const QUERY_GQL = gql`
  query CertType($id: ID!) {
    certType(id: $id) {
      _id publish name myRole requested hasTemplate requestFormTitle requestFormDescription
      variables {
        _id key format valueType
      }
      promptAction { _id promptTemplate }
    }
    me {
      ${personSum}
    }
  }
`;

//******************************************************************************
// style definition
//******************************************************************************
const useStyles = makeStyles((theme) => ({
  container: {
    paddingTop: theme.spacing(3)
  },
  inputBox: {
    paddingTop: theme.spacing(2)
  },
  btn: {
    "&.MuiButtonBase-root": {
      margin: 'auto'
    }
  }
}));

//******************************************************************************
// component definition
//******************************************************************************
export default function CertRequest() {
  const classes = useStyles();
  const params = useParams();
  const [reqData, setReqData] = React.useState({});
  const { loading, data } = useQuery(QUERY_GQL, { variables: {
    id: params.id
  }});
  // console.log(data);

  React.useEffect(() => {
    if (data?.me) {
      setReqData({
        name: data.me.name,
        email: data.me.email
      });
      formFilledOK(true);
    }
  }, [data]);

  //----------------------------------------------------------------------------
  // funtions
  //----------------------------------------------------------------------------
  const updateData = (key, value) => {
    let newData = {...reqData};
    newData[key.toLowerCase()] = value;
    setReqData(newData);
    if (newData.email) {
      formFilledOK(true);
    } else {
      formFilledOK(false)
    }
  }

  //----------------------------------------------------------------------------
  // component rendering
  //----------------------------------------------------------------------------
  if (loading) return <LinearProgress />;
  if (!data) return 'Network error.'
  if (!data.certType || (!data.certType.publish && !data.certType.myRole)) return <NotFount />

  return (
    <Container maxWidth="md" className={classes.container}>
      <div>
        <a href='/'><img alt="logo" src="/lc-logo.png" /></a>
      </div>
      <Paper>
        <CardHeader title={data.certType.requestFormTitle}  />
        <CardContent>
          <div dangerouslySetInnerHTML={{ 
            __html: data.certType.requestFormDescription 
            }} />
          { data.certType.variables.filter(v=>v.valueType==='data').map(v=>(
            <div key={v._id} className={classes.inputBox}>
              <TemplateVarInput templateVar={v}
                updateData={updateData} data={reqData} />
            </div>
          ))}
        </CardContent>
        <CardActions>
          <SubmitBtn certTypeId={params.id}
            reqData={reqData}
            submitPrompt={data.certType.promptAction} />
        </CardActions>
      </Paper>
    </Container>
  );
}

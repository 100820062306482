import React from 'react';
import { Button, Grid, IconButton } from '@mui/material';
import { makeStyles } from '@material-ui/core/styles';
import EditIcon from '@mui/icons-material/Edit';
import { useMutation } from "@apollo/client";
import HtmlEditor from "../ContentEditor/HtmlEditor";
import { UPDATE_VALUE_FIELD } from '../../constants/gqls/dataValues';

//******************************************************************************
// style definition
//******************************************************************************
const useStyles = makeStyles((theme) => ({
  box: {
    backgroundColor: theme.palette.primary.pale,
    padding: theme.spacing(2)
  },
}));

//******************************************************************************
// component definition
//******************************************************************************
export default function ValuetoStringInput({valueField, editId, setEditId}) {
  const classes = useStyles();
  const [updateKey, { loading }] = useMutation(UPDATE_VALUE_FIELD);
  const [toStr, setToStr] = React.useState(valueField.toStr);
  // console.log(profileKey);

  //----------------------------------------------------------------------------
  // funtions
  //----------------------------------------------------------------------------
  const handleChange = (value) => {
    if (value !== toStr) {
      setToStr(value);
    }
  }

  const handleEditIdChange = () => {
    editId === valueField._id ? 
      setEditId(null) : setEditId(valueField._id);
  }

  const handleSave = () => {
    let rgx = new RegExp("<p>(.*)</p>");
    let match = toStr.trim().match(rgx);
    updateKey({ variables: {
      where: { _id: valueField._id },
      update: { toStr: match? match[1] : toStr }
    }});
  }


  //----------------------------------------------------------------------------
  // component rendering
  //----------------------------------------------------------------------------
  return (
    <div>
      <Grid container alignItems="center" spacing={2}>
        <Grid item>{valueField.name}</Grid>
        <Grid item>
          <IconButton onClick={handleEditIdChange}
            color={editId === valueField._id? "primary" : "default"}
          >
            <EditIcon />
          </IconButton>
        </Grid>
      </Grid>
      <div className={classes.box}>
        <HtmlEditor placeholder="Format text not set"
          html={toStr} onChange={handleChange}
          readOnly={editId !== valueField._id} />
        { editId === valueField._id &&
          <Button variant="outlined" onClick={handleSave}
            disabled={loading}>
            Save
          </Button>
        }
      </div>
    </div>
  );
}

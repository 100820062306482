import React from 'react';
// import { useSearchParams } from "react-router-dom";
// import { useQuery } from "@apollo/client";
import { dataMap, formSubmitted } from '../../constants/cache';
// import { SUBMISSION } from '../../constants/gqls/forms';

//******************************************************************************
// component definition
//******************************************************************************
export default function SubmissionClear() {
  // console.log('SubmissionClear');

  React.useEffect(() => {
    dataMap(null);
    formSubmitted(false);
  });

  //----------------------------------------------------------------------------
  // funtions
  //----------------------------------------------------------------------------

  //----------------------------------------------------------------------------
  // component rendering
  //----------------------------------------------------------------------------
  return (<div />);
}

import React from 'react';
import { FormControl, InputLabel, Select, MenuItem, Grid } from '@mui/material';
import TimeInput from './TimeInput';

const dayOptions = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];

//******************************************************************************
// component definition
//******************************************************************************
export default function MeetingTimeInput({value, onChange, handleUpdate, loading}) {
  const [day, time] = value? value.split(' => ') : ['', ''];

  //----------------------------------------------------------------------------
  // funtions
  //----------------------------------------------------------------------------
  const handleDayChange = (event) => {
    if (onChange) {
      onChange([event.target.value, time].join(' => '));
    }
    if (handleUpdate) {
      handleUpdate({
        meetingTime: [event.target.value, time].join(' => ')
      }).then(res => {
        // console.log(res);
      });
    }
  };

  const handleTimeChange = (event) => {
    if (onChange) {
      onChange([day, event.target.value].join(' => '));
    }
    if (handleUpdate) {
      handleUpdate({
        meetingTime: [day, event.target.value].join(' => ')
      }).then(res => {
        // console.log(res);
      });
    }
  };

  //----------------------------------------------------------------------------
  // component rendering
  //----------------------------------------------------------------------------
  return (
    <Grid container spacing={2}>
      <Grid item xs={5}>
        <FormControl fullWidth disabled={loading}>
          <InputLabel>Select week day</InputLabel>
          <Select label="Select week day"
            value={day} onChange={handleDayChange}
          >
            { dayOptions.map(option=>
              <MenuItem key={option} value={option}>
                {option}
              </MenuItem>
            )}
          </Select>
        </FormControl>
      </Grid>
      <Grid item xs={7}>
        <TimeInput loading={loading}
           value={time} handleChange={handleTimeChange} />
      </Grid>
    </Grid>
  )
}

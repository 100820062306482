import React from 'react';
import { useParams } from "react-router-dom";
import { Container, Paper, LinearProgress, IconButton, CardHeader } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import { makeStyles } from '@material-ui/core/styles';
import { useQuery } from "@apollo/client";
import PageDetails from '../components/Pages/PageDetails';
import NotFount from './NotFount';
import { PAGE } from '../constants/gqls/pages';

//******************************************************************************
// style definition
//******************************************************************************
const useStyles = makeStyles((theme) => ({
  container: {
    paddingTop: theme.spacing(3)
  },
  secondPaper: {
    borderTop: '1px dashed '+theme.palette.primary.light
  }
}));

//******************************************************************************
// component definition
//******************************************************************************
export default function PageManage() {
  const classes = useStyles();
  const params = useParams();
  const [editMode, setEditMode] = React.useState(false);
  const { loading, data } = useQuery(PAGE, { variables: {
    where: {_id: params.id}
  }});
  // console.log(data);


  //----------------------------------------------------------------------------
  // funtions
  //----------------------------------------------------------------------------
  const editClicked = () => {
    setEditMode(!editMode);
  }


  //----------------------------------------------------------------------------
  // component rendering
  //----------------------------------------------------------------------------
  if (loading) return <LinearProgress />;
  if (!data) return 'Network error.'
  if ((!data.pages) || data.pages.length === 0) return <NotFount />

  return (
    <Container maxWidth="xl" className={classes.container}>
      <Paper>
        <CardHeader title="Page Management"
          action={
            <IconButton onClick={editClicked}>
              <EditIcon />
            </IconButton>
          } />
        <PageDetails page={data.pages[0]} 
          editMode={editMode} editClicked={editClicked} />
      </Paper>
    </Container>
  );
}

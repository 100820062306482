import React from 'react';
import { TextField, Button } from '@mui/material';
import SaveIcon from '@mui/icons-material/Save';
import { makeStyles } from '@material-ui/core/styles';
import { useMutation } from "@apollo/client";
import HtmlEditor from "../ContentEditor/HtmlEditor";
import DeleteBtn from "./DeleteBtn";
import { UPDATE_EMAIL_TEMPLATE } from '../../constants/gqls/emailTemplates';
import { emailTemplatesFixed } from '../../constants/utils';

//******************************************************************************
// style definition
//******************************************************************************
const useStyles = makeStyles((theme) => ({
  root: {
    '& .MuiTextField-root': {
      marginBottom: theme.spacing(2)
    }
  },
  btns: {
    display: 'flex',
    '& button': {
      margin: 'auto'
    }
  }
}));

//******************************************************************************
// component definition
//******************************************************************************
export default function TemplateForm({template}) {
  const classes = useStyles();
  const [key, setKey] = React.useState(template.key);
  const [description, setDescription] = React.useState(template.description || '');
  const [subject, setSubject] = React.useState(template.subject || '');
  const [bcc, setBcc] = React.useState(template.bcc || '');
  const [variables, setVariables] = React.useState(template.variables? template.variables.join(', ') : "");
  const [html, setHtml] = React.useState(template.html);
  const [changed, setChanged] = React.useState(false);
  const [updateEmailTemplate, { loading }] = useMutation(UPDATE_EMAIL_TEMPLATE);
  const isFixed = emailTemplatesFixed.includes(template.key);

  //----------------------------------------------------------------------------
  // funtions
  //----------------------------------------------------------------------------
  const onKeyChange = (event) => {
    setKey(event.target.value);
    setChanged(true);
  }

  const onDescriptionChange = (event) => {
    setDescription(event.target.value);
    setChanged(true);
  }

  const onSubjectChange = (event) => {
    setSubject(event.target.value);
    setChanged(true);
  }

  const onBccChange = (event) => {
    setBcc(event.target.value);
    setChanged(true);
  }

  const onVariablesChange = (event) => {
    setVariables(event.target.value);
    setChanged(true);
  }

  const onHtmlChange = (value) => {
    if (value !== html) {
      setHtml(value);
      setChanged(true);
    }
  }

  const save = () => {
    if (changed) {
      updateEmailTemplate({ variables: {
        id: template._id,
        update: { key, description, subject, bcc, html,
          variables: variables.split(",").map(item=>item.trim())
        }
      }}).then(res => {
        // console.log(res);
        setChanged(false);
      });
    }
  }

  //----------------------------------------------------------------------------
  // component rendering
  //----------------------------------------------------------------------------
  return (
    <div className={classes.root}>
      { template.isSystem &&
        <TextField id="email-template-key" label="Template Key"
          variant="outlined" fullWidth disabled={loading || isFixed}
          value={key} onChange={onKeyChange} />
      }
      <TextField id="email-template-description" label="Template Description"
        variant="outlined" fullWidth disabled={loading}
        value={description} onChange={onDescriptionChange} />
      <TextField id="email-template-variables" label="Template Variables"
        variant="outlined" fullWidth disabled={loading || !template.isSystem}
        value={variables} onChange={onVariablesChange} />
      <TextField id="email-template-subject" label="Email Subject"
        variant="outlined" fullWidth disabled={loading}
        value={subject} onChange={onSubjectChange} />
      <TextField id="email-template-bcc" label="Bcc"
        variant="outlined" fullWidth disabled={loading}
        value={bcc} onChange={onBccChange} />
      <p>Email Body:</p>
      <HtmlEditor html={template.html} onChange={onHtmlChange} placeholder="Email Body" />
      <div className={classes.btns}>
        <Button variant="contained" startIcon={<SaveIcon />} onClick={save}>
          Save
        </Button>
        { !isFixed && template.isSystem &&
          <DeleteBtn templateId={template._id} />
        }
      </div>
    </div>
  );
}

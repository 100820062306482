import React from 'react';
// import { Button } from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import { useMutation } from "@apollo/client";
import { SPACE_MEMBERS, SPACE_IMPORT_UGC } from '../../constants/gqls/spaces';


//******************************************************************************
// component definition
//******************************************************************************
export default function ImportUGCBtn({spaceId}) {
  const [importUGC, { loading }] = useMutation(SPACE_IMPORT_UGC, {
    refetchQueries: [ SPACE_MEMBERS ],
  });

  //----------------------------------------------------------------------------
  // funtions
  //----------------------------------------------------------------------------
  const handleClick = () => {
    importUGC({ variables: { spaceId }})
  };

  //----------------------------------------------------------------------------
  // component rendering
  //----------------------------------------------------------------------------
  return (
    <LoadingButton variant="outlined" onClick={handleClick}
      loadingIndicator="Importing …" loading={loading}>
      Import UGC students
    </LoadingButton>
  );
}

import React from 'react';
import { Snackbar, Alert } from '@mui/material';
import { useSubscription } from "@apollo/client";
import { SUB_ADMIN_NOTICE } from './constants/gqls/user';

function AdminNotic() {
  const [msg, setMsg] = React.useState(null);
  const [severity, setSeverity] = React.useState("info");
  const [autoHide, setAutoHide] = React.useState(null);

  useSubscription(SUB_ADMIN_NOTICE, {
    // variables: { value: 3},
    onSubscriptionData: ({subscriptionData}) => {
      // console.log(subscriptionData.data);
      let notice = subscriptionData.data.adminNotice;
      if (notice.error) {
        setMsg(`${notice.message}: ${notice.error}`);
        setSeverity('warning');
        setAutoHide(null);
      } else if (!notice.total) {
        setMsg(`${notice.message}, please wait ...`);
        setSeverity('info');
        setAutoHide(null);
      } else if (notice.finished === notice.total) {
        setMsg(`${notice.message}, total: ${notice.total}`);
        setSeverity('success');
        setAutoHide(5000);
      } else {
        setMsg(`${notice.message}, please wait: ${notice.finished}/${notice.total}`);
      }
    }
  });

  //----------------------------------------------------------------------------
  // funtions
  //----------------------------------------------------------------------------
  const handleClose = () => {
    setMsg(null);
  }

  //----------------------------------------------------------------------------
  // component rendering
  //----------------------------------------------------------------------------
  return (
    <Snackbar open={Boolean(msg)} 
      autoHideDuration={autoHide} 
      onClose={handleClose}>
        <Alert elevation={6} variant="filled" sx={{ width: '100%' }}
          onClose={handleClose} 
          severity={severity} >
          {msg}
        </Alert>
    </Snackbar>
  );
}

export default AdminNotic;

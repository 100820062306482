import React from 'react';
// import clsx from 'clsx';
import { useSearchParams } from "react-router-dom";
import { ListItemButton, ListItemText } from '@mui/material';
import { makeStyles } from '@material-ui/core/styles';
// import FormSubFunctions from './FormSubFunctions';

//******************************************************************************
// style definition
//******************************************************************************
const useStyles = makeStyles((theme) => ({
  item: {
    '&.MuiListItemButton-root': {
      background: (props) => props.selected? 'linear-gradient(90deg, '+ theme.palette.secondary.light +' 0%, rgba(255,255,255,0) 100%)' : 'unset'
    },
  },
  service: {
    color: theme.palette.primary.light,
    // '&.css-i4bv87-MuiSvgIcon-root': {
    //   position: 'absolute',
    //   color: theme.palette.primary.light,
    //   fontSize: '3rem'
    // }
  }
}));

//******************************************************************************
// component definition
//******************************************************************************
export default function FormProfileItem({profile, disabled}) {
  const [searchParams, setSearchParams] = useSearchParams();
  // const [anchorEl, setAnchorEl] = React.useState(null);
  const [selected, setSelected] = React.useState(false);
  const classes = useStyles({selected});
  // console.log(submission);

  React.useEffect(() => {
    if (searchParams.get("profile") === profile._id) {
      setSelected(true);
    } else {
      setSelected(false);
    }
  }, [searchParams, profile]);

  //----------------------------------------------------------------------------
  // funtions
  //----------------------------------------------------------------------------
  const handleClick = () => {
    if (!selected) {
      setSearchParams({ profile: profile._id });
    }
  }

  // const handleEdit = () => {
  //   setSearchParams({
  //     profile: profile._id,
  //     action: 'editData'
  //   });
  // }

  const onRightClick = (event) => {
    // event.preventDefault();
    // setAnchorEl(event.currentTarget);
  }

  // const handleClose = () => {
  //   setAnchorEl(null);
  // }

  //----------------------------------------------------------------------------
  // component rendering
  //----------------------------------------------------------------------------
  return (
    <ListItemButton className={classes.item} disabled={disabled}
      onClick={handleClick} onContextMenu={onRightClick}>
      <ListItemText primary={profile.name} />
    </ListItemButton>
  );
}
